import React, { Component } from "react";
import { connect } from "react-redux";

import OrdersFilter from "./Orders/OrdersFilter";
import EstimateLine from "./Estimates/EstimateLine";
import { commonHelpers, customersHelpers, estimatesHelpers } from "../helpers";
import { uiConstants } from "../constants";

class Orders extends Component {
  filterList(list, status) {
    const { ordersFilters } = this.props.user.profile;
    return estimatesHelpers.filterItems(list, status, ordersFilters);
  }

  exportToCSV() {
    const { list } = this.props.estimates;
    const excel = [];
    list.forEach(el => {
      const lv = el.v[el.v.length - 1];
      excel.push({
        numero: el.number,
        cliente: customersHelpers.getNameByUid(lv.info.customer),
        titolo: lv.info.title,
        tipo: lv.billing.billingPlan,
        importo_preventivato: lv.configurator.totalCustom,
        data_preventivo: commonHelpers.timeToDate(el.v[0].versionDate),
        data_invio: commonHelpers.timeToDate(
          estimatesHelpers.getLastStatusDate(el.v, "waiting")
        ),
        data_comferma: commonHelpers.timeToDate(
          estimatesHelpers.getLastStatusDate(el.v, "confirmed")
        ),
        data_annullamento: commonHelpers.timeToDate(
          estimatesHelpers.getLastStatusDate(el.v, "refused")
        ),
        ultimo_stato: lv.versionStatus,
        importo_fatturato:
          'é la somma delle righe di fatturazione con stato "INVIATA"'
      });
    });
    // console.log(excel)
    commonHelpers.exportExcel(excel, "ordini");
  }

  render() {
    const { list, subtotal } = this.props.estimates;
    const { ordersFilters } = this.props.user.profile;
    return (
      <>
        <h1>Ordini</h1>
        <button className="lnk-new" onClick={() => this.exportToCSV()}>
          <i className="fas fa-file-download"></i> esporta
        </button>
        <OrdersFilter subtotal={subtotal} />
        {uiConstants.ordersFilters.map((s, index) => {
          return (
            ordersFilters[s.value] && (
              <div key={index}>
                <div className={"title-section " + s.value}>{s.label}</div>
                {this.filterList(list, s.value).map((e, index2) => {
                  return <EstimateLine key={index2} e={e} />;
                })}
              </div>
            )
          );
        })}
      </>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps, {})(Orders);
