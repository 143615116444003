import { economicsRef } from '../database'
import { economicsConstants } from '../constants'
import jsreport from 'jsreport-browser-client-dist'

export let economicsSnap

export function loadEconomics(user) {
  return (dispatch) => {
    dispatch(loadingEconomics())
    economicsSnap = economicsRef.onSnapshot(
      (result) => {
        result.docChanges().forEach((change) => {
          const data = change.doc.data()
          const id = change.doc.id
          if (change.type === 'added') {
            dispatch(updateEconomic({ id, ...data }))
          }
          if (change.type === 'modified') {
            dispatch(updateEconomic({ id, ...data }))
          }
          if (change.type === 'removed') {
            dispatch(removeEconomic({ id, ...data }))
          }
        })
      },
      (error) => {
        console.log(error)
        dispatch(loadingEconomicsError('Error: ' + error))
      }
    )
  }
}

function loadingEconomics() {
  return {
    type: economicsConstants.LOADING_ECONOMICS
  }
}

function loadingEconomicsError(payload) {
  return {
    type: economicsConstants.LOADING_ECONOMICS_ERROR,
    payload
  }
}

function updateEconomic(payload) {
  return {
    type: economicsConstants.UPDATE_ECONOMIC,
    payload
  }
}

function removeEconomic(payload) {
  return {
    type: economicsConstants.REMOVE_ECONOMIC,
    payload
  }
}

export function loadReportEconomics(payload) {
  return (dispatch) => {
    dispatch(ajaxCall())

    jsreport.serverUrl = payload.url
    jsreport.headers.Authorization = 'Basic ' + payload.auth

    const reportRequest = {
      template: {
        shortid: payload.shortId,
        recipe: 'html-to-xlsx',
        engine: 'handlebars'
      },
      options: {
        preview: payload.preview,
        reportName: payload.title,
        'Content-Type': payload.type
      }
    }

    jsreport
      .renderAsync(reportRequest)
      // jsreport.render(reportRequest)
      .then(function (res) {
        if (payload.preview === false) {
          var file = payload.title + '.xlsx'
          res.download(file)
        } else {
          window.open(res.toObjectURL())
          URL.revokeObjectURL(res)
        }
        dispatch(ajaxCallSuccess())
      })
      .catch((err) => {
        console.log(err)
        dispatch(ajaxCallFailure())
      })
  }
}

export function loadReportProject(payload, params) {
  return (dispatch) => {
    dispatch(ajaxCall())

    jsreport.serverUrl = payload.url
    jsreport.headers.Authorization = 'Basic ' + payload.auth

    const reportRequest = {
      template: {
        shortid: payload.shortId,
        recipe: 'html-to-xlsx',
        engine: 'handlebars'
      },
      data: { ...params },
      options: {
        preview: payload.preview,
        reportName: payload.title,
        'Content-Type': payload.type
      }
    }

    jsreport
      .renderAsync(reportRequest)
      // jsreport.render(reportRequest)
      .then(function (res) {
        if (payload.preview === false) {
          var file = payload.title + '.xlsx'
          res.download(file)
        } else {
          window.open(res.toObjectURL())
          URL.revokeObjectURL(res)
        }
        dispatch(ajaxCallSuccess())
      })
      .catch((err) => {
        console.log(err)
        dispatch(ajaxCallFailure())
      })
  }
}

function ajaxCall() {
  return {
    type: economicsConstants.ECONOMICS_AJAX_CALL
  }
}

function ajaxCallSuccess() {
  return {
    type: economicsConstants.ECONOMICS_AJAX_SUCCESS
  }
}

function ajaxCallFailure(payload) {
  return {
    type: economicsConstants.ECONOMICS_AJAX_FAILURE,
    payload
  }
}
