import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import firebase from '../firestore'

import {
  userLogout,
  userLoad,
  usersLoad,
  loadColleagues,
  loadEstimates,
  loadCustomers,
  loadCompanies,
  loadInvoices,
  loadTasks,
  loadProjects,
  loadSprints,
  loadExecutions,
  loadEconomics,
  loadProviders,
  loadRoles,
  loadAllEstimates
} from '../actions'

import Spinner from './Spinner'
import { timeHelper } from '../helpers'

const initialState = {
  redirect: false
}

class CheckUser extends Component {
  constructor() {
    super()
    this.state = initialState
  }

  logout() {
    this.props.userLogout()
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((result) => {
      if (result) {
        if (this.props.user.profile === null) {
          this.props.userLoad(result)
          this.props.usersLoad()
          if (this.props.colleagues.list === null) {
            this.props.loadColleagues(result)
          }
          if (this.props.customers.list === null) {
            this.props.loadCustomers()
          }
          if (this.props.providers.list === null) {
            this.props.loadProviders()
          }
          if (this.props.companies.list === null) {
            this.props.loadCompanies()
          }
          if (this.props.economics.list.length === 0) {
            this.props.loadEconomics()
          }
          if (this.props.roles.list === null) {
            this.props.loadRoles()
          }
        }
      } else {
        this.setState({ redirect: true })
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.props.user.profile || {}).currentYear !==
      (prevProps.user.profile || {}).currentYear
    ) {
      const year = this.props.user.profile.currentYear
      const startYear = timeHelper.startOfYear(year)
      const endYear = timeHelper.endOfYear(year)

      this.props.loadEstimates(startYear, endYear)
      this.props.loadInvoices(year)
      this.props.loadProjects(startYear, endYear)
      this.props.loadTasks(startYear, endYear)
      this.props.loadSprints(startYear, endYear)
      this.props.loadExecutions(this.props.user.profile.uid, startYear, endYear)
      this.props.loadAllEstimates()
    }
  }

  render() {
    const me = this.props.user
    return (
      <div>
        {me.profile === null && me.loading && <Spinner />}
        {this.state.redirect && <Redirect to="/" />}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {
  userLoad,
  usersLoad,
  userLogout,
  loadColleagues,
  loadEstimates,
  loadCustomers,
  loadCompanies,
  loadInvoices,
  loadTasks,
  loadProjects,
  loadSprints,
  loadExecutions,
  loadEconomics,
  loadProviders,
  loadRoles,
  loadAllEstimates
})(CheckUser)
