export const uiConstants = {
  estimatesFilters: [
    {
      label: 'in redazione',
      value: 'draft'
    },
    {
      label: 'in attesa',
      value: 'waiting'
    },
    {
      label: 'rifiutato',
      value: 'refused'
    },
    {
      label: 'confermato',
      value: 'confirmed'
    },
    {
      label: 'fatturato',
      value: 'invoiced'
    }
  ],
  ordersFilters: [
    {
      label: 'in redazione',
      value: 'draft'
    },
    {
      label: 'in attesa',
      value: 'waiting'
    },
    {
      label: 'rifiutato',
      value: 'refused'
    },
    {
      label: 'confermato',
      value: 'confirmed'
    },
    {
      label: 'fatturato',
      value: 'invoiced'
    }
  ],
  estimateStatuses: {
    draft: 'draft',
    waiting: 'waiting',
    refused: 'refused',
    confirmed: 'confirmed',
    invoiced: 'invoiced'
  },
  toBeInvoicedFilters: [
    {
      label: 'progetto',
      value: 'progetto'
    },
    {
      label: 'canone',
      value: 'canone'
    },
    {
      label: 'consumo',
      value: 'consumo'
    }
  ],
  days: ['lun', 'mar', 'mer', 'gio', 'ven', 'sab', 'dom'],
  timeToDateInput: 'YYYY-MM-DD',
  economics: {
    view: 'text/html',
    download:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  }
}
