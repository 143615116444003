import React, { Component } from 'react'
import Dialog from '../../Dialog/Dialog'

import moment from 'moment'
import _ from 'lodash'

import { commonHelpers } from '../../../helpers'

import { addProjectForm } from '../../../constants/project.constants'

class ProjectDialog extends Component {
  constructor(props) {
    super(props)

    //creating structure of component state
    let formData = {}

    Object.keys(addProjectForm).map(
      (info) => (formData[info] = addProjectForm[info].default)
    )

    this.state = {
      ...formData
    }

    console.log('### state of projects')
    console.log(this.state)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState, this.state) && this.state.firstValidation) {
      this.setState(commonHelpers.isFormNotValid(addProjectForm, this.state))
    }
  }

  editState = (key, value) => this.setState({ [key]: value })

  submit = () => {
    this.setState(commonHelpers.isFormNotValid(addProjectForm, this.state))

    if (
      !commonHelpers.isFormNotValid(addProjectForm, this.state).validationError
    ) {
      let payload = {
        creation_date: moment().unix(),
        start_date: moment(this.state.start_date).unix(),
        deadline: moment(this.state.deadline).unix(),
        description: this.state.description,
        edit_date: moment().unix(),
        estimate_id: '',
        name: this.state.name,
        providers: [],
        tasks: [],
        archived: false,
        archivedNotes: [],
        managers: []
      }
      this.props.saveProject(null, payload)
      this.props.toggleProjectModal()
    } else {
      return console.log('Compilare tutti i campi prima di salvare')
    }
  }

  render() {
    return (
      <Dialog
        formInputs={addProjectForm}
        title={'Aggiungi progetto'}
        columns={3}
        submittedForm={this.submit}
        buttons={[
          {
            label: 'Annulla',
            func: this.props.toggleProjectModal,
            submit: ''
          },
          {
            label: 'Aggiungi',
            func: this.submit,
            submit: true
          }
        ]}
        close={this.props.close}
        editState={this.editState}
        values={this.state}
        validationError={this.state.validationError}
      ></Dialog>
    )
  }
}

export default ProjectDialog
