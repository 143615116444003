import { invoicesConstants } from '../constants'

const initialState = {
  list: null,
  error: null,
  loading: false,
  ajaxSpinner: false,
  ajaxStatus: null
}

export default function invoices(state = initialState, action) {
  switch (action.type) {
    case invoicesConstants.LOADING_INVOICES:
      return { ...state, loading: true, error: null }
    case invoicesConstants.LOADING_INVOICES_ERROR:
      return { ...state, loading: false, error: action.payload }
    case invoicesConstants.UPDATE_INVOICE:
      return {
        ...state,
        list:
          state.list === null
            ? [action.payload]
            : state.list.findIndex((x) => x.id === action.payload.id) === -1
            ? state.list.concat(action.payload)
            : state.list.map((item) => {
                return item.id === action.payload.id ? action.payload : item
              }),
        loading: false
      }
    case invoicesConstants.REMOVE_INVOICE:
      return {
        ...state,
        list: state.list.filter((item) => {
          return item.id !== action.payload.id
        }),
        loading: false
      }
    case invoicesConstants.INVOICE_AJAX_CALL:
      return { ...state, ajaxSpinner: true, ajaxStatus: null }
    case invoicesConstants.INVOICE_AJAX_SUCCESS:
      return { ...state, ajaxSpinner: false, ajaxStatus: null }
    case invoicesConstants.INVOICE_AJAX_FAILURE:
      return { ...state, ajaxSpinner: false, ajaxStatus: action.payload }
    case invoicesConstants.INVOICE_RESET:
      return { ...state, list: null }
    default:
      return state
  }
}
