export const estimatesConstants = {
  UPDATE_ESTIMATE: '[Estimates] Update',
  REMOVE_ESTIMATE: '[Estimates] Remove',
  LOADING_ESTIMATES: '[Estimates] Loading',
  LOADING_ESTIMATES_ERROR: '[Estimates] Loading error',
  ESTIMATE_AJAX_CALL: '[Estimates] Ajax call',
  ESTIMATE_AJAX_SUCCESS: '[Estimates] Ajax success',
  ESTIMATE_AJAX_FAILURE: '[Estimates] Ajax error',
  ESTIMATE_RESET: '[Estimate] Reset list'
}
