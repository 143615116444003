import React, { Component } from 'react'
import _ from 'lodash'
import moment from 'moment'

import Dialog from '../../Dialog/Dialog'

import { commonHelpers } from '../../../helpers'

import { dialogForm } from '../../../constants/tasks.constants'

let touchedDialogForm = { ...dialogForm }
const originalDialogForm = { ...dialogForm }

class TaskDialog extends Component {
  constructor(props) {
    super(props)

    //Adding parent task for select's options constant
    let tasksArray = this.props.tasks.map((t) => {
      return { label: t.name, value: t.id }
    })

    if (this.props.onEditTask) {
      tasksArray = _.filter(
        tasksArray,
        (t) => t.value !== this.props.onEditTask.id
      )
    }

    // estimated_time
    // priority

    if (this.props.onEditTask && this.props.onEditTask.subtasks.length > 0) {
      delete touchedDialogForm.estimated_time
      delete touchedDialogForm.priority
      delete touchedDialogForm.parentTask
    } else {
      touchedDialogForm = { ...originalDialogForm }
    }

    this.formInputs = {
      ...touchedDialogForm
    }
    if (touchedDialogForm.parentTask) {
      this.formInputs.parentTask = {
        ...touchedDialogForm.parentTask,
        options: [touchedDialogForm.parentTask.options[0], ...tasksArray]
      }
    }

    //creating structure of component state
    let formData = {}

    if (!this.props.onEditTask) {
      Object.keys(touchedDialogForm).map(
        (info) => (formData[info] = touchedDialogForm[info].default)
      )
    } else {
      formData = {
        ...this.props.onEditTask,
        parentTask: this.props.onEditTaskParent?.id || 1
      }
    }

    this.state = {
      ...formData
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState, this.state) && this.state.firstValidation) {
      this.setState(commonHelpers.isFormNotValid(touchedDialogForm, this.state))
    }
  }

  editState = (key, value) => this.setState({ [key]: value })

  submit = () => {
    this.setState(commonHelpers.isFormNotValid(touchedDialogForm, this.state))

    if (
      !commonHelpers.isFormNotValid(touchedDialogForm, this.state)
        .validationError
    ) {
      let payload = {
        name: this.state.name,
        description: this.state.description,
        priority: parseInt(this.state.priority),
        estimated_time: parseInt(this.state.estimated_time),
        creation_date: this.state.creation_date || moment().unix(),
        edit_date: moment().unix(),
        spent_time: this.state.spent_time || 0,
        resources: this.state.resources || [],
        sprint_id: this.state.sprint_id || '',
        status: 0,
        subtasks: this.state.subtasks || [],
        project_id: this.props.projectId
      }

      this.props.saveTask(
        this.props.onEditTask?.id || null,
        this.state.parentTask === 1 ? null : this.state.parentTask,
        this.props.onEditTaskParent?.id || null,
        this.props.projectId,
        payload
      )

      this.props.toggleTaskModal()
      // console.log(this.state)
    } else {
      return null // console.log('Compilare tutti i campi prima di salvare')
    }
  }

  render() {
    return (
      <Dialog
        formInputs={this.formInputs}
        title={this.props.onEditTask ? 'Modifica task' : 'Aggiungi Task'}
        columns={4}
        submittedForm={this.submit}
        buttons={[
          {
            label: 'Annulla',
            func: this.props.toggleTaskModal,
            submit: ''
          },
          {
            label: this.props.onEditTask ? 'Salva' : 'Aggiungi',
            func: this.submit,
            submit: true
          }
        ]}
        close={this.props.close}
        editState={this.editState}
        values={this.state}
        validationError={this.state.validationError}
      ></Dialog>
    )
  }
}

export default TaskDialog
