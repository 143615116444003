import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import {
  saveTaskRemediation,
  saveProject,
  saveTaskExecutionRemediation,
  deleteExecution
} from '../actions'

const Remediation = (props) => {
  const bonificaTashProjectId = () => {
    console.log(
      props.tasks.list.filter((x) => x.project_id === undefined).length
    )
    // NOTE: TASKS
    props.tasks.list.forEach((t) => {
      let p = props.projects.list.find((x) => x.tasks.indexOf(t.id) >= 0)
      if (p !== undefined && !t.project_id) {
        const nt = {
          ...t,
          project_id: p.id
        }
        delete nt.id
        props.saveTaskRemediation(t.id, nt)
      }
    })
    // NOTE: SUBTASKS
    props.tasks.list
      .filter((x) => x.project_id === undefined)
      .forEach((t) => {
        let p = props.tasks.list.find((x) => x.subtasks.indexOf(t.id) >= 0)
        if (p !== undefined && !t.project_id && p.project_id !== undefined) {
          const nt = {
            ...t,
            project_id: p.project_id
          }
          delete nt.id
          props.saveTaskRemediation(t.id, nt)
        }
      })
  }

  const setNotArchivedProject = () => {
    props.projects.list.forEach((p) => {
      const np = { ...p }
      np.archived = false
      np.managers = []
      delete np.id
      console.log(np)
      props.saveProject(p.id, np)
    })
  }

  const remediationTasks = () => {
    console.log(props.tasks.list)
    props.tasks.list.forEach((t) => {
      const task = { ...t }
      const res = [...task.resources]
      let index = 0
      res.forEach((r) => {
        let user = props.colleagues.list.find((u) => u.uid === r)
        if (!user) {
          user = props.colleagues.list.find((u) => u.id === r)
          if (!user) {
            if (r === props.user.profile.id) user = props.user.profile
            if (user) {
              res[index] = user.uid
            }
          } else {
            res[index] = user.uid
          }
        }
        index++
      })
      task.resources = res
      if (JSON.stringify(t) !== JSON.stringify(task)) {
        console.log(t, task)
        delete task.id
        props.saveTaskRemediation(t.id, task)
      }
    })
    // console.log(props.tasks.list.filter((x) => !x.project_id).length)
  }

  const fixTimeTaskExecution = () => {
    ;(props.executions.list || []).forEach((e) => {
      const ne = { ...e }
      // ne.creation_date = moment
      //   .unix(e.creation_date)
      //   .startOf('day')
      //   .utc()
      //   .unix()
      // ne.creation_date = moment
      //   .utc(moment(ne.creation_date).format('YYYY-MM-DD'))
      //   .startOf('day')
      //   .unix()
      // ne.edit_date = moment.unix(ne.edit_date).startOf('day').utc().unix()
      // ne.end_date = moment.unix(e.end_date).startOf('day').utc().unix()
      // ne.end_date = moment
      //   .utc(moment.unix(e.end_date).format('YYYY-MM-DD'))
      //   .add(12, 'hour')
      //   .startOf('day')
      //   .unix()
      // ne.start_date = moment.unix(e.start_date).startOf('day').utc().unix()
      // ne.start_date = moment
      //   .utc(moment.unix(ne.start_date).format('YYYY-MM-DD'))
      //   .startOf('day')
      //   .unix()
      // ne.start_date = moment.unix(e.start_date).startOf('day').utc().unix()
      console.log(moment().utcOffset())
      ne.start_date = moment
        .unix(e.start_date)
        .add(4, 'hour')
        .utc()
        .startOf('day')
        .unix()
      ne.end_date = ne.start_date + (e.end_date - e.start_date)
      let user = props.colleagues.list.find((x) => x.id === e.user_id)
      if (!user) {
        user = props.user.profile
      }
      ne.user_uid = user.uid
      ne.spent_seconds = e.end_date - e.start_date
      delete ne.id
      delete ne.edit_date

      // console.log(moment.unix(e.start_date).format('YYYY-MM-DD HH:mm:ss'))
      // console.log(moment.unix(e.end_date).format('YYYY-MM-DD HH:mm:ss'))
      // console.log(moment.unix(ne.start_date).format('YYYY-MM-DD HH:mm:ss'))
      // console.log(moment.unix(ne.end_date).format('YYYY-MM-DD HH:mm:ss'))
      // console.log(ne)
      // props.deleteExecution(e.id)
      props.saveTaskExecutionRemediation(e.id, ne)
    })
  }

  return (
    <div>
      <h1>Bonifica</h1>
      <button className="lnk-utils" onClick={bonificaTashProjectId}>
        bonifica tasks con project_id
      </button>
      <br />
      <button className="lnk-utils" onClick={setNotArchivedProject}>
        for every project set archived=false
      </button>
      <br />
      <button className="lnk-utils" onClick={remediationTasks}>
        count taks
      </button>
      <br />
      <button className="lnk-utils" onClick={fixTimeTaskExecution}>
        fix time task execution
      </button>
    </div>
  )
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {
  saveTaskRemediation,
  saveProject,
  saveTaskExecutionRemediation,
  deleteExecution
})(Remediation)
