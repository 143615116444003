import { invoicesConstants } from '../constants'
import { invoicesRef } from '../database'
// import { toast } from 'react-toastify'
import { notify } from 'react-notify-toast'

export let invoicesSnap

export function loadInvoices(year) {
  return (dispatch) => {
    dispatch(loadingInvoices())
    if (invoicesSnap) {
      invoicesSnap()
      dispatch(resetInvoiceList())
    }
    invoicesSnap = invoicesRef
      .where('deleted', '==', false)
      .where('year', '>=', year)
      .onSnapshot(
        (result) => {
          result.docChanges().forEach((change) => {
            const data = change.doc.data()
            const id = change.doc.id
            if (change.type === 'added') {
              dispatch(updateInvoice({ id, ...data }))
            }
            if (change.type === 'modified') {
              dispatch(updateInvoice({ id, ...data }))
            }
            if (change.type === 'removed') {
              dispatch(removeInvoice({ id, ...data }))
            }
          })
        },
        (error) => {
          console.log(error)
          dispatch(loadingInvoicesError('Error: ' + error))
        }
      )
  }
}

function loadingInvoices() {
  return {
    type: invoicesConstants.LOADING_INVOICES
  }
}

function loadingInvoicesError(payload) {
  return {
    type: invoicesConstants.LOADING_INVOICES_ERROR,
    payload
  }
}

function updateInvoice(payload) {
  return {
    type: invoicesConstants.UPDATE_INVOICE,
    payload
  }
}

function removeInvoice(payload) {
  return {
    type: invoicesConstants.REMOVE_INVOICE,
    payload
  }
}

export function createInvoice(payload) {
  return (dispatch) => {
    dispatch(ajaxCall())
    invoicesRef
      .add(payload)
      .then((res) => {
        dispatch(ajaxCallSuccess())
      })
      .catch((error) => {
        if (error.response !== undefined) {
          dispatch(ajaxCallFailure(error.response.statusText))
        } else {
          dispatch(ajaxCallFailure(error.message))
        }
      })
  }
}

function ajaxCall() {
  return {
    type: invoicesConstants.INVOICE_AJAX_CALL
  }
}

function ajaxCallSuccess() {
  return {
    type: invoicesConstants.INVOICE_AJAX_SUCCESS
  }
}

function ajaxCallFailure(payload) {
  return {
    type: invoicesConstants.INVOICE_AJAX_FAILURE,
    payload
  }
}

export function saveInvoice(id, payload, showNotify = true) {
  return (dispatch) => {
    if (showNotify) {
      dispatch(ajaxCall())
    }
    invoicesRef
      .doc(id)
      .update({ ...payload })
      .then(() => {
        dispatch(ajaxCallSuccess())
        if (showNotify) {
          notify.show('salvato con successo', 'success', 3000)
        }
      })
      .catch((error) => {
        if (error.response !== undefined) {
          dispatch(ajaxCallFailure(error.response.statusText))
        } else {
          dispatch(ajaxCallFailure(error.message))
        }
      })
  }
}

export function deleteInvoice(id) {
  return (dispatch) => {
    dispatch(ajaxCall())
    invoicesRef
      .doc(id)
      .update({ deleted: true })
      .then(() => {
        dispatch(ajaxCallSuccess())
        notify.show('eliminato con successo', 'success', 3000)
      })
      .catch((error) => {
        if (error.response !== undefined) {
          dispatch(ajaxCallFailure(error.response.statusText))
        } else {
          dispatch(ajaxCallFailure(error.message))
        }
      })
  }
}

export function deleteInvoiceByGuid(guid) {
  return (dispatch) => {
    dispatch(ajaxCall())
    invoicesRef
      .where('guid', '==', guid)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.docs.length > 0) {
          querySnapshot.forEach((doc) => {
            doc.ref.delete()
            dispatch(ajaxCallSuccess())
            notify.show('eliminato con successo', 'success', 3000)
          })
        } else {
          dispatch(
            ajaxCallFailure(
              'Impossibile trovare un documento con questo guid ' + guid
            )
          )
        }
      })
    // invoicesRef.doc(id).update({ deleted: true }).then(() => {
    //   dispatch(ajaxCallSuccess())
    //   toast.success('eliminato con successo')
    // }).catch(error => {
    //   if (error.response !== undefined) { dispatch(ajaxCallFailure(error.response.statusText)) } else { dispatch(ajaxCallFailure(error.message)) }
    // })
  }
}

function resetInvoiceList() {
  return {
    type: invoicesConstants.INVOICE_RESET
  }
}
