import React, { Component } from 'react'
import { connect } from 'react-redux'

import { animalConstants } from '../../constants'
import { setAvatar } from '../../actions'

const images = require.context('../../assets/animals', true)

class ChangeAvatar extends Component {
  render() {
    return (
      <>
        <ul className="animals-list">
          {animalConstants.map((a, index) => {
            return (
              <li key={index}>
                <img
                  src={images(`./${a}`)}
                  alt={a}
                  onClick={() => this.props.setAvatar(a)}
                />
              </li>
            )
          })}
        </ul>
      </>
    )
  }
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, { setAvatar })(ChangeAvatar)
