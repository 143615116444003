import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'

import { ENV } from './environment'

/* GIRINO STAGING */
let config = {
  apiKey: 'AIzaSyC_YlZiRB_cgoejdFZBr46DnJdo7iMrX4w',
  authDomain: 'projectbox-3bdab.firebaseapp.com',
  databaseURL: 'https://projectbox-3bdab.firebaseio.com',
  projectId: 'projectbox-3bdab',
  storageBucket: 'projectbox-3bdab.appspot.com',
  messagingSenderId: '777063981949',
  appId: '1:777063981949:web:43033960b454ba4f'
}

if (ENV.production) {
  const domain = window.location.hostname.split('.')[0]
  switch (domain) {
    case 'fantoni':
      config = {
        apiKey: 'AIzaSyB0u3vHsFG7pNaQzeObAnj5VlmRiVcpua4',
        authDomain: 'girino-fantoni-179fa.firebaseapp.com',
        projectId: 'girino-fantoni-179fa',
        storageBucket: 'girino-fantoni-179fa.appspot.com',
        messagingSenderId: '618901920976',
        appId: '1:618901920976:web:c4b97388114b65350552d5',
        measurementId: 'G-7BKFVRB6L4'
      }
      break
    case '8one':
      config = {
        apiKey: 'AIzaSyDapB95oNbKTeQSL6H61_hBxS_M9_ZWqqo',
        authDomain: 'girino-8one.firebaseapp.com',
        projectId: 'girino-8one',
        storageBucket: 'girino-8one.appspot.com',
        messagingSenderId: '162172135564',
        appId: '1:162172135564:web:9f2987922045bcb6e3b3a9',
        measurementId: 'G-PE2PYN2DT7'
      }
      break
    case 'mas':
      config = {
        apiKey: 'AIzaSyDn_kdZV9249R49V6X_MrN1oMmOgfwyTeY',
        authDomain: 'girino-mas.firebaseapp.com',
        projectId: 'girino-mas',
        storageBucket: 'girino-mas.appspot.com',
        messagingSenderId: '742710220485',
        appId: '1:742710220485:web:1d97a81bfd284d24b7c2ac',
        measurementId: 'G-M056PLB4QQ'
      }
      break
    default:
      // FF3D
      config = {
        apiKey: 'AIzaSyDe0jhWEzc3efGWX3oLl1bsUwup0pfqCRo',
        authDomain: 'girino-5ebcf.firebaseapp.com',
        databaseURL: 'https://girino-5ebcf.firebaseio.com',
        projectId: 'girino-5ebcf',
        storageBucket: 'girino-5ebcf.appspot.com',
        messagingSenderId: '446900146670',
        appId: '1:446900146670:web:0197f24ef97a50bd'
      }
      break
  }
}

firebase.initializeApp(config)

// const domain = window.location.hostname.split('.')[0]
// let tenant = ''
// switch (domain) {
//   case 'fantoni':
//     tenant = 'fantoni-0e4hd'
//     break
//   default:
//     tenant = 'localhost-zfm9h'
// }
// console.log(tenant)
// firebase.auth().tenantId = tenant

export default firebase
