import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Dropdown from '../Dropdown/Dropdown'
import Chips from '../UI/Chips/Chips'

class TaskLineEditor extends Component {
  render() {
    const t = this.props.t

    let st = t.spent_time
    let et = t.estimated_time
    let pr = t.priority
    if (t.subtasks.length > 0) {
      st = 0
      et = 0
      pr = 0
      t.subtasks.forEach((s) => {
        st += s.spent_time
        et += s.estimated_time
        pr += s.priority
      })
      pr = Math.round(pr / t.subtasks.length)
    }

    const p = parseInt((st * 100) / et, 10)
    let hourglass = 'fas fa-hourglass-half'
    if (p < 30) {
      hourglass = 'fas fa-hourglass-start'
    } else if (p > 70) {
      hourglass = 'fas fa-hourglass-end'
    }

    let sprint = null
    if (t.sprint_id !== '') {
      const s = (this.props.sprints.list || []).filter(
        (s) => s.id === t.sprint_id
      )
      if (s.length > 0) {
        sprint = (
          <Link to={`/projects/${this.props.project.id}/sprints/${s[0].id}`}>
            {s[0].name}
          </Link>
        )
      }
    }
    return (
      <div className={'prj-task' + (this.props.isSubtask ? ' sub' : '')}>
        <span className="td">
          <span className="title">{t.name}</span>
          <span className="description">{t.description}</span>
        </span>
        <span className="resources">
          {t.subtasks.length === 0 && (
            <>
              <button
                className="btn-add-resource"
                onClick={() => {
                  this.props.addResource(t.id, t.resources)
                }}
              >
                <i className="fas fa-plus"></i>
              </button>
              {t.resources.map((m) => (
                <Chips
                  user={m}
                  key={m}
                  delete={() => this.props.deleteElement(t.id, m)}
                />
              ))}
            </>
          )}
        </span>
        <span className={`priority pr${pr}`}>
          <i className="fas fa-circle"></i>
        </span>
        <span className="time">
          {Math.round(st)} / {et}
          <i className={hourglass}></i>
        </span>
        <span className="progress">
          <p>
            <b style={{ width: p + '%' }}></b>
          </p>
        </span>
        <span className="sprint">{sprint}</span>
        <span className="dropdown">
          <i className="fas fa-ellipsis-v"></i>
          <Dropdown
            listItems={[
              {
                text: 'Modifica task',
                click: () => {
                  this.props.editTask(t.id)
                }
              },
              {
                text: 'Elimina task',
                click: () => this.props.deleteElement(t.id)
              },
              {
                text: 'Modifica Sprint',
                click: () => {
                  this.props.editSprint(t.sprint_id)
                }
              }
            ].concat(
              t.subtasks.length === 0
                ? {
                    text: 'Aggiungi tempo',
                    click: () => {
                      this.props.toggleSetTimeModal(t.id)
                    }
                  }
                : null
            )}
          />
        </span>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {})(TaskLineEditor)
