import { store } from '../store'

export const customersHelpers = {
  getNameByUid,
  getList
}

function getNameByUid(id) {
  const s = store.getState()
  const list = s.customers.list
  if (list === null) { return null }
  const e = list.filter(x => x.id === id)
  if (e.length > 0) {
    return e[0].name
  }
  return id
}

function getList() {
  return store.getState().customers.list;
}
