import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import Chips from '../UI/Chips/Chips'

class ProjectManagers extends Component {
  state = {
    dropdown: ''
  }
  changeDropDown = (uid) => {
    this.setState({ dropdown: '' }, () => {
      this.props.addManager(uid)
    })
  }
  render() {
    let colleagues = (this.props.colleagues.list || []).filter(
      (u) => (this.props.managers || []).indexOf(u.uid) < 0
    )
    if ((this.props.managers || []).indexOf(this.props.user.profile.uid) < 0) {
      colleagues = colleagues.concat(this.props.user.profile)
    }
    colleagues = _.sortBy(colleagues, ['displayName'], ['asc'])
    return (
      <>
        <h2>Responsabili progetto</h2>
        <select
          value={this.state.dropdown}
          onChange={(e) => this.changeDropDown(e.target.value)}
        >
          <option value="">[Aggiungi Responsabile]</option>
          {colleagues.map((c) => {
            return (
              <option key={c.uid} value={c.uid}>
                {c.displayName}
              </option>
            )
          })}
        </select>
        <div className="chips-inline-c">
          {(this.props.managers || []).map((m) => (
            <Chips user={m} key={m} delete={this.props.deleteManager} />
          ))}
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {})(ProjectManagers)
