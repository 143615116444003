import { estimatesAllConstants } from '../constants'

const initialState = {
    list: null,
    error: null,
    loading: false,
    ajaxSpinner: false,
    ajaxStatus: null
}

export default function estimatesAll(state = initialState, action) {
    switch (action.type) {
        case estimatesAllConstants.LOADING_ESTIMATES:
            return { ...state, loading: true, error: null }
        case estimatesAllConstants.LOADING_ESTIMATES_ERROR:
            return { ...state, loading: false, error: action.payload }
        case estimatesAllConstants.UPDATE_ESTIMATE:
            return {
                ...state,
                list:
                    state.list === null
                        ? [action.payload]
                        : state.list.findIndex((x) => x.id === action.payload.id) === -1
                            ? state.list.concat(action.payload)
                            : state.list.map((item) => {
                                return item.id === action.payload.id ? action.payload : item
                            }),
                loading: false
            }
        case estimatesAllConstants.REMOVE_ESTIMATE:
            return {
                ...state,
                list: state.list.filter((item) => {
                    return item.id !== action.payload.id
                }),
                loading: false
            }
        case estimatesAllConstants.PROJECTS_AJAX_CALL:
            return { ...state, ajaxSpinner: true, ajaxStatus: null }
        case estimatesAllConstants.PROJECTS_AJAX_SUCCESS:
            return { ...state, ajaxSpinner: false, ajaxStatus: null }
        case estimatesAllConstants.PROJECTS_AJAX_FAILURE:
            return { ...state, ajaxSpinner: false, ajaxStatus: action.payload }
        case estimatesAllConstants.ESTIMATE_RESET:
            return { ...state, list: null }
        default:
            return state
    }
}
