import moment from 'moment'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { v4 } from 'uuid'

export const commonHelpers = {
  noUndefNull,
  noUndefNullNumber,
  sameObject,
  time,
  timeToDate,
  dateToTime,
  timeToDateFull,
  round,
  roundOneDecimal,
  toMoney,
  float,
  dateToTimeStartDay,
  dateToTimeEndDay,
  urlPath,
  timeToMonthYear,
  timeToYear,
  guid,
  currentMonth,
  addMonth,
  endOfCurrentMonth,
  currentYear,
  getCurrentDate,
  exportExcel,
  getMoment,
  isFormNotValid,
  enumerateDaysBetweenDates,
  timeToYYYY,
  getDate,
  timeToMonth,
  addMonthCurrentYear,
  setYMFromMonth,
  currentMonthYear,
  dateInputToTime
}

function enumerateDaysBetweenDates(startDate, endDate) {
  const dates = []

  const start = moment(startDate).startOf('day')
  const end = moment(endDate).startOf('day')

  dates.push(start.format('DD/MM/YYYY'))
  while (start.add(1, 'days').diff(end) < 1) {
    dates.push(start.clone().format('DD/MM/YYYY'))
  }

  return dates
}

function getMoment() {
  const m = (...args) => moment.tz(...args, moment.tz.guess())
  m.localeData = moment.localeData
  return m
}

function noUndefNull(val) {
  return val !== null && val !== undefined
}

function noUndefNullNumber(val) {
  if (val !== null && val !== undefined) {
    return val > 0
  }
  return false
}

function sameObject(a, b) {
  return JSON.stringify(a) === JSON.stringify(b)
  // // Create arrays of property names
  // var aProps = Object.getOwnPropertyNames(a)
  // var bProps = Object.getOwnPropertyNames(b)

  // // If number of properties is different,
  // // objects are not equivalent
  // if (aProps.length !== bProps.length) {
  //   return false
  // }

  // for (var i = 0; i < aProps.length; i++) {
  //   var propName = aProps[i]

  //   // If values of same property are not equal,
  //   // objects are not equivalent
  //   if (a[propName] !== b[propName]) {
  //     return false
  //   }
  // }

  // // If we made it this far, objects
  // // are considered equivalent
  // return true
}

function time() {
  return moment.utc().unix()
}

function timeToDate(time) {
  if (!time) return ''
  return moment.unix(time).tz('Europe/Rome').format('YYYY-MM-DD')
}

function timeToMonthYear(time) {
  return moment.unix(time).utc().format('MMMM YYYY')
}

function timeToYear(time) {
  return moment.unix(time).utc().format('YYYY')
}

function timeToMonth(time) {
  return moment.unix(time).utc().format('MMMM')
}

function timeToYYYY(time) {
  return parseInt(moment.unix(time).utc().format('YYYY'))
}

function timeToDateFull(time) {
  return moment.unix(time).tz('Europe/Rome').format('YYYY-MM-DD HH:mm')
}

function dateToTime(date) {
  // const mOffset = moment().utcOffset(60)
  // console.log(mOffset)
  // return moment(date, "YYYY-MM-DD")
  //     .subtract(mOffset)
  //     .unix()
  return moment(date, 'YYYY-MM-DD').subtract(60, 'minutes').unix()
}

function round(num) {
  return Math.round(num * 100) / 100
}

function roundOneDecimal(num) {
  return Math.round(num * 10) / 10
}

function toMoney(amount, showZero = false) {
  if (!amount && !showZero) {
    return amount
  }
  return amount.toLocaleString('it-IT', {
    style: 'currency',
    currency: 'EUR'
  })
}

function float(val, def = 0) {
  if (val === null || val === undefined || val === '') {
    return def
  }
  return parseFloat(val, 10)
}

function dateToTimeStartDay(date) {
  const mOffset = moment().utcOffset()
  return moment(date, 'YYYY-MM-DD')
    .utc()
    .add(mOffset, 'minutes')
    .startOf('day')
    .unix()
}

function dateToTimeEndDay(date) {
  const mOffset = moment().utcOffset()
  return moment(date, 'YYYY-MM-DD')
    .utc()
    .add(mOffset, 'minutes')
    .endOf('day')
    .unix()
}

function dateInputToTime(d) {
  return moment(d).startOf('day').utc().unix()
}

function urlPath(url) {
  return url.split('/')
}

function guid() {
  return v4()
  // return uuid()
}

function currentMonth() {
  return moment.utc().startOf('month').startOf('day').unix()
}

function addMonth(date, dir) {
  return moment.unix(date).add(dir, 'month').unix()
}

function addMonthCurrentYear(date, dir) {
  const year = moment.unix(date).format('YYYY')
  let future = addMonth(date, dir)
  if (moment.unix(future).format('YYYY') !== year) {
    future = moment
      .unix(future)
      .add(dir * -1, 'years')
      .unix()
    return future
  }
  return future
}

function endOfCurrentMonth() {
  return moment.utc().endOf('month').startOf('day').unix()
}

function currentYear() {
  const newDate = new Date()
  return newDate.getFullYear()
  // return parseInt(moment.tz("Europe/Rome").format("YYYY"), 10);
  /* return moment
        .unix(time)
        .utc()
        .format("YYYY") */
}

function getCurrentDate(separator = '/') {
  const newDate = new Date()
  const date = newDate.getDate()
  const month = newDate.getMonth() + 1
  const year = newDate.getFullYear()

  return `${date}${separator}${month < 10 ? `0${month}` : `${month}`
    }${separator}${year}`
}

const fileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const fileExtension = '.xlsx'

function exportExcel(csvData, fileName) {
  const ws = XLSX.utils.json_to_sheet(csvData)
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const data = new Blob([excelBuffer], { type: fileType })
  FileSaver.saveAs(data, fileName + fileExtension)
}

function isFormNotValid(formInputs, formData) {
  let validationError = {}
  const keys = Object.keys(formInputs)

  for (var i = 0, len = keys.length; i < len; i++) {
    if (!formData[keys[i]]) {
      validationError = {
        ...validationError,
        [keys[i]]: formInputs[keys[i]].label
      }
    } else {
      delete validationError[keys[i]]
    }
  }

  if (!Object.keys(validationError).length) validationError = ''

  return {
    validationError: validationError,
    firstValidation: true
  }
}

function getDate(dataUTC) {
  const date = new Date(dataUTC * 1000)
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString()
  const formatedMonth = month.length === 1 ? '0' + month : month
  const day = date.getDate().toString()
  const formatedDay = day.length === 1 ? '0' + day : day
  // console.log(formatedDay + "/" + formatedMonth + "/" + year);

  return formatedDay + '/' + formatedMonth + '/' + year
}

function setYMFromMonth(time, year) {
  if (!time) {
    return null
  }
  const y = moment.unix(time).year()
  if (y !== year) {
    const nd = moment.unix(time).add(year - y, 'years')
    return nd.unix()
  }
  return time
}

function currentMonthYear(year) {
  return setYMFromMonth(currentMonth(), year)
}
