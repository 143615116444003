import React, { Component } from 'react'

import Chips from '../../components/UI/Chips/Chips'
import Dialog from '../Dialog/Dialog'
import ProjectEstimateFilter from './ProjectEstimateFilter'

import {
  commonHelpers,
  customersHelpers,
  estimatesHelpers
} from '../../helpers'

const initialState = {
  name: '',
  description: '',
  deadline: '',
  start_date: '',
  // estimate_year: '',
  // estimate_filter: '',
  show_customer_delete_modal: false,
  show_estimate_delete_modal: false
}

class ProjectInfo extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
    this.onChange = this.onChange.bind(this)
    this.customerChangeHandler = this.customerChangeHandler.bind(this)
    this.estimateChangeHandler = this.estimateChangeHandler.bind(this)
    this.deleteCustomerHandler = this.deleteCustomerHandler.bind(this)
    this.deleteEstimateHandler = this.deleteEstimateHandler.bind(this)
    this.closeDialog = this.closeDialog.bind(this)
    this.showDialog = this.showDialog.bind(this)
    this.removeValueHandler = this.removeValueHandler.bind(this)
  }
  onChange(e) {
    let newValue = e.target.value
    if (['deadline', 'start_date'].indexOf(e.target.name) > -1) {
      newValue = commonHelpers.dateToTime(e.target.value)
    }
    this.setState({ [e.target.name]: newValue }, () => {
      this.props.handleInfo(this.state)
    })
  }

  customerChangeHandler(e) {
    // e.persist()
    // setTimeout(() => {
    let newValue = e.target.value
    this.setState({ [e.target.name]: newValue }, () => {
      this.props.handleInfo(this.state)
    }) // Works
    // }, 100)
  }

  closeDialog() {
    if (this.state.show_customer_delete_modal)
      this.setState({ show_customer_delete_modal: false })
    else this.setState({ show_estimate_delete_modal: false })
  }

  showDialog(isCustomer) {
    if (isCustomer) this.setState({ show_customer_delete_modal: true })
    else this.setState({ show_estimate_delete_modal: true })
  }

  estimateChangeHandler(e) {
    // let newValue = e
    this.setState({ estimate_id: e, customer_id: null }, () => {
      this.props.handleInfo(this.state)
    })
  }

  deleteCustomerHandler() {
    this.showDialog(true)
  }

  deleteEstimateHandler() {
    this.showDialog(false)
  }

  removeValueHandler() {
    if (this.state.show_customer_delete_modal) {
      this.setState(
        { customer_id: null, show_customer_delete_modal: false },
        () => {
          this.props.handleInfo(this.state)
        }
      )
    } else {
      this.setState(
        {
          show_estimate_delete_modal: false,
          estimate_id: null
          // estimate_year: '',
          // estimate_filter: ''
        },
        () => {
          this.props.handleInfo(this.state)
        }
      )
    }
  }

  componentDidMount() {
    this.setState(
      {
        name: this.props.project.name,
        description: this.props.project.description,
        deadline: this.props.project.deadline,
        start_date: this.props.project.start_date,
        customer_id: this.props.project.customer_id,
        estimate_id: this.props.project.estimate_id
      },
      () => {
        // console.log(this.state)
        this.props.handleInfo(this.state)
      }
    )
  }

  render() {
    const e = estimatesHelpers.getAllEstimateById(this.state.estimate_id)
    const v = e ? e.v[e.v.length - 1] : null
    const c = !e
      ? this.state.customer_id != null
        ? this.state.customer_id
        : null
      : null

    // const y = this.state.estimate_year
    // const t = this.state.estimate_filter

    let chip

    if (v) {
      chip = (
        <div className="chips-inline-c">
          <Chips
            customer={customersHelpers.getNameByUid(v.info.customer)}
            key={v.info.customer}
            delete={false}
          />
        </div>
      )
    }

    if (c) {
      chip = (
        <div className="chips-inline-c">
          <Chips
            customer={customersHelpers.getNameByUid(c)}
            key={c}
            delete={this.deleteCustomerHandler}
          />
        </div>
      )
    }

    let estimateSpan

    if (e) {
      estimateSpan = (
        <span>
          <div className="chips-inline-c">
            <Chips
              estimate={e.number}
              key={c}
              delete={this.deleteEstimateHandler}
            />
          </div>
        </span>
      )
    } else {
      estimateSpan = (
        <ProjectEstimateFilter onPickEstimate={this.estimateChangeHandler} />
      )
    }

    return (
      <>
        <h2>dati generali</h2>
        <ul className="ul-edit-form">
          <li className={v || c ? 'not-selectable' : ''}>
            <label>
              Cliente
              {(v || c) && <span>{chip}</span>}
              {!v && !c && (
                <select
                  name="customer_id"
                  onChange={(e) => this.customerChangeHandler(e)}
                >
                  <option value="" key="null">
                    [Cliente]
                  </option>
                  {customersHelpers.getList().map((i) => (
                    <option value={i.id} key={i.id}>
                      {i.name}
                    </option>
                  ))}
                </select>
              )}
            </label>
          </li>
          <li className={v ? 'not-selectable' : ''}>
            <label>
              preventivo
              {/* {e ? e.number : null} */}
              {estimateSpan}
            </label>
          </li>
          <li>
            <label>
              data inizio
              <input
                type="date"
                name="start_date"
                onChange={this.onChange}
                value={commonHelpers.timeToDate(this.state.start_date)}
              />
            </label>
          </li>
          <li>
            <label>
              data fine
              <input
                type="date"
                name="deadline"
                onChange={this.onChange}
                value={commonHelpers.timeToDate(this.state.deadline)}
              />
            </label>
          </li>
          <li>
            <label>
              Titolo
              <input
                type="text"
                name="name"
                onChange={this.onChange}
                value={this.state.name}
              />
            </label>
          </li>
          <li>
            <label>
              Descrizione
              <textarea
                name="description"
                onChange={this.onChange}
                value={this.state.description}
              ></textarea>
            </label>
          </li>
        </ul>

        {(this.state.show_customer_delete_modal ||
          this.state.show_estimate_delete_modal) && (
          <Dialog
            title={`Elimina ${
              this.state.show_customer_delete_modal ? 'cliente' : 'preventivo'
            }`}
            subtitle={`Vuoi davvero eliminare questo ${
              this.state.show_customer_delete_modal ? 'cliente' : 'preventivo'
            }?`}
            buttons={[
              {
                label: 'Annulla',
                func: this.closeDialog,
                submit: undefined
              },
              {
                label: 'Elimina',
                func: this.removeValueHandler,
                submit: true
              }
            ]}
          ></Dialog>
        )}
      </>
    )
  }
}

export default ProjectInfo
