import { notify } from 'react-notify-toast'

import { calendarRef } from '../database'
import { calendarConstants } from '../constants'

export let calendarSnap

export function loadCalendar(year) {
    return (dispatch) => {
        dispatch(ajaxCall())
        dispatch(updateMassiveCalendar({ id: null, year: year, days: [], selectedDays: [] }))
        calendarSnap = calendarRef.where('year', '==', year).onSnapshot((result) => {
            dispatch(ajaxCallSuccess())
            result.docChanges().forEach((change) => {
                const data = change.doc.data()
                const id = change.doc.id
                dispatch(updateMassiveCalendar({ id, ...data }))
                // dispatch(taskSuccess(null))
            })
        })
    }
}



// function loadingCalendar() {
//     return {
//         type: calendarConstants.LOADING_CALENDAR
//     }
// }

// function loadingCalendarError(payload) {
//     return {
//         type: calendarConstants.LOADING_CALENDAR_ERROR,
//         payload
//     }
// }

function updateMassiveCalendar(payload) {
    return {
        type: calendarConstants.UPDATE_MASSIVE_CALENDAR,
        payload
    }
}


export function saveCalendar(id, payload) {
    return (dispatch) => {
        // dispatch(ajaxCall())
        if (id === null) {
            calendarRef
                .add(payload)
                .then((res) => {
                    // dispatch(ajaxCallSuccess())
                    notify.show('salvato con successo', 'success', 3000)
                })
                .catch((error) => {
                    if (error.response !== undefined) {
                        dispatch(ajaxCallFailure(error.response.statusText))
                    } else {
                        dispatch(ajaxCallFailure(error.message))
                    }
                })
        } else {
            calendarRef
                .doc(id)
                .update({ ...payload })
                .then(() => {
                    // dispatch(ajaxCallSuccess())
                    notify.show('salvato con successo', 'success', 3000)
                })
                .catch((error) => {
                    if (error.response !== undefined) {
                        dispatch(ajaxCallFailure(error.response.statusText))
                    } else {
                        dispatch(ajaxCallFailure(error.message))
                    }
                })
        }
    }
}

function ajaxCall() {
    return {
        type: calendarConstants.CALENDAR_AJAX_CALL
    }
}

function ajaxCallSuccess() {
    return {
        type: calendarConstants.CALENDAR_AJAX_SUCCESS
    }
}

function ajaxCallFailure(payload) {
    return {
        type: calendarConstants.CALENDAR_AJAX_FAILURE,
        payload
    }
}
