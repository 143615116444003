import React, { Component } from 'react'
import _ from 'lodash'
import moment from 'moment'

import Dialog from '../Dialog/Dialog'

import { commonHelpers } from '../../helpers'

import { floatingDialogForm } from '../../constants/tasks.constants'

class FloatingTaskDialog extends Component {
  constructor(props) {
    super(props)

    //Adding projects for select's options constant
    let projectsArray = this.props.projects.map((t) => {
      return { label: t.name, value: t.id }
    })

    this.formInputs = {
      ...floatingDialogForm,
      project: {
        ...floatingDialogForm.project,
        options: [floatingDialogForm.project.options[0], ...projectsArray]
      }
    }

    //creating structure of component state
    let formData = {}

    if (!this.props.onEditTask) {
      Object.keys(floatingDialogForm).map(
        (info) => (formData[info] = floatingDialogForm[info].default)
      )
    } else {
      formData = {
        ...this.props.onEditTask,
        parentTask: this.props.onEditTaskParent?.id || 1
      }
    }

    this.state = {
      ...formData
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState, this.state) && this.state.firstValidation) {
      this.setState(
        commonHelpers.isFormNotValid(floatingDialogForm, this.state)
      )
    }
  }

  editState = (key, value) => this.setState({ [key]: value })

  submit = () => {
    this.setState(commonHelpers.isFormNotValid(floatingDialogForm, this.state))

    if (
      !commonHelpers.isFormNotValid(floatingDialogForm, this.state)
        .validationError
    ) {
      let payload = {
        name: this.state.name,
        description: this.state.description,
        priority: parseInt(this.state.priority),
        estimated_time: parseInt(this.state.estimated_time),
        creation_date: this.state.creation_date || moment().unix(),
        edit_date: moment().unix(),
        spent_time: this.state.spent_time || 0,
        resources: this.state.resources || [this.props.profile.id],
        sprint_id: '',
        status: 0,
        subtasks: []
      }

      this.props.saveTask(
        this.props.onEditTask?.id || null,
        'nobody',
        null,
        this.state.project !== 'nobody' ? this.state.project : null,
        payload
      )

      this.props.toggleTaskModal()
    } else {
      console.log(this.state)
      return console.log('Compilare tutti i campi prima di salvare')
    }
  }

  render() {
    return (
      <div>
        <Dialog
          formInputs={this.formInputs}
          title={this.props.onEditTask ? 'Modifica task' : 'Aggiungi Task'}
          columns={4}
          submittedForm={this.submit}
          buttons={[
            {
              label: 'Annulla',
              func: this.props.toggleTaskModal,
              submit: ''
            },
            {
              label: this.props.onEditTask ? 'Salva' : 'Aggiungi',
              func: this.submit,
              submit: true
            }
          ]}
          close={this.props.close}
          editState={this.editState}
          values={this.state}
          validationError={this.state.validationError}
        ></Dialog>
      </div>
    )
  }
}

export default FloatingTaskDialog
