import React from 'react'
import moment from 'moment'
import css from './WeekView.module.scss'
import { uiConstants } from '../../../constants/ui.constants'
import ExecutionCard from './ExecutionCard/ExecutionCard'
import { timeHelper } from '../../../helpers'

const WeekView = ({
  week,
  list,
  navigatorHandler,
  addEventHandler,
  projects,
  tasks
}) => (
  <div className={css.Week}>
    <ul className={css.UlNavigator}>
      <li>
        <button className={css.NavButton} onClick={() => navigatorHandler(-1)}>
          <i className="fas fa-chevron-left"></i>
        </button>
      </li>
      <li>
        <button className={css.NavButton} onClick={() => navigatorHandler(0)}>
          <i className="fas fa-circle"></i>
        </button>
      </li>
      <li>
        <button className={css.NavButton} onClick={() => navigatorHandler(1)}>
          <i className="fas fa-chevron-right"></i>
        </button>
      </li>
    </ul>
    <div className={css.WeekContainer}>
      {Array.from(Array(7).keys()).map((d) => {
        const day = moment(week).startOf('isoWeek').add(d, 'day').utc()
        const startDay = day.startOf('day').unix()
        const endDay = day.endOf('day').unix()
        const todayList = list.filter(
          (x) => x.start_date >= startDay && x.start_date <= endDay
        )
        return (
          <div key={d} className={css.Day}>
            <div
              className={css.DayHeader}
              onClick={(e) => addEventHandler(startDay, true)}
            >
              {uiConstants.days[d]} {day.format('D')} {day.format('MMM YYYY')}
              <div className={css.SpentSeconds}>
                {timeHelper.timeDiffHM(
                  0,
                  todayList.reduce(
                    (n, { spent_seconds }) => n + spent_seconds,
                    0
                  )
                )}
              </div>
            </div>
            <div className={css.ExecutionContainer}>
              {todayList.map((te) => {
                const project = projects.find((x) => x.id === te.project_id)
                const task = tasks.find((x) => x.id === te.task_id)
                return (
                  <ExecutionCard
                    key={te.id}
                    card={te}
                    project={project}
                    task={task}
                    addEventHandler={addEventHandler}
                  />
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  </div>
)

export default WeekView
