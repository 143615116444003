import moment from 'moment'
import { dateFormat } from './utils.constants'

export const projectsConstants = {
  OPEN_PROJECT: '[Projects] Open Project',
  UPDATE_PROJECTS: '[Projects] Update',
  REMOVE_PROJECTS: '[Projects] Remove',
  PROJECTS_AJAX_CALL: '[Projects] Ajax call',
  PROJECTS_AJAX_SUCCESS: '[Projects] Ajax success',
  PROJECTS_AJAX_FAILURE: '[Projects] Ajax error',
  PROJECT_RESET: '[Projects] Reset list'
}

export const ProjectCol = [
  { key: 'name', label: 'nome', visibility: true },
  { key: 'start_date', label: 'data inizio', visibility: true },
  { key: 'description', label: 'descrizione', visibility: true },
  { key: 'deadline', label: 'deadline', visibility: true },
  { key: 'status', label: 'stato', visibility: false },
  { key: 'rec', label: 'registra', visibility: false },
  { key: 'set_time', label: 'imposta ore', visibility: false }
]

export const DetailCol = [
  { key: 'name', label: 'nome', visibility: true },
  { key: 'resources', label: 'risorse', visibility: true },
  { key: 'spent_time', label: 'ore spese', visibility: true },
  { key: 'estimated_time', label: 'ore stimate', visibility: true },
  { key: 'priority', label: 'priorità', visibility: true },
  { key: 'progress', label: 'avanzamento', visibility: true },
  { key: 'sprint', label: 'sprint', visibility: true }
]

export const dates = ['start_date', 'estimate_date', 'deadline']

export const addProjectForm = {
  name: { label: 'Nome', type: 'text', id: 'name', default: '' },
  start_date: {
    label: 'Data inizio',
    type: 'date',
    default: moment().format(dateFormat)
  },
  deadline: {
    label: 'Deadline',
    type: 'date',
    default: moment().format(dateFormat)
  },
  description: {
    label: 'Descrizione',
    type: 'text',
    col: 12,
    default: ''
  }
}

export const addProjectArchivedNote = {
  note: {
    label: 'Nota',
    type: 'textarea',
    default: ''
  }
}

/* export const addProjectArchivedNote = [
  { label:'note', id: '', note:'', type: 'text', owner:'ELISA', data: '323423', default: '' },
  { label:'note', id: '', note:'', type: 'text', owner:'ELISA', data: '323423', default: '' },
] */

/* export const addProjectArchivedNote = [
  //archivia_note: { key: 'note', label:'note', id: '', note:'', type: 'text', owner:'ELISA', data: '323423', default: '' },
  { key: 'note', label:'note', id: '', note:'', type: 'text', owner:'ELISA', data: '323423', default: '' },
] */
