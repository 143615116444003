import React, { Component } from 'react'

import { Status } from '../../constants/tasks.constants'

class TaskLine extends Component {
  state = { status: Status }
  render() {
    const t = this.props.task

    // console.log(
    //   !this.props.task.subtasks?.length &&
    //     this.props.resources.includes(this.props.userId) &&
    //     this.props.updateRecording &&
    //     !this.props.recording
    // )
    // console.log(
    //   !this.props.task.subtasks?.length,
    //   this.props.resources.includes(this.props.userId),
    //   !this.props.recording
    // )
    // console.log(this.props.resources.includes(this.props.userId))

    return (
      <div className={`task-line ${this.props.subtask ? 'sub' : ''}`}>
        <span className="name">{t.name}</span>
        <span className="description">{t.description}</span>
        <span className={`priority pr${t.priority}`}>
          <i className="fas fa-circle"></i>
        </span>
        <span className="status">
          {!this.props.task.subtasks?.length &&
            this.props.resources.includes(this.props.userId) && (
              <select
                value={this.props.task.status}
                onChange={(event) => {
                  this.props.saveTask(this.props.task.id, null, null, null, {
                    status: event.target.value
                  })
                }}
              >
                {this.state.status.map((s, i) => (
                  <option key={i} value={s.key}>
                    {s.value}
                  </option>
                ))}
              </select>
            )}
        </span>
        <span className="btns">
          {((!this.props.task.subtasks?.length &&
            this.props.resources.includes(this.props.userId) &&
            !this.props.recording) ||
            this.props.recording?.task_id === this.props.task.id) && (
            <button
              onClick={() => {
                let executionId = null
                executionId = this.props.lastRecord?.recording
                  ? (executionId = this.props.lastRecord.id)
                  : null
                this.props.updateRecording(executionId, this.props.task.id)
              }}
            >
              {this.props.recording?.task_id === this.props.task.id ? (
                <i className="fas fa-stop"></i>
              ) : (
                <i className="fas fa-play"></i>
              )}
            </button>
          )}
        </span>
        <span className="btns">
          {this.props.resources.includes(this.props.userId) && (
            <button
              onClick={() => this.props.toggleSetTimeModal(this.props.task.id)}
            >
              <i className="far fa-clock"></i>
            </button>
          )}
        </span>
      </div>
    )
  }
}

export default TaskLine
