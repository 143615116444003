import React, { Component } from "react"
import moment from "moment"

import Dialog from "./Dialog/Dialog"

import { commonHelpers } from "../helpers"

class MultiTsDialog extends Component {
    constructor(props) {
        super(props)

        //creating structure of component state
        let formData = {}

        Object.keys(this.props.dialogForm).map(
            info => (formData[info] = this.props.dialogForm[info].default)
        )

        this.state = {
            ...formData,
        }
    }

    editState = (key, value) => this.setState({ [key]: value })

    submit = () => {
        this.setState(
            commonHelpers.isFormNotValid(this.props.dialogForm, this.state)
        )

        if (
            !commonHelpers.isFormNotValid(this.props.dialogForm, this.state)
                .validationError
        ) {
            let executions = []
            executions = Object.keys(this.state).map(date => ({
                creation_date: moment().unix(),
                edit_date: moment().unix(),
                start_date: moment(date, "DD/MM/YYYY").unix(),
                end_date:
                    moment(date, "DD/MM/YYYY").unix() + this.state[date] * 3600,
                recording: false,
                user_id: this.props.userId,
                task_id: this.props.taskId,
            }))
            this.props.saveMultipleExecutions(
                this.props.executionId,
                executions
            )
            this.props.close()
        }
    }

    render() {
        return (
            <Dialog
                formInputs={this.props.dialogForm}
                title={"Inserisci le ore per i giorni elencati"}
                columns={6}
                submittedForm={this.submit}
                buttons={[
                    {
                        label: "Salva",
                        func: this.submit,
                        submit: true,
                    },
                    {
                        label: "Annulla",
                        func: this.props.close,
                        submit: "",
                    },
                ]}
                close={this.props.close}
                editState={this.editState}
                values={this.state}
                validationError={this.state.validationError}
            ></Dialog>
        )
    }
}

export default MultiTsDialog
