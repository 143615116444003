import { projectsConstants } from '../constants'
import { produce } from 'immer'
import _ from 'lodash'

const initialState = {
  list: [],
  error: null,
  loading: false,
  ajaxSpinner: false,
  ajaxStatus: null
}

export default (state = initialState, action) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case projectsConstants.UPDATE_PROJECTS:
        draftState.list = !draftState.list.length
          ? [action.payload]
          : draftState.list.findIndex((x) => x.id === action.payload.id) === -1
          ? draftState.list.concat(action.payload)
          : draftState.list.map((item) => {
              return item.id === action.payload.id ? action.payload : item
            })
        break
      case projectsConstants.REMOVE_PROJECTS:
        draftState.list = state.list.filter((item) => {
          return item.id !== action.payload.id
        })
        draftState.loading = false
        break

      case projectsConstants.OPEN_PROJECT:
        var project = _.find(draftState.list, { id: action.payload.id })

        if (project) {
          project.open = !project.open
        }

        break
      case projectsConstants.PROJECTS_AJAX_CALL:
        return { ...state, ajaxSpinner: true, ajaxStatus: null }
      case projectsConstants.PROJECTS_AJAX_SUCCESS:
        return { ...state, ajaxSpinner: false, ajaxStatus: null }
      case projectsConstants.PROJECTS_AJAX_FAILURE:
        return { ...state, ajaxSpinner: false, ajaxStatus: action.payload }
      case projectsConstants.PROJECT_RESET:
        return { ...state, list: [] }
      default:
        return draftState
    }
  })
