import React, { useState } from 'react'
import Modal from '../Modal'

const RegistryQuickAdd = (props) => {
  const [name, setName] = useState('')

  return (
    <Modal cancel={props.cancel} confirm={() => props.confirm(name)}>
      <h1>Aggiungi {props.title}</h1>
      <label>
        Nome {props.title}
        <input
          type="text"
          placeholder="Nome"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </label>
    </Modal>
  )
}

export default RegistryQuickAdd
