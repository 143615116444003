import { calendarConstants } from '../constants'

const initialState = {
    year: { id: null, year: null, days: [] },
    error: null,
    loading: false,
    ajaxSpinner: false,
    ajaxStatus: null,
    ajaxAction: null
}

export default function calendar(state = initialState, action) {
    switch (action.type) {
        case calendarConstants.LOADING_CALENDAR:
            return { ...state, loading: true, error: null }
        case calendarConstants.LOADING_CALENDAR_ERROR:
            return { ...state, loading: false, error: action.payload }
        case calendarConstants.UPDATE_MASSIVE_CALENDAR:
            return {
                ...state,
                year: {
                    id: action.payload.id,
                    year: action.payload.year,
                    days: action.payload.days.sort((a, b) => (a.name > b.name ? 1 : -1)),
                    selectedDays: action.payload.selectedDays
                },
                ajaxAction: 'update',
                loading: false
            }
        case calendarConstants.CALENDAR_AJAX_CALL:
            return { ...state, ajaxSpinner: true, ajaxStatus: null }
        case calendarConstants.CALENDAR_AJAX_SUCCESS:
            return { ...state, ajaxSpinner: false, ajaxStatus: null }
        case calendarConstants.CALENDAR_AJAX_FAILURE:
            return { ...state, ajaxSpinner: false, ajaxStatus: action.payload }
        default:
            return state
    }
}
