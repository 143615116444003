import React, { Component } from 'react'
import { connect } from 'react-redux'

import { saveInvoicesFilter } from '../../actions'
import { commonHelpers } from '../../helpers'

const initialState = {
  customer: null,
  sent: true,
}

class InvoicesFilters extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
  }

  saveFilters() {
    const nf = {
      ...this.props.user.profile.invoicesFilters,
      customer: this.state.customer,
      sent: this.state.sent,
    }
    this.props.saveInvoicesFilter(nf)
  }

  static getDerivedStateFromProps(props, state) {
    if (state.customer === null) {
      return {
        customer: props.user.profile.invoicesFilters.customer,
        sent: props.user.profile.invoicesFilters.sent,
      }
    }
    return null
  }

  render() {
    const customers = this.props.customers.list
    return (
      <div className="filters-container">
        <ul className="filters-list">
          <li>
            <select
              onChange={(event) =>
                this.setState({ customer: event.target.value }, () => {
                  this.saveFilters()
                })
              }
              value={this.state.customer}
            >
              <option value="">[Cliente]</option>
              {(customers || []).map((c, index) => {
                return (
                  <option key={index} value={c.id}>
                    {c.name}
                  </option>
                )
              })}
            </select>
          </li>
          <li>
            <label className="switch">
              <input
                type="checkbox"
                onChange={(event) =>
                  this.setState({ sent: event.target.checked }, () => {
                    this.saveFilters()
                  })
                }
                checked={this.state.sent}
              />
              <span className="slider"></span>
            </label>
            Da inviare
          </li>
        </ul>
        <div className="amount-container">
          Totale: {commonHelpers.toMoney(this.props.subtotal)}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, { saveInvoicesFilter })(InvoicesFilters)
