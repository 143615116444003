import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

const Registry = (props) => {
  const [showCustomers, setShowCustomers] = useState(true)
  const [showProviders, setShowProviders] = useState(true)
  const [showRoles, setShowRoles] = useState(true)
  const [searchInput, setSearchInput] = useState('')

  const localFilter = (list) => {
    return (list || []).filter(
      (x) =>
        x.name?.toLowerCase().indexOf(searchInput.toLowerCase()) > -1 ||
        searchInput === ''
    )
  }

  return (
    <>
      <div className="project-editor-tool-container">
        <Link to="/registry/customers/new" className="lnk-utils mr">
          <i className="fas fa-plus"></i> Nuovo cliente
        </Link>
        <Link to="/registry/providers/new" className="lnk-utils mr">
          <i className="fas fa-plus"></i> Nuovo fornitore
        </Link>
        <Link to="/registry/roles/new" className="lnk-utils">
          <i className="fas fa-plus"></i> Nuovo ruolo
        </Link>
      </div>

      <h1>Anagrafiche</h1>

      <ul className="filters-list">
        <li>
          <button
            className={`btn-flt draft ${showCustomers && 'active'}`}
            onClick={() => setShowCustomers(!showCustomers)}
          >
            clienti
          </button>
        </li>
        <li>
          <button
            className={`btn-flt confirmed ${showProviders && 'active'}`}
            onClick={() => setShowProviders(!showProviders)}
          >
            fornitori
          </button>
        </li>
        <li>
          <button
            className={`btn-flt invoiced ${showRoles && 'active'}`}
            onClick={() => setShowRoles(!showRoles)}
          >
            ruoli
          </button>
        </li>
        <li>
          <input
            type="text"
            placeholder="Cerca"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </li>
      </ul>
      {showCustomers && (
        <>
          <div className="title-section draft">clienti</div>
          {localFilter(props.customers.list || []).map((c) => (
            <Link
              key={c.id}
              to={`/registry/customers/${c.id}`}
              className="lnk-estimate-line draft"
            >
              <span className="title">{c.name}</span>
            </Link>
          ))}
        </>
      )}
      {showProviders && (
        <>
          <div className="title-section confirmed">fornitori</div>
          {localFilter(props.providers.list || []).map((c) => (
            <Link
              key={c.id}
              to={`/registry/providers/${c.id}`}
              className="lnk-estimate-line confirmed"
            >
              <span className="title">{c.name}</span>
            </Link>
          ))}
        </>
      )}
      {showRoles && (
        <>
          <div className="title-section invoiced">ruoli</div>
          {localFilter(props.roles.list || []).map((c) => (
            <Link
              key={c.id}
              to={`/registry/roles/${c.id}`}
              className="lnk-estimate-line invoiced"
            >
              <span className="title">{c.name}</span>
            </Link>
          ))}
        </>
      )}
    </>
  )
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {})(Registry)
