import React, { Component } from 'react'
import { connect } from 'react-redux'

import { uiConstants } from '../../constants'

import { localSaveEstimateFilter, saveEstimateFilter } from '../../actions'

const initialState = {
  searchTerm: undefined,
  customer: '',
  fromDate: '',
  toDate: ''
}

class EstimatesFilter extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
  }

  turnOffFilter(f) {
    const estimatesFilters = { ...this.props.user.profile.estimatesFilters }
    estimatesFilters[f.value] = !estimatesFilters[f.value]
    this.props.saveEstimateFilter(estimatesFilters)
  }

  saveFilters(local = false) {
    const estimatesFilters = this.props.user.profile.estimatesFilters
    const nf = {
      ...estimatesFilters,
      searchTerm: this.state.searchTerm,

      customer: this.state.customer
    }
    if (local) {
      this.props.localSaveEstimateFilter(nf)
    } else {
      this.props.saveEstimateFilter(nf)
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.searchTerm === undefined) {
      return {
        searchTerm: props.user.profile.estimatesFilters.searchTerm,

        customer: props.user.profile.estimatesFilters.customer
      }
    }
    return null
  }

  render() {
    const profile = this.props.user.profile
    const customers = this.props.customers.list

    return (
      <ul className="filters-list">
        {uiConstants.estimatesFilters.map((f, index) => {
          return (
            <li key={index}>
              <div>
                <button
                  className={
                    'btn-flt ' +
                    f.value +
                    (profile.estimatesFilters[f.value] ? ' active' : '')
                  }
                  onClick={() => this.turnOffFilter(f)}
                >
                  {f.label}
                </button>
                <div className="amount-status">
                  {this.props.subtotal
                    ? this.props.subtotal[f.value] + ' €'
                    : ''}
                </div>
              </div>
            </li>
          )
        })}
        <li>
          <select
            onChange={(event) =>
              this.setState({ customer: event.target.value }, () => {
                this.saveFilters()
              })
            }
            value={this.state.customer}
          >
            <option value="">[Cliente]</option>
            {(customers || []).map((c, index) => {
              return (
                <option key={index} value={c.id}>
                  {c.name}
                </option>
              )
            })}
          </select>
        </li>
        <li>
          <input
            type="text"
            placeholder="Cerca"
            onChange={(event) =>
              this.setState({ searchTerm: event.target.value }, () => {
                this.saveFilters(true)
              })
            }
            value={this.state.searchTerm}
            onBlur={() => this.saveFilters()}
          />
        </li>
        {/* <li>
          <input type="date" placeholder="Da"
            onChange={event => this.setState({ fromDate: event.target.value }, () => { this.saveFilters(true) })}
            value={this.state.fromDate}
            onBlur={() => this.saveFilters()}
          />
        </li>
        <li>
          <input type="date" placeholder="A"
            onChange={event => this.setState({ toDate: event.target.value }, () => { this.saveFilters(true) })}
            value={this.state.toDate}
            onBlur={() => this.saveFilters()}
          />
        </li> */}
        {/* <li>
          <select
            onChange={(event) =>
              this.setState({ fromDate: event.target.value }, () => {
                this.saveFilters(true)
              })
            }
            value={this.state.fromDate}
          >
            {range(2018, new Date().getFullYear() + 1, 1).map((year, index) => {
              return (
                <option key={index} value={year}>
                  {year}
                </option>
              )
            })}
          </select>
        </li>
        <li></li> */}
      </ul>
    )
  }
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {
  localSaveEstimateFilter,
  saveEstimateFilter
})(EstimatesFilter)
