import React from 'react'
import { DragSource, DropTarget } from 'react-dnd'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import _ from 'lodash'

import Dropdown from '../Dropdown/Dropdown'

export function Card(props) {
  return _.flowRight(
    props.connectDragSource,
    props.connectDropTarget
  )(
    <div
      className={cn('Card', {
        'Card--dragging': props.isDragging,
        'Card--spacer': props.isSpacer,
        'Card--thin': props.thinSpacer
      })}
    >
      <div className="Card__top-info" style={{ background: props.color }}>
        <div className="Card__title">{props.title}</div>
        <div className="icons-container">
          {/* {(props.recordAction && !props.isRecording) ||
          props.isRecording?.task_id === props.id ? (
            <div
              className={`Card__icon ${
                props.isRecording?.task_id === props.id ? 'recording' : ''
              }`}
              onClick={() => {
                let executionId = null
                executionId = props.lastRecord?.recording
                  ? (executionId = props.lastRecord.id)
                  : null
                props.recordAction(executionId, props.id)
              }}
            ></div>
          ) : (
            ''
          )} */}

          {props.isRecording?.task_id !== props.id && props.recordAction ? (
            <div
              className={`Card__icon time`}
              onClick={() => {
                props.toggleSetTimeModal(props.id)
              }}
            ></div>
          ) : (
            ''
          )}
          {!props.projectId && !props.sprint ? (
            <Dropdown
              listItems={
                props.isAdmin
                  ? [
                      {
                        text: 'Modifica task',
                        click: () => {
                          props.toggleTaskModal(props.id)
                        }
                      },
                      {
                        text: 'Aggiungi risorsa',
                        click: () => props.toggleResourceModal(props.id)
                      },
                      {
                        text: 'Elimina task',
                        click: () => props.toggleDeleteDialog(props.id, null)
                      }
                    ]
                  : [
                      {
                        text: 'Modifica task',
                        click: () => {
                          props.toggleTaskModal(props.id)
                        }
                      },
                      {
                        text: 'Elimina task',
                        click: () => props.toggleDeleteDialog(props.id, null)
                      }
                    ]
              }
            />
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="Card__body">
        {!props.isSpacer ? (
          <div>
            {!props.sprint ? (
              <div className="space-container">
                <div className="align-info">
                  <label>Progetto:</label>
                  {props.projectId ? (
                    props.isAdmin ? (
                      <Link to={`/projects/${props.projectId}`}>
                        {props.projectName}
                      </Link>
                    ) : (
                      <label>{props.projectName}</label>
                    )
                  ) : (
                    <label>non assegnato</label>
                  )}
                </div>
              </div>
            ) : (
              ''
            )}

            <div className="space-container top">
              <div className="align-info">
                <label>Spese:</label>
                <label className="evidence">
                  {props.spentHours
                    ? `${_.round(props.spentHours, 1)}h`
                    : `${props.spentHours || 0}h`}
                </label>
              </div>
              <div className="align-info">
                <label>Stimate:</label>
                <label className="evidence">
                  {`${props.estimatedHours || 0}h`}
                </label>
              </div>
            </div>

            <div className="space-container top">
              <div className="align-info priority">
                <label>Prioritá:</label>
                <div className={`priority s-${props.priority}`}></div>
              </div>
              {props.sprintName ? (
                <div className="align-info priority">
                  <label>Sprint:</label>
                  <Link
                    to={`/projects/${props.projectId}/sprints/${props.sprintId}`}
                  >
                    {props.sprintName}
                  </Link>
                </div>
              ) : (
                ''
              )}
            </div>

            <div className="resources-container">
              {props.resources
                ? props.resources.map((resource) => (
                    <div className="align-info resources" key={resource.id}>
                      <label>{resource.displayName}</label>
                      {!props.projectId && !props.sprint && props.isAdmin && (
                        <div
                          className="delete-resource"
                          onClick={() =>
                            props.toggleDeleteDialog(props.id, resource.id)
                          }
                        ></div>
                      )}
                    </div>
                  ))
                : ''}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export const DraggableCard = _.flowRight([
  DropTarget(
    'Card',
    {
      hover(props, monitor) {
        const { columnId, columnIndex } = props
        const draggingItem = monitor.getItem()
        if (draggingItem.id !== props.id) {
          props.moveCard(draggingItem.id, columnId, columnIndex)
        }
      },

      drop(props, monitor) {
        const { columnId } = props
        const draggingItem = monitor.getItem()

        if (draggingItem.id) {
          props.saveTask(draggingItem.id, null, null, null, {
            status: columnId
          })
        }
      }
    },
    (connect) => ({
      connectDropTarget: connect.dropTarget()
    })
  ),
  DragSource(
    'Card',
    {
      beginDrag(props) {
        return { id: props.id }
      },

      isDragging(props, monitor) {
        return props.id === monitor.getItem().id
      },
      endDrag(props, monitor) {
        const { columnId, columnIndex } = props
        if (!monitor.didDrop()) {
          props.moveCard(props.id, columnId, columnIndex)
        }
      }
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging()
    })
  )
])(Card)
