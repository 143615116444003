export const invoicesConstants = {
  UPDATE_INVOICE: '[Invoices] Update',
  REMOVE_INVOICE: '[Invoices] Remove',
  LOADING_INVOICES: '[Invoices] Loading',
  LOADING_INVOICES_ERROR: '[Invoices] Loading error',
  INVOICE_AJAX_CALL: '[Invoices] Ajax call',
  INVOICE_AJAX_SUCCESS: '[Invoices] Ajax success',
  INVOICE_AJAX_FAILURE: '[Invoices] Ajax failure',
  INVOICE_RESET: '[Invoices] Reset list'
}
