import React from 'react'
import moment from 'moment'

import css from './CalendarSummary.module.scss'

const wd = ['L', 'M', 'M', 'G', 'V', 'S', 'D']

const CalendarSummary = ({ month, list, navigatorHandler, dayHandler }) => {
  const grid = () => {
    const today = moment().utc().startOf('day').unix()
    let sc = moment(month).startOf('month').startOf('day').isoWeekday()

    let days = []
    for (let s = 1; s < sc; s++) {
      days.push(<div key={`s${s}`} className={css.Spacer}></div>)
    }
    for (let d = 1; d <= month.daysInMonth(); d++) {
      const startDay = month
        .utc()
        .startOf('month')
        .startOf('day')
        .add(d - 1, 'days')
        .unix()
      const endDay = month
        .utc()
        .startOf('month')
        .endOf('day')
        .add(d - 1, 'days')
        .unix()
      days.push(
        <div
          key={`d${d}`}
          className={`${css.Day} ${startDay === today ? css.Today : ''} ${
            list.filter(
              (x) => x.start_date >= startDay && x.start_date <= endDay
            ).length > 0
              ? css.Exists
              : ''
          }`}
          onClick={() => dayHandler(d)}
        >
          {d}
        </div>
      )
    }
    return days
  }

  return (
    <ul className={css.UlSummary}>
      <li>
        <ul className={css.UlNavigator}>
          <li>{month.format('MMMM YYYY')}</li>
          {navigatorHandler && (
            <React.Fragment>
              <li>
                <button
                  className={css.NavButton}
                  onClick={() => navigatorHandler(-1)}
                >
                  <i className="fas fa-chevron-left"></i>
                </button>
              </li>
              <li>
                <button
                  className={css.NavButton}
                  onClick={() => navigatorHandler(0)}
                >
                  <i className="fas fa-circle"></i>
                </button>
              </li>
              <li>
                <button
                  className={css.NavButton}
                  onClick={() => navigatorHandler(1)}
                >
                  <i className="fas fa-chevron-right"></i>
                </button>
              </li>
            </React.Fragment>
          )}
        </ul>
      </li>
      <li
        className={`${css.CalContainer} ${
          !navigatorHandler ? css.Compact : ''
        }`}
      >
        <div className={css.WeekDays}>
          {wd.map((d, key) => {
            return <div key={key}>{d}</div>
          })}
        </div>
        <div className={css.Grid}>{grid().map((d) => d)}</div>
      </li>
    </ul>
  )
}

export default CalendarSummary
