import moment from 'moment'
import { dateFormat } from './utils.constants'

export const tasksConstants = {
  OPEN_TASK: '[Tasks] Open Task',
  UPDATE_TASKS: '[Tasks] Update',
  REMOVE_TASKS: '[Tasks] Remove',
  TASKS_AJAX_CALL: '[Tasks] Ajax call',
  TASKS_AJAX_SUCCESS: '[Tasks] Ajax success',
  TASKS_AJAX_FAILURE: '[Tasks] Ajax error',
  DASHBOARD_FILTER: '[Tasks] Change dashboard filter',
  TASKS_RESET: '[Tasks] Reset list'
}

export const Status = [
  { key: 0, value: 'To do' },
  { key: 1, value: 'In progress' },
  { key: 2, value: 'In review' },
  { key: 3, value: 'Waiting' },
  { key: 4, value: 'Done' }
]

export const TaskCol = [
  { key: 'name', label: 'nome', visibility: true },
  { key: 'creation_date', label: 'data ins.', visibility: false },
  { key: 'description', label: 'descrizione', visibility: true },
  { key: 'edit_date', label: 'data scad.', visibility: false },
  { key: 'status', label: 'stato', visibility: true },
  { key: 'rec', label: 'registra', visibility: true },
  { key: 'set_time', label: 'imposta ore', visibility: true }
]

export const dates = ['creation_date', 'edit_date']

export const dialogForm = {
  name: { label: 'Nome', type: 'text', id: 'name', default: '' },
  priority: {
    label: 'Priorità',
    type: 'select',
    options: [
      { label: 'Molto alta', value: 5 },
      { label: 'Alta', value: 4 },
      { label: 'Normale', value: 3 },
      { label: 'Bassa', value: 2 },
      { label: 'Molto bassa', value: 1 }
    ],
    default: 3
  },
  estimated_time: {
    label: 'Ore stimate',
    type: 'number',
    default: 0
  },
  parentTask: {
    label: 'Subtask di',
    type: 'select',
    options: [{ label: 'Nessuno', value: 'nobody' }],
    default: 'nobody'
  },
  description: {
    label: 'Descrizione',
    type: 'text',
    col: 12,
    default: ''
  }
}

export const floatingDialogForm = {
  name: { label: 'Nome', type: 'text', id: 'name', default: '' },
  priority: {
    label: 'Priorità',
    type: 'select',
    options: [
      { label: 'Molto alta', value: 5 },
      { label: 'Alta', value: 4 },
      { label: 'Normale', value: 3 },
      { label: 'Bassa', value: 2 },
      { label: 'Molto bassa', value: 1 }
    ],
    default: 3
  },
  estimated_time: {
    label: 'Ore stimate',
    type: 'number',
    default: 0
  },
  project: {
    label: 'Progetto',
    type: 'select',
    options: [{ label: 'Nessuno', value: 'nobody' }],
    default: 'nobody'
  },
  description: {
    label: 'Descrizione',
    type: 'text',
    col: 12,
    default: ''
  }
}

export const dialogSetTime = {
  start_date: {
    label: 'Data Inserimento',
    type: 'date',
    id: 'start_date',
    col: 6,
    default: moment().format(dateFormat)
  },
  hours: {
    label: 'Ore',
    type: 'number',
    id: 'hours',
    col: 6,
    default: 0
  }
}

export const addResource = {
  resources: {
    label: 'Risorsa',
    type: 'select',
    id: 'resource',
    default: '',
    options: [{ label: 'Nessuno', value: '' }]
  }
}
