import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { commonHelpers } from '../../helpers'

import { saveCustomer, saveProvider, saveRole } from '../../actions'

const RegistryEditor = (props) => {
  const [name, setName] = useState('')
  const [cost, setCost] = useState('')
  const [unit, setUnit] = useState('')
  const [url, setUrl] = useState({ id: null, collection: null })

  const [budgets, setBudgets] = useState([])
  const [year, setYear] = useState('')
  const [amount, setAmount] = useState('')

  const addBudgetHandler = () => {
    if (budgets.filter((x) => x.year === parseInt(year)).length === 0) {
      const b = [...budgets].concat({
        year: parseInt(year),
        amount: parseFloat(amount)
      })
      setBudgets(b)
    } else {
      setBudgets(
        budgets.map((x) =>
          x.year === parseInt(year) ? { ...x, amount: parseFloat(amount) } : x
        )
      )
    }
    setYear('')
    setAmount('')
  }

  const saveButtonHandler = () => {
    if (url.collection === 'roles') {
      const payload = {
        name,
        cost: parseFloat(cost),
        amount: parseFloat(amount),
        unit: unit
      }
      props.saveRole(url.id, payload)
    } else {
      const payload = {
        name,
        budgets
      }
      if (url.collection === 'customers') {
        props.saveCustomer(url.id, payload)
      } else if (url.collection === 'providers') {
        props.saveProvider(url.id, payload)
      }
    }
  }

  useEffect(() => {
    const url = props.location.pathname
    const params = url.split('/')
    setUrl({
      id: params[3] === 'new' ? null : params[3],
      collection: params[2]
    })
    const item = (props[params[2]].list || []).find((x) => x.id === params[3])
    if (item && item !== undefined) {
      setName(item.name)
      setCost(item.cost)
      if (item.unit) setUnit(item.unit)
      if (item.amount) setAmount(item.amount)
      if (item.budgets) setBudgets([...item.budgets])
    }
  }, [props])

  return (
    <>
      <h1>
        {url.id ? 'Modifica' : 'Aggiungi'} anagrafica{' '}
        {url.collection === 'customers' ? 'cliente' : 'fornitore'}
      </h1>
      <Link to="/registry" className="lnk-new">
        <i className="fas fa-times"></i> esci
      </Link>
      <label>
        nome
        <input
          type="text"
          placeholder="Nome"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </label>

      {url.collection === 'roles' ? (
        <>
          <label>
            costo risorsa
            <input
              type="number"
              placeholder="Costo risorsa"
              value={cost}
              onChange={(e) => setCost(e.target.value)}
            />
          </label>
          <label>
            prezzo risorsa
            <input
              type="number"
              placeholder="Prezzo risorsa"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </label>
          <label>
            unità di misura
            <input
              type="text"
              placeholder="Unità di misura"
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
            />
          </label>
        </>
      ) : (
        <>
          <label>
            budget
            <ul className="form-add-epic multi">
              <li className="txt">
                <input
                  type="number"
                  placeholder="Anno"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  min={2019}
                  max={new Date().getFullYear() + 5}
                />
              </li>
              <li className="txt">
                <input
                  type="number"
                  placeholder="Importo"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </li>
              <li className="add">
                <button
                  className="lnk-only-icon green"
                  disabled={
                    year === '' ||
                    amount === '' ||
                    year < 2019 ||
                    year > new Date().getFullYear() + 5
                  }
                  onClick={addBudgetHandler}
                >
                  <i className="fas fa-plus"></i>
                </button>
              </li>
            </ul>
          </label>
          {(budgets || [])
            .sort((a, b) => (a.year > b.year ? 1 : b.year > a.year ? -1 : 0))
            .map((b, key) => (
              <ul key={key} className="form-add-epic multi list">
                <li className="txt">{b.year}</li>
                <li className="txt">{commonHelpers.toMoney(b.amount)}</li>
                <li className="add">
                  <button
                    className="lnk-only-icon red"
                    onClick={() =>
                      setBudgets(budgets.filter((x) => x.year !== b.year))
                    }
                  >
                    <i className="fas fa-trash-alt"></i>
                  </button>
                </li>
              </ul>
            ))}
        </>
      )}

      <ul className="bottom-utils-buttons">
        <li className="right">
          <ul className="ul-utils-btn">
            <li>
              <Link to="/registry" className="lnk-utils">
                <i className="fas fa-arrow-left"></i> annulla
              </Link>
            </li>
            <li>
              <button
                className="lnk-utils"
                disabled={name === ''}
                onClick={saveButtonHandler}
              >
                <i className="far fa-save"></i> salva
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </>
  )
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {
  saveCustomer,
  saveProvider,
  saveRole
})(RegistryEditor)
