import { tasksConstants } from '../constants'
import produce from 'immer'
import _ from 'lodash'

const initialState = {
  list: [],
  error: null,
  loading: false,
  ajaxSpinner: false,
  ajaxStatus: null,
  dashBoardFilter: ''
}

export default (state = initialState, action) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case tasksConstants.UPDATE_TASKS:
        draftState.list = !draftState.list.length
          ? [action.payload]
          : state.list.findIndex((x) => x.id === action.payload.id) === -1
          ? draftState.list.concat(action.payload)
          : draftState.list.map((item) => {
              return item.id === action.payload.id ? action.payload : item
            })
        break

      case tasksConstants.REMOVE_TASKS:
        draftState.list = state.list.filter((item) => {
          return item.id !== action.payload.id
        })
        draftState.loading = false
        break

      case tasksConstants.OPEN_TASK:
        var task = _.find(draftState.list, { id: action.payload.id })
        if (task) {
          task.open = !task.open
        }

        break

      case tasksConstants.DASHBOARD_FILTER:
        draftState.dashBoardFilter = action.payload.projectId
        break
      case tasksConstants.TASKS_RESET:
        draftState.list = []
        break
      default:
        return draftState
    }
  })
