import React, { Component } from 'react'

import ChangeAvatar from './Settings/ChangeAvatar'
import firestore from '../firestore'
import packageJson from '../../package.json'

class Settings extends Component {
  render() {
    return (
      <>
        <h1>Cambia avatar</h1>
        <ChangeAvatar />
        <h1>Info</h1>
        {firestore.database().app.options.projectId} &bull; v.{' '}
        {packageJson.version}
      </>
    )
  }
}

export default Settings
