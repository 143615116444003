import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
// import update from 'immutability-helper'

import { commonHelpers } from '../../helpers'
import { saveInvoice, propagateOda, updateOda } from '../../actions'

const initialState = {
  id: '',
  lastUpdate: '',
  customer: '',
  amount: '',
  oda: '',
  bem: '',
  invoiceNumber: '',
  year: '',
  invoiceDate: '',
  invoiceExpireDate: '',
  sent: false,
  estimateNumber: '',
  title: ''
}

class InvoiceLine extends PureComponent {
  constructor() {
    super()
    this.state = initialState
    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  onChange(e) {
    let newValue = e.target.value
    if (
      e.target.name === 'invoiceDate' ||
      e.target.name === 'invoiceExpireDate'
    ) {
      newValue = commonHelpers.dateToTime(e.target.value)
    }
    if (e.target.name === 'year') {
      newValue = parseInt(e.target.value)
    }
    this.setState({ [e.target.name]: newValue })
  }

  onBlur(e) {
    const id = this.state.id
    const i = { ...this.state }
    delete i.id
    this.props.saveInvoice(id, i, false)
    if (e.target.name === 'oda' && this.props.i.oda !== i.oda) {
      // this.props.propagateOda(i.eid, i.oda)
      const me = this.props.user.profile.uid
      this.props.updateOda(i.eid, i.oda, me)
    }
  }

  setSent() {
    // const i = this.props.i
    // console.log(i)
    // const invoice = update(i, {
    //   sent: { $set: !i.sent },
    //   lastUpdate: { $set: commonHelpers.time() }
    // })
    // delete invoice.id
    // console.log(invoice)

    const id = this.state.id
    const invoice = { ...this.state }
    invoice.lastUpdate = commonHelpers.time()
    invoice.sent = !this.state.sent
    delete invoice.id
    this.props.saveInvoice(id, invoice)
  }

  static getDerivedStateFromProps(props, state) {
    if (props.i.lastUpdate !== state.lastUpdate) {
      return {
        id: props.i.id,
        customer: props.i.customer,
        amount: props.i.amount,
        oda: props.i.oda,
        bem: props.i.bem,
        invoiceNumber: props.i.invoiceNumber,
        year: props.i.year,
        invoiceDate: props.i.invoiceDate,
        invoiceExpireDate: props.i.invoiceExpireDate,
        sent: props.i.sent,
        lastUpdate: props.i.lastUpdate,
        estimateNumber: props.i.estimateNumber,
        title: props.i.title,
        eid: props.i.eid
      }
    }
    return null
  }

  render() {
    const i = this.state
    return (
      <div className={'invoice-line-c ' + (i.sent ? 'form-disabled' : '')}>
        <span className="del">
          <button
            className="lnk-del-eh"
            onClick={() => this.props.removeInvoice(i)}
          >
            <i className="far fa-trash-alt"></i>
          </button>
        </span>
        <span className="eid">{i.estimateNumber}</span>
        <span className="title">{i.title}</span>
        <span className="amount">{commonHelpers.toMoney(i.amount)}</span>
        <span className="oda">
          <input
            type="text"
            name="oda"
            placeholder="ODA"
            value={i.oda}
            onChange={this.onChange}
            onBlur={this.onBlur}
          />
        </span>
        <span className="bem">
          <input
            type="text"
            placeholder="BEM"
            name="bem"
            value={i.ben}
            onChange={this.onChange}
            onBlur={this.onBlur}
          />
        </span>
        <span className="year">
          <input
            type="text"
            name="invoiceNumber"
            placeholder="Numero fattura"
            value={i.invoiceNumber}
            onChange={this.onChange}
            onBlur={this.onBlur}
          />
        </span>
        <span className="year">
          <input
            type="number"
            placeholder="Anno"
            name="year"
            onChange={this.onChange}
            onBlur={this.onBlur}
            value={i.year}
          />
        </span>
        <span className="expire">
          <input
            type="date"
            name="invoiceDate"
            placeholder="Data fattura"
            onChange={this.onChange}
            onBlur={this.onBlur}
            value={commonHelpers.timeToDate(i.invoiceDate)}
          />
        </span>
        <span className="expire">
          <input
            type="date"
            name="invoiceExpireDate"
            placeholder="Data scadenza fattura"
            onChange={this.onChange}
            onBlur={this.onBlur}
            value={commonHelpers.timeToDate(i.invoiceExpireDate)}
          />
        </span>
        <span className="button">
          <button
            className={'lnk-only-icon ' + (i.sent ? 'green' : 'trasp')}
            onClick={() => this.setSent()}
          >
            <i className="fas fa-check"></i>
          </button>
        </span>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {
  saveInvoice,
  propagateOda,
  updateOda
})(InvoiceLine)
