import moment from 'moment'
import { store } from '../store'
import { companiesHelpers } from './companies.helpers'
import { timeHelper, customersHelpers } from './'
import { uiConstants } from '../constants'

export const estimatesHelpers = {
  generateNumber,
  getEstimateById,
  buttonIsDisabled,
  canSave,
  getHero,
  calcPerc,
  formIsDisabled,
  filterItems,
  showConfirmColumn,
  getLastStatusDate,
  getEstimatesYears,
  filterEstimates,
  getAllEstimateById
}

function generateNumber(year) {
  const curState = store.getState().estimates
  if (!year) return defaultNumber(moment.utc().format('YY'))
  const yy = year.toString().slice(-2)
  if (curState === null) {
    return defaultNumber(yy)
  }
  const list = curState.list
  if (list === null) {
    return defaultNumber(yy)
  }
  try {
    // NOTE: regex per togliere dal numero eventuli lettere (19.005B -> 19.005)
    const regex = /\d+.\d+/gim
    const nl = list
      .filter((x) => x.number.startsWith(yy))
      .map((e) => {
        const n = e.number
        return n.match(regex)[0]
      })
    const max = Math.max.apply(
      String,
      nl.map((o) => {
        return o
      })
    )
    const p = max.toString().split('.')
    const n = parseInt(p[1] + '000'.slice(p[1].toString().length), 10) + 1
    return yy + '.' + '000'.slice(n.toString().length) + n
  } catch (err) {
    return defaultNumber(yy)
  }
}

function defaultNumber(yy) {
  const curState = store.getState().user
  if (curState === null) {
    return yy + '.001'
  }
  const list = curState.profile
  if (list === null) {
    return yy + '.001'
  }

  return curState.profile.currentYear.toString().slice(-2) + '.001'
}

function getEstimateById(id) {
  const curState = store.getState().estimates
  if (curState === null) {
    return null
  }
  const list = curState.list
  if (list === null) {
    return null
  }
  const e = list.filter((x) => x.id === id)
  if (e.length > 0) {
    return e[0]
  }
}

function getAllEstimateById(id) {
  const curState = store.getState().estimatesAll
  if (curState === null) {
    return null
  }
  const list = curState.list
  if (list === null) {
    return null
  }
  const e = list.filter((x) => x.id === id)
  if (e.length > 0) {
    return e[0]
  }
}

function buttonIsDisabled(index, dir, id) {
  if (id === 'new') {
    return true
  }
  if (dir === -1) {
    return index === 0
  } else {
    const e = this.getEstimateById(id)
    if (e === null) {
      return false
    }
    const ni = index + dir
    return !(ni < e.v.length)
  }
}

function canSave(index, id) {
  if (id === 'new') {
    return false
  }
  if (id === null) {
    return false
  }
  const e = this.getEstimateById(id)
  return e.v.length > index + 1
}

function getHero(id) {
  const c = companiesHelpers.getCurrent()
  if (c !== null) {
    const h = c.roles.filter((x) => x.id === id)
    if (h.length > 0) {
      return h[0]
    }
  }
  return null
}

function calcPerc(days, percentage) {
  return days * (1 + (1 - percentage / 100))
}

function formIsDisabled(props, alwaysEnabled = false) {
  if (props.item.e.v !== undefined) {
    return (
      props.item.e.v.length > parseInt(props.version, 10) ||
      (!alwaysEnabled &&
        props.item.e.lastStatus === uiConstants.estimateStatuses.confirmed)
    )
  } else {
    return false
  }
}

function filterItems(list, status, filters) {
  if (list === null) {
    return []
  }
  return list
    .filter((item) => {
      return (
        filters.customer === '' ||
        item.v[item.v.length - 1].info.customer === filters.customer
      )
    })
    .filter((item) => item.lastStatus === status)
    .filter((item) => {
      return (
        filters.searchTerm === '' ||
        item.v[item.v.length - 1].info.title
          .toLowerCase()
          .indexOf(filters.searchTerm.toLowerCase()) > -1 ||
        item.number.toLowerCase().indexOf(filters.searchTerm.toLowerCase()) > -1
      )
    })
    .filter((item) => !item.deleted)
}

function showConfirmColumn(status) {
  return status === uiConstants.estimateStatuses.confirmed
}

function getLastStatusDate(list, status) {
  let vd = ''
  list
    .filter((x) => x.versionStatus === status)
    .forEach((el) => {
      vd = el.versionDate
    })
  return vd
}

function getEstimatesYears(list) {
  if (list === null) {
    return null
  }

  const years = []

  list.forEach(e => {
    const info = e.v[e.v.length - 1].info

    if (info && info.estimateDate) {
      const d = timeHelper.timeToDate(info.estimateDate)

      if (!years.find(i => i === d.format('YYYY'))) {
        years.push(d.format('YYYY'))
      }
    }
  })

  return years

}

function filterEstimates(list, year, text) {
  if (list === null) {
    return null
  }

  const filteredList = []

  list.forEach(e => {
    const info = e.v[e.v.length - 1].info
    const y = timeHelper.timeToDate(info.estimateDate)
    const c = customersHelpers.getNameByUid(info.customer).toUpperCase()
    const t = info.title.toUpperCase()
    const n = e.number.toUpperCase()

    if (y.format('YYYY') === year && (
      c.includes(text.toUpperCase()) || t.includes(text.toUpperCase()) || n.includes(text.toUpperCase())
    )) {
      filteredList.push(e)
    }

  })

  return filteredList
}
