export const calendarConstants = {
    UPDATE_MASSIVE_CALENDAR: '[Calendar] Update massive',
    REMOVE_CALENDAR: '[Calendar] Remove',
    ADD_CALENDAR: '[Calendar] Add',
    LOADING_CALENDAR: '[Calendar] Loading',
    LOADING_CALENDAR_ERROR: '[Calendar] Loading error',
    CALENDAR_AJAX_CALL: '[Calendar] Ajax call',
    CALENDAR_AJAX_SUCCESS: '[Calendar] Ajax success',
    CALENDAR_AJAX_FAILURE: '[Calendar] Ajax error'
}
