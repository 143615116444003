import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { ToastContainer } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'
import Notifications, { notify } from 'react-notify-toast'

import { updateUIVars } from '../actions'

import CheckUser from './CheckUser'
import Nav from './Nav'
import Spinner from './Spinner'

class AuthLayout extends Component {
  constructor() {
    super()
    this.mouseUp = this.mouseUp.bind(this)
    this.show = notify.createShowQueue()
  }

  mouseUp(event) {
    const c = JSON.stringify(event.target.classList).indexOf('submenu')
    this.child.closeSubMenu(c)
  }

  ajaxCalling() {
    const e =
      this.props.estimates.ajaxSpinner ||
      this.props.invoices.ajaxSpinner ||
      this.props.economics.ajaxSpinner ||
      this.props.projects.ajaxSpinner ||
      this.props.customers.ajaxSpinner ||
      this.props.providers.ajaxSpinner ||
      this.props.companies.ajaxSpinner ||
      this.props.roles.ajaxSpinner ||
      this.props.calendar.ajaxSpinner ||
      this.props.vacations.ajaxSpinner ||
      this.props.vacationDescriptions.ajaxSpinner
    return e
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0)
    }
  }

  // NOTE: nel caso fosse da cambiare l'oggetto dell'utente (filtri, ecc)
  // componentDidMount () {
  //   setTimeout(() => {
  //     console.log('create filters')
  //     this.props.updateUIVars(null)
  //     this.props.updateUIVars(true)
  //   }, 2000)
  // }

  render() {
    const { profile } = this.props.user
    return (
      <div
        className={'auth-layout ' + (this.ajaxCalling() ? 'blur' : '')}
        onMouseUp={(e) => this.mouseUp(e)}
      >
        <CheckUser />
        {/* <ToastContainer
          autoClose={5000}
          position="bottom-right"
          draggable
        /> */}
        <Notifications />
        <Nav
          location={this.props.location}
          childRef={(ref) => (this.child = ref)}
        />
        {profile !== null && (
          <main
            className={'right-pan ' + (profile.navCollapsed ? 'collapsed' : '')}
          >
            {this.props.children}
          </main>
        )}
        {this.ajaxCalling() && <Spinner ajax={true} />}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, { updateUIVars })(AuthLayout)
