import React, { useState, useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux'

import RegistryQuickAdd from '../Registry/RegistryQuickAdd'
import Dialog from '../Dialog/Dialog'
import ProviderRowDialog from './modals/ProviderRowDialog'

import { saveProvider } from '../../actions'
import { commonHelpers } from '../../helpers'

const ProjectProviders = (props) => {
  const [provider, setProvider] = useState('')
  const [list, setList] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [showProviderRowModal, setShowProviderRowModal] = useState(false)
  const [showProviderDeleteModal, setshowProviderDeleteModal] = useState(false)
  const [selectedProvider, setSelectedProvider] = useState(null)
  const [open, setOpen] = useState(false)

  const openProviderRowDialog = (e) => {
    const prov = props.providers.list.find((i) => i.id === e.target.value)
    setSelectedProvider({ ...prov })
    setShowProviderRowModal(true)
  }

  const closeProviderRowHandler = () => {
    setShowProviderRowModal(false)
    setSelectedProvider(null)
  }

  const addProviderHandler = (provider) => {
    let tmp = [...(list || [])]
    tmp = tmp.concat({
      ...provider
    })
    setList(tmp)
    setProvider('')
    props.handleProviders(tmp)
    setShowProviderRowModal(false)
    setSelectedProvider(null)
  }

  const removeProviderHandler = () => {
    const tmp = [...(list || [])].filter(
      (x) => x.guid !== selectedProvider.guid
    )
    setList(tmp)
    props.handleProviders(tmp)

    setSelectedProvider(null)
    setshowProviderDeleteModal(false)
  }

  const saveProviderHandler = (name) => {
    props.saveProvider(
      null,
      {
        name,
        budgets: []
      },
      false
    )
    setShowModal(false)
  }

  const changeInput = (e, provider) => {
    let newValue

    if (e.target.name === 'amount') {
      newValue = e.target.value ? parseFloat(e.target.value) : 0
    }
    if (e.target.name === 'date') {
      newValue = e.target.value
        ? commonHelpers.dateToTime(e.target.value)
        : commonHelpers.dateToTime(new Date())
    } else newValue = e.target.value

    let tmp = [...(list || [])]
    tmp = tmp.map((x) =>
      x.guid !== provider.guid
        ? x
        : {
            ...x,
            [e.target.name]: e.target.name === 'amount' ? +newValue : newValue
          }
    )
    setList(tmp)
    props.handleProviders(tmp)
  }

  const toggleOpenHandler = () => {
    setOpen((prevState) => !prevState)
  }

  const closeProviderDeleteDialog = () => {
    setshowProviderDeleteModal(false)
    setSelectedProvider(null)
  }

  const openProviderDeleteDialog = (provider) => {
    setSelectedProvider(provider)
    setshowProviderDeleteModal(true)
  }

  useEffect(() => {
    if (!list) setList(props.projectProviders)
  }, [props.projectProviders, list])

  return (
    <>
      <div className="providers-line-c">
        <h2>Fornitori esterni</h2>
        <span className="tools">
          <button
            onClick={toggleOpenHandler}
            className="lnk-estimate-clone"
            data-tip={open ? 'Collassa' : 'Espandi'}
            disabled={false}
          >
            <i
              className={
                open ? 'fas fa-compress-arrows-alt' : 'fas fa-expand-arrows-alt'
              }
            ></i>
          </button>
        </span>
        <ReactTooltip place="top" type="dark" effect="solid"></ReactTooltip>
      </div>

      {open && (
        <>
          <label>
            Fornitore
            <span className="span-add-customer">
              <select
                name="provider"
                onChange={openProviderRowDialog}
                value={provider}
              >
                <option value="">[Aggiungi Fornitore]</option>
                {(props.providers.list || [])
                  // .filter((x) => !(list || []).find((p) => p.id === x.id))
                  .map((c, index) => {
                    return (
                      <option key={index} value={c.id}>
                        {c.name}
                      </option>
                    )
                  })}
              </select>
              <button onClick={() => setShowModal(true)}>
                <i className="fas fa-plus"></i>
              </button>
            </span>
          </label>
          <ul className="ul-project-providers">
            {(list || []).map((provider, key) => {
              const p = props.providers.list.find((x) => x.id === provider.id)
              return (
                <li key={key}>
                  <span className="name">{p.name}</span>
                  <span className="description">
                    <label>Descrizione</label>
                    <input
                      type="text"
                      placeholder="Descrizione"
                      name="description"
                      value={provider.description}
                      onChange={(e) => changeInput(e, provider)}
                    />
                  </span>
                  <span className="date">
                    <label>Data competenza</label>
                    <input
                      type="date"
                      placeholder="Data competenza"
                      name="date"
                      value={commonHelpers.timeToDate(provider.date)}
                      onChange={(e) => changeInput(e, provider)}
                    />
                  </span>
                  <span className="amount">
                    <label>Importo</label>
                    <input
                      type="number"
                      placeholder="Importo"
                      min="0"
                      step="0.01"
                      name="amount"
                      value={provider.amount}
                      onChange={(e) => changeInput(e, provider)}
                    />
                  </span>
                  <span className="number">
                    <label>Numero fattura</label>
                    <input
                      type="text"
                      placeholder="Numero fattura"
                      name="invoice_num"
                      value={provider.invoice_num}
                      onChange={(e) => changeInput(e, provider)}
                    />
                  </span>
                  <span className="button">
                    <button onClick={() => openProviderDeleteDialog(provider)}>
                      <i className="far fa-trash-alt"></i>
                    </button>
                  </span>
                </li>
              )
            })}
          </ul>
        </>
      )}
      {showModal && (
        <RegistryQuickAdd
          cancel={() => setShowModal(false)}
          confirm={saveProviderHandler}
          title={'fornitore'}
        />
      )}
      {showProviderDeleteModal && (
        <Dialog
          title={'Elimina riga di costo'}
          subtitle={'Vuoi davvero eliminare questa riga?'}
          buttons={[
            {
              label: 'Annulla',
              func: closeProviderDeleteDialog,
              submit: undefined
            },
            {
              label: 'Elimina',
              func: removeProviderHandler,
              submit: true
            }
          ]}
        ></Dialog>
      )}
      {showProviderRowModal && (
        <ProviderRowDialog
          title="Riga di costo"
          name={selectedProvider.name}
          id={selectedProvider.id}
          cancel={closeProviderRowHandler}
          confirm={addProviderHandler}
        />
      )}
    </>
  )
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, { saveProvider })(ProjectProviders)
