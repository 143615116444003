export const animalConstants = [
  'bat.svg',
  'beaver.svg',
  'bee.svg',
  'beetle.svg',
  'bulldog.svg',
  'camel.svg',
  'canary.svg',
  'cat.svg',
  'chameleon.svg',
  'chicken.svg',
  'clown-fish.svg',
  'cobra.svg',
  'cow.svg',
  'crab.svg',
  'crocodile.svg',
  'duck.svg',
  'elephant.svg',
  'fox.svg',
  'frog.svg',
  'giraffe.svg',
  'hippopotamus.svg',
  'humming-bird.svg',
  'kangaroo.svg',
  'lion.svg',
  'llama.svg',
  'macaw.svg',
  'monkey.svg',
  'moose.svg',
  'mouse.svg',
  'octopus.svg',
  'ostrich.svg',
  'owl.svg',
  'panda.svg',
  'pelican.svg',
  'penguin.svg',
  'pig.svg',
  'rabbit.svg',
  'racoon.svg',
  'rhinoceros.svg',
  'shark.svg',
  'sheep.svg',
  'siberian-husky.svg',
  'sloth.svg',
  'snake.svg',
  'squirrel.svg',
  'swan.svg',
  'tiger.svg',
  'toucan.svg',
  'turtle.svg',
  'whale.svg'
]
