import React, { Component } from 'react'

import Frog from '../assets/frog.svg'

class Spinner extends Component {
  render () {
    return (
      <div className={'spinner ' + (this.props.ajax ? 'ajax' : '')}>
        <div className='circle'><img src={Frog} alt="frog" /></div>
      </div>
    )
  }
}

export default Spinner
