import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import update from 'immutability-helper'

import Modal from '../Modal'

// FIXME: poter confermare le epiche

import { estimatesHelpers, commonHelpers } from '../../helpers'
import { uiConstants } from '../../constants'
import RegistryQuickAdd from '../Registry/RegistryQuickAdd'

const initialState = {
  roles: [],
  epics: [],
  epicTitle: '',
  totalDays: '',
  totalCalculated: '',
  totalCustom: '',
  totalPercentage: '',
  totalConfirmed: '',
  totalCost: '',
  totalDelta: '',
  showDeleteModal: false,
  deleteMessage: '',
  deleteProps: null,
  firstHandle: false,
  showAddCustomer: false
}

class EstimateConfigurator extends Component {
  constructor() {
    super()
    this.state = initialState
    this.onChange = this.onChange.bind(this)
    this.escFunction = this.escFunction.bind(this)
    this.hideModal = this.hideModal.bind(this)
    this.confirmModalAction = this.confirmModalAction.bind(this)
  }

  escFunction(event) {
    if (event.ctrlKey) {
      switch (event.keyCode) {
        case 13:
          if (this.state.showDeleteModal) {
            this.confirmModalAction()
            event.preventDefault()
          }
          break
        default:
          break
      }
    }
    switch (event.keyCode) {
      case 27:
        this.hideModal()
        break
      default:
        break
    }
  }

  hideModal() {
    this.setState({ showDeleteModal: false, deleteMessage: '' })
  }

  confirmModalAction() {
    let epics
    const eIndex = this.state.deleteProps[0]
    if (this.state.deleteProps.length === 1) {
      epics = update(this.state.epics, { $splice: [[eIndex, 1]] })
    } else if (this.state.deleteProps.length === 2) {
      const hIndex = this.state.deleteProps[1]
      epics = update(this.state.epics, {
        [eIndex]: {
          heroes: {
            $splice: [[hIndex, 1]]
          }
        }
      })
    }
    this.setState(
      {
        epics,
        showDeleteModal: false,
        deleteMessage: '',
        deleteProps: null
      },
      () => {
        if (epics.length > 0) {
          this.recalculateEpic(eIndex)
        } else {
          this.recalculateStory()
        }
      }
    )
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  tryAddEpic(event) {
    if (event.charCode === 13) {
      this.addEpic()
    }
  }

  addEpic() {
    const s = this.state
    if (s.epicTitle !== '') {
      const epics = update(this.state.epics, {
        $push: [
          {
            title: s.epicTitle,
            heroes: [],
            heroSelect: '',
            days: '',
            percentage: '',
            calculated: '',
            cost: '',
            custom: '',
            confirmed: '',
            isConfirmed: false,
            delta: ''
          }
        ]
      })
      this.setState(
        {
          epics,
          epicTitle: ''
        },
        () => {
          ReactTooltip.rebuild()
          this.props.handleConfigurator(this.state)
        }
      )
    }
  }

  changeRoleValue(event, id) {
    const roles = [...this.state.roles].map((r) =>
      r.id === id ? { ...r, amount: event.target.value } : r
    )
    this.setState({ roles }, () => {
      const r = this.state.roles.find((x) => x.id === id)
      const epics = [...this.state.epics]
      epics.forEach((epic, eIndex) => {
        epic.heroes.forEach((h, hIndex) => {
          if (h.hero === r.id) {
            this.recalculateHero(eIndex, hIndex)
          }
        })
      })
      this.props.handleConfigurator(this.state)
    })
  }

  changeEpicTitle(event, eIndex) {
    const epics = update(this.state.epics, {
      [eIndex]: {
        title: { $set: event.target.value }
      }
    })
    this.setState({ epics }, () => {
      this.props.handleConfigurator(this.state)
    })
  }

  changeEpic(event, eIndex) {
    const e = this.state.epics
    let epics
    if (event.target.name === 'custom') {
      epics = update(this.state.epics, {
        [eIndex]: {
          touched: { $set: true },
          custom: { $set: parseInt(event.target.value, 10) },
          confirmed: { $set: parseInt(event.target.value, 10) },
          delta: { $set: parseInt(event.target.value, 10) - e[eIndex].cost }
        }
      })
    } else {
      epics = update(this.state.epics, {
        [eIndex]: {
          confirmed: { $set: parseInt(event.target.value, 10) },
          delta: { $set: parseInt(event.target.value, 10) - e[eIndex].cost }
        }
      })
    }
    this.setState({ epics }, () => {
      this.recalculateStory()
    })
  }

  addHero(event, eIndex) {
    let epics
    if (event.target.value === '') {
      epics = update(this.state.epics, {
        [eIndex]: {
          heroes: {
            $push: [
              {
                hero: 'Fornitore esterno',
                days: 1,
                percentage: 100,
                calculated: 0,
                cost: 0,
                custom: 0,
                confirmed: 0,
                delta: 0,
                touched: false,
                type: 'supplier'
              }
            ]
          },
          heroSelect: { $set: '' }
        }
      })
    } else {
      const hero = this.getHero(event.target.value)
      epics = update(this.state.epics, {
        [eIndex]: {
          heroes: {
            $push: [
              {
                hero: event.target.value,
                days: 1,
                percentage: 100,
                calculated: parseFloat(hero.amount, 10),
                cost: parseFloat(hero.cost, 10),
                custom: parseFloat(hero.amount, 10),
                confirmed: parseFloat(hero.amount, 10),
                delta: parseFloat(hero.amount, 10) - parseFloat(hero.cost, 10),
                touched: false,
                type: 'role'
              }
            ]
          },
          heroSelect: { $set: '' }
        }
      })
    }
    this.setState({ epics }, () => {
      this.recalculateEpic(eIndex)
    })
  }

  changeHero(event, eIndex, hIndex) {
    let epics
    if (
      ['days', 'percentage', 'custom', 'cost'].indexOf(event.target.name) > -1
    ) {
      const hero = this.state.epics[eIndex].heroes[hIndex]
      epics = update(this.state.epics, {
        [eIndex]: {
          heroes: {
            [hIndex]: {
              [event.target.name]: {
                $set: commonHelpers.float(event.target.value, '')
              },
              touched: {
                $set: event.target.name === 'custom' && hero.type === 'role'
              }
            }
          }
        }
      })
    } else {
      epics = update(this.state.epics, {
        [eIndex]: {
          heroes: {
            [hIndex]: {
              [event.target.name]: { $set: event.target.value },
              touched: { $set: event.target.name === 'custom' }
            }
          }
        }
      })
    }
    this.setState({ epics }, () => {
      this.recalculateHero(eIndex, hIndex)
    })
  }

  getHero(id) {
    const h = this.state.roles.filter((x) => x.id === id)
    if (h.length > 0) {
      return h[0]
    }
    return null
  }

  recalculateHero(eIndex, hIndex) {
    const h = this.state.epics[eIndex].heroes[hIndex]
    const hero = this.getHero(h.hero)
    let epics
    if (hero !== null) {
      const sum = commonHelpers.round(
        estimatesHelpers.calcPerc(h.days, h.percentage) * hero.amount
      )
      epics = update(this.state.epics, {
        [eIndex]: {
          heroes: {
            [hIndex]: {
              calculated: { $set: sum },
              cost: { $set: hero.cost * h.days },
              custom: { $set: h.touched ? h.custom : sum },
              delta: { $set: sum - hero.cost * h.days }
            }
          }
        }
      })
    } else {
      epics = update(this.state.epics, {
        [eIndex]: {
          heroes: {
            [hIndex]: {
              delta: { $set: h.custom - h.cost }
            }
          }
        }
      })
    }
    this.setState({ epics }, () => {
      this.recalculateEpic(eIndex)
    })
  }

  recalculateEpic(eIndex) {
    const e = this.state.epics
    if (e[eIndex]) {
      let epics
      if (e[eIndex].heroes.length > 0) {
        const calc = e[eIndex].heroes.reduce((previousValue, currentValue) => {
          return {
            days: previousValue.days + commonHelpers.float(currentValue.days),
            calculated:
              previousValue.calculated +
              commonHelpers.float(currentValue.calculated),
            custom:
              previousValue.custom + commonHelpers.float(currentValue.custom),
            cost: previousValue.cost + commonHelpers.float(currentValue.cost),
            percentage:
              previousValue.percentage +
              commonHelpers.float(currentValue.percentage)
          }
        })
        epics = update(this.state.epics, {
          [eIndex]: {
            days: { $set: commonHelpers.roundOneDecimal(calc.days) },
            calculated: { $set: commonHelpers.round(calc.calculated) },
            custom: { $set: commonHelpers.round(calc.custom) },
            confirmed: { $set: commonHelpers.round(calc.custom) },
            percentage: {
              $set: commonHelpers.round(
                calc.percentage / e[eIndex].heroes.length
              )
            },
            cost: { $set: calc.cost },
            delta: { $set: calc.custom - calc.cost },
            touched: { $set: false }
          }
        })
      } else {
        epics = update(this.state.epics, {
          [eIndex]: {
            days: { $set: '' },
            calculated: { $set: '' },
            custom: { $set: '' },
            confirmed: { $set: '' },
            percentage: { $set: '' },
            cost: { $set: '' },
            delta: { $set: '' },
            touched: { $set: false }
          }
        })
      }
      this.setState({ epics }, () => {
        this.recalculateStory()
      })
    } else {
      this.recalculateStory()
    }
  }

  recalculateStory() {
    const epics = [...this.state.epics]
    const e = this.props.item.e
    if (epics.length > 0) {
      const calc = {
        days: 0,
        calculated: 0,
        custom: 0,
        cost: 0,
        percentage: 0,
        confirmed: 0
      }
      epics.forEach((el) => {
        calc.days += commonHelpers.float(el.days)
        calc.calculated += commonHelpers.float(el.calculated)
        calc.custom += commonHelpers.float(el.custom)
        calc.cost += commonHelpers.float(el.cost)
        calc.percentage += commonHelpers.float(el.percentage)
        calc.confirmed +=
          e.lastStatus === uiConstants.estimateStatuses.confirmed
            ? el.isConfirmed
              ? commonHelpers.float(el.confirmed)
              : 0
            : commonHelpers.float(el.confirmed)
      })
      // console.log(x)
      // const calc = epics.reduce((previousValue, currentValue) => {
      //   return {
      //     days: previousValue.days + commonHelpers.float(currentValue.days),
      //     calculated: previousValue.calculated + commonHelpers.float(currentValue.calculated),
      //     custom: previousValue.custom + commonHelpers.float(currentValue.custom),
      //     cost: previousValue.cost + commonHelpers.float(currentValue.cost),
      //     percentage: previousValue.percentage + commonHelpers.float(currentValue.percentage),
      //     confirmed: (
      //       (e.lastStatus === uiConstants.estimateStatuses.confirmed)
      //         ? previousValue.confirmed + (currentValue.isConfirmed ? commonHelpers.float(currentValue.confirmed) : 0)
      //         : previousValue.confirmed + commonHelpers.float(currentValue.confirmed)
      //     )
      //     // confirmed: previousValue.confirmed + (currentValue.isConfirmed ? commonHelpers.float(currentValue.confirmed) : 0)
      //   }
      // })

      this.setState(
        {
          totalDays: calc.days,
          totalCalculated: commonHelpers.round(calc.calculated),
          totalCustom: commonHelpers.round(calc.custom),
          totalConfirmed:
            calc.confirmed > 0
              ? commonHelpers.round(calc.confirmed)
              : commonHelpers.round(calc.calculated),
          totalPercentage: commonHelpers.round(calc.percentage / epics.length),
          totalCost: calc.cost,
          totalDelta: calc.custom - calc.cost
        },
        () => {
          this.props.handleConfigurator(this.state)
        }
      )
    } else {
      this.setState(
        {
          totalDays: '',
          totalCalculated: '',
          totalCustom: '',
          totalPercentage: '',
          totalConfirmed: '',
          totalCost: '',
          totalDelta: ''
        },
        () => {
          this.props.handleConfigurator(this.state)
        }
      )
    }
  }

  selectUnselectAll() {
    let epics = [...this.state.epics]
    let nv = true
    let cont = 0
    epics.forEach((e, i) => {
      cont += e.isConfirmed ? 1 : 0
    })
    if (cont === epics.length) {
      nv = false
    }
    this.state.epics.forEach((e, i) => {
      epics = update(epics, {
        [i]: {
          isConfirmed: { $set: nv }
        }
      })
    })

    this.setState({ epics }, () => {
      this.recalculateStory()
    })
  }

  removeEpic(eIndex) {
    this.setState(
      {
        deleteMessage: 'Eliminare attività?',
        showDeleteModal: true,
        deleteProps: [eIndex]
      },
      () => {
        this.props.handleConfigurator(this.state)
      }
    )
  }

  removeHero(eIndex, hIndex) {
    this.setState({
      deleteMessage: 'Eliminare riga attività?',
      showDeleteModal: true,
      deleteProps: [eIndex, hIndex]
    })
  }

  confirmEpic(eIndex) {
    const e = this.state.epics[eIndex]
    const epics = update(this.state.epics, {
      [eIndex]: {
        isConfirmed: { $set: !e.isConfirmed }
      }
    })
    this.setState({ epics }, () => {
      this.recalculateStory()
    })
  }

  // static getDerivedStateFromProps(props, state) {
  //   if (props.item.id === null && props.companies !== state.companies) {
  //     const c = companiesHelpers.getCurrent()
  //     return {
  //       companies: props.companies,
  //       roles: c !== null ? c.roles : []
  //     }
  //   }
  //   if (props.item.configurator === undefined) {
  //     const c = companiesHelpers.getCurrent()
  //     if (props.item.e.number !== state.number) {
  //       return {
  //         companies: props.companies,
  //         roles: c !== null ? c.roles : [],
  //         epics: [],
  //         number: props.item.e.number,
  //         lastUpdate: props.item.e.lastUpdate,
  //         vIndex: props.vIndex
  //       }
  //     }
  //   } else {
  //     if (
  //       props.item.lastUpdate !== state.lastUpdate ||
  //       props.item.vIndex !== state.vIndex
  //     ) {
  //       return {
  //         roles: props.item.configurator.roles
  //           ? props.item.configurator.roles.length > 0
  //             ? props.item.configurator.roles.length
  //             : props.roles.list
  //           : props.roles.list,
  //         epics: props.item.configurator.epics,
  //         lastUpdate: props.item.lastUpdate,
  //         vIndex: props.item.vIndex,
  //         totalDays: props.item.configurator.totalDays,
  //         totalCalculated: props.item.configurator.totalCalculated,
  //         totalCustom: props.item.configurator.totalCustom,
  //         totalConfirmed: props.item.configurator.totalConfirmed,
  //         totalPercentage: props.item.configurator.totalPercentage,
  //         totalCost: props.item.configurator.totalCost,
  //         totalDelta: props.item.configurator.totalDelta
  //       }
  //     }
  //   }
  //   return null
  // }

  resetRoles() {
    this.setState({ roles: this.props.roles.list }, () => {
      this.props.handleConfigurator(this.state)
    })
  }

  popolateState() {
    const props = this.props

    this.setState({
      roles:
        (props.item.configurator?.roles || []).length > 0
          ? props.item.configurator.roles
          : props.roles.list,
      epics: props.item.configurator?.epics || [],
      lastUpdate: props.item.lastUpdate,
      vIndex: props.item.vIndex,
      totalDays: props.item.configurator?.totalDays,
      totalCalculated: props.item.configurator?.totalCalculated,
      totalCustom: props.item.configurator?.totalCustom,
      totalConfirmed: props.item.configurator?.totalConfirmed,
      totalPercentage: props.item.configurator?.totalPercentage,
      totalCost: props.item.configurator?.totalCost,
      totalDelta: props.item.configurator?.totalDelta
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.version !== this.props.version ||
      JSON.stringify(prevProps.roles.list) !==
        JSON.stringify(this.props.roles.list) ||
      JSON.stringify(prevProps.item) !== JSON.stringify(this.props.item)
    ) {
      this.popolateState()
    }
    // if (prevProps.companies.list) {
    //   if (
    //     prevProps.companies.list.length !== this.props.companies.list.length
    //   ) {
    //     this.props.handleConfigurator(this.state)
    //   }
    // }
    // if (!this.state.firstHandle) {
    //   this.setState({ firstHandle: true }, () => {
    //     this.props.handleConfigurator(this.state)
    //   })
    // }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false)

    this.popolateState()
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false)
  }

  render() {
    return (
      <div
        className={
          estimatesHelpers.formIsDisabled(this.props) ? 'form-disabled' : ''
        }
      >
        <h2>Configuratore</h2>
        <ul className="estimate-configurator-bone">
          <li className="registry">
            <h3>Profili</h3>
            <button
              className="registry-default"
              data-tip="Ripristina ruoli di default"
              onClick={() => this.resetRoles()}
            >
              <i className="fas fa-sync-alt"></i>
            </button>

            <ul className="roles">
              {(this.state.roles || []).map((r, index) => {
                return (
                  <li key={index}>
                    <label>
                      <span>{r.cost}</span>
                      {r.name} {r.unit && `(${r.unit})`}
                      <input
                        type="number"
                        value={r.amount}
                        onChange={(event) => this.changeRoleValue(event, r.id)}
                      />
                    </label>
                  </li>
                )
              })}
            </ul>
          </li>
          <li className="epics">
            <h3>Attività</h3>
            <label>
              titolo nuova attività
              <ul className="form-add-epic">
                <li className="txt">
                  <input
                    type="text"
                    name="epicTitle"
                    onChange={this.onChange}
                    value={this.state.epicTitle}
                    placeholder="Titolo attività"
                    onKeyPress={(event) => this.tryAddEpic(event)}
                  />
                </li>
                <li className="add">
                  <button
                    className="lnk-only-icon green"
                    onClick={() => this.addEpic()}
                    disabled={this.state.epicTitle === ''}
                  >
                    <i className="fas fa-plus"></i>
                  </button>
                </li>
              </ul>
            </label>

            <ul className="epics-list">
              {estimatesHelpers.showConfirmColumn(
                this.props.item.e.lastStatus
              ) && (
                <li>
                  <ul className="head">
                    <li className="epic-menu">
                      <button
                        className="lnk-confirm-epic alwaysEnabled yes"
                        onClick={() => this.selectUnselectAll()}
                      >
                        <i className="fas fa-check-double"></i>
                      </button>
                    </li>
                    <li className="txt-left">
                      Seleziona/Deseleziona tutte le attività
                    </li>
                  </ul>
                </li>
              )}
              {this.state.epics.map((e, index) => {
                return (
                  <li key={index}>
                    <ul className="head">
                      <li className="epic-menu">
                        {!estimatesHelpers.showConfirmColumn(
                          this.props.item.e.lastStatus
                        ) && (
                          <button
                            className="lnk-del-eh"
                            onClick={() => this.removeEpic(index)}
                          >
                            <i className="far fa-trash-alt"></i>
                          </button>
                        )}
                        {estimatesHelpers.showConfirmColumn(
                          this.props.item.e.lastStatus
                        ) && (
                          <button
                            className={
                              'lnk-confirm-epic alwaysEnabled ' +
                              (e.isConfirmed ? 'yes' : '')
                            }
                            onClick={() => this.confirmEpic(index)}
                          >
                            <i className="fas fa-check"></i>
                          </button>
                        )}
                      </li>
                      <li className="txt">
                        <input
                          type="text"
                          className="editable"
                          value={e.title}
                          onChange={(event) =>
                            this.changeEpicTitle(event, index)
                          }
                        />
                      </li>
                      <li className="num" data-tip="Giorni">
                        <i className="fas fa-calendar-day"></i>
                      </li>
                      <li className="num" data-tip="Percentuale accuratezza">
                        <i className="fas fa-percentage"></i>
                      </li>
                      <li className="num" data-tip="Costo per azienda">
                        <i className="fas fa-coins"></i>
                      </li>
                      <li
                        className="num"
                        data-tip="Costo per il cliente calcolato"
                      >
                        <i className="fas fa-square-root-alt"></i>
                      </li>
                      <li
                        className="num"
                        data-tip="Costo per cliente personalizzato"
                      >
                        <i className="fas fa-comment-dollar"></i>
                      </li>
                      <li className="num" data-tip="Margine">
                        <i className="fas fa-cheese"></i>
                      </li>
                      {estimatesHelpers.showConfirmColumn(
                        this.props.item.e.lastStatus
                      ) && (
                        <li className="num" data-tip="Attività confermata">
                          <i className="fas fa-clipboard-check"></i>
                        </li>
                      )}
                    </ul>
                    {e.heroes.map((h, index2) => {
                      return (
                        <ul className="hero" key={index2}>
                          <li className="epic-menu">
                            <button
                              className="lnk-del-eh"
                              onClick={() => this.removeHero(index, index2)}
                            >
                              <i className="far fa-trash-alt"></i>
                            </button>
                          </li>
                          <li className="txt">
                            {h.type === 'role' && (
                              <select
                                name="hero"
                                value={h.hero}
                                onChange={(event) =>
                                  this.changeHero(event, index, index2)
                                }
                              >
                                <option value=""></option>
                                {this.state.roles.map((r, index2) => {
                                  return (
                                    <option key={index2} value={r.id}>
                                      {r.name} {r.unit && `(${r.unit})`}
                                    </option>
                                  )
                                })}
                              </select>
                            )}
                            {h.type === 'supplier' && (
                              <input
                                type="text"
                                name="hero"
                                value={h.hero}
                                onChange={(event) =>
                                  this.changeHero(event, index, index2)
                                }
                              />
                            )}
                          </li>
                          {h.type === 'role' && (
                            <li className="num">
                              <input
                                type="number"
                                name="days"
                                value={h.days}
                                onChange={(event) =>
                                  this.changeHero(event, index, index2)
                                }
                                min="0"
                                step="1"
                              />
                            </li>
                          )}
                          {h.type === 'role' && (
                            <li className="num">
                              <input
                                type="number"
                                name="percentage"
                                value={h.percentage}
                                onChange={(event) =>
                                  this.changeHero(event, index, index2)
                                }
                                min="1"
                                max="100"
                                step="1"
                              />
                            </li>
                          )}
                          <li className="num">
                            <input
                              type="number"
                              name="cost"
                              value={h.cost}
                              onChange={(event) =>
                                this.changeHero(event, index, index2)
                              }
                              disabled={h.type === 'role'}
                            />
                          </li>
                          <li className="num">
                            {h.type === 'role' && (
                              <input
                                type="number"
                                name="calculated"
                                value={h.calculated}
                                onChange={(event) =>
                                  this.changeHero(event, index, index2)
                                }
                                disabled
                              />
                            )}
                          </li>
                          <li className="num">
                            <input
                              type="number"
                              className={h.touched ? 'touched' : ''}
                              name="custom"
                              value={h.custom}
                              onChange={(event) =>
                                this.changeHero(event, index, index2)
                              }
                            />
                          </li>
                          <li className="num">
                            <input
                              type="number"
                              name="delta"
                              value={h.delta}
                              onChange={(event) =>
                                this.changeHero(event, index, index2)
                              }
                              disabled
                            />
                          </li>
                          {estimatesHelpers.showConfirmColumn(
                            this.props.item.e.lastStatus
                          ) && <li className="num"></li>}
                        </ul>
                      )
                    })}
                    {/* NOTE: aggiunta hero delle epiche */}
                    {!estimatesHelpers.showConfirmColumn(
                      this.props.item.e.lastStatus
                    ) && (
                      <ul className="add-hero">
                        <li>
                          <select
                            onChange={(event) => this.addHero(event, index)}
                            value={e.heroSelect}
                          >
                            <option value="">
                              [Seleziona ruolo da aggiungere]
                            </option>
                            {this.state.roles.map((r, index2) => {
                              return (
                                <option key={index2} value={r.id}>
                                  {r.name} {r.unit && `(${r.unit})`}
                                </option>
                              )
                            })}
                            <option value="">[Fornitore Esterno]</option>
                          </select>
                        </li>
                      </ul>
                    )}
                    {
                      // NOTE: footer delle epiche
                      e.heroes.length > 0 && (
                        <ul className="bottom">
                          <li className="txt"></li>
                          <li className="num">{e.days}</li>
                          <li className="num">{e.percentage}</li>
                          <li className="num">
                            {commonHelpers.toMoney(e.cost)}
                          </li>
                          <li className="num">
                            {commonHelpers.toMoney(e.calculated)}
                          </li>
                          <li className="num">
                            <input
                              type="number"
                              className={
                                'editable ' + (e.touched ? 'touched' : '')
                              }
                              name="custom"
                              value={e.custom}
                              onChange={(event) =>
                                this.changeEpic(event, index)
                              }
                            />
                          </li>
                          <li className="num">
                            {commonHelpers.toMoney(e.delta)}
                          </li>
                          {estimatesHelpers.showConfirmColumn(
                            this.props.item.e.lastStatus
                          ) && (
                            <li className="num">
                              <input
                                type="number"
                                className="editable alwaysEnabled"
                                name="confirmed"
                                value={e.confirmed}
                                onChange={(event) =>
                                  this.changeEpic(event, index)
                                }
                              />
                            </li>
                          )}
                        </ul>
                      )
                    }
                  </li>
                )
              })}
            </ul>
            {this.state.totalDays !== '' && (
              <div>
                <ul className="epics-list">
                  <li>
                    <ul className="head">
                      <li className="epic-menu"></li>
                      <li className="txt"></li>
                      <li className="num" data-tip="Giorni">
                        <i className="fas fa-calendar-day"></i>
                      </li>
                      <li className="num" data-tip="Percentuale accuratezza">
                        <i className="fas fa-percentage"></i>
                      </li>
                      <li className="num" data-tip="Costo per azienda">
                        <i className="fas fa-coins"></i>
                      </li>
                      <li
                        className="num"
                        data-tip="Costo per il cliente calcolato"
                      >
                        <i className="fas fa-square-root-alt"></i>
                      </li>
                      <li
                        className="num"
                        data-tip="Costo per cliente personalizzato"
                      >
                        <i className="fas fa-comment-dollar"></i>
                      </li>
                      <li className="num" data-tip="Margine">
                        <i className="fas fa-cheese"></i>
                      </li>
                      {estimatesHelpers.showConfirmColumn(
                        this.props.item.e.lastStatus
                      ) && (
                        <li className="num" data-tip="Attività confermata">
                          <i className="fas fa-clipboard-check"></i>
                        </li>
                      )}
                    </ul>
                  </li>
                </ul>
                <ul className="epic-total">
                  <li className="txt">Totale</li>
                  <li className="num">{this.state.totalDays}</li>
                  <li className="num">{this.state.totalPercentage}</li>
                  <li className="num">
                    {commonHelpers.toMoney(this.state.totalCost)}
                  </li>
                  <li className="num">
                    {commonHelpers.toMoney(this.state.totalCalculated)}
                  </li>
                  <li className="num">
                    {commonHelpers.toMoney(this.state.totalCustom)}
                  </li>
                  <li className="num">
                    {commonHelpers.toMoney(this.state.totalDelta)}
                  </li>
                  {estimatesHelpers.showConfirmColumn(
                    this.props.item.e.lastStatus
                  ) && (
                    <li className="num">
                      {commonHelpers.toMoney(this.state.totalConfirmed)}
                    </li>
                  )}
                </ul>
              </div>
            )}
          </li>
        </ul>
        {this.state.showDeleteModal && (
          <Modal cancel={this.hideModal} confirm={this.confirmModalAction}>
            <h3>Elimina</h3>
            {this.state.deleteMessage}
          </Modal>
        )}
        {this.state.showAddCustomer && (
          <RegistryQuickAdd
            cancel={() => this.setState({ showAddCustomer: false })}
            confirm={this.addCustomerHandler}
            title={'cliente'}
          />
        )}
        <ReactTooltip place="top" type="dark" effect="solid" />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {})(EstimateConfigurator)
