import React, { useState, useEffect, useRef } from 'react'

import css from './Autocomplete.module.scss'

const Autocomplete = ({ onSelect, list, placeholder }) => {
  const [input, setInput] = useState('')
  const [showList, _setShowList] = useState(false)
  const showRef = useRef(showList)

  const setShowList = (value) => {
    showRef.current = value
    _setShowList(value)
  }

  const choiceHandler = (c) => {
    setInput(c.name)
    setShowList(false)
    onSelect(c.id)
  }

  useEffect(() => {
    const handleMouseUp = (event) => {
      const c = JSON.stringify(event.target.classList)
      if (c.indexOf('autocomplete') === -1) {
        if (showRef.current) {
          setShowList(false)
          if (input === '') {
            onSelect('')
          }
        }
      }
    }

    const handleKeyUp = (key) => {
      if (key.keyCode === 13) {
        if (input === '') {
          onSelect('')
        }
      }
    }

    window.addEventListener('mouseup', handleMouseUp)
    window.addEventListener('keyup', handleKeyUp)
    return () => {
      window.removeEventListener('mouseup', handleMouseUp)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [input, onSelect])

  const filteredList = () => {
    return (list || [])
      .filter((x) => x.name.toUpperCase().indexOf(input.toUpperCase()) > -1)
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
  }

  return (
    <div className={css.Container}>
      <input
        type="text"
        className="input-autocomplete"
        placeholder={placeholder}
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onFocus={() => setShowList(true)}
      />
      {showList && (
        <div className={css.Autocomplete}>
          {filteredList().map((c) => {
            return (
              <span
                key={c.id}
                className="mouseup-autocomplete"
                onClick={() => choiceHandler(c)}
              >
                {c.name}
              </span>
            )
          })}
          {filteredList().length === 0 && (
            <span className={css.Nothing}>Nessun risultato</span>
          )}
        </div>
      )}
    </div>
  )
}

export default Autocomplete
