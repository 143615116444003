import { sprintConstants } from '../constants'
import { produce } from 'immer'

const initialState = {
  list: [],
  error: null,
  loading: false,
  ajaxSpinner: false,
  ajaxStatus: null
}

export default (state = initialState, action) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case sprintConstants.UPDATE_SPRINTS:
        draftState.list = !draftState.list.length
          ? [action.payload]
          : draftState.list.findIndex((x) => x.id === action.payload.id) === -1
          ? draftState.list.concat(action.payload)
          : draftState.list.map((item) => {
              return item.id === action.payload.id ? action.payload : item
            })

        break
      case sprintConstants.SPRINT_RESET:
        draftState.list = []
        break
      default:
        return draftState
    }
  })
