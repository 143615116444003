import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import { colleaguesHelpers, commonHelpers, customersHelpers } from '../../helpers'
import { uiConstants } from '../../constants'
const images = require.context('../../assets/animals', true)

class EstimateLine extends PureComponent {
  baseUrl () {
    const e = this.props.e
    if (e.lastStatus === uiConstants.estimateStatuses.confirmed) {
      return 'orders'
    }
    return 'estimates'
  }

  render () {
    const e = this.props.e
    const url = `/${this.baseUrl()}/${e.id}/${e.number}/${e.v.length}`
    const cloneUrl = `/estimates/new/${e.id}`
    const v = e.v[e.v.length - 1]
    const owner = colleaguesHelpers.getUserByUid(v.info.account)
    return (
      <div className="estimate-line-c">
        <Link to={url} className={'lnk-estimate-line ' + e.lastStatus}>
          <span className="number">{e.number}</span>
          <span className="title">{v.info.title}</span>
          <span className="amount">{commonHelpers.toMoney(v.configurator.totalCustom)}</span>
          {
            owner !== e.owner && (
              <span className="account"><img src={images(`./${owner.avatar}`)} alt={owner.displayName} /> {owner.displayName}</span>
            )
          }
          <span className="customer">{customersHelpers.getNameByUid(v.info.customer)}</span>
          <span className="date">{commonHelpers.timeToDateFull(v.versionDate)}</span>

        </Link>
        <span className="clone">
          <Link to={cloneUrl} className="lnk-estimate-clone" data-tip='clona'>
            <i className="fas fa-clone"></i>
          </Link>
        </span>
        <ReactTooltip place="top" type="dark" effect="solid"></ReactTooltip>
      </div>
    )
  }
}

export default EstimateLine
