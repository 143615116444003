import { colleaguesConstants } from '../constants'

const initialState = {
  list: null,
  error: null,
  loading: false,
  ajaxSpinner: false,
  ajaxStatus: null,
  ajaxAction: null
}

export default function colleagues (state = initialState, action) {
  switch (action.type) {
    case colleaguesConstants.LOADING_COLLEAGUES:
      return { ...state, loading: true, error: null }
    case colleaguesConstants.LOADING_COLLEAGUES_ERROR:
      return { ...state, loading: false, error: action.payload }
    case colleaguesConstants.UPDATE_COLLEAGUE:
      return {
        ...state,
        list: (state.list === null) ? [action.payload] : ((state.list.findIndex(x => x.id === action.payload.id) === -1)
          ? state.list.concat(action.payload)
          : state.list.map((item) => {
            return (item.id === action.payload.id) ? action.payload : item
          })),
        ajaxAction: 'update',
        loading: false
      }
    case colleaguesConstants.REMOVE_COLLEAGUE:
      return {
        ...state,
        list: state.list.filter((item) => {
          return item.id !== action.payload.id
        }),
        ajaxAction: 'remove',
        loading: false
      }
    default:
      return state
  }
}
