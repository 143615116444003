import React, { Component } from 'react'

const DropdownList = ({ listItems, toggleShow }) => {
  return listItems
    .filter((x) => x !== null)
    .map((i, index) => {
      return (
        <li
          key={index}
          onClick={() => {
            i.click()
            toggleShow()
          }}
        >
          {i.text}
        </li>
      )
    })
}

class Dropdown extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show: false,
    }

    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.toggleShow()
    }
  }

  toggleShow = () => {
    this.setState({ show: !this.state.show })
  }

  render() {
    return (
      <div className="dropdown-wrapper">
        <div className="button button-options" onClick={this.toggleShow}>
          {this.state.show && (
            <ul id="dropdown" ref={this.setWrapperRef}>
              <div className="arrow-up"></div>
              <DropdownList
                listItems={this.props.listItems}
                toggleShow={this.toggleShow}
              />
            </ul>
          )}
        </div>
      </div>
    )
  }
}

export default Dropdown
