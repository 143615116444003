import React, { Component } from 'react'
import { connect } from 'react-redux'

import { uiConstants } from '../../constants'

import { localSaveOrderFilter, saveOrderFilter } from '../../actions'

const initialState = {
  searchTerm: undefined,
  customer: '',
  fromDate: '',
  toDate: ''
}

class OrdersFilter extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
  }

  turnOffFilter(f) {
    const ordersFilters = { ...this.props.user.profile.ordersFilters }
    ordersFilters[f.value] = !ordersFilters[f.value]
    this.props.saveOrderFilter(ordersFilters)
  }

  saveFilters(local = false) {
    const ordersFilters = this.props.user.profile.ordersFilters
    const nf = {
      ...ordersFilters,
      searchTerm: this.state.searchTerm,
      customer: this.state.customer
    }
    if (local) {
      this.props.localSaveOrderFilter(nf)
    } else {
      this.props.saveOrderFilter(nf)
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.searchTerm === undefined) {
      return {
        searchTerm: props.user.profile.ordersFilters.searchTerm,
        customer: props.user.profile.ordersFilters.customer
      }
    }
    return null
  }

  render() {
    const profile = this.props.user.profile
    const customers = this.props.customers.list
    return (
      <ul className="filters-list">
        {uiConstants.ordersFilters.map((f, index) => {
          return (
            <li key={index}>
              <div>
                <button
                  className={
                    'btn-flt ' +
                    f.value +
                    (profile.estimatesFilters[f.value] ? ' active' : '')
                  }
                  onClick={() => this.turnOffFilter(f)}
                >
                  {f.label}
                </button>
                <div className="amount-status">
                  {this.props.subtotal
                    ? this.props.subtotal[f.value] + ' €'
                    : ''}
                </div>
              </div>
            </li>
          )
        })}
        <li>
          <select
            onChange={(event) =>
              this.setState({ customer: event.target.value }, () => {
                this.saveFilters()
              })
            }
            value={this.state.customer}
          >
            <option value="">[Cliente]</option>
            {(customers || []).map((c, index) => {
              return (
                <option key={index} value={c.id}>
                  {c.name}
                </option>
              )
            })}
          </select>
        </li>
        <li>
          <input
            type="text"
            placeholder="Cerca"
            onChange={(event) =>
              this.setState({ searchTerm: event.target.value }, () => {
                this.saveFilters(true)
              })
            }
            value={this.state.searchTerm}
            onBlur={() => this.saveFilters()}
          />
        </li>
      </ul>
    )
  }
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {
  localSaveOrderFilter,
  saveOrderFilter
})(OrdersFilter)
