import React, { Component } from 'react'
import {
  TextInput,
  RadioInput,
  SelectInput,
  Buttons,
  TextAreaInput,
} from './Inputs'

class Dialog extends Component {
  render() {
    const colDiv = 12 / this.props.columns
    return (
      <div className="dialog">
        <div className="modal-container">
          <div className="modal">
            {this.props.childrenPreTitle && this.props.children}
            <h2 className="title">{this.props.title}</h2>
            {this.props.subtitle ? (
              <h3 className="subtitle">{this.props.subtitle}</h3>
            ) : (
              ''
            )}
            {this.props.viewNote && this.props.viewNote !== '' ? (
              <h4 className="subtitle">{this.props.viewNote}</h4>
            ) : (
              ''
            )}
            {this.props.beforeDialogBody}
            {this.props.formInputs ? (
              <form>
                {this.props.formInputs &&
                  Object.keys(this.props.formInputs).map((key) => {
                    let type = this.props.formInputs[key].type

                    let sColDiv = this.props.formInputs[key]?.col || colDiv

                    //Check for special input types
                    if (type === 'radio') {
                      return (
                        <RadioInput
                          key={key}
                          id={key}
                          input={this.props.formInputs[key]}
                          className={'col' + sColDiv}
                          onChange={(e) =>
                            this.props.editState(key, e.target.value)
                          }
                          value={
                            this.props.values ? this.props.values[key] : ''
                          }
                        />
                      )
                    } else if (type === 'select') {
                      return (
                        <SelectInput
                          error={this.props.validationError || []}
                          key={key}
                          id={key}
                          className={'col' + sColDiv}
                          input={this.props.formInputs[key]}
                          onChange={(e) =>
                            this.props.editState(key, e.target.value)
                          }
                          value={
                            this.props.values ? this.props.values[key] : ''
                          }
                        />
                      )
                    } else if (type === 'textarea') {
                      return (
                        <TextAreaInput
                          error={this.props.validationError || []}
                          key={key}
                          id={key}
                          input={this.props.formInputs[key]}
                          className={
                            this.props.formInputs[key].col
                              ? 'col' + this.props.formInputs[key].col
                              : 'col' +
                                sColDiv +
                                (' ' +
                                  (this.props.formInputs[key].class &&
                                    this.props.formInputs[key].class))
                          }
                          onChange={(e) =>
                            this.props.editState(key, e.target.value)
                          }
                          value={
                            this.props.values ? this.props.values[key] : ''
                          }
                        />
                      )
                    } else {
                      return (
                        <TextInput
                          error={this.props.validationError || []}
                          key={key}
                          id={key}
                          input={this.props.formInputs[key]}
                          className={
                            this.props.formInputs[key].col
                              ? 'col' + this.props.formInputs[key].col
                              : 'col' +
                                sColDiv +
                                (' ' +
                                  (this.props.formInputs[key].class &&
                                    this.props.formInputs[key].class))
                          }
                          onChange={(e) =>
                            this.props.editState(key, e.target.value)
                          }
                          value={
                            this.props.values ? this.props.values[key] : ''
                          }
                        />
                      )
                    }
                  })}
              </form>
            ) : (
              ''
            )}

            {this.props.childrenAfter && this.props.children}

            {this.props.validationError && (
              <span className="response-error">
                <p>
                  {this.props.validationError &&
                  Object.keys(this.props.validationError).length > 1
                    ? 'Più campi vuoti da compilare prima di procedere'
                    : `Compilare il campo "${
                        this.props.validationError[
                          Object.keys(this.props.validationError)[0]
                        ]
                      }" prima di procedere`}
                </p>
              </span>
            )}

            <Buttons buttons={this.props.buttons} />
          </div>
        </div>
      </div>
    )
  }
}

export default Dialog
