import React, { Component } from "react"
import _ from "lodash"

export default class TopInfo extends Component {
    formatUNIXDate(unixdate) {
        let date = new Date(unixdate * 1000)
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()

        return day + "/" + month + "/" + year
    }

    firstBtnClick = () => this.props.firstBtnFunction("")

    secondBtnClick = () => this.props.secondBtnFunction("")

    redBtnClick = () => this.props.redBtnFunction("")

    backBtnClick = () => this.props.backButton("")

    render() {
        return (
            <div className="topinfobar-wrapper">
                <div className="title-wrapper">
                    <h1>
                        {this.props.backButton && (
                            <span className="back" onClick={this.backBtnClick}>
                                <i
                                    aria-hidden="true"
                                    className="fas fa-arrow-alt-circle-left"
                                ></i>
                            </span>
                        )}
                        {this.props.title}
                    </h1>
                    <div className="buttons-wrapper">
                        {this.props.firstBtnFunction && (
                            <button
                                className="btn"
                                onClick={this.firstBtnClick}
                            >
                                <i
                                    className="fas fa-plus"
                                    aria-hidden="true"
                                ></i>
                                {this.props.firstBtnText}
                            </button>
                        )}
                        {this.props.secondBtnFunction && (
                            <button
                                className="btn"
                                onClick={this.secondBtnClick}
                            >
                                <i
                                    className="fas fa-plus"
                                    aria-hidden="true"
                                ></i>
                                {this.props.secondBtnText}
                            </button>
                        )}
                        {this.props.redBtnFunction && (
                            <button
                                className="btn"
                                onClick={this.redBtnClick}
                                style={{ backgroundColor: "#e74c3c" }}
                            >
                                <i
                                    className="fas fa-trash-alt"
                                    aria-hidden="true"
                                ></i>
                                {this.props.redBtnText}
                            </button>
                        )}
                    </div>
                </div>
                {!this.props.hideInfo ? (
                    <div>
                        <div className="info-wrapper">
                            <div className="spent">
                                <p>Ore Spese:</p>
                                <span>{_.round(this.props.spentHours, 1)}</span>
                            </div>
                            <div className="estimated">
                                <p>Ore Stimate:</p>
                                <span>{this.props.estimatedHours}</span>
                            </div>
                            <div className="date">
                                <p>Data Fine:</p>
                                <span>{this.props.endDate}</span>
                            </div>
                        </div>
                        <div className="progressbar-wrapper">
                            <div className="outer-bar">
                                <div className="percentual">{`${_.round(
                                    (this.props.spentHours /
                                        this.props.estimatedHours) *
                                        100,
                                    1
                                ) || 0}%`}</div>
                                <div
                                    className="inner-bar"
                                    style={{
                                        width: `${(this.props.spentHours /
                                            this.props.estimatedHours) *
                                            100}%`,
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </div>
        )
    }
}
