import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { commonHelpers } from '../helpers/common.helpers'

import VacationTable from './Vacation/VacationTable/VacationTable'
import VacationFilter from './Vacation/VacationFilter/VacationFilter'

import {
  loadVacationDescription,
  saveVacationFilter,
  usersLoad,
  loadCalendar,
  loadVacation,
  createVacation,
  saveVacation,
  showLoader,
  hideLoader
} from '../actions'

const Vacation = (props) => {
  const {
    loadVacationDescription,
    saveVacationFilter,
    loadCalendar,
    usersLoad,
    loadVacation,
    createVacation,
    saveVacation,
    showLoader,
    hideLoader
  } = props
  const [currentMonth, setCurrentMonth] = useState(null)
  const [configuredDays, setConfiguredDays] = useState([])
  const [descList, setDescList] = useState(null)
  const [year, setYear] = useState(null)
  const [employeeUid, setEmployeeUid] = useState(null)

  const { vacationFilters } = props.user.profile

  useEffect(() => {
    if (!props.vacationDescriptions.list) loadVacationDescription()
    else {
      setDescList([...props.vacationDescriptions.list])
    }
  }, [props.vacationDescriptions.list, loadVacationDescription])

  useEffect(() => {
    if (year) {
      if (year !== props.user.profile.currentYear) {
        const cm = commonHelpers.setYMFromMonth(
          currentMonth,
          props.user.profile.currentYear
        )
        setCurrentMonth(
          cm || commonHelpers.currentMonthYear(props.user.profile.currentYear)
        )
        saveVacationFilter({
          ...vacationFilters,
          currentMonth: currentMonth
        })
        setYear(props.user.profile.currentYear)
      } else if (
        vacationFilters &&
        vacationFilters.currentMonth &&
        currentMonth !== vacationFilters.currentMonth
      ) {
        const cm = commonHelpers.setYMFromMonth(
          vacationFilters.currentMonth,
          year
        )

        setCurrentMonth(cm || commonHelpers.currentMonthYear(year))
      } else if (!vacationFilters || !vacationFilters.currentMonth) {
        setCurrentMonth(commonHelpers.currentMonthYear(year))
      }
    } else {
      setYear(props.user.profile.currentYear)
      setCurrentMonth(props.user.profile.currentYear)
    }
  }, [
    props.user.profile.currentYear,
    year,
    vacationFilters,
    currentMonth,
    saveVacationFilter
  ])

  useEffect(() => {
    loadVacation(props.user.profile.currentYear)
  }, [loadVacation, props.user.profile.currentYear])

  useEffect(() => {
    if (!props.user.list || props.user.list === []) {
      usersLoad()
    }
  }, [usersLoad, props.user.list])

  useEffect(() => {
    if (props.calendar.year.year !== props.user.profile.currentYear) {
      loadCalendar(props.user.profile.currentYear)
    }
  }, [loadCalendar, props.user.profile.currentYear, props.calendar.year.year])

  useEffect(() => {
    const filterArray = []

    const sm = moment.unix(currentMonth).utc().startOf('month').unix()
    const em = moment.unix(currentMonth).utc().endOf('month').unix()

    if (props.calendar.year.days && props.calendar.year.days.length > 0) {
      props.calendar.year.days.forEach((x) => {
        if (x <= em && x >= sm) filterArray.push(x)
      })
    }

    setConfiguredDays(filterArray)
    hideLoader()
  }, [currentMonth, props.calendar.year.days, hideLoader])

  const getTotalSum = useCallback(() => {
    let sum = 0
    if (
      employeeUid &&
      props.vacations.list &&
      props.vacations.list.find(
        (i) =>
          i.month === currentMonth && i.year === props.user.profile.currentYear
      )
    ) {
      if (
        props.vacations.list.find(
          (i) =>
            i.month === currentMonth &&
            i.year === props.user.profile.currentYear
        ).users
      ) {
        const userData = props.vacations.list
          .find(
            (i) =>
              i.month === currentMonth &&
              i.year === props.user.profile.currentYear
          )
          .users.find((i) => i.uid === employeeUid)

        if (userData && userData.records) {
          userData.records.forEach((i) => {
            sum += i.value ?? 0
          })
        }
      }
    }
    return sum.toFixed(2).toString().replace('.', ',')
  }, [
    employeeUid,
    currentMonth,
    props.user.profile.currentYear,
    props.vacations.list
  ])

  const changeMonth = (dir) => {
    showLoader()
    const newVal = commonHelpers.addMonthCurrentYear(currentMonth, dir)
    saveVacationFilter({
      ...props.user.profile.vacationFilters,
      currentMonth: newVal
    })
    setCurrentMonth(newVal)
  }

  const saveHandler = useCallback(
    (id, item) => {
      if (!id) {
        createVacation(item)
      } else {
        saveVacation(id, item)
      }
    },
    [createVacation, saveVacation]
  )

  const selectEmpHandler = (uid) => {
    setEmployeeUid(uid)
  }

  const exportHandler = () => {}

  return (
    <div className="calendar-container">
      <h1>Presenze</h1>
      <ul className="vacation-nav">
        <li>
          <button className="lnk-utils big" onClick={() => exportHandler()}>
            <i className="fas fa-file-download" /> ESPORTA
          </button>
        </li>
        <li>
          <button className="lnk-utils nm" onClick={() => changeMonth(-1)}>
            <i className="fas fa-chevron-left"></i>
          </button>
        </li>
        <li className="current">{commonHelpers.timeToMonth(currentMonth)}</li>
        <li>
          <button className="lnk-utils nm" onClick={() => changeMonth(1)}>
            <i className="fas fa-chevron-right"></i>
          </button>
        </li>
      </ul>

      <ul className="vacation-header">
        <li>
          <VacationFilter
            users={(props.user.list || [])
              .filter((i) => i.manage_vacation === true)
              .sort((a, b) => (a.displayName > b.displayName ? 1 : -1))}
            onSelect={selectEmpHandler}
          />
        </li>
        <li className="total-month">
          <div className="end">
            <span>Totale del mese: {getTotalSum()}</span>
          </div>
        </li>
      </ul>

      {descList && (
        <VacationTable
          month={currentMonth}
          year={props.user.profile.currentYear}
          types={descList}
          users={(props.user.list || []).filter(
            (i) => i.manage_vacation === true && i.uid === employeeUid
          )}
          calendarDays={configuredDays}
          record={props.vacations.list || []}
          onSave={saveHandler}
        />
      )}
    </div>
  )
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {
  saveVacationFilter,
  loadVacationDescription,
  usersLoad,
  loadCalendar,
  loadVacation,
  createVacation,
  saveVacation,
  showLoader,
  hideLoader
})(Vacation)
