import React, { Component } from 'react'

import ProjectsFilters from './Projects/ProjectsFilters.jsx'
// import ProjectRecord from './Projects/ProjectRecord.jsx'
// import Task from './Projects/Task.jsx'
import TopInfo from './TopInfo'
import ProjectDialog from './Projects/modals/ProjectDialog'
import TimeDialog from './Projects/modals/TimeDialog'
import MultiTsDialog from './MultiTsDialog'
import ProjectArchivedNoteDialog from './Projects/modals/ProjectArchivedNoteDialog'

import { connect } from 'react-redux'
import {
  toggleProject,
  toggleTask,
  saveTask,
  saveExecution,
  saveProject,
  saveMultipleExecutions,
  changeProjectArchivedStatus,
  saveProjectFilter
} from '../actions'

// import { ProjectCol } from '../constants/project.constants'
import { dialogSetTime } from '../constants/tasks.constants'

import ProjectLine from './Projects/ProjectLine'
import TaskLine from './Projects/TaskLine'
import { commonHelpers } from '../helpers'
import _ from 'lodash'
// import produce from 'immer'
const moment = commonHelpers.getMoment()

class Projects extends Component {
  constructor(props) {
    super(props)

    let formData = {}
    Object.keys(dialogSetTime).map(
      (info) => (formData[info] = dialogSetTime[info].default)
    )

    this.state = {
      currentYear: commonHelpers.currentYear(),
      showArchived: false,

      description: '',
      deadline: '',
      assigned: '',

      multipleDatesToSetForm: '',
      selectedExecution: '',

      selectedTask: '',

      showProjectModal: false,

      showProjectArchivedNote: false,
      projectArchivedNote: null,
      ...formData
    }
  }

  //functions that manage the execution's modal
  editState = (key, value) => {
    this.setState({ [key]: value })
  }

  toggleProjectModal = () => {
    this.setState({
      showProjectModal: !this.state.showProjectModal
    })
  }

  toggleSetTimeModal = (id) => {
    this.setState({
      selectedTask: this.state.selectedTask ? '' : id
    })
  }

  //methods that manage table's functionality
  setFilter = (key, value) => {
    // const ns = produce((draftState) => {
    //   draftState[key] = value
    // })
    const ns = {
      ...this.state
    }
    ns[key] = value
    this.setState({ ...ns }, () => {
      this.props.saveProjectFilter({
        description: ns.description,
        deadline: ns.deadline,
        assigned: ns.assigned,
        showArchived: ns.showArchived,
        customer: ns.customer || ''
      })
    })
  }

  toggleRow = (id, isTask) => {
    let payload = {
      id: isTask ? isTask : id
    }

    isTask ? this.props.toggleTask(payload) : this.props.toggleProject(payload)
  }

  changeArchiveStatus = (id, archived) => {
    // console.log(id, !archived)
    this.props.changeProjectArchivedStatus(id, !archived)
  }

  updateRecording = (executionId, taskId) => {
    console.log(executionId, taskId)

    let payload = {}
    let currentDate = moment().unix()

    let selectedExecution = _.find(this.props.filteredExecutions, {
      id: executionId
    })

    if (
      (selectedExecution &&
        moment(commonHelpers.timeToDate(selectedExecution.end_date)).isSame(
          commonHelpers.timeToDate(currentDate),
          'day'
        )) ||
      selectedExecution?.recording
    ) {
      if (
        moment(commonHelpers.timeToDate(selectedExecution.start_date)).isSame(
          commonHelpers.timeToDate(currentDate),
          'day'
        )
      ) {
        payload.recording = !selectedExecution.recording
        payload.end_date = currentDate
        payload.edit_date = currentDate

        this.props.saveExecution(executionId, payload)
      } else {
        let dates = commonHelpers.enumerateDaysBetweenDates(
          commonHelpers.timeToDate(selectedExecution.start_date),
          commonHelpers.timeToDate(currentDate)
        )

        let datesObject = {}

        dates.forEach(
          (date) =>
            (datesObject[date] = {
              label: date,
              type: 'number',
              id: date,
              col: 6,
              default: 0
            })
        )

        this.setState({
          multipleDatesToSetForm: datesObject,
          selectedExecution: selectedExecution
        })
      }
    } else {
      payload.creation_date = currentDate
      payload.start_date = currentDate
      payload.end_date = currentDate
      payload.edit_date = currentDate
      payload.task_id = taskId
      payload.recording = true
      payload.user_id = this.props.user.profile.id

      this.props.saveExecution(executionId, payload)
    }
  }

  toggleMultiTs = () => {
    this.setState({
      multipleDatesToSetForm: ''
    })
  }

  // generateTasks = () => {
  //   let tasks = []
  //   //generating the rows that will be passed to the table
  //   this.props.projects.list
  //     .filter(
  //       (project) =>
  //         (_.toUpper(project.name).includes(
  //           _.toUpper(this.state.description)
  //         ) ||
  //           this.state.description === '') &&
  //         (project.deadline <= this.state.deadline ||
  //           this.state.deadline === '') &&
  //         ((this.state.assigned !== '' &&
  //           _.filter(
  //             this.props.tasks.list,
  //             (task) =>
  //               task.resources.includes(this.props.user.profile.id) &&
  //               project.tasks.includes(task.id)
  //           ).length) ||
  //           this.state.assigned === '')
  //     )
  //     .filter(
  //       (x) =>
  //         commonHelpers.timeToYYYY(x.creation_date) === this.state.currentYear
  //     )
  //     .filter((x) => x.archived === this.state.showArchived)
  //     .forEach((project, i) => {
  //       tasks.push(
  //         <ProjectRecord
  //           key={project.id}
  //           columns={ProjectCol}
  //           project={project}
  //           toggleRow={this.toggleRow}
  //           changeArchiveStatus={this.changeArchiveStatus}
  //           index={i}
  //           hasTask={project.tasks.length}
  //           admin={this.props.user.profile.admin}
  //           saveProject={this.props.saveProject}
  //         />
  //       )
  //       if (project.open) {
  //         _.filter(this.props.tasks.list, (task) =>
  //           project.tasks.includes(task.id)
  //         ).forEach((task, i) => {
  //           tasks.push(
  //             <Task
  //               key={task.id}
  //               task={task}
  //               columns={TaskCol}
  //               isSubtask={false}
  //               last={i === project.tasks.length - 1}
  //               projectIndex={this.props.index}
  //               index={i}
  //               saveTask={this.props.saveTask}
  //               toggleRow={task.subtasks?.length ? this.toggleRow : undefined}
  //               toggleSetTimeModal={this.toggleSetTimeModal}
  //               userId={this.props.user.profile.id}
  //               resources={task.resources}
  //               updateRecording={this.updateRecording}
  //               lastRecord={
  //                 _.orderBy(
  //                   _.filter(this.props.filteredExecutions, {
  //                     task_id: task.id,
  //                   }),
  //                   'end_date',
  //                   'desc'
  //                 )[0]
  //               }
  //               recording={_.find(this.props.filteredExecutions, {
  //                 user_id: this.props.user.profile.id,
  //                 recording: true,
  //               })}
  //             />
  //           )
  //           if (task.open) {
  //             _.filter(this.props.tasks.list, (subtask) =>
  //               task.subtasks.includes(subtask.id)
  //             ).forEach((subtask) => {
  //               tasks.push(
  //                 <Task
  //                   key={subtask.id}
  //                   task={subtask}
  //                   columns={TaskCol}
  //                   isSubtask={true}
  //                   saveTask={this.props.saveTask}
  //                   saveExecution={this.props.saveExecution}
  //                   toggleSetTimeModal={this.toggleSetTimeModal}
  //                   userId={this.props.user.profile.id}
  //                   resources={subtask.resources}
  //                   updateRecording={this.updateRecording}
  //                   lastRecord={
  //                     _.orderBy(
  //                       _.filter(this.props.filteredExecutions, {
  //                         task_id: subtask.id,
  //                       }),
  //                       'end_date',
  //                       'desc'
  //                     )[0]
  //                   }
  //                   recording={_.find(this.props.filteredExecutions, {
  //                     user_id: this.props.user.profile.id,
  //                     recording: true,
  //                   })}
  //                 />
  //               )
  //             })
  //           }
  //         })
  //       }
  //     })

  //   return tasks
  // }

  showArchivedNoteModal = (project) => {
    this.setState({
      showProjectArchivedNote: true,
      projectArchivedNote: project
    })
  }

  hideArchivedNoteModal = () => {
    this.setState({
      showProjectArchivedNote: false,
      projectArchivedNote: null
    })
  }

  generateTasks = () => {
    let tasks = []
    //generating the rows that will be passed to the table
    this.props.projects.list
      .filter(
        (project) =>
          (_.toUpper(project.name).includes(
            _.toUpper(this.state.description)
          ) ||
            this.state.description === '') &&
          (project.deadline <= this.state.deadline ||
            this.state.deadline === '') &&
          ((this.state.assigned !== '' &&
            _.filter(
              this.props.tasks.list,
              (task) =>
                task.resources.includes(this.props.user.profile.id) &&
                project.tasks.includes(task.id)
            ).length) ||
            this.state.assigned === '')
      )
      .filter((x) => x.archived === this.state.showArchived)
      .filter((i) => {
        if (this.state.customer) {
          if (this.state.customer === 'null') {
            return !i.customer_id && !i.estimate_id
          } else {
            if (i.estimate_id) {
              const estm = (this.props.estimatesAll.list || []).find(
                (e) => e.id === i.estimate_id
              )
              if (
                estm &&
                estm.v[estm.v.length - 1].info.customer === this.state.customer
              )
                return i
            } else return i.customer_id === this.state.customer
          }
        } else return i
      })
      .forEach((project) => {
        tasks.push(
          <ProjectLine
            key={project.id}
            p={project}
            toggleRow={this.toggleRow}
            showArchivedNote={this.showArchivedNoteModal}
          />
        )
        if (project.open) {
          _.filter(this.props.tasks.list, (task) =>
            project.tasks.includes(task.id)
          ).forEach((task, i) => {
            let pr = task.priority
            if (task.subtasks.length > 0) {
              pr = 0
              task.subtasks.forEach((s) => {
                const st = this.props.tasks.list.find((x) => x.id === s)
                if (st) pr += st.priority
              })
              pr = Math.round(pr / task.subtasks.length)
            }
            const newTask = { ...task }
            newTask.priority = pr
            tasks.push(
              <TaskLine
                key={task.id}
                task={newTask}
                saveTask={this.props.saveTask}
                toggleSetTimeModal={this.toggleSetTimeModal}
                userId={this.props.user.profile.id}
                resources={task.resources}
                updateRecording={this.updateRecording}
                lastRecord={
                  _.orderBy(
                    _.filter(this.props.filteredExecutions, {
                      task_id: task.id
                    }),
                    'end_date',
                    'desc'
                  )[0]
                }
                recording={_.find(this.props.filteredExecutions, {
                  user_id: this.props.user.profile.id,
                  recording: true
                })}
              />
            )
            _.filter(this.props.tasks.list, (subtask) =>
              task.subtasks.includes(subtask.id)
            ).forEach((subtask) => {
              tasks.push(
                <TaskLine
                  subtask={true}
                  key={subtask.id}
                  task={subtask}
                  saveTask={this.props.saveTask}
                  toggleSetTimeModal={this.toggleSetTimeModal}
                  userId={this.props.user.profile.id}
                  resources={subtask.resources}
                  updateRecording={this.updateRecording}
                  lastRecord={
                    _.orderBy(
                      _.filter(this.props.filteredExecutions, {
                        task_id: task.id
                      }),
                      'end_date',
                      'desc'
                    )[0]
                  }
                  recording={_.find(this.props.filteredExecutions, {
                    user_id: this.props.user.profile.id,
                    recording: true
                  })}
                />
              )
            })
          })
        }
      })

    return tasks
  }

  static getDerivedStateFromProps(props, state) {
    if (state.customer === undefined) {
      return {
        customer: props.user.profile.projectsFilters.customer,
        description: props.user.profile.projectsFilters.description
      }
    } else return null
  }

  render() {
    return (
      <div className="full-container">
        <TopInfo
          title={'Progetti'}
          firstBtnFunction={this.toggleProjectModal}
          firstBtnText={'nuovo'}
          hideInfo={true}
        />

        <div className="fls-top-container">
          <ProjectsFilters
            setFilter={this.setFilter}
            description={this.state.description}
            customer={this.state.customer}
            customers={this.props.customers.list}
          />
        </div>

        {this.generateTasks()}

        {this.state.showProjectArchivedNote && (
          <ProjectArchivedNoteDialog
            title={'Aggiungi una nota'}
            toggleProjectArchivedNote={this.hideArchivedNoteModal}
            saveProject={this.props.saveProject}
            project={this.state.projectArchivedNote}
            changeArchiveStatus={this.props.changeArchiveStatus}
          ></ProjectArchivedNoteDialog>
        )}

        {this.state.showProjectModal ? (
          <ProjectDialog
            toggleProjectModal={this.toggleProjectModal}
            saveProject={this.props.saveProject}
          ></ProjectDialog>
        ) : (
          ''
        )}
        {this.state.selectedTask && (
          <TimeDialog
            toggleTimeModal={this.toggleSetTimeModal}
            saveExecution={this.props.saveExecution}
            userId={this.props.user.profile.id}
            singleExecutionId={this.state.selectedTask}
            // toggleObj={this.props.filteredExecutions.find(exec => {
            //     return (
            //         exec.user_id === this.props.user.profile.id &&
            //         exec.task_id === this.state.selectedTask &&
            //         moment(
            //             commonHelpers.timeToDate(exec.end_date)
            //         ).isSame(
            //             commonHelpers.timeToDate(moment().unix()),
            //             "day"
            //         )
            //     )
            // })}
          />
        )}
        {this.state?.multipleDatesToSetForm && (
          <MultiTsDialog
            saveMultipleExecutions={this.props.saveMultipleExecutions}
            dialogForm={this.state.multipleDatesToSetForm}
            close={this.toggleMultiTs}
            executionId={this.state.selectedExecution.id}
            userId={this.state.selectedExecution.user_id}
            taskId={this.state.selectedExecution.task_id}
          />
        )}
      </div>
    )
  }
}

function mapStateToProps({
  projects,
  tasks,
  user,
  executions,
  customers,
  estimatesAll
}) {
  let filteredExecutions = _.filter(executions.list, {
    user_id: user.profile.id
  })

  return { projects, tasks, user, filteredExecutions, customers, estimatesAll }
}

export default connect(
  mapStateToProps,
  {
    toggleProject,
    toggleTask,
    saveTask,
    saveExecution,
    saveProject,
    saveMultipleExecutions,
    changeProjectArchivedStatus,
    saveProjectFilter
  },
  null,
  {
    areStatesEqual: (next, prev) =>
      prev.projects.list === next.projects.list &&
      prev.tasks.list === next.tasks.list &&
      prev.executions.list === next.executions.list &&
      prev.customers.list === next.customers.list &&
      prev.estimatesAll.list === next.estimatesAll.list
  }
)(Projects)
