import history from '../history'
// import { toast } from 'react-toastify'
import { notify } from 'react-notify-toast'
import update from 'immutability-helper'

import { estimatesRef, invoicesRef } from '../database'
import { estimatesConstants } from '../constants'
import { commonHelpers } from '../helpers'

export let estimatesSnap

export function loadEstimates(startYear, endYear) {
  return (dispatch) => {
    dispatch(loadingEstimates())
    if (estimatesSnap) {
      estimatesSnap()
      dispatch(resetEstimateList())
    }
    estimatesSnap = estimatesRef
      .where('creationDate', '>=', startYear)
      .where('creationDate', '<=', endYear)
      .orderBy('creationDate', 'asc')
      .onSnapshot(
        (result) => {
          result.docChanges().forEach((change) => {
            const data = change.doc.data()
            const id = change.doc.id
            if (change.type === 'added') {
              dispatch(updateEstimate({ id, ...data }))
            }
            if (change.type === 'modified') {
              dispatch(updateEstimate({ id, ...data }))
            }
            if (change.type === 'removed') {
              dispatch(removeEstimate({ id, ...data }))
            }
          })
        },
        (error) => {
          console.log(error)
          dispatch(loadingEstimatesError('Error: ' + error))
        }
      )
  }
}

function loadingEstimates() {
  return {
    type: estimatesConstants.LOADING_ESTIMATES,
  }
}

function loadingEstimatesError(payload) {
  return {
    type: estimatesConstants.LOADING_ESTIMATES_ERROR,
    payload,
  }
}

function updateEstimate(payload) {
  return {
    type: estimatesConstants.UPDATE_ESTIMATE,
    payload,
  }
}

function removeEstimate(payload) {
  return {
    type: estimatesConstants.REMOVE_ESTIMATE,
    payload,
  }
}

export function saveEstimate(id, payload) {
  return (dispatch) => {
    dispatch(ajaxCall())
    if (id === null) {
      estimatesRef
        .add(payload)
        .then((res) => {
          dispatch(ajaxCallSuccess())
          notify.show('salvato con successo', 'success', 3000)
          history.push(`/estimates/${res.id}/${payload.number}/1`)
        })
        .catch((error) => {
          if (error.response !== undefined) {
            dispatch(ajaxCallFailure(error.response.statusText))
          } else {
            dispatch(ajaxCallFailure(error.message))
          }
        })
    } else {
      estimatesRef
        .doc(id)
        .update({ ...payload })
        .then(() => {
          dispatch(ajaxCallSuccess())
          notify.show('salvato con successo', 'success', 3000)
          history.push(`/estimates/${id}/${payload.number}/${payload.v.length}`)
        })
        .catch((error) => {
          if (error.response !== undefined) {
            dispatch(ajaxCallFailure(error.response.statusText))
          } else {
            dispatch(ajaxCallFailure(error.message))
          }
        })
    }
  }
}

function ajaxCall() {
  return {
    type: estimatesConstants.ESTIMATE_AJAX_CALL,
  }
}

function ajaxCallSuccess() {
  return {
    type: estimatesConstants.ESTIMATE_AJAX_SUCCESS,
  }
}

function ajaxCallFailure(payload) {
  return {
    type: estimatesConstants.ESTIMATE_AJAX_FAILURE,
    payload,
  }
}

export function deleteEstimate(id) {
  return (dispatch) => {
    dispatch(ajaxCall())
    estimatesRef
      .doc(id)
      .update({ deleted: true })
      .then(() => {
        dispatch(ajaxCallSuccess())
        notify.show('eliminato con successo', 'success', 3000)
        history.push('/estimates')
      })
      .catch((error) => {
        if (error.response !== undefined) {
          dispatch(ajaxCallFailure(error.response.statusText))
        } else {
          dispatch(ajaxCallFailure(error.message))
        }
      })
  }
}

// NOTE: non più usata, l'oggetto non ha più la proprietà pay per sapere se può richiamare la fattura
// export function setPay (id, payload) {
//   return dispatch => {
//     dispatch(ajaxCall())
//     estimatesRef.doc(id).update({ ...payload }).then(() => {
//       dispatch(ajaxCallSuccess())
//       toast.success('salvato con successo')
//     }).catch(error => {
//       if (error.response !== undefined) { dispatch(ajaxCallFailure(error.response.statusText)) } else { dispatch(ajaxCallFailure(error.message)) }
//     })
//   }
// }

export function propagateOda(id, oda) {
  return (dispatch) => {
    invoicesRef
      .where('eid', '==', id)
      .get()
      .then((res) => {
        res.forEach((change) => {
          const id = change.id
          invoicesRef.doc(id).update({
            oda,
            lastUpdate: commonHelpers.time(),
          })
        })
      })
      .catch((err) => {
        notify.show(err.message, 'error', 3000)
      })
  }
}

export function updateOda(id, oda, me) {
  return (dispatch) => {
    estimatesRef
      .doc(id)
      .get()
      .then((res) => {
        const doc = res.data()
        const lastV = doc.v[doc.v.length - 1]
        const time = commonHelpers.time()
        const newVersion = update(lastV, {
          info: {
            oda: { $set: oda },
          },
          versionDate: { $set: time },
          versionNote: { $set: 'Aggiornato oda' },
          versionOwner: { $set: me },
          versionStatus: { $set: lastV.versionStatus },
        })
        const ne = update(doc, {
          v: {
            $push: [newVersion],
          },
          lastUpdate: { $set: time },
        })
        estimatesRef.doc(id).update({ ...ne })
      })
      .catch((err) => {
        notify.show(err.message, 'error', 3000)
      })
  }
}

function resetEstimateList() {
  return {
    type: estimatesConstants.ESTIMATE_RESET,
  }
}
