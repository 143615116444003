export const sprintConstants = {
  UPDATE_SPRINTS: '[Sprint] Update sprint',
  SAVE_SPRINT: '[Sprint] Save sprint',
  SPRINT_AJAX_CALL: '[Sprint] Ajax call',
  SPRINT_AJAX_SUCCESS: '[Sprint] Ajax success',
  SPRINT_AJAX_FAILURE: '[Sprint] Ajax error',
  SPRINT_RESET: '[Sprint] Reset list'
}

export const formDataSprint = {
  name: {
    id: 'name',
    label: 'Nome sprint',
    type: 'text',
    class: 'input-name'
  },
  startingDate: { label: 'Data  inizio', type: 'date' },
  endDate: { label: 'Data  fine', type: 'date' }
}
