import React, { useState } from "react"
import { Column } from "./Column"
import { DraggableCard } from "./Card"
import search from "../../../../assets/icons/play.svg"
import _ from "lodash"

export function Board({ cards, moveCard, columns, cardsColor }) {
    const [searchQuery, setSearchQuery] = useState("")

    return (
        <div className="board">
            <Column title="Trascina le task che vuoi aggiungere">
                <div className="search">
                    <input
                        type="text"
                        placeholder="Cerca..."
                        onChange={e => setSearchQuery(e.target.value)}
                    />
                    <img src={search} alt="" />
                </div>
                {columns[0]
                    .map(cardId => cards.find(card => card.id === cardId))
                    .map(
                        (card, index) =>
                            _.includes(
                                card.title.toLowerCase(),
                                searchQuery.toLocaleLowerCase()
                            ) && (
                                <DraggableCard
                                    key={card.id}
                                    id={card.id}
                                    columnId={0}
                                    columnIndex={index}
                                    title={card.title}
                                    moveCard={moveCard}
                                />
                            )
                    )}
                {columns[0].length === 0 && (
                    <DraggableCard
                        isSpacer
                        moveCard={cardId => moveCard(cardId, 0, 0)}
                    />
                )}
            </Column>
            <Column title="Tasks">
                {columns[1]
                    .map(cardId => cards.find(card => card.id === cardId))
                    .map((card, index) => (
                        <DraggableCard
                            color={cardsColor}
                            key={card.id}
                            id={card.id}
                            columnId={1}
                            columnIndex={index}
                            title={card.title}
                            moveCard={moveCard}
                        />
                    ))}
                {[0, 1].includes(columns[1].length) && (
                    <DraggableCard
                        isSpacer
                        moveCard={cardId => moveCard(cardId, 1, 0)}
                    />
                )}
            </Column>
        </div>
    )
}
