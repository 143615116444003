import { userConstants } from '../constants'

const initialState = {
  list: null,
  profile: null,
  error: null,
  valid: false,
  logout: false,
  loading: false
}

export default function user(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return { ...state, loading: true, error: null, valid: false }
    case userConstants.LOGIN_SUCCESS:
      return { ...state, valid: true, error: null }
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        valid: false
      }
    case userConstants.LOGOUT:
      return {
        ...state,
        error: null,
        loading: true,
        valid: false,
        profile: null,
        logout: true
      }
    case userConstants.LOGOUT_SUCCESS:
      return initialState
    case userConstants.LOAD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        profile: action.payload
      }
    // case userConstants.LOAD_USERS:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: null,
    //     list:
    //                 state.list === null
    //                   ? [action.payload]
    //                   : state.list.concat(action.payload)
    //   }
    case userConstants.UPDATE_USERS:
      return {
        ...state,
        list:
          state.list === null
            ? [action.payload]
            : state.list.findIndex((x) => x.id === action.payload.id) === -1
              ? state.list.concat(action.payload)
              : state.list.map((item) => {
                return item.id === action.payload.id ? action.payload : item
              }),
        loading: false
      }
    case userConstants.UPDATE_USER_RAL:
      return {
        ...state,
        list:
          state.list === null
            ? [action.payload]
            : state.list.findIndex((x) => x.id === action.payload.id) === -1
              ? state.list.concat(action.payload)
              : state.list.map((item) => {
                return item.id === action.payload.id ? { ...item, ral: action.payload.ral } : item
              }),
        loading: false
      }
    case userConstants.UPDATE_USER_HOURS:
      return {
        ...state,
        list:
          state.list === null
            ? [action.payload]
            : state.list.findIndex((x) => x.id === action.payload.id) === -1
              ? state.list.concat(action.payload)
              : state.list.map((item) => {
                return item.id === action.payload.id ? { ...item, hours: action.payload.hours } : item
              }),
        loading: false
      }
    case userConstants.UPDATE_USER_VACATION:
      return {
        ...state,
        list:
          state.list === null
            ? [action.payload]
            : state.list.findIndex((x) => x.id === action.payload.id) === -1
              ? state.list.concat(action.payload)
              : state.list.map((item) => {
                return item.id === action.payload.id ? { ...item, manage_vacation: action.payload.manage_vacation } : item
              }),
        loading: false
      }
    case userConstants.REMOVE_USERS:
      return {
        ...state,
        list: state.list.filter((item) => {
          return item.id !== action.payload.id
        }),
        loading: false
      }
    case userConstants.LOCAL_SAVE_ESTIMATE_FILTERS:
      return {
        ...state,
        profile: { ...state.profile, estimatesFilters: action.payload }
      }
    case userConstants.LOCAL_SAVE_ORDER_FILTERS:
      return {
        ...state,
        profile: { ...state.profile, ordersFilters: action.payload }
      }
    case userConstants.LOCAL_SAVE_TOBEINVOICED_FILTERS:
      return {
        ...state,
        profile: {
          ...state.profile,
          toBeInvoicedFilters: action.payload
        }
      }
    case userConstants.LOCAL_SAVE_PROJECT_FILTERS:
      return {
        ...state,
        profile: { ...state.profile, projectsFilters: action.payload }
      }
    default:
      return state
  }
}
