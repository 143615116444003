import firebase from './firestore'

const db = firebase.firestore()

db.settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
})

db.enablePersistence({ synchronizeTabs: true }).catch((err) => {
  if (err.code === 'failed-precondition') {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    // ...
  } else if (err.code === 'unimplemented') {
    // The current browser does not support all of the
    // features required to enable persistence
    // ...
  }
})

export const database = db

export const usersRef = db.collection('users')
export const colleaguesRef = db.collection('users')
export const companiesRef = db.collection('companies')
export const customersRef = db.collection('customers')
export const providersRef = db.collection('providers')
export const estimatesRef = db.collection('estimates')
export const estimatesAllRef = db.collection('estimates')
export const invoicesRef = db.collection('invoices')
export const tasksRef = db.collection('tasks')
export const projectsRef = db.collection('projects')
export const sprintsRef = db.collection('sprints')
export const executionsRef = db.collection('task_execution')
export const economicsRef = db.collection('reports')
export const rolesRef = db.collection('roles')
export const calendarRef = db.collection('calendars')
export const vacationRef = db.collection('vacations')
export const vacationDescRef = db.collection("vacation_descriptions")
export const storageRef = firebase.storage().ref()
