import React, { Component } from 'react'
import { connect } from 'react-redux'

import { loadReportEconomics } from '../actions'
import { uiConstants } from '../constants'

class Economics extends Component {
  loadReports(eco, preview, type) {
    this.props.loadReportEconomics({
      ...eco,
      preview,
      type
    })
  }

  render() {
    const { list } = this.props.economics
    return (
      <div>
        <h1>Economics</h1>
        <ul className="ul-economics">
          {(list || [])
            .filter((x) => x.economics)
            .map((eco) => {
              return (
                <li key={eco.id}>
                  <span className="title">{eco.title}</span>

                  <div className="buttons">
                    <button
                      className="lnk-utils"
                      onClick={() =>
                        this.loadReports(eco, true, uiConstants.economics.view)
                      }
                    >
                      Visualizza
                    </button>
                    <button
                      className="lnk-utils"
                      onClick={() =>
                        this.loadReports(
                          eco,
                          false,
                          uiConstants.economics.download
                        )
                      }
                    >
                      Scarica
                    </button>
                  </div>
                </li>
              )
            })}
        </ul>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, { loadReportEconomics })(Economics)
