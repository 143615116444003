import React, { Component } from "react"
// import PropTypes from "prop-types"

import _ from "lodash"
import produce from "immer"
import { Board } from "./Board"

const initialColumns = [
    { id: 0, title: "To Do", cardIds: [] },
    { id: 1, title: "In progress", cardIds: [] },
    { id: 2, title: "In review", cardIds: [] },
    { id: 3, title: "Waiting", cardIds: [] },
    { id: 4, title: "Done", cardIds: [] },
]

export default class DashBoard extends Component {
    state = {
        columns: initialColumns,
    }

    componentDidMount() {
        this.fillColumns(this.props.tasks)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.filter !== this.props.filter) {
            this.setState(
                {
                    columns: initialColumns,
                },
                () => {
                    this.fillColumns(this.props.tasks)
                }
            )
        } else if (prevProps.tasks !== this.props.tasks) {
            this.fillColumns(this.props.tasks)
        }
    }

    fillColumns = tasks =>
        this.setState(
            produce(draftState => {
                tasks.forEach(task => {
                    // console.log(task.resources[0].id)
                    draftState.columns.forEach(column => {
                        _.pull(column.cardIds, task.id)
                    })
                    draftState.columns[task.status].cardIds.push(task.id)
                })
            })
        )

    addCard = (columnId, _title) =>
        this.setState(
            produce(draftState => {
                let title = _title.trim()
                if (!title) return

                const newCard = {
                    id: draftState.cards.length + 1,
                    title: title,
                }

                draftState.cards = draftState.cards.concat(newCard)

                draftState.columns.map(column =>
                    column.id === columnId
                        ? (column.cardIds = column.cardIds.concat(newCard.id))
                        : column
                )
            })
        )

    moveCard = (cardId, destColumnId, index) => {
        this.setState(
            produce(draftState => {
                draftState.columns.map(column =>
                    _.flowRight(
                        ids =>
                            column.id === destColumnId
                                ? ids.splice(index, 0, cardId)
                                : ids,
                        ids => _.pull(ids, cardId)
                    )(column.cardIds)
                )
            })
        )
    }

    render() {
        return (
            <Board
                cards={this.props.tasks}
                columns={this.state.columns}
                moveCard={this.moveCard}
                addCard={this.addCard}
                saveTask={this.props.saveTask}
                sprint={this.props.sprint}
                resources={this.props.resources}
                recordAction={this.props.recordAction}
                executions={this.props.executions}
                userId={this.props.userId}
                toggleSetTimeModal={this.props.toggleSetTimeModal}
                toggleTaskModal={this.props.toggleTaskModal}
                toggleDeleteDialog={this.props.toggleDeleteDialog}
                toggleResourceModal={this.props.toggleResourceModal}
                isAdmin={this.props.isAdmin}
            />
        )
    }
}
