export const tenantHelpers = {
  getId
}

function getId() {
  const domain = window.location.hostname.split('.')[0]
  switch (domain) {
    default:
      return 'localhost-zfm9h'
  }
}
