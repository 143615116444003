// import history from '../history'

import { estimatesAllRef } from '../database'
import { estimatesAllConstants } from '../constants'

export let estimatesSnap

export function loadAllEstimates() {
    return (dispatch) => {
        dispatch(loadingEstimates())
        if (estimatesSnap) {
            estimatesSnap()
            dispatch(resetEstimateList())
        }
        estimatesSnap = estimatesAllRef
            .orderBy('creationDate', 'asc')
            .onSnapshot(
                (result) => {
                    result.docChanges().forEach((change) => {
                        const data = change.doc.data()
                        const id = change.doc.id
                        if (change.type === 'added') {
                            dispatch(updateEstimate({ id, ...data }))
                        }
                        if (change.type === 'modified') {
                            dispatch(updateEstimate({ id, ...data }))
                        }
                        if (change.type === 'removed') {
                            dispatch(removeEstimate({ id, ...data }))
                        }
                    })
                },
                (error) => {
                    console.log(error)
                    dispatch(loadingEstimatesError('Error: ' + error))
                }
            )
    }
}

function loadingEstimates() {
    return {
        type: estimatesAllConstants.LOADING_ESTIMATES,
    }
}

function loadingEstimatesError(payload) {
    return {
        type: estimatesAllConstants.LOADING_ESTIMATES_ERROR,
        payload,
    }
}

function updateEstimate(payload) {
    return {
        type: estimatesAllConstants.UPDATE_ESTIMATE,
        payload,
    }
}

function removeEstimate(payload) {
    return {
        type: estimatesAllConstants.REMOVE_ESTIMATE,
        payload,
    }
}

// function ajaxCall() {
//     return {
//         type: estimatesAllConstants.ESTIMATE_AJAX_CALL,
//     }
// }

// function ajaxCallSuccess() {
//     return {
//         type: estimatesAllConstants.ESTIMATE_AJAX_SUCCESS,
//     }
// }

// function ajaxCallFailure(payload) {
//     return {
//         type: estimatesAllConstants.ESTIMATE_AJAX_FAILURE,
//         payload,
//     }
// }


function resetEstimateList() {
    return {
        type: estimatesAllConstants.ESTIMATE_RESET,
    }
}
