import React, { Component } from 'react'
import { connect } from 'react-redux'
import update from 'immutability-helper'
import ReactTooltip from 'react-tooltip'
// import { createSubscription } from 'create-subscription'
import moment from 'moment'

import { commonHelpers, estimatesHelpers, invoicesHelpers } from '../../helpers'
import Modal from '../Modal'
import { createInvoice, deleteInvoiceByGuid, saveEstimate } from '../../actions'

const initialState = {
  stories: [],
  totalAmount: '',
  totalPercentage: '',
  billingPlan: '',
  lastUpdate: '',
  vIndex: '',
  lastStatus: '',
  showDeleteModal: ''
}

// const Subscription = createSubscription({
//   getCurrentValue (sourceProp) {
//     return sourceProp.value
//   },

//   subscribe (sourceProp, callback) {
//     function handleSubscriptionChange () {
//       callback(sourceProp.value)
//     }

//     // Subscribe (e.g. add an event listener) to the subscription (sourceProp).
//     // Call callback(newValue) whenever a subscription changes.
//     sourceProp.subscribe(handleSubscriptionChange)

//     // Return an unsubscribe method.
//     return function unsubscribe () {
//       sourceProp.unsubscribe(handleSubscriptionChange)
//     }
//   }
// })

class EstimateBilling extends Component {
  constructor() {
    super()
    this.state = initialState
    this.onChange = this.onChange.bind(this)
    this.escFunction = this.escFunction.bind(this)
    this.hideModal = this.hideModal.bind(this)
    this.confirmModalAction = this.confirmModalAction.bind(this)
  }

  escFunction(event) {
    if (event.ctrlKey) {
      switch (event.keyCode) {
        case 13:
          if (this.state.showDeleteModal) {
            this.confirmModalAction()
            event.preventDefault()
          }
          break
        default:
          break
      }
    }
    switch (event.keyCode) {
      case 27:
        this.hideModal()
        break
      default:
        break
    }
  }

  hideModal() {
    this.setState({ showDeleteModal: false })
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.props.handleBilling(this.state)
    })
  }

  addProgram() {
    const stories = update(this.state.stories, {
      $push: [
        {
          title: '',
          date: commonHelpers.endOfCurrentMonth(),
          percentage: '',
          amount: '',
          guid: commonHelpers.guid()
        }
      ]
    })
    this.setState({ stories }, () => {
      ReactTooltip.rebuild()
      this.props.handleBilling(this.state)
    })
  }

  changeStory(event, index) {
    const field = event.target.name
    let value = event.target.value
    if (['percentage', 'amount'].indexOf(event.target.name) > -1) {
      value = commonHelpers.float(event.target.value, '')
    }
    let stories = this.state.stories
    if (event.target.name === 'date') {
      value = commonHelpers.dateToTime(event.target.value)
      stories = update(stories, {
        [index]: {
          year: { $set: commonHelpers.timeToYYYY(value) }
        }
      })
    }
    stories = update(stories, {
      [index]: {
        [event.target.name]: { $set: value }
      }
    })
    this.setState({ stories }, () => {
      this.recalculateStory(field, index)
    })
  }

  recalculateStory(field, index) {
    const conf = this.props.item.configurator
    const s = this.state.stories[index]
    if (commonHelpers.noUndefNull(conf)) {
      if (conf.totalConfirmed > 0) {
        let prop
        let calc
        if (field === 'amount') {
          calc = commonHelpers.roundOneDecimal(
            (100 / conf.totalConfirmed) * s.amount
          )
          prop = 'percentage'
        } else {
          calc = commonHelpers.roundOneDecimal(
            (s.percentage * conf.totalConfirmed) / 100
          )
          prop = 'amount'
        }
        const stories = update(this.state.stories, {
          [index]: {
            [prop]: { $set: calc }
          }
        })
        this.setState({ stories }, () => {
          this.recalculateTotal()
        })
      } else {
        this.recalculateTotal()
      }
    } else {
      this.recalculateTotal()
    }
  }

  recalculateTotal() {
    const s = this.state.stories
    // const s = this.props.item.billing.stories;
    // console.log("STORIES ==>", s);
    if (s.length > 0) {
      const calc = s.reduce((previousValue, currentValue) => {
        return {
          percentage:
            previousValue.percentage +
            commonHelpers.float(currentValue.percentage),
          amount:
            previousValue.amount + commonHelpers.float(currentValue.amount)
        }
      })
      this.setState(
        {
          totalPercentage: commonHelpers.roundOneDecimal(calc.percentage),
          totalAmount: commonHelpers.roundOneDecimal(calc.amount)
        },
        () => {
          this.props.handleBilling(this.state)
        }
      )
    } else {
      this.setState(
        {
          totalPercentage: '',
          totalAmount: ''
        },
        () => {
          this.props.handleBilling(this.state)
        }
      )
    }
  }

  removeStory(index) {
    this.setState({
      showDeleteModal: true,
      deleteProps: [index]
    })
  }

  confirmModalAction() {
    const index = this.state.deleteProps[0]
    const stories = update(this.state.stories, { $splice: [[index, 1]] })
    this.setState({ stories, showDeleteModal: false }, () => {
      this.recalculateTotal()
    })
  }

  remaining() {
    const conf = this.props.item.configurator
    if (this.state.totalAmount > 0) {
      const r = this.state.totalAmount - conf.totalConfirmed
      if (r !== 0) {
        return commonHelpers.toMoney(commonHelpers.roundOneDecimal(r))
      }
    }
    return 0
  }

  mustBePay(index, pay) {
    const s = this.state.stories[index]
    const e = { ...this.props.item.e }
    const year = parseInt(moment.unix(s.date).format('YYYY'))
    // this.props.user.profile.currentYear !== moment.utc().format('YY')
    //   ? parseInt(
    //       moment
    //         .utc(this.props.user.profile.currentYear, 'YYYY')
    //         .format('YYYY')
    //     )
    //   : commonHelpers.currentYear()
    if (!pay) {
      this.props.createInvoice(invoicesHelpers.createInvoiceLine(e, s, year))
    } else {
      this.props.deleteInvoiceByGuid(s.guid)
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.item.billing === undefined) {
      if (props.item.e.number !== state.number) {
        return {
          number: props.item.e.number,
          lastUpdate: props.item.e.lastUpdate,
          vIndex: props.vIndex,
          stories: []
        }
      }
    } else {
      if (
        props.item.lastUpdate !== state.lastUpdate ||
        props.item.vIndex !== state.vIndex
      ) {
        return {
          number: props.item.e.number,
          lastUpdate: props.item.lastUpdate,
          vIndex: props.item.vIndex,
          stories: props.item.billing.stories,
          totalAmount: props.item.billing.totalAmount,
          totalPercentage: props.item.billing.totalPercentage,
          billingPlan: props.item.billing.billingPlan
        }
      }
    }
    return null
  }

  componentDidUpdate(prevProps, prevState) {
    const p = prevProps.invoices
    const c = this.props.invoices
    if (p.ajaxSpinner && !c.ajaxSpinner) {
      this.props.saveAlsoEstimate()
    }
    if (
      !commonHelpers.sameObject(
        prevProps.item.configurator,
        this.props.item.configurator
      )
    ) {
      const conf = this.props.item.configurator
      let stories = [...this.state.stories]
      this.state.stories.forEach((s, index) => {
        // let percentage = 0;
        let percentage = s.percentage
        if (conf.totalConfirmed !== 0) {
          // calc = commonHelpers.roundOneDecimal(s.percentage * conf.totalConfirmed / 100)
          percentage = commonHelpers.roundOneDecimal(
            (s.amount * 100) / conf.totalConfirmed
          )
        }
        stories = update(stories, {
          [index]: {
            percentage: { $set: percentage }
          }
        })
      })
      this.setState({ stories }, () => {
        this.recalculateTotal()
      })
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false)
  }

  render() {
    // let stories = this.props.item.e.v
    //   ? this.props.item.e.v[this.props.item.e.v.length - 1].billing.stories
    //   : [];
    return (
      <div
        className={
          estimatesHelpers.formIsDisabled(this.props, true)
            ? 'form-disabled'
            : ''
        }
      >
        <h2>Piano fatturazione</h2>
        <h3>Tipo</h3>
        <label>
          Tipo fatturazione
          <select
            name="billingPlan"
            onChange={this.onChange}
            value={this.state.billingPlan}
          >
            <option value=""></option>
            <option value="progetto">Progetto</option>
            <option value="canone">Canone</option>
            <option value="consumo">Consumo</option>
          </select>
        </label>
        <h3 className="mt">piano fatturazione</h3>

        <ul className="stories-list">
          <li>
            <ul className="head">
              <li className="epic-menu"></li>
              <li className="date" data-tip="Data">
                <i className="fas fa-calendar-day"></i>
              </li>
              <li className="txt"></li>
              <li className="num" data-tip="Percentuale">
                <i className="fas fa-percentage"></i>
              </li>
              <li className="num" data-tip="Importo">
                <i className="fas fa-money-bill-wave"></i>
              </li>
              {estimatesHelpers.showConfirmColumn(
                this.props.item.e.lastStatus
              ) && (
                <li className="num" data-tip="Invia a fatturazione">
                  <i className="fas fa-file-invoice-dollar"></i>
                </li>
              )}
            </ul>
          </li>
          {/* precedentemente this.state.stories */}
          {(this.state.stories || []).map((s, index) => {
            const pay = invoicesHelpers.invoiceExists(s)

            return (
              <li key={index} className={pay ? 'form-disabled' : ''}>
                <ul className="story">
                  <li className="epic-menu">
                    <button
                      className="lnk-del-eh"
                      onClick={() => this.removeStory(index)}
                    >
                      <i className="far fa-trash-alt"></i>
                    </button>
                  </li>
                  <li className="date">
                    <input
                      type="date"
                      name="date"
                      value={commonHelpers.timeToDate(s.date)}
                      onChange={(event) => this.changeStory(event, index)}
                    />
                  </li>
                  <li className="txt">
                    <input
                      type="text"
                      name="title"
                      value={s.title}
                      onChange={(event) => this.changeStory(event, index)}
                    />
                  </li>
                  <li className="num">
                    <input
                      type="number"
                      name="percentage"
                      value={s.percentage}
                      onChange={(event) => this.changeStory(event, index)}
                    />
                  </li>
                  <li className="num">
                    <input
                      type="number"
                      name="amount"
                      value={s.amount}
                      onChange={(event) => this.changeStory(event, index)}
                    />
                  </li>
                  {estimatesHelpers.showConfirmColumn(
                    this.props.item.e.lastStatus
                  ) && (
                    <li className="num">
                      <button
                        className={
                          'lnk-send-to-invoiced alwaysEnabled ' +
                          (!pay ? '' : 'red')
                        }
                        onClick={() => this.mustBePay(index, pay)}
                        disabled={
                          invoicesHelpers.canRetire(s) ||
                          this.state.billingPlan === ''
                        }
                      >
                        {!pay && (
                          <ul>
                            <li>Invia</li>
                            <li>
                              <i className="fas fa-angle-double-right"></i>
                            </li>
                          </ul>
                        )}
                        {pay && (
                          <ul>
                            <li>Richiama</li>
                            <li>
                              <i className="fas fa-angle-double-left"></i>
                            </li>
                          </ul>
                        )}
                      </button>
                    </li>
                  )}
                </ul>
              </li>
            )
          })}
        </ul>
        <button className="lnk-add-story" onClick={() => this.addProgram()}>
          [Aggiungi piano]
        </button>
        {this.state.stories.length > 0 && (
          <ul className="epic-total">
            <li className="epic-menu"></li>
            <li className="date">{this.state.stories.length} righe</li>
            <li className="txt">Totale</li>
            <li
              className="num"
              data-tip={commonHelpers.round(100 - this.state.totalPercentage)}
            >
              {this.state.totalPercentage} %{' '}
            </li>
            <li className="num" data-tip={this.remaining()}>
              {commonHelpers.toMoney(this.state.totalAmount)}
            </li>
            {estimatesHelpers.showConfirmColumn(
              this.props.item.e.lastStatus
            ) && <li className="num"></li>}
          </ul>
        )}
        {this.state.showDeleteModal && (
          <Modal cancel={this.hideModal} confirm={this.confirmModalAction}>
            <h3>Elimina</h3>
            Eliminare riga?
          </Modal>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  // console.log("MAP STATE");
  return state
}

export default connect(mapStateToProps, {
  createInvoice,
  deleteInvoiceByGuid,
  saveEstimate
})(EstimateBilling)
