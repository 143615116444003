import React, { Component } from "react"

export class TextInput extends Component {
    render() {
        return (
            <div className={this.props.className}>
                <label htmlFor={this.props.id}>
                    {this.props.input?.label || ""}
                </label>
                <input
                    type={this.props.input.type}
                    id={this.props.id}
                    onChange={this.props.onChange}
                    value={this.props.value}
                    className={
                        Object.keys(this.props.error).find(
                            k => k === this.props.id
                        )
                            ? "validation-error"
                            : ""
                    }
                />
            </div>
        )
    }
}

export class RadioInput extends Component {
    render() {
        return (
            <div
                className={this.props.className + " radio-wrapper"}
                key={this.props.id}
            >
                <label>{this.props.input?.label || ""}</label>
                {this.props.input.options.map(option => {
                    const parsedOption = option.toLowerCase().replace(/\s/g, "")
                    return (
                        <label
                            key={this.props.input.options.indexOf(option)}
                            htmlFor={parsedOption}
                        >
                            <span>{option}</span>
                            <input
                                type="radio"
                                id={parsedOption}
                                name={this.props.input.label}
                                value={parsedOption}
                                onChange={this.props.onChange}
                            />
                        </label>
                    )
                })}
            </div>
        )
    }
}

export class SelectInput extends Component {
    render() {
        return (
            <div key={this.props.id} className={this.props.className}>
                <label htmlFor={this.props.id}>
                    {this.props.input?.label || ""}
                </label>
                <select
                    className={
                        Object.keys(this.props.error).find(
                            k => k === this.props.id
                        )
                            ? "validation-error"
                            : ""
                    }
                    name={this.props.id}
                    id={this.props.id}
                    onChange={this.props.onChange}
                    defaultValue={this.props.value}
                >
                    {this.props.input.options.map((option, index) => {
                        return (
                            <option key={option?.value} value={option?.value}>
                                {option[Object.keys(option)[0]]}
                            </option>
                        )
                    })}
                </select>
            </div>
        )
    }
}

export class TextAreaInput extends Component {
    render() {
        return (
            <div className={this.props.className}>
                <label htmlFor={this.props.id}>
                    {this.props.input?.label || ""}
                </label>
                <textarea
                    type={this.props.input.type}
                    id={this.props.id}
                    onChange={this.props.onChange}
                    className={
                        Object.keys(this.props.error).find(
                            k => k === this.props.id
                        )
                            ? "validation-error"
                            : ""
                    }
                    value={this.props.value}
                ></textarea>
            </div>
        )
    }
}

export class Buttons extends Component {
    render() {
        return (
            <div className="buttons-wrapper">
                {this.props.buttons &&
                    this.props.buttons.map(button => {
                        return (
                            <button
                                className="lnk-submit confirm"
                                key={button.label.toLowerCase()}
                                onClick={() => button.func(button.submit)}
                            >
                                {button.label}
                            </button>
                        )
                    })}
            </div>
        )
    }
}
