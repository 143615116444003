import React from 'react'
import { Column } from './Column'
import { DraggableCard } from './Card'

import _ from 'lodash'
// import {TextForm} from './TextForm';

export function Board({
  cards,
  columns,
  moveCard,
  addCard,
  addColumn,
  sprint,
  saveTask,
  resources,
  recordAction,
  executions,
  userId,
  toggleSetTimeModal,
  toggleTaskModal,
  toggleDeleteDialog,
  toggleResourceModal,
  isAdmin
}) {
  return (
    <div className="board-container">
      <div className={`Board ${sprint ? 'sprint' : ''}`}>
        {columns &&
          columns.map((column) => (
            <Column
              key={column.id}
              title={column.title}
              addCard={addCard.bind(null, column.id)}
            >
              {cards &&
                column.cardIds
                  .map((cardId) => cards.find((card) => card.id === cardId))
                  .map((card, index) =>
                    card ? (
                      <DraggableCard
                        key={card?.id}
                        id={card?.id}
                        isAdmin={isAdmin}
                        columnId={column.id}
                        columnIndex={index}
                        title={card?.name}
                        moveCard={moveCard}
                        saveTask={saveTask}
                        color={card?.color}
                        estimatedHours={card?.estimated_time}
                        spentHours={card?.spent_time}
                        priority={card?.priority}
                        sprintName={card?.sprint_name}
                        sprintId={card?.sprint_id}
                        projectId={card?.project_id}
                        projectName={card?.project_name}
                        sprint={sprint}
                        toggleSetTimeModal={toggleSetTimeModal}
                        toggleTaskModal={toggleTaskModal}
                        toggleDeleteDialog={toggleDeleteDialog}
                        toggleResourceModal={toggleResourceModal}
                        resources={
                          resources
                            ? _.filter(resources, (resource) =>
                                card?.resources.includes(resource.id)
                              )
                            : ''
                        }
                        recordAction={recordAction}
                        lastRecord={
                          executions
                            ? _.orderBy(
                                _.filter(executions, {
                                  task_id: card?.id
                                }),
                                'end_date',
                                'desc'
                              )[0]
                            : ''
                        }
                        isRecording={
                          executions
                            ? _.find(executions, {
                                recording: true,
                                user_id: userId
                              })
                            : ''
                        }
                      />
                    ) : (
                      ''
                    )
                  )}
              {[0, 1].includes(column.cardIds.length) && (
                <DraggableCard
                  isSpacer
                  thinSpacer={column.cardIds.length === 1}
                  moveCard={(cardId) => moveCard(cardId, column.id, 0)}
                />
              )}
            </Column>
          ))}
      </div>
    </div>
  )
}
