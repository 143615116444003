import React, { Component } from 'react'
import { connect } from 'react-redux'

import RegistryQuickAdd from '../Registry/RegistryQuickAdd'
import { uiConstants } from '../../constants'
import { commonHelpers, estimatesHelpers } from '../../helpers'
import { saveCustomer } from '../../actions'

const initialState = {
  account: '',
  title: '',
  oda: '',
  customer: '',
  estimateDate: '',
  number: '',
  lastUpdate: '',
  note: '',
  vIndex: '',
  lastStatus: '',
  showAddCustomer: false
}

class EstimateInfo extends Component {
  constructor() {
    super()
    this.state = initialState
    this.onChange = this.onChange.bind(this)
  }

  odaIsDisabled() {
    return this.state.lastStatus !== uiConstants.estimateStatuses.confirmed
  }

  onChange(e) {
    let newValue = e.target.value
    if (e.target.name === 'estimateDate') {
      newValue = commonHelpers.dateToTime(e.target.value)
    }
    this.setState({ [e.target.name]: newValue }, () => {
      this.props.handleInfo(this.state)
    })
  }

  addCustomerHandler(name) {
    this.setState({ showAddCustomer: false })
    this.props.saveCustomer(
      null,
      {
        name,
        company: this.props.user.profile.currentCompany,
        budgets: []
      },
      false
    )
  }

  static getDerivedStateFromProps(props, state) {
    if (props.item.info === undefined) {
      if (props.item.e.number !== state.number) {
        return {
          account: props.user.profile.uid,
          title: '',
          oda: '',
          customer: '',
          note: '',
          number: props.item.e.number,
          lastUpdate: props.item.e.lastUpdate,
          estimateDate: commonHelpers.time(),
          vIndex: props.vIndex,
          lastStatus: uiConstants.estimateStatuses.draft
        }
      }
    } else {
      if (
        props.item.lastUpdate !== state.lastUpdate ||
        props.item.vIndex !== state.vIndex ||
        (state.e !== undefined && props.item.e.number !== state.e.number)
      ) {
        return {
          e: props.item.e,
          account: props.item.info.account,
          title: props.item.info.title,
          oda: props.item.info.oda,
          customer: props.item.info.customer,
          estimateDate: props.item.info.estimateDate,
          note: props.item.info.note,
          number: props.item.e.number,
          lastUpdate: props.item.lastUpdate,
          vIndex: props.item.vIndex,
          lastStatus: props.item.e.lastStatus
        }
      }
    }
    return null
  }

  componentDidMount() {
    this.props.handleInfo(this.state)
  }

  render() {
    const customers = this.props.customers.list
    const colleagues = this.props.colleagues.list
    const me = this.props.user.profile
    return (
      <>
        <h2>Dati generali</h2>
        <ul
          className={
            'ul-edit-form ' +
            (estimatesHelpers.formIsDisabled(this.props) ? 'form-disabled' : '')
          }
        >
          <li>
            <label>
              Account
              <select
                name="account"
                onChange={this.onChange}
                value={this.state.account}
              >
                <option value=""></option>
                <option value={me.uid}>{me.displayName}</option>
                {(colleagues || []).map((c, index) => {
                  return (
                    <option key={index} value={c.uid}>
                      {c.displayName}
                    </option>
                  )
                })}
              </select>
            </label>
          </li>
          <li>
            <label>
              Numero
              <input type="text" disabled value={this.state.number} />
            </label>
          </li>
          <li>
            <label>
              Cliente
              <span className="span-add-customer">
                <select
                  name="customer"
                  onChange={this.onChange}
                  value={this.state.customer}
                >
                  <option value=""></option>
                  {(customers || []).map((c, index) => {
                    return (
                      <option key={index} value={c.id}>
                        {c.name}
                      </option>
                    )
                  })}
                </select>
                <button
                  onClick={() => this.setState({ showAddCustomer: true })}
                >
                  <i className="fas fa-plus"></i>
                </button>
              </span>
            </label>
          </li>
          <li>
            <label>
              Data
              <input
                type="date"
                name="estimateDate"
                onChange={this.onChange}
                value={commonHelpers.timeToDate(this.state.estimateDate)}
              />
            </label>
          </li>
          <li>
            <label>
              Titolo
              <input
                type="text"
                name="title"
                onChange={this.onChange}
                value={this.state.title}
              />
            </label>
          </li>
          <li>
            <label>
              ODA
              <input
                type="text"
                className="alwaysEnabled"
                name="oda"
                onChange={this.onChange}
                value={this.state.oda}
                disabled={this.odaIsDisabled()}
              />
            </label>
          </li>
          <li>
            <label>
              Note
              <textarea
                name="note"
                onChange={this.onChange}
                value={this.state.note}
                className="alwaysEnabled"
              ></textarea>
            </label>
          </li>
        </ul>
        {this.state.showAddCustomer && (
          <RegistryQuickAdd
            cancel={() => this.setState({ showAddCustomer: false })}
            confirm={this.addCustomerHandler.bind(this)}
            title={'cliente'}
          />
        )}
      </>
    )
  }
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, { saveCustomer })(EstimateInfo)
