import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { uiConstants } from '../constants'
import { commonHelpers, customersHelpers, invoicesHelpers } from '../helpers'

import Line from './ToBeInvoiced/Line'
import TBIFilters from './ToBeInvoiced/TBIFilters'

class ToBeInvoiced extends Component {
  filterList (list, status) {
    const { toBeInvoicedFilters } = this.props.user.profile
    return (list || []).filter((x) => x.lastStatus === uiConstants.estimateStatuses.confirmed)
      .filter(e => {
        const v = e.v[e.v.length - 1]
        return v.billing.billingPlan === status
      }).filter(item => {
        return toBeInvoicedFilters.customer === '' || item.v[item.v.length - 1].info.customer === toBeInvoicedFilters.customer
      }).filter(item => {
        return toBeInvoicedFilters.searchTerm === '' ||
        item.v[item.v.length - 1].info.title.toLowerCase().indexOf(toBeInvoicedFilters.searchTerm.toLowerCase()) > -1 ||
        item.number.toLowerCase().indexOf(toBeInvoicedFilters.searchTerm.toLowerCase()) > -1
      }).filter(
        item => !item.deleted
      ).filter(e => {
        const v = e.v[e.v.length - 1]
        const p = invoicesHelpers.progress(v.billing.stories)
        return !toBeInvoicedFilters.invoiced || p !== 100
      })
  }

  render () {
    const { list } = this.props.estimates
    const { toBeInvoicedFilters } = this.props.user.profile
    return (
      <>
        <h1>Da fatturare</h1>
        <TBIFilters />
        {
          uiConstants.toBeInvoicedFilters.map((s, index) => {
            return (
              toBeInvoicedFilters[s.value] && (
                <div key={index}>
                  <div className={'title-section ' + s.value}>{s.label}</div>
                  {
                    this.filterList(list, s.value).map((e, index) => {
                      const v = e.v[e.v.length - 1]
                      const p = invoicesHelpers.progress(v.billing.stories)
                      const url = `/orders/${e.id}/${e.number}/${e.v.length}`
                      return (
                        <ul key={index} className="tbi-c">
                          <li className="e-info">
                            <Link to={url}>
                              <span className="number">{e.number}</span>
                              <span className="title">{v.info.title}</span>
                              <span className="customer">{customersHelpers.getNameByUid(v.info.customer)}</span>
                              <span className="total">{commonHelpers.toMoney(v.configurator.totalConfirmed)}</span>
                              <span className="progress" data-tip={p}>
                                <p>
                                  <b style={{ width: p + '%' }}></b>
                                </p>
                              </span>
                            </Link>
                          </li>
                          {
                            toBeInvoicedFilters.expanded && (
                              <li className="e-stories">
                                {
                                  (v.billing.stories || []).map((b, i2) => {
                                    return (
                                      <Line
                                        key={i2}
                                        b={b}
                                        e={e}
                                      />
                                    )
                                  })
                                }
                              </li>
                            )
                          }
                        </ul>
                      )
                    })
                  }
                </div>
              )
            )
          })
        }
      </>
    )
  }
}

function mapStateToProps (state) {
  return state
}

export default connect(mapStateToProps, { })(ToBeInvoiced)
