import React, { Component } from 'react'
import { connect } from 'react-redux'

// import { createSelector } from "reselect"
import { commonHelpers } from '../helpers'
import produce from 'immer'
import _ from 'lodash'

import DashBoard from './dashboard/DashBoard'
import MultiTsDialog from './MultiTsDialog'
import TopInfo from './TopInfo'
import TimeDialog from './Projects/modals/TimeDialog'
import FloatingTaskModal from './dashboard/FloatingTaskModal'
import Dialog from './Dialog/Dialog'
import ResourceDialog from './Projects/modals/ResourceDialog'
import Chips from './UI/Chips/Chips'
import Autocomplete from './UI/Autocomplete/Autocomplete'

import {
  saveTask,
  saveExecution,
  saveMultipleExecutions,
  changeDashboardFilter,
  deleteTask,
  manageResource
} from '../actions'

const moment = commonHelpers.getMoment()

// const tasksSelector = state => state.tasks.list
// const profileSelector = state => state.user.profile

// export const filteredTasks = createSelector(
//     [tasksSelector, profileSelector],
//     (tasks, profile) => {
//         return _.filter(tasks, task => task.resources.includes(profile.id))
//     }
// )

class Dashboard extends Component {
  state = {
    multipleDatesToSetForm: '',
    selectedExecution: '',
    selectedTask: '',

    showTaskDialog: false,
    onEditTask: '',

    deletingElement: {
      taskId: '',
      resourceId: ''
    },

    showResourceDialog: false,

    filters: {
      onlyMyTasks: true,
      project: '',
      resource: '',
      selectedResources: []
    }
  }

  toggleResourceModal = (id) => {
    this.setState({ showResourceDialog: id })
  }

  toggleDeleteDialog = (taskId, resourceId) => {
    this.setState(
      produce((draftState) => {
        draftState.deletingElement.taskId = taskId
        draftState.deletingElement.resourceId = resourceId
      })
    )
  }

  deleteElement = () => {
    if (this.state.deletingElement.resourceId) {
      this.props.manageResource(
        'remove',
        this.state.deletingElement.taskId,
        this.state.deletingElement.resourceId
      )
    } else {
      this.props.deleteTask(this.state.deletingElement.taskId, null)
    }
    this.toggleDeleteDialog()
  }

  toggleSetTimeModal = (id) => {
    this.setState({
      selectedTask: this.state.selectedTask ? '' : id
    })
  }

  toggleTaskModal = (id) => {
    this.setState({
      showTaskDialog: !this.state.showTaskDialog,
      onEditTask: id
    })
  }

  updateRecording = (executionId, taskId) => {
    let payload = {}
    let currentDate = moment().unix()

    let selectedExecution = _.find(this.props.filteredExecutions, {
      id: executionId
    })

    if (
      (selectedExecution &&
        moment(commonHelpers.timeToDate(selectedExecution.end_date)).isSame(
          commonHelpers.timeToDate(currentDate),
          'day'
        )) ||
      selectedExecution?.recording
    ) {
      if (
        moment(commonHelpers.timeToDate(selectedExecution.start_date)).isSame(
          commonHelpers.timeToDate(currentDate),
          'day'
        )
      ) {
        payload.recording = !selectedExecution.recording
        payload.end_date = currentDate
        payload.edit_date = currentDate

        this.props.saveExecution(executionId, payload)
      } else {
        let dates = commonHelpers.enumerateDaysBetweenDates(
          commonHelpers.timeToDate(selectedExecution.start_date),
          commonHelpers.timeToDate(currentDate)
        )

        let datesObject = {}

        dates.forEach(
          (date) =>
            (datesObject[date] = {
              label: date,
              type: 'number',
              id: date,
              col: 6,
              default: 0
            })
        )

        this.setState({
          multipleDatesToSetForm: datesObject,
          selectedExecution: selectedExecution
        })
      }
    } else {
      // stop recording
      const t = this.props.tasks.find((x) => x.id === taskId)

      payload.creation_date = currentDate
      payload.start_date = currentDate
      payload.end_date = currentDate
      payload.edit_date = currentDate
      payload.task_id = taskId
      payload.recording = true
      payload.user_id = this.props.profile.id
      payload.project_id = t.project_id !== undefined ? t.project_id : ''

      this.props.saveExecution(executionId, payload)
    }
  }

  toggleMultiTs = () => {
    this.setState({
      multipleDatesToSetForm: ''
    })
  }

  setFilter = (id) => {
    let payload = { projectId: id }
    this.props.changeDashboardFilter(payload)
  }

  addResource = (r) => {
    this.setState({
      filters: {
        ...this.state.filters,
        selectedResources: this.state.filters.selectedResources.concat(r),
        resource: ''
      }
    })
  }

  removeResource = (r) => {
    this.setState({
      filters: {
        ...this.state.filters,
        selectedResources: this.state.filters.selectedResources.filter(
          (u) => u !== r
        )
      }
    })
  }

  filteredProjects = () => {
    const f = this.state.filters
    const projects = [...this.props.plist.filter((x) => !x.archived)]

    let rtrn = projects.filter(
      (p) =>
        (this.props.tasks || []).filter(
          (t) =>
            t.project_id === p.id &&
            t.resources.indexOf(this.props.profile.uid) > -1
        ).length > 0
    )
    if (!f.onlyMyTasks) {
      rtrn = rtrn.concat(
        projects.filter(
          (p) => (p.managers || []).indexOf(this.props.profile.uid) > -1
        )
      )
      rtrn = _.uniqBy(rtrn, 'id')
    }
    return rtrn
  }

  filteredTasks = () => {
    const f = this.state.filters
    const uid = this.props.profile.uid

    const projects = this.filteredProjects().filter((p) =>
      f.project !== '' ? p.id === f.project : true
    )

    return (this.props.tasks || [])
      .filter((t) => projects.filter((p) => p.id === t.project_id).length > 0)
      .filter(
        (t) =>
          (t.resources.indexOf(uid) > -1 &&
            (f.project === '' ? true : t.project_id === f.project)) ||
          (f.onlyMyTasks
            ? false
            : projects.find(
                (p) =>
                  p.id === t.project_id && (p.managers || []).indexOf(uid) > -1
              ))
      )
  }

  filteredResources = () => {
    const f = this.state.filters
    let res = []
    this.filteredTasks().forEach((x) => {
      x.resources.forEach((a) => res.push(a))
    })
    res = _.uniq(res)

    return (this.props.ulist || []).filter(
      (u) =>
        f.selectedResources.indexOf(u.uid) < 0 &&
        (res.indexOf(u.uid) >= 0 || res.indexOf(u.id) >= 0)
    )
  }

  autocompleteSelected = (value) => {
    this.setState({
      filters: {
        ...this.state.filters,
        project: value,
        selectedResources: []
      }
    })
  }

  render() {
    const f = this.state.filters
    return (
      <div className="full-container">
        <TopInfo
          title="Dashboard"
          firstBtnFunction={
            this.props.profile.admin ? this.toggleTaskModal : ''
          }
          firstBtnText={'AGGIUNGI TASK'}
          hideInfo={true}
        />
        <ul className="filters-list">
          <li>
            <Autocomplete
              list={this.filteredProjects()}
              placeholder={'Tutti i progetti'}
              onSelect={this.autocompleteSelected}
            />
            {/* <select
              onChange={(event) =>
                this.setState({
                  filters: {
                    ...f,
                    project: event.target.value,
                    selectedResources: []
                  }
                })
              }
              // defaultValue={this.props.dashBoardFilter}
              value={f.project}
            >
              <option key={'all'} value={''}>
                Tutti i progetti
              </option>
              {this.filteredProjects().map((project) => (
                <option key={project.id} value={project.id}>
                  {project.name}
                </option>
              ))}
            </select> */}
          </li>
          <li>
            <label className="switch">
              <input
                type="checkbox"
                onChange={(event) =>
                  this.setState({
                    filters: {
                      ...f,
                      onlyMyTasks: event.target.checked,
                      selectedResources: []
                    }
                  })
                }
                checked={f.onlyMyTasks}
              />
              <span className="slider"></span>
            </label>
            solo task assegnate a me
          </li>
          {!f.onlyMyTasks && (
            <>
              <li>
                <select
                  onChange={(e) => this.addResource(e.target.value)}
                  value={f.resource}
                >
                  <option value="">Tutte le risorse</option>
                  {this.filteredResources().map((c) => (
                    <option key={c.uid} value={c.uid}>
                      {c.displayName}
                    </option>
                  ))}
                </select>
              </li>
              <li className="li-chips">
                {f.selectedResources.map((r) => (
                  <Chips key={r} user={r} delete={this.removeResource} />
                ))}
              </li>
            </>
          )}
        </ul>
        <DashBoard
          tasks={
            this.filteredTasks().filter((t) =>
              f.selectedResources.length === 0
                ? true
                : t.resources.filter((r) => {
                    const uid = f.selectedResources.indexOf(r) > -1
                    if (uid) return true
                    const user = this.props.ulist.find((x) => x.id === r)
                    return user
                      ? f.selectedResources.indexOf(user.uid) > -1
                      : false
                  }).length > 0
            )
            // !this.props.dashBoardFilter
            //   ? _.orderBy(this.props.tasks, 'priority', 'desc')
            //   : this.props.dashBoardFilter === 'nothing'
            //   ? _.orderBy(
            //       _.filter(this.props.tasks, (task) => !task.project_id),
            //       'priority',
            //       'desc'
            //     )
            //   : _.orderBy(
            //       _.filter(this.props.tasks, {
            //         project_id: this.props.dashBoardFilter
            //       }),
            //       'priority',
            //       'desc'
            //     )
          }
          saveTask={this.props.saveTask}
          resources={this.props.ulist}
          recordAction={this.updateRecording}
          executions={this.props.filteredExecutions}
          userId={this.props.profile.id}
          toggleSetTimeModal={this.toggleSetTimeModal}
          filter={this.props.dashBoardFilter}
          toggleTaskModal={this.toggleTaskModal}
          toggleDeleteDialog={this.toggleDeleteDialog}
          toggleResourceModal={this.toggleResourceModal}
          isAdmin={this.props.profile.admin}
        />

        {this.state.selectedTask && (
          <TimeDialog
            toggleTimeModal={this.toggleSetTimeModal}
            saveExecution={this.props.saveExecution}
            userId={this.props.profile.id}
            singleExecutionId={this.state.selectedTask}
          />
        )}
        {this.state.showTaskDialog && (
          <FloatingTaskModal
            projects={this.props.plist}
            close={this.toggleTaskModal}
            saveTask={this.props.saveTask}
            toggleTaskModal={this.toggleTaskModal}
            availableResources={this.props.resources}
            profile={this.props.profile}
            onEditTask={_.find(this.props.tasks, {
              id: this.state.onEditTask
            })}
          />
        )}
        {this.state.showResourceDialog && (
          <ResourceDialog
            close={this.toggleResourceModal}
            toggleResourceModal={this.toggleResourceModal}
            taskId={this.state.showResourceDialog}
            availableResources={this.props.resources}
            manageResource={this.props.manageResource}
          />
        )}
        {this.state?.multipleDatesToSetForm ? (
          <MultiTsDialog
            saveMultipleExecutions={this.props.saveMultipleExecutions}
            dialogForm={this.state.multipleDatesToSetForm}
            close={this.toggleMultiTs}
            executionId={this.state.selectedExecution.id}
            userId={this.state.selectedExecution.user_id}
            taskId={this.state.selectedExecution.task_id}
          />
        ) : (
          ''
        )}
        {this.state.deletingElement.taskId ? (
          <Dialog
            title={
              this.state.deletingElement.resourceId
                ? 'Elimina risorsa'
                : 'Elimina task'
            }
            subtitle={
              this.state.deletingElement.resourceId
                ? 'Vuoi davvero eliminare questa risorsa?'
                : 'Vuoi davvero eliminare questo task?'
            }
            buttons={[
              {
                label: 'Annulla',
                func: this.toggleDeleteDialog,
                submit: undefined
              },
              {
                label: 'Elimina',
                func: this.deleteElement,
                submit: true
              }
            ]}
          ></Dialog>
        ) : (
          ''
        )}
      </div>
    )
  }
}

function mapStateToProps({
  tasks: { list, dashBoardFilter },
  user: { profile, list: ulist },
  projects: { list: plist },
  sprints: { list: slist },
  executions
}) {
  var tasks = list // _.filter(list, (task) => task.resources.includes(profile.id))
  tasks = tasks.map((task) => {
    let linkedSprint = _.find(slist, { id: task.sprint_id })
    let linkedProject = _.find(plist, (project) =>
      project.tasks.includes(task.id)
    )

    linkedProject = linkedProject
      ? linkedProject
      : _.find(plist, (project) =>
          project.tasks.includes(
            _.find(list, (t) => t.subtasks.includes(task.id))?.id
          )
        )

    return {
      ...task,
      color: linkedSprint?.color || '',
      sprint_id: linkedSprint?.id || '',
      sprint_name: linkedSprint?.name || '',
      project_id: linkedProject?.id || '',
      project_name: linkedProject?.name || ''
    }
  })

  let filteredExecutions = _.filter(executions.list, {
    user_id: profile.id
  })

  let resources = []
  if (ulist) {
    resources = ulist.map((resource) => ({
      label: `${resource.displayName} XX - ${resource.role || 'non definito'}`,
      value: resource.id
    }))
  }

  return {
    tasks,
    profile,
    ulist,
    filteredExecutions,
    plist,
    dashBoardFilter,
    resources
  }
}

export default connect(
  mapStateToProps,
  {
    saveTask,
    saveExecution,
    saveMultipleExecutions,
    changeDashboardFilter,
    deleteTask,
    manageResource
  },
  null,
  {
    areStatesEqual: (next, prev) =>
      prev.tasks.list === next.tasks.list &&
      prev.sprints.list === next.sprints.list &&
      prev.executions.list === next.executions.list &&
      prev.projects.list === next.projects.list &&
      prev.tasks.dashBoardFilter === next.dashBoardFilter
  }
)(Dashboard)
