import firebase from '../firestore'
// import history from "../history"
import { notify } from 'react-notify-toast'
// import update from "immutability-helper"

import { tasksRef, projectsRef, database } from '../database'
import { tasksConstants } from '../constants'
// import { commonHelpers } from "../helpers"

export let tasksSnap

export function loadTasks(startYear, endYear) {
  return (dispatch) => {
    // dispatch(loadingtasks())
    if (tasksSnap) {
      tasksSnap()
      dispatch(resetTaskList())
    }
    tasksSnap = tasksRef
      // .where('creation_date', '>=', startYear)
      // .where('creation_date', '<=', endYear)
      .orderBy('creation_date', 'asc')
      .onSnapshot(
        (result) => {
          result.docChanges().forEach((change) => {
            const data = change.doc.data()
            const id = change.doc.id
            if (change.type === 'added') {
              dispatch(updateTasks({ id, ...data }))
            }
            if (change.type === 'modified') {
              dispatch(updateTasks({ id, ...data }))
            }
            if (change.type === 'removed') {
              dispatch(removeTasks({ id, ...data }))
            }
          })
        },
        (error) => {
          console.log(error)
          //   dispatch(loadingTasksError('Error: ' + error))
        }
      )
  }
}

export function manageResource(type, taskId, resourceId) {
  return (dispatch) => {
    dispatch(ajaxCall())
    if (type === 'remove') {
      tasksRef.doc(taskId).update({
        resources: firebase.firestore.FieldValue.arrayRemove(resourceId)
      })
    } else {
      tasksRef.doc(taskId).update({
        resources: firebase.firestore.FieldValue.arrayUnion(resourceId)
      })
    }
  }
}

export function deleteTask(id, parentTask) {
  return (dispatch) => {
    dispatch(ajaxCall())
    let batch = database.batch()

    batch.delete(tasksRef.doc(id))

    if (parentTask) {
      batch.update(tasksRef.doc(parentTask), {
        subtasks: firebase.firestore.FieldValue.arrayRemove(id)
      })
    }

    batch
      .commit()
      .then(() => {
        notify.show('eliminato con successo', 'success', 3000)
      })
      .catch((error) => {
        if (error.response !== undefined) {
          dispatch(ajaxCallFailure(error.response.statusText))
        } else {
          dispatch(ajaxCallFailure(error.message))
        }
      })
  }
}

export function saveTask(id, newParentTask, oldParentTask, projectId, payload) {
  return (dispatch) => {
    dispatch(ajaxCall())
    if (id === null) {
      tasksRef
        .add(payload)
        .then((res) => {
          dispatch(ajaxCallSuccess())
          if (newParentTask !== 'nobody') {
            tasksRef
              .doc(newParentTask)
              .update({
                subtasks: firebase.firestore.FieldValue.arrayUnion(res.id)
              })
              .then((res) =>
                notify.show('salvato con successo', 'success', 3000)
              )
          } else {
            projectsRef
              .doc(projectId)
              .update({
                tasks: firebase.firestore.FieldValue.arrayUnion(res.id)
              })
              .then((res) =>
                notify.show('salvato con successo', 'success', 3000)
              )
          }
          // history.push(`/tasks/${res.id}/${payload.number}/1`)
        })
        .catch((error) => {
          if (error.response !== undefined) {
            dispatch(ajaxCallFailure(error.response.statusText))
          } else {
            dispatch(ajaxCallFailure(error.message))
          }
        })
    } else {
      let batch = database.batch()

      batch.update(tasksRef.doc(id), { ...payload })

      if (newParentTask !== 'nobody' && newParentTask !== oldParentTask) {
        console.log('enter')
        batch.update(projectsRef.doc(projectId), {
          tasks: firebase.firestore.FieldValue.arrayRemove(id)
        })
        batch.update(tasksRef.doc(newParentTask), {
          subtasks: firebase.firestore.FieldValue.arrayUnion(id),
          resources: []
        })
      }

      if (oldParentTask && newParentTask !== oldParentTask) {
        console.log('enter 2')
        batch.update(tasksRef.doc(oldParentTask), {
          subtasks: firebase.firestore.FieldValue.arrayRemove(id)
        })

        if (newParentTask === 'nobody') {
          batch.update(projectsRef.doc(projectId), {
            tasks: firebase.firestore.FieldValue.arrayUnion(id)
          })
        }
      }

      if (projectId && newParentTask === 'nobody') {
        batch.update(projectsRef.doc(projectId), {
          tasks: firebase.firestore.FieldValue.arrayUnion(id)
        })
      }

      batch
        .commit()
        .then(() => {
          dispatch(ajaxCallSuccess())
          notify.show('salvato con successo', 'success', 3000)
        })
        .catch((error) => {
          if (error.response !== undefined) {
            dispatch(ajaxCallFailure(error.response.statusText))
          } else {
            dispatch(ajaxCallFailure(error.message))
          }
        })
    }
  }
}

function updateTasks(payload) {
  return {
    type: tasksConstants.UPDATE_TASKS,
    payload
  }
}

function removeTasks(payload) {
  return {
    type: tasksConstants.REMOVE_TASKS,
    payload
  }
}

export function toggleTask(payload) {
  return {
    type: tasksConstants.OPEN_TASK,
    payload
  }
}

export function changeDashboardFilter(payload) {
  return {
    type: tasksConstants.DASHBOARD_FILTER,
    payload
  }
}

function ajaxCall() {
  return {
    type: tasksConstants.TASKS_AJAX_CALL
  }
}

function ajaxCallSuccess() {
  return {
    type: tasksConstants.TASKS_AJAX_SUCCESS
  }
}

function ajaxCallFailure(payload) {
  return {
    type: tasksConstants.TASKS_AJAX_FAILURE,
    payload
  }
}

function resetTaskList() {
  return {
    type: tasksConstants.TASKS_RESET
  }
}

export function saveTaskRemediation(id, payload) {
  return (dispatch) => {
    tasksRef
      .doc(id)
      .update({ ...payload })
      .then(() => {
        console.log(id, 'ok')
      })
      .catch((error) => {
        console.log(id, error)
      })
  }
}
