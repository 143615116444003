import { sprintsRef, tasksRef, database } from '../database'
import { sprintConstants } from '../constants'

export let sprintSnap

export function loadSprints(startYear, endYear) {
  return (dispatch) => {
    // dispatch(loadingtasks())
    if (sprintSnap) {
      sprintSnap()
      dispatch(resetSprintList())
    }
    sprintSnap = sprintsRef
      .where('created_at', '>=', startYear)
      .where('created_at', '<=', endYear)
      .orderBy('created_at', 'asc')
      .onSnapshot(
        (result) => {
          result.docChanges().forEach((change) => {
            const data = change.doc.data()
            const id = change.doc.id
            if (change.type === 'added') {
              dispatch(updateSprints({ id, ...data }))
            }
            if (change.type === 'modified') {
              dispatch(updateSprints({ id, ...data }))
            }
            if (change.type === 'removed') {
              //   dispatch(removeTasks({ id, ...data }))
            }
          })
        },
        (error) => {
          console.log(error)
          //   dispatch(loadingTasksError('Error: ' + error))
        }
      )
  }
}

export function saveSprint(id, payload, oldSprintTasks) {
  return (dispatch) => {
    dispatch(ajaxCall())
    if (id === null) {
      sprintsRef
        .add(payload.dataset)
        .then((res) => {
          let batch = database.batch()

          for (let i = 0; i < payload.tasks.length; i++) {
            const tRef = tasksRef.doc(payload.tasks[i])

            batch.update(tRef, { sprint_id: res.id })
          }

          batch
            .commit()
            .then(() => {
              dispatch(ajaxCallSuccess())
            })
            .catch((e) => console.log(e))
        })
        .catch((error) => {
          if (error.response !== undefined) {
            dispatch(ajaxCallFailure(error.response.statusText))
          } else {
            dispatch(ajaxCallFailure(error.message))
          }
        })
    } else {
      sprintsRef
        .doc(id)
        .update({ ...payload.dataset })
        .then(() => {
          let batch = database.batch()

          for (let i = 0; i < payload.tasks.length; i++) {
            const tRef = tasksRef.doc(payload.tasks[i])

            batch.update(tRef, { sprint_id: id })
          }

          for (let i = 0; i < oldSprintTasks.length; i++) {
            if (
              !payload.tasks.find((t) => {
                console.log(t, ' = ', oldSprintTasks[i].id)
                return t === oldSprintTasks[i].id
              })
            ) {
              const tRef = tasksRef.doc(oldSprintTasks[i].id)

              batch.update(tRef, { sprint_id: '' })
            }
          }

          batch
            .commit()
            .then(() => {
              dispatch(ajaxCallSuccess())
            })
            .catch((e) => console.log(e))

          dispatch(ajaxCallSuccess())
          console.log('Sprint aggiornato con successo: ', id)
        })
        .catch((error) => {
          if (error.response !== undefined) {
            dispatch(ajaxCallFailure(error.response.statusText))
          } else {
            dispatch(ajaxCallFailure(error.message))
          }
        })
    }
  }
}

export function deleteSprint(id, tasks) {
  return (dispatch) => {
    sprintsRef
      .doc(id)
      .delete()
      .then(() => {
        console.log('Sprint deleted')

        let batch = database.batch()

        for (let i = 0; i < tasks.length; i++) {
          const tRef = tasksRef.doc(tasks[i].id)

          batch.update(tRef, { sprint_id: '' })
        }

        batch
          .commit()
          .then(() => {
            dispatch(ajaxCallSuccess())
          })
          .catch((e) => console.log(e))
      })
      .catch((error) => {
        if (error.response !== undefined) {
          dispatch(ajaxCallFailure(error.response.statusText))
        } else {
          dispatch(ajaxCallFailure(error.message))
        }
      })
  }
}

function ajaxCall() {
  return {
    type: sprintConstants.SPRINT_AJAX_CALL
  }
}

function ajaxCallSuccess() {
  return {
    type: sprintConstants.SPRINT_AJAX_SUCCESS
  }
}

function ajaxCallFailure(payload) {
  return {
    type: sprintConstants.SPRINT_AJAX_FAILURE,
    payload
  }
}

function updateSprints(payload) {
  return {
    type: sprintConstants.UPDATE_SPRINTS,
    payload
  }
}

function resetSprintList() {
  return {
    type: sprintConstants.SPRINT_RESET
  }
}
