import { store } from '../store'
import { commonHelpers } from './common.helpers'
import { companiesHelpers } from './companies.helpers'

export const invoicesHelpers = {
  progress,
  createInvoiceLine,
  canRetire,
  invoiceExists
}

function progress(stories) {
  const s = store.getState()
  const invoices = s.invoices
  const list = invoices.list
  if (list === null) {
    return false
  }
  let completed = 0
  stories.forEach((st) => {
    const yes = list.filter((x) => x.guid === st.guid)
    if (yes.length > 0) {
      completed++
    }
  })
  const t = stories.length
  const r = parseInt((completed * 100) / t, 10)
  if (!r) return 0
  return r
}

function createInvoiceLine(e, b, year) {
  const lastV = e.v[e.v.length - 1]
  const company = companiesHelpers.getCurrent()
  return {
    eid: e.id,
    estimateNumber: e.number,
    guid: b.guid,
    amount: b.amount || 0,
    oda: lastV.oda || '',
    bem: '',
    invoiceNumber: '',
    year,
    invoiceDate: b.date,
    invoiceExpireDate: b.date,
    sent: false,
    company: company.id,
    lastUpdate: commonHelpers.time(),
    customer: lastV.info.customer,
    deleted: false,
    title: lastV.info.title
  }
}

function canRetire(story) {
  const s = store.getState()
  const invoices = s.invoices
  const list = invoices.list
  if (list === null) {
    return false
  }
  const exists = list.filter((x) => x.guid === story.guid)
  if (exists.length > 0) {
    if (exists[0].sent) {
      return true
    } else {
      return false
    }
  }
  return false
}

function invoiceExists(i) {
  const s = store.getState()
  const invoices = s.invoices
  const list = invoices.list
  if (list === null) {
    return false
  }
  const exists = list.filter((x) => x.guid === i.guid)
  // list.forEach((x) => {
  //   console.log(x.guid, x.guid)
  // })
  // console.log(i.guid)
  // console.log(list.length)
  return exists.length > 0
}
