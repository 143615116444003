import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'

import _ from 'lodash'

import Modal from '../Modal'
import Spinner from '../Spinner'
import EstimateConfigurator from './EstimateConfigurator'
import EstimateInfo from './EstimateInfo'
import EstimateBilling from './EstimateBilling'

import { cloneDeep } from 'lodash'

import {
  saveEstimate,
  deleteEstimate,
  propagateOda,
  orderToProject
} from '../../actions'
import { uiConstants } from '../../constants'
import {
  estimatesHelpers,
  commonHelpers,
  colleaguesHelpers
} from '../../helpers'

const initialState = {
  showModal: false,
  showDeleteModal: false,
  futureStatus: null,
  futureDate: null,
  versionNote: '',
  saveError: '',
  saveDateError: false,

  showCreateProject: false
}

class EstimateEditor extends Component {
  constructor() {
    super()
    this.state = initialState
    this.navFunction = this.navFunction.bind(this)
    this.handleInfo = this.handleInfo.bind(this)
    this.handleConfigurator = this.handleConfigurator.bind(this)
    this.handleBilling = this.handleBilling.bind(this)
    this.hideModal = this.hideModal.bind(this)
    this.saveEstimate = this.saveEstimate.bind(this)
    this.deleteEstimate = this.deleteEstimate.bind(this)
  }

  navFunction(event) {
    if (event.ctrlKey && event.shiftKey) {
      switch (event.keyCode) {
        case 49:
          this.go2Version(-1)
          break
        case 50:
          this.go2Version(1)
          break
        case 51:
          this.go2Version(0)
          break
        case 57:
          this.props.history.push('/estimates')
          break
        default:
          break
      }
    }
    switch (event.keyCode) {
      case 27:
        this.hideModal()
        break
      default:
        break
    }
  }

  hideModal() {
    this.setState({
      showModal: false,
      showDeleteModal: false,
      saveError: '',
      futureStatus: null,
      saveDateError: false
    })
  }

  showModal(futureStatus = null) {
    let futureDate = null
    if (futureStatus) {
      futureDate = commonHelpers.dateToTime(moment.utc())
    }
    this.setState(
      {
        showModal: true,
        futureStatus,
        futureDate,
        versionNote: ''
      },
      () => {
        this.vNoteInput.focus()
      }
    )
  }

  deleteEstimate() {
    this.props.deleteEstimate(this.state.id)
  }

  saveEstimate() {
    const s = this.state
    if (s.futureStatus !== null && s.futureDate === '') {
      this.setState({ saveDateError: true })
      return
    }
    // NOTE: le note di versione non sono più obbligatorie, obbligatorio piano fatturazione
    if (s.billing.billingPlan === '') {
      this.setState({
        saveError: 'Il campo "tipo fatturazione" è obbligatorio'
      })
      return
    }

    const time = commonHelpers.time()
    const newStatus =
      s.futureStatus !== null
        ? s.futureStatus
        : s.e.lastStatus
        ? s.e.lastStatus
        : uiConstants.estimateStatuses.draft
    let id = null
    let estimate = {
      lastUpdate: s.futureStatus !== null ? s.futureDate : time,
      company: this.props.user.profile.currentCompany,
      v: [],
      lastStatus: newStatus
    }

    // var billing_copy = cloneDeep(s.billing)

    // if (billing_copy) {
    //     billing_copy.stories.forEach((v, index) => {
    //         if (v.guid) {
    //             s.billing.stories[index].guid = commonHelpers.guid()
    //         }
    //     })
    // }

    const v = {
      versionDate: s.futureStatus !== null ? s.futureDate : time,
      versionOwner: this.props.user.profile.uid,
      versionNote: s.versionNote,
      info: { ...s.info },
      configurator: { ...s.configurator },
      billing: { ...s.billing },
      versionStatus: newStatus,
      versionInfo: []
    }
    let propagateOda = false
    if (s.id === null || s.id === 'new') {
      estimate = {
        ...estimate,
        creationDate:
          this.props.user.profile.currentYear !== moment.utc().format('YY')
            ? moment
                .utc(this.props.user.profile.currentYear, 'YYYY')
                .utc()
                .unix()
            : time,
        lastStatus: uiConstants.estimateStatuses.draft,
        number: s.e.number,
        owner: this.props.user.profile.uid,
        v: [v],
        deleted: false
      }
    } else {
      const oldVersions = [...s.e.v]
      // estimate.v = [...s.e.v]
      if (oldVersions.length > 1) {
        oldVersions.forEach((i) => {
          if (oldVersions.indexOf(i) !== oldVersions.length - 1) {
            const vi = {
              versionDate: i.versionDate,
              versionOwner: i.versionOwner,
              versionNote: i.versionNote,
              versionStatus: i.versionStatus
            }
            v.versionInfo.push(vi)
          }
        })
      } else {
        if (
          oldVersions[0].versionInfo &&
          oldVersions[0].versionInfo.length > 0
        ) {
          v.versionInfo = [...oldVersions[0].versionInfo]
        }
      }

      if (s.futureStatus) {
        // estimate.v.push(v)
        // estimate.v.splice(estimate.v.length - 1, 1)
        const lastVersion = oldVersions[oldVersions.length - 1]
        const vi = {
          versionDate: lastVersion.versionDate,
          versionOwner: lastVersion.versionOwner,
          versionNote: lastVersion.versionNote,
          versionStatus: lastVersion.versionStatus
        }
        v.versionInfo.push(vi)
      }
      estimate.v = [v]
      id = s.id
      estimate.number = s.e.number
      if (estimate.v[estimate.v.length - 1].info.oda !== s.info.oda) {
        propagateOda = true
      }
    }
    // console.log(id)
    // console.log(estimate)

    // console.log(estimate)

    this.props.saveEstimate(id, estimate)
    this.hideModal()
    if (propagateOda) {
      this.props.propagateOda(id, s.info.oda)
    }
  }

  handleInfo(state) {
    this.setState({
      info: {
        account: state.account,
        title: state.title,
        oda: state.oda,
        customer: state.customer,
        estimateDate: state.estimateDate,
        note: state.note
      }
    })
  }

  handleConfigurator(state) {
    this.setState({
      configurator: {
        epics: state.epics,
        roles: state.roles,
        totalDays: state.totalDays,
        totalCalculated: state.totalCalculated,
        totalCustom: state.totalCustom,
        totalPercentage: state.totalPercentage,
        totalCost: state.totalCost,
        totalDelta: state.totalDelta,
        totalConfirmed: state.totalConfirmed
      }
    })
  }

  handleBilling(state) {
    this.setState({
      billing: {
        stories: state.stories,
        totalAmount: state.totalAmount,
        totalPercentage: state.totalPercentage,
        billingPlan: state.billingPlan
      }
    })
  }

  go2Version(dir) {
    this.hideModal()
    if (commonHelpers.noUndefNull(this.state.id)) {
      const { id, number } = this.props.match.params
      const s = this.state
      const e = s.e
      if (dir === 0) {
        this.props.history.push(`/estimates/${id}/${number}/${e.v.length}`)
      } else {
        const nv = s.vIndex + dir
        if (nv >= 0 && nv < e.v.length) {
          this.props.history.push(`/estimates/${id}/${number}/${nv + 1}`)
        }
      }
    }
  }

  pageTitle() {
    switch (this.state.id) {
      case 'new':
        return 'clona'
      case null:
        return 'nuovo'
      default:
        return 'modifica'
    }
  }

  baseUrl() {
    const { pathname } = this.props.location
    const u = commonHelpers.urlPath(pathname)
    return '/' + u[1]
  }

  static getDerivedStateFromProps(props, state) {
    const { id, version, number } = props.match.params
    if (id === 'new') {
      if (number !== undefined) {
        const e = estimatesHelpers.getEstimateById(number)
        if (commonHelpers.noUndefNull(e)) {
          const newNum = estimatesHelpers.generateNumber(
            this.props.user.profile.currentYear
          )
          if (state.e === undefined || newNum !== state.e.number) {
            const nb = cloneDeep(e.v[e.v.length - 1].billing)
            nb.stories.forEach((s) => {
              s.guid = commonHelpers.guid()
            })
            return {
              id,
              e: {
                number: newNum
              },
              vIndex: 0,
              lastUpdate: commonHelpers.time(),
              info: e.v[e.v.length - 1].info,
              configurator: e.v[e.v.length - 1].configurator,
              billing: nb,
              versionInfo: e.v[e.v.length - 1].versionInfo
            }
          }
        }
      } else {
        return {
          id: null,
          e: {
            number: estimatesHelpers.generateNumber(
              props.user.profile.currentYear
            )
          },
          vIndex: 0,
          lastUpdate: commonHelpers.time()
        }
      }
    } else {
      const e = estimatesHelpers.getEstimateById(id)

      if (commonHelpers.noUndefNull(e)) {
        if (
          id !== state.id ||
          version - 1 !== state.vIndex ||
          e.lastUpdate !== state.lastUpdate
        )
          if (e.v[version - 1]) {
            return {
              id,
              e,
              vIndex: version - 1,
              lastUpdate: e.lastUpdate,
              info: e.v[version - 1].info,
              configurator: e.v[version - 1].configurator,
              billing: { ...e.v[version - 1].billing },
              versionInfo: { ...e.v[version - 1].versionInfo }
            }
          } else {
            return {
              id,
              e,
              vIndex: 0,
              lastUpdate: e.lastUpdate,
              info: e.v[0].info,
              configurator: e.v[0].configurator,
              billing: { ...e.v[0].billing },
              versionInfo: { ...e.v[0].versionInfo }
            }
          }
      }
    }
    return null
  }

  componentDidMount() {
    document.addEventListener('keydown', this.navFunction, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.navFunction, false)
  }

  createProject = (id, epics, projectInfo) => {
    let projectPayload = {
      creation_date: moment().unix(),
      deadline: projectInfo.estimateDate,
      edit_date: moment().unix(),
      start_date: moment().unix(),
      estimate_id: id,
      description: projectInfo.note,
      name: projectInfo.title + ' - ' + this.state.e.number,
      archived: false,
      archivedNotes: [],
      managers: []
    }

    let tasksPayload = []

    tasksPayload = epics.map((epic) => ({
      creation_date: moment().unix(),
      edit_date: moment().unix(),
      estimated_time: epic.days * 8,
      name: epic.title,
      description: '',
      spent_time: 0,
      sprint_id: '',
      status: 0,
      priority: 1,
      resources: [],
      subtasks: []
    }))

    this.props.orderToProject(projectPayload, tasksPayload)
    this.toggleShowCreateProject()
  }

  toggleShowCreateProject = () => {
    this.setState({
      showCreateProject: !this.state.showCreateProject
    })
  }

  render() {
    const item = this.state
    // console.log("STATE ==>", item.e);
    // console.log(item)

    if (item.id === undefined) {
      return <Spinner />
    } else {
      const { version } = this.props.match.params
      return (
        <>
          <Link to={this.baseUrl()} className="lnk-new">
            <i className="fas fa-times"></i> esci
          </Link>
          {!_.find(this.props.projects.list, {
            estimate_id: this.state.id
          }) ? (
            <div
              className="lnk-new create-project"
              onClick={this.toggleShowCreateProject}
            >
              CREA PROGETTO
            </div>
          ) : (
            ''
          )}

          <h1>
            {this.pageTitle()} preventivo
            <i className="fas fa-info-circle" data-tip data-for="nav-help"></i>
          </h1>

          <div className={'estimate-bar ' + item.e.lastStatus}></div>

          <div className="estimate-container">
            <div className="info">
              <EstimateInfo
                handleInfo={this.handleInfo}
                item={item}
                version={version}
              />
            </div>
            {item.id !== null && item.id !== 'new' && (
              <div className="version">
                <h2>versione {version}</h2>
                <ul className="navigator">
                  <li>
                    <button
                      className="lnk-nav-ver"
                      onClick={() => this.go2Version(-1)}
                      disabled={estimatesHelpers.buttonIsDisabled(
                        item.vIndex,
                        -1,
                        item.id
                      )}
                    >
                      <i className="fas fa-chevron-left"></i>
                    </button>
                  </li>
                  <li>
                    <button
                      className="lnk-nav-ver"
                      onClick={() => this.go2Version(0)}
                    >
                      <i className="fas fa-circle"></i>
                    </button>
                  </li>
                  <li>
                    <button
                      className="lnk-nav-ver"
                      onClick={() => this.go2Version(1)}
                      disabled={estimatesHelpers.buttonIsDisabled(
                        item.vIndex,
                        1,
                        item.id
                      )}
                    >
                      <i className="fas fa-chevron-right"></i>
                    </button>
                  </li>
                </ul>
                <label>
                  Data versione
                  <span>
                    {commonHelpers.timeToDateFull(
                      item.e.v[item.vIndex].versionDate
                    )}
                  </span>
                </label>
                <label>
                  Proprietario
                  <span>
                    {colleaguesHelpers.getNameByUid(
                      item.e.v[item.vIndex].versionOwner
                    )}
                  </span>
                </label>
                <label>
                  Note
                  <span>{item.e.v[item.vIndex].versionNote}</span>
                </label>
                <label>
                  Stato
                  <span
                    className={'bold ' + item.e.v[item.vIndex].versionStatus}
                  >
                    {item.e.v[item.vIndex].versionStatus}
                  </span>
                </label>
              </div>
            )}
          </div>

          <EstimateConfigurator
            handleConfigurator={this.handleConfigurator}
            item={item}
            version={version}
          />

          <EstimateBilling
            handleBilling={this.handleBilling}
            item={item}
            saveAlsoEstimate={this.saveEstimate}
            version={version}
          />

          <ul className="bottom-utils-buttons">
            <li className="left">
              {item.id !== 'new' && item.id !== null && (
                <ul className="ul-utils-btn">
                  <li>
                    <button
                      className="lnk-utils red"
                      onClick={() =>
                        this.setState({
                          showDeleteModal: true
                        })
                      }
                      disabled={
                        estimatesHelpers.canSave(item.vIndex, item.id) ||
                        item.id === null
                      }
                    >
                      <i className="far fa-trash-alt"></i> elimina
                    </button>
                  </li>
                </ul>
              )}
            </li>
            <li className="right">
              <ul className="ul-utils-btn">
                <li>
                  <Link to={this.baseUrl()} className="lnk-utils">
                    <i className="fas fa-arrow-left"></i> annulla
                  </Link>
                </li>
                <li>
                  <button
                    className="lnk-utils"
                    onClick={() => this.showModal()}
                    disabled={estimatesHelpers.canSave(item.vIndex, item.id)}
                  >
                    <i className="far fa-save"></i> salva
                  </button>
                </li>
              </ul>
            </li>
          </ul>
          {item.id !== 'new' && (
            <ul className="bottom-utils-buttons">
              <li className="right">
                <ul className="ul-utils-btn">
                  {item.e.lastStatus === uiConstants.estimateStatuses.draft && (
                    <li>
                      <button
                        className="lnk-utils waiting"
                        onClick={() =>
                          this.showModal(uiConstants.estimateStatuses.waiting)
                        }
                        disabled={estimatesHelpers.canSave(
                          item.vIndex,
                          item.id
                        )}
                      >
                        <i className="far fa-paper-plane"></i> salva e invia al
                        cliente
                      </button>
                    </li>
                  )}
                  {item.e.lastStatus ===
                    uiConstants.estimateStatuses.waiting && (
                    <li>
                      <button
                        className="lnk-utils refused"
                        onClick={() =>
                          this.showModal(uiConstants.estimateStatuses.refused)
                        }
                        disabled={estimatesHelpers.canSave(
                          item.vIndex,
                          item.id
                        )}
                      >
                        <i className="far fa-thumbs-down"></i> rifiutato
                      </button>
                    </li>
                  )}
                  {item.e.lastStatus ===
                    uiConstants.estimateStatuses.waiting && (
                    <li>
                      <button
                        className="lnk-utils green"
                        onClick={() =>
                          this.showModal(uiConstants.estimateStatuses.confirmed)
                        }
                        disabled={estimatesHelpers.canSave(
                          item.vIndex,
                          item.id
                        )}
                      >
                        <i className="far fa-thumbs-up"></i> accettato
                      </button>
                    </li>
                  )}
                  {item.e.lastStatus !== uiConstants.estimateStatuses.draft &&
                    item.id !== null && (
                      <li>
                        <button
                          className="lnk-utils draft"
                          onClick={() =>
                            this.showModal(uiConstants.estimateStatuses.draft)
                          }
                          disabled={estimatesHelpers.canSave(
                            item.vIndex,
                            item.id
                          )}
                        >
                          <i className="fas fa-hand-point-left"></i> bozza
                        </button>
                      </li>
                    )}
                </ul>
              </li>
            </ul>
          )}
          {this.state.showModal && (
            <Modal cancel={this.hideModal} confirm={this.saveEstimate}>
              <h3>Salva</h3>
              {item.futureStatus && (
                <div className="status-change">
                  <span className={item.e.lastStatus}>{item.e.lastStatus}</span>
                  <i className="fas fa-angle-double-right"></i>
                  <span className={item.futureStatus}>{item.futureStatus}</span>
                </div>
              )}
              {item.futureStatus && (
                <label>
                  Data cambio stato
                  <input
                    type="date"
                    className={item.saveDateError ? 'input-error' : ''}
                    onChange={(event) =>
                      this.setState({
                        futureDate: commonHelpers.dateToTime(event.target.value)
                      })
                    }
                    value={commonHelpers.timeToDate(item.futureDate)}
                  />
                </label>
              )}
              <label>
                Note di versione
                <textarea
                  onChange={(event) =>
                    this.setState({
                      versionNote: event.target.value
                    })
                  }
                  value={item.versionNote}
                  ref={(input) => {
                    this.vNoteInput = input
                  }}
                ></textarea>
              </label>
              {item.saveError !== '' && (
                <span className="response-error">{item.saveError}</span>
              )}
            </Modal>
          )}
          {this.state.showDeleteModal && (
            <Modal cancel={this.hideModal} confirm={this.deleteEstimate}>
              <h3>Elimina</h3>
              Confermi di voler eliminare questo preventivo?
            </Modal>
          )}
          {this.state.showCreateProject && (
            <Modal
              cancel={this.toggleShowCreateProject}
              confirm={() =>
                this.createProject(
                  this.state.id,
                  this.state?.configurator?.epics,
                  this.state.info
                )
              }
            >
              <h3>Crea progetto</h3>
              Confermi di voler creare un progetto?
            </Modal>
          )}
          <ReactTooltip place="bottom" id="nav-help" type="dark" effect="solid">
            <p>
              <span>Ctrl + Shift + 1</span> Versione precedente
            </p>
            <p>
              <span>Ctrl + Shift + 2</span> Versione Successiva
            </p>
            <p>
              <span>Ctrl + Shift + 3</span> Ultima Versione
            </p>
            <p>
              <span>Ctrl + Shift + 9</span> Torna a preventivi
            </p>
          </ReactTooltip>
        </>
      )
    }
  }
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {
  saveEstimate,
  deleteEstimate,
  propagateOda,
  orderToProject
})(EstimateEditor)
