import React, { Component } from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"

import { Uris } from "../Uris"
import packageJson from "../../package.json"

import { loadBackground, loginFirebase, loginMicrosoft } from "../actions"
import Spinner from "./Spinner"

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loginform: {
                email: "",
                password: "",
            }
        }
    }

    authFirebase(e) {
        e.preventDefault()

        let payload = {
            email: this.state.loginform.email,
            password: this.state.loginform.password,
        }

        this.props.loginFirebase(payload)
    }

    componentDidMount() {
        if (this.props.background.bing === null) {
            this.props.loadBackground()
        }
    }

    render() {
        const { error, loading, valid } = this.props.user
        const { bkgLoading, bing } = this.props.background
        return (
            <div className="layout">
                {(loading || bkgLoading) && <Spinner />}
                {bing !== null && (
                    <div className="login-bkg">
                        <img src={Uris.bing + bing.images[0].url} alt="bkg" />
                    </div>
                )}
                <div className="login-container">
                    <div className="login-wrapper">
                        <div className="site-title-wrapper">
                            <span className="site-title">Girino</span>
                            <span className="version">
                                {packageJson.version}
                            </span>
                        </div>
                            <form onSubmit={e => this.authFirebase(e)}>
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    onChange={e =>
                                        this.setState({
                                            loginform: {
                                                ...this.state.loginform,
                                                email: e.target.value,
                                            },
                                        })
                                    }
                                />
                                <label htmlFor="password">Password</label>
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    onChange={e =>
                                        this.setState({
                                            loginform: {
                                                ...this.state.loginform,
                                                password: e.target.value,
                                            },
                                        })
                                    }
                                />
                                <button
                                    className="btn-login"
                                    type="submit"
                                >
                                    accedi
                                </button>
                            </form>
                            <div className="social-login">
                                or
                                <button className="btn-login" onClick={() => this.props.loginMicrosoft()}><i className="fab fa-microsoft"></i> Microsoft Login</button>
                            </div>
                            <span className="response-error">
                                <p>{error}</p>
                            </span>

                    </div>
                </div>
                {valid && <Redirect to="/dashboard" />}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return state
}

export default connect(mapStateToProps, { loginFirebase, loadBackground, loginMicrosoft })(
    Login
)
