import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import {
  commonHelpers,
  customersHelpers,
  estimatesHelpers
} from '../../helpers'

class ProjectLine extends Component {
  render() {
    const p = this.props.p
    const url = `/projects/${p.id}/`
    const e = estimatesHelpers.getAllEstimateById(p.estimate_id)
    const v = e ? e.v[e.v.length - 1] : null
    return (
      <div className="project-line-c">
        <Link to={url} className={'lnk-project-line'}>
          <span className="title">{p.name}</span>
          <span className="description">{p.description}</span>
          <span className="customer">
            {v ? customersHelpers.getNameByUid(v.info.customer) : null}
          </span>
          <span className="estimate">{e ? e.number : null}</span>
          <span className="date l">
            <i className="far fa-calendar-check"></i>
            {commonHelpers.timeToDate(p.start_date)}
          </span>
          <span className="date r">
            {commonHelpers.timeToDate(p.deadline)}
            <i className="fas fa-flag-checkered"></i>
          </span>
        </Link>
        <span className="tools">
          <button
            className="lnk-estimate-clone"
            data-tip="Archivia"
            onClick={() => this.props.showArchivedNote(p)}
          >
            <i className="far fa-folder"></i>
          </button>
          <button
            onClick={() => this.props.toggleRow(p.id, false)}
            className="lnk-estimate-clone"
            data-tip={p.open ? 'Collassa' : 'Espandi'}
            disabled={p.tasks.length === 0}
          >
            <i
              className={
                p.open
                  ? 'fas fa-compress-arrows-alt'
                  : 'fas fa-expand-arrows-alt'
              }
            ></i>
          </button>
        </span>
        <ReactTooltip place="top" type="dark" effect="solid"></ReactTooltip>
      </div>
    )
  }
}

export default ProjectLine
