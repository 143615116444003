import React, { useEffect, useState } from 'react'

import css from './VacationFilter.module.scss'

const VacationFilter = (props) => {
  const [selectedUid, setSelectedUid] = useState('')

  const { onSelect } = props

  useEffect(() => {
    if (!selectedUid && props.users && props.users.length > 0) {
      const uid = props.users[0].uid

      setSelectedUid(uid)
      onSelect(uid)
    }
  }, [props.users, selectedUid, onSelect])

  const onSelectHandler = (event) => {
    setSelectedUid(event.target.value)

    onSelect(event.target.value)
  }

  return (
    <div className={css.Filter}>
      <label>
        Dipendente
        <span>
          <select
            name="employee"
            onChange={onSelectHandler}
            value={selectedUid}
          >
            <option value=""></option>
            {props.users
              // .sort((a, b) =>
              //   a.displayName.toUpperCase() > b.displayName.toUpperCase()
              //     ? 1
              //     : -1
              // )
              .map((c, index) => {
                return (
                  <option key={index} value={c.uid}>
                    {c.displayName}
                  </option>
                )
              })}
          </select>
        </span>
      </label>
    </div>
  )
}

export default VacationFilter
