// import history from "../history"
import { notify } from 'react-notify-toast'
// import update from "immutability-helper"

import { projectsRef, tasksRef, database } from '../database'
import { projectsConstants } from '../constants'
// import { commonHelpers } from "../helpers"

export let projectsSnap

export function loadProjects(startYear, endYear) {
  return (dispatch) => {
    if (projectsSnap) {
      projectsSnap()
      dispatch(resetProjectList())
    }
    projectsSnap = projectsRef
      // .where('creation_date', '>=', startYear)
      // .where('creation_date', '<=', endYear)
      .orderBy('creation_date', 'asc')
      .onSnapshot(
        (result) => {
          result.docChanges().forEach((change) => {
            const data = change.doc.data()
            const id = change.doc.id
            if (change.type === 'added') {
              dispatch(updateProjects({ id, ...data }))
            }
            if (change.type === 'modified') {
              dispatch(updateProjects({ id, ...data }))
            }
            if (change.type === 'removed') {
              dispatch(removeProjects({ id, ...data }))
            }
          })
        },
        (error) => {
          console.log(error)
          //   dispatch(loadingTasksError('Error: ' + error))
        }
      )
  }
}

export function saveProject(id, payload) {
  return (dispatch) => {
    dispatch(ajaxCall())
    if (id == null) {
      projectsRef
        .add(payload)
        .then((res) => {
          dispatch(ajaxCallSuccess())
          notify.show('salvato con successo', 'success', 3000)
        })
        .catch((error) => {
          if (error.response !== undefined) {
            dispatch(ajaxCallFailure(error.response.statusText))
          } else {
            dispatch(ajaxCallFailure(error.message))
          }
        })
    } else {
      projectsRef
        .doc(id)
        .update({ ...payload })
        .then(() => {
          dispatch(ajaxCallSuccess())
          notify.show('salvato con successo', 'success', 3000)
        })
        .catch((error) => {
          if (error.response !== undefined) {
            dispatch(ajaxCallFailure(error.response.statusText))
          } else {
            dispatch(ajaxCallFailure(error.message))
          }
        })
    }
  }
}

export function deleteProject(id) {
  return (dispatch) => {
    dispatch(ajaxCall())
    projectsRef
      .doc(id)
      .delete()
      .then((res) => {
        dispatch(ajaxCallSuccess())
        notify.show('progetto eliminato con successo', 'success', 3000)
      })
      .catch((error) => {
        if (error.response !== undefined) {
          dispatch(ajaxCallFailure(error.response.statusText))
        } else {
          dispatch(ajaxCallFailure(error.message))
        }
      })
  }
}

export function orderToProject(projectPayload, tasksPayload) {
  return (dispatch) => {
    dispatch(ajaxCall())
    const tasksIds = []
    const tasks = [];

    const batch = database.batch()

    tasksPayload.forEach((task, i) => {
      const newDoc = tasksRef.doc()
      tasksIds.push(newDoc.id)
      batch.set(newDoc, task)

      tasks.push({ id: newDoc.id, task: task });
    })

    projectPayload.tasks = tasksIds
    const newDoc = projectsRef.doc()
    batch.set(newDoc, projectPayload)

    batch
      .commit()
      .then((result) => {
        tasks.forEach(t => {
          const payload = { ...t.task, project_id: newDoc.id };
          tasksRef.doc(t.id).update({ ...payload });
        }).then((res) => {
          notify.show('salvato con successo', 'success', 3000)
          dispatch(ajaxCallSuccess())
        })
      })
      .catch((error) => {
        if (error.response !== undefined) {
          dispatch(ajaxCallFailure(error.response.statusText))
        } else {
          dispatch(ajaxCallFailure(error.message))
        }
      })
  }
}

function ajaxCall() {
  return {
    type: projectsConstants.PROJECTS_AJAX_CALL
  }
}

function ajaxCallSuccess() {
  return {
    type: projectsConstants.PROJECTS_AJAX_SUCCESS
  }
}

function ajaxCallFailure(payload) {
  return {
    type: projectsConstants.PROJECTS_AJAX_FAILURE,
    payload
  }
}

function updateProjects(payload) {
  return {
    type: projectsConstants.UPDATE_PROJECTS,
    payload
  }
}

function removeProjects(payload) {
  return {
    type: projectsConstants.REMOVE_PROJECTS,
    payload
  }
}

export function toggleProject(payload) {
  return {
    type: projectsConstants.OPEN_PROJECT,
    payload
  }
}

export function changeProjectArchivedStatus(id, archived) {
  return (dispatch) => {
    dispatch(ajaxCall())
    projectsRef
      .doc(id)
      .update({ archived })
      .then(() => {
        dispatch(ajaxCallSuccess())
        notify.show('salvato con successo', 'success', 3000)
      })
      .catch((error) => {
        if (error.response !== undefined) {
          dispatch(ajaxCallFailure(error.response.statusText))
        } else {
          dispatch(ajaxCallFailure(error.message))
        }
      })
  }
}

function resetProjectList() {
  return {
    type: projectsConstants.PROJECT_RESET
  }
}
