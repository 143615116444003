import React from 'react'
import _ from 'lodash'

const ProjectProgress = (props) => {
  const prc = _.round((props.spentHours / props.estimatedHours) * 100, 1) || 0
  return (
    <>
      <h2>progresso</h2>
      <div className="pp-progress-bar">
        <div className="estimated">{props.estimatedHours} ore</div>
        <div className="bar">
          <div
            className="barc"
            style={{
              width: `${prc}%`
            }}
          ></div>
          <span className="perc">{`${prc}%`}</span>
        </div>
        <div
          className={`spent ${prc > 5 ? 'right' : ''}`}
          style={{
            width: `${prc}%`
          }}
        >
          {Math.floor(props.spentHours)} ore
        </div>
      </div>
    </>
  )
}

export default ProjectProgress
