import { vacationConstants } from '../constants'
import { vacationRef } from '../database'
// import { toast } from 'react-toastify'
import { notify } from 'react-notify-toast'

export let vacationSnap

export function loadVacation(year) {
    return (dispatch) => {
        dispatch(loadingVacation())
        dispatch(ajaxCall())
        vacationSnap = vacationRef
            .where('year', '==', year)
            .onSnapshot(
                (result) => {
                    dispatch(ajaxCallSuccess())
                    result.docChanges().forEach((change) => {
                        const data = change.doc.data()
                        const id = change.doc.id
                        if (change.type === 'added') {
                            dispatch(updateVacation({ id, ...data }))
                        }
                        if (change.type === 'modified') {
                            dispatch(updateVacation({ id, ...data }))
                        }
                        if (change.type === 'removed') {
                            dispatch(removeVacation({ id, ...data }))
                        }


                    })
                },
                (error) => {
                    console.log(error)
                    dispatch(loadingVacationError('Error: ' + error))
                }
            )
    }
}

function loadingVacation() {
    return {
        type: vacationConstants.LOADING_VACATION
    }
}

function loadingVacationError(payload) {
    return {
        type: vacationConstants.LOADING_VACATION_ERROR,
        payload
    }
}

function updateVacation(payload) {
    return {
        type: vacationConstants.UPDATE_VACATION,
        payload
    }
}

function removeVacation(payload) {
    return {
        type: vacationConstants.REMOVE_VACATION,
        payload
    }
}

export function createVacation(payload) {
    return (dispatch) => {
        // dispatch(ajaxCall())
        vacationRef
            .add(payload)
            .then((res) => {
                // dispatch(ajaxCallSuccess())
            })
            .catch((error) => {
                if (error.response !== undefined) {
                    dispatch(ajaxCallFailure(error.response.statusText))
                } else {
                    dispatch(ajaxCallFailure(error.message))
                }
            })
    }
}

export function showLoader() {
    return (dispatch) => {
        dispatch(ajaxCall())
    }
}

export function hideLoader() {
    return (dispatch) => {
        dispatch(ajaxCallSuccess())
    }
}

function ajaxCall() {
    return {
        type: vacationConstants.VACATION_AJAX_CALL
    }
}

function ajaxCallSuccess() {
    return {
        type: vacationConstants.VACATION_AJAX_SUCCESS
    }
}

function ajaxCallFailure(payload) {
    return {
        type: vacationConstants.VACATION_AJAX_FAILURE,
        payload
    }
}

export function saveVacation(id, payload, showNotify = true) {
    return (dispatch) => {
        if (showNotify) {
            // dispatch(ajaxCall())
        }
        vacationRef
            .doc(id)
            .update({ ...payload })
            .then(() => {
                // dispatch(ajaxCallSuccess())
                if (showNotify) {
                    notify.show('salvato con successo', 'success', 3000)
                }
            })
            .catch((error) => {
                if (error.response !== undefined) {
                    dispatch(ajaxCallFailure(error.response.statusText))
                } else {
                    dispatch(ajaxCallFailure(error.message))
                }
            })
    }
}

// export function deleteInvoice(id) {
//   return (dispatch) => {
//     dispatch(ajaxCall())
//     vacationRef
//       .doc(id)
//       .update({ deleted: true })
//       .then(() => {
//         dispatch(ajaxCallSuccess())
//         notify.show('eliminato con successo', 'success', 3000)
//       })
//       .catch((error) => {
//         if (error.response !== undefined) {
//           dispatch(ajaxCallFailure(error.response.statusText))
//         } else {
//           dispatch(ajaxCallFailure(error.message))
//         }
//       })
//   }
// }

// export function deleteInvoiceByGuid(guid) {
//   return (dispatch) => {
//     dispatch(ajaxCall())
//     vacationRef
//       .where('guid', '==', guid)
//       .get()
//       .then((querySnapshot) => {
//         if (querySnapshot.docs.length > 0) {
//           querySnapshot.forEach((doc) => {
//             doc.ref.delete()
//             dispatch(ajaxCallSuccess())
//             notify.show('eliminato con successo', 'success', 3000)
//           })
//         } else {
//           dispatch(
//             ajaxCallFailure(
//               'Impossibile trovare un documento con questo guid ' + guid
//             )
//           )
//         }
//       })
//     // invoicesRef.doc(id).update({ deleted: true }).then(() => {
//     //   dispatch(ajaxCallSuccess())
//     //   toast.success('eliminato con successo')
//     // }).catch(error => {
//     //   if (error.response !== undefined) { dispatch(ajaxCallFailure(error.response.statusText)) } else { dispatch(ajaxCallFailure(error.message)) }
//     // })
//   }
// }

// function resetVacationList() {
//     return {
//         type: vacationConstants.VACATION_RESET
//     }
// }
