import moment from 'moment'
import { store } from '../store'
import firebase from '../firestore'
import { usersRef } from '../database'
import { userConstants, animalConstants, dbConstants } from '../constants'

import {
  colleaguesSnap,
  companiesSnap,
  customersSnap,
  estimatesSnap,
  vacationSnap,
  projectsSnap,
  sprintSnap,
  tasksSnap,
  executionsSnap,
  calendarSnap,
  vacationDescSnap
} from '.'

import { notify } from 'react-notify-toast'

let usersSnap

export function loginMicrosoft(payload) {
  return (dispatch) => {
    dispatch(loginInProgress())
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        const provider = new firebase.auth.OAuthProvider(
          'microsoft.com'
        ).setCustomParameters({
          tenant: 'e7ba574e-9b8c-48ea-936e-372a1074b6f0'
        })
        firebase
          .auth()
          .signInWithPopup(provider)
          .then((result) => {
            dispatch(createUser(result.user))
          })
          .catch((error) => {
            dispatch(loginFailure(error.message))
          })
      })
      .catch((error) => {
        console.log(error)
        dispatch(loginFailure(error.message))
      })
  }
}

export function loginFirebase(payload) {
  return (dispatch) => {
    dispatch(loginInProgress())
    firebase
      .auth()
      .signInWithEmailAndPassword(payload.email, payload.password)
      .then((result) => {
        dispatch(createUser(result.user))
      })
      .catch((error) => {
        dispatch(loginFailure(error.message))
      })
      .catch((error) => {
        dispatch(loginFailure(error.message))
      })
  }
}

// CALLBACKS

function loginInProgress() {
  return {
    type: userConstants.LOGIN_REQUEST
  }
}

export function loginSuccess(payload) {
  return {
    type: userConstants.LOGIN_SUCCESS,
    payload
  }
}

function loginFailure(payload) {
  return {
    type: userConstants.LOGIN_FAILURE,
    payload
  }
}

// LOGOUT

export function userLogout() {
  return (dispatch) => {
    dispatch(logoutInProgress())
    usersSnap()
    colleaguesSnap()
    companiesSnap()
    executionsSnap()
    customersSnap()
    estimatesSnap()
    vacationSnap()
    projectsSnap()
    sprintSnap()
    tasksSnap()
    calendarSnap()
    vacationDescSnap()
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch(logoutSuccess())
      })
      .catch((error) => {
        console.log(error)
        // dispatch(taskFailure(error.message))
      })
  }
}

function logoutInProgress() {
  return {
    type: userConstants.LOGOUT
  }
}

function logoutSuccess() {
  return {
    type: userConstants.LOGOUT_SUCCESS
  }
}

function createUser(result) {
  return (dispatch) => {
    usersRef
      .where('uid', '==', result.uid)
      .get()
      .then((doc) => {
        if (doc.size === 0) {
          const u = {
            uid: result.uid,
            displayName: result.displayName,
            email: result.email,
            companies: [],
            avatar:
              animalConstants[
              Math.ceil(Math.random() * animalConstants.length - 1)
              ],
            economics: false,
            dashboard: true,
            estimates: false,
            currentCompany: '',
            currentYear: moment().year(),
            orders: false,
            projects: true,
            invoices: false,
            admin: false,
            navCollapsed: false,
            tobeinvoiced: false,
            timesheets: true,
            calendar: false,
            vacation: false,
            role: 'Nessuno',
            ral: null,
            hours: null,
            manage_vacation: false,
            ...dbConstants
          }
          usersRef.add(u)
        }
        dispatch(loginSuccess())
      })
  }
}

export function userLoad(user) {
  return (dispatch) => {
    // dispatch(taskLoading())
    usersSnap = usersRef.where('uid', '==', user.uid).onSnapshot((result) => {
      result.docChanges().forEach((change) => {
        const data = change.doc.data()
        const id = change.doc.id
        dispatch(userLoadSuccess({ id, ...data }))
        // dispatch(taskSuccess(null))
      })
    })
  }
}

export function usersLoad() {
  return (dispatch) => {
    // dispatch(taskLoading())
    usersSnap = usersRef.onSnapshot((result) => {
      // let data = []
      result.docChanges().forEach((change) => {
        // data.push({ id, ...change.doc.data() })
        // dispatch(usersLoadSuccess({ id, ...change.doc.data() }))
        // dispatch(taskSuccess(null))

        const data = change.doc.data()
        const id = change.doc.id
        if (change.type === 'added') {
          dispatch(updateUser({ id, ...data }))
        }
        if (change.type === 'modified') {
          dispatch(updateUser({ id, ...data }))
        }
        if (change.type === 'removed') {
          dispatch(removeUser({ id, ...data }))
        }
      })
      // dispatch(usersLoadSuccess(data))
    })
  }
}

export function saveUser(id, payload) {
  return (dispatch) => {
    dispatch(ajaxCall())

    usersRef
      .doc(id)
      .update({ ...payload })
      .then(() => {
        dispatch(ajaxCallSuccess())
        notify.show('salvato con successo', 'success', 3000)
      })
      .catch((error) => {
        if (error.response !== undefined) {
          dispatch(ajaxCallFailure(error.response.statusText))
        } else {
          dispatch(ajaxCallFailure(error.message))
        }
      })
  }
}

function ajaxCall() {
  return {
    type: userConstants.USERS_AJAX_CALL
  }
}

function ajaxCallSuccess() {
  return {
    type: userConstants.USERS_AJAX_SUCCESS
  }
}

function ajaxCallFailure(payload) {
  return {
    type: userConstants.USERS_AJAX_FAILURE,
    payload
  }
}

function userLoadSuccess(payload) {
  return {
    type: userConstants.LOAD_USER_SUCCESS,
    payload
  }
}

function updateUser(payload) {
  return {
    type: userConstants.UPDATE_USERS,
    payload
  }
}

function removeUser(payload) {
  return {
    type: userConstants.REMOVE_USERS,
    payload
  }
}

export function toggleNav() {
  return (dispatch) => {
    const user = store.getState().user.profile
    usersRef.doc(user.id).update({ navCollapsed: !user.navCollapsed })
  }
}

export function localSaveEstimateFilter(payload) {
  return {
    type: userConstants.LOCAL_SAVE_ESTIMATE_FILTERS,
    payload
  }
}

export function saveEstimateFilter(estimatesFilters) {
  return (dispatch) => {
    const user = store.getState().user.profile
    usersRef.doc(user.id).update({ estimatesFilters })
  }
}

export function localSaveProjectFilter(payload) {
  return {
    type: userConstants.LOCAL_SAVE_PROJECT_FILTERS,
    payload
  }
}

export function saveProjectFilter(projectsFilters) {
  return (dispatch) => {
    const user = store.getState().user.profile
    usersRef.doc(user.id).update({ projectsFilters })
  }
}

export function localSaveToBeInvoicedFilter(payload) {
  return {
    type: userConstants.LOCAL_SAVE_TOBEINVOICED_FILTERS,
    payload
  }
}

export function saveToBeInvoicedFilter(toBeInvoicedFilters) {
  return (dispatch) => {
    const user = store.getState().user.profile
    usersRef.doc(user.id).update({ toBeInvoicedFilters })
  }
}

export function localSaveOrderFilter(payload) {
  return {
    type: userConstants.LOCAL_SAVE_ORDER_FILTERS,
    payload
  }
}

export function saveOrderFilter(ordersFilters) {
  return (dispatch) => {
    const user = store.getState().user.profile
    usersRef.doc(user.id).update({ ordersFilters })
  }
}

export function saveInvoicesFilter(invoicesFilters) {
  return (dispatch) => {
    const user = store.getState().user.profile
    usersRef.doc(user.id).update({ invoicesFilters })
  }
}

export function saveVacationFilter(vacationFilters) {
  return (dispatch) => {
    const user = store.getState().user.profile
    usersRef.doc(user.id).update({ vacationFilters })
  }
}

export function setAvatar(a) {
  return (dispatch) => {
    const user = store.getState().user.profile
    usersRef.doc(user.id).update({ avatar: a })
  }
}

export function updateUIVars(id) {
  return (dispatch) => {
    if (id === null) {
      const coll = store.getState().colleagues.list
      coll.forEach((c) => {
        usersRef.doc(c.id).update({ ...dbConstants })
      })
    } else {
      const user = store.getState().user.profile
      usersRef.doc(user.id).update({ ...dbConstants })
    }
  }
}

export function setYear(currentYear) {
  return (dispatch) => {
    const user = store.getState().user.profile
    usersRef.doc(user.id).update({ currentYear })
  }
}

export function updateUserRAL(payload) {
  return {
    type: userConstants.UPDATE_USER_RAL,
    payload
  }
}

export function updateUserHours(payload) {
  return {
    type: userConstants.UPDATE_USER_HOURS,
    payload
  }
}

export function updateUserManageVacation(payload) {
  return {
    type: userConstants.UPDATE_USER_VACATION,
    payload
  }
}
