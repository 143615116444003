import React from 'react'

import { timeHelper } from '../../../../helpers'

import css from './ExecutionCard.module.scss'

const ExecutionCard = ({ card, project, task, addEventHandler }) => (
  <div className={css.Card} onClick={() => addEventHandler(card.id, false)}>
    <div className={css.Project}>{(project || {}).name}</div>
    <div className={css.Task}>{(task || {}).name}</div>
    <div className={css.Note}>{card.note}</div>
    <div className={css.Time}>
      {timeHelper.timeDiffHM(0, card.spent_seconds)}
    </div>
  </div>
)

export default ExecutionCard
