import { notify } from 'react-notify-toast'
import history from '../history'

import { customersRef } from '../database'
import { customersConstants } from '../constants'

export let customersSnap

export function loadCustomers(user) {
  return (dispatch) => {
    dispatch(loadingCustomers())
    customersSnap = customersRef.onSnapshot(
      (result) => {
        result.docChanges().forEach((change) => {
          const data = change.doc.data()
          const id = change.doc.id
          if (change.type === 'added') {
            dispatch(updateCustomer({ id, ...data }))
          }
          if (change.type === 'modified') {
            dispatch(updateCustomer({ id, ...data }))
          }
          if (change.type === 'removed') {
            dispatch(removeCustomer({ id, ...data }))
          }
        })
      },
      (error) => {
        console.log(error)
        dispatch(loadingCustomersError('Error: ' + error))
      }
    )
  }
}

function loadingCustomers() {
  return {
    type: customersConstants.LOADING_CUSTOMERS
  }
}

function loadingCustomersError(payload) {
  return {
    type: customersConstants.LOADING_CUSTOMERS_ERROR,
    payload
  }
}

function updateCustomer(payload) {
  return {
    type: customersConstants.UPDATE_CUSTOMER,
    payload
  }
}

function removeCustomer(payload) {
  return {
    type: customersConstants.REMOVE_CUSTOMER,
    payload
  }
}

export function saveCustomer(id, payload, redirect = true) {
  return (dispatch) => {
    dispatch(ajaxCall())
    if (id === null) {
      customersRef
        .add(payload)
        .then((res) => {
          dispatch(ajaxCallSuccess())
          notify.show('salvato con successo', 'success', 3000)
          if (redirect) history.push(`/registry`)
        })
        .catch((error) => {
          if (error.response !== undefined) {
            dispatch(ajaxCallFailure(error.response.statusText))
          } else {
            dispatch(ajaxCallFailure(error.message))
          }
        })
    } else {
      customersRef
        .doc(id)
        .update({ ...payload })
        .then(() => {
          dispatch(ajaxCallSuccess())
          notify.show('salvato con successo', 'success', 3000)
          if (redirect) history.push(`/registry`)
        })
        .catch((error) => {
          if (error.response !== undefined) {
            dispatch(ajaxCallFailure(error.response.statusText))
          } else {
            dispatch(ajaxCallFailure(error.message))
          }
        })
    }
  }
}

function ajaxCall() {
  return {
    type: customersConstants.CUSTOMER_AJAX_CALL
  }
}

function ajaxCallSuccess() {
  return {
    type: customersConstants.CUSTOMER_AJAX_SUCCESS
  }
}

function ajaxCallFailure(payload) {
  return {
    type: customersConstants.CUSTOMER_AJAX_FAILURE,
    payload
  }
}
