import React, { Component } from 'react'
// import { userRole } from '../../constants/user.constants'
import { connect } from 'react-redux'
import {
  updateUserRAL,
  updateUserHours,
  updateUserManageVacation
} from '../../actions'
const images = require.context('../../assets/animals', true)

class UserLine extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  changeRALHandler(event) {
    const newUser = {
      ...this.props.user,
      ral: event.target.value ? +event.target.value : null
    }
    this.props.updateUserRAL(newUser)
  }

  changeHoursHandler(event) {
    const newUser = {
      ...this.props.user,
      hours: event.target.value ? +event.target.value : null
    }
    this.props.updateUserHours(newUser)
  }

  changeManageVactionHandler(event) {
    const newUser = {
      ...this.props.user,
      manage_vacation: event.target.checked
    }
    this.props.updateUserManageVacation(newUser)
  }

  saveChangesHandler() {
    // console.log(this.props.user)
    const payload = {
      ral: this.props.user.ral ?? null,
      hours: this.props.user.hours ?? null,
      manage_vacation: this.props.user.manage_vacation ?? false
    }
    this.props.saveUser(this.props.user.id, payload)
  }

  render() {
    const u = this.props.user
    return (
      <div className="admin-user-line">
        <img src={images(`./${u.avatar}`)} alt={u.displayName} />{' '}
        <div className="user-body">
          <div className="user-head">
            <label>
              Nome
              <span>{u.displayName}</span>
            </label>
            <label>
              email
              <span>{u.email}</span>
            </label>
          </div>
          <div className="user-options">
            {Object.keys(this.props.user.options).map((option) => {
              return (
                <button
                  key={option}
                  className={
                    'btn-admin-ed ' +
                    (this.props.user.options[option] ? 'active' : '')
                  }
                  onClick={(event) =>
                    this.props.saveUser(this.props.user.id, {
                      [option]: !this.props.user.options[option]
                    })
                  }
                >
                  {this.props.user.options[option] ? (
                    <i className="far fa-check-square"></i>
                  ) : (
                    <i className="far fa-square"></i>
                  )}
                  {option}
                </button>
              )
            })}
          </div>
          <div className="user-info">
            <label>
              Costo anno
              <input
                type="number"
                min="0.01"
                step="0.01"
                onChange={(event) => this.changeRALHandler(event)}
                value={this.props.user.ral || ''}
              />
            </label>
            <label>
              Ore/giorno
              <input
                type="number"
                min="0"
                step="0.1"
                onChange={(event) => this.changeHoursHandler(event)}
                value={this.props.user.hours || ''}
              />
            </label>
            <label>
              gestisci ferie/permessi
              <label className="switch">
                <input
                  type="checkbox"
                  onChange={(event) => this.changeManageVactionHandler(event)}
                  checked={this.props.user.manage_vacation || false}
                />
                <span className="slider"></span>
              </label>
            </label>
            <button
              className="lnk-utils"
              onClick={() => this.saveChangesHandler()}
            >
              <i className="fas fa-save" /> SALVA
            </button>
          </div>
        </div>
      </div>

      //   <div className="user-line">
      //     <div>{this.props.user.email}</div>
      //     <div className="options-container">
      //       {Object.keys(this.props.user.options).map((option) => {
      //         switch (option) {
      //           default:
      //             return (
      //               <div key={option} className={'select-container ' + option}>
      //                 <label>{option}</label>
      //                 <select
      //                   className="admin"
      //                   onChange={(event) =>
      //                     this.props.saveUser(this.props.user.id, {
      //                       [option]: event.target.value,
      //                     })
      //                   }
      //                   defaultValue={this.props.user.options[option]}
      //                 >
      //                   <option key={option + 1} value={false}>
      //                     false
      //                   </option>
      //                   <option key={option + 2} value={true}>
      //                     true
      //                   </option>
      //                 </select>
      //               </div>
      //             )

      //           case 'role':
      //             return (
      //               <div key={option} className={'select-container ' + option}>
      //                 <label>{option}</label>
      //                 <select
      //                   className="admin"
      //                   onChange={(event) =>
      //                     this.props.saveUser(this.props.user.id, {
      //                       [option]: event.target.value,
      //                     })
      //                   }
      //                   defaultValue={this.props.user.options[option]}
      //                 >
      //                   {userRole.map((role) => {
      //                     return (
      //                       <option key={role} value={role}>
      //                         {role}
      //                       </option>
      //                     )
      //                   })}
      //                 </select>
      //               </div>
      //             )
      //         }
      //       })}
      //     </div>
      //   </div>
    )
  }
}

// function mapStateToProps(state) {
//   return state;
// }

export default connect(null, {
  updateUserRAL,
  updateUserHours,
  updateUserManageVacation
})(UserLine)
// export default UserLine
