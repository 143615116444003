import React from "react"
import { DragSource, DropTarget } from "react-dnd"
import cn from "classnames"
import _ from "lodash"
import drag_indicator from "../../../../assets/icons/play.svg"

export function Card(props) {
    return _.flowRight(
        props.connectDragSource,
        props.connectDropTarget
    )(
        <div
            className={cn("card", {
                "card--dragging": props.isDragging,
                "card--spacer": props.isSpacer,
            })}
        >
            <div
                style={{ backgroundColor: props.color }}
                className="card__title"
            >
                <div className="card-content">
                    <img src={drag_indicator} alt="" />

                    <span>{props.title}</span>
                </div>
            </div>
        </div>
    )
}

export const DraggableCard = _.flowRight([
    DropTarget(
        "Card",
        {
            hover(props, monitor) {
                const { columnId, columnIndex } = props
                const draggingItem = monitor.getItem()
                if (draggingItem.id !== props.id) {
                    props.moveCard(draggingItem.id, columnId, columnIndex)
                }
            },
        },
        connect => ({
            connectDropTarget: connect.dropTarget(),
        })
    ),
    DragSource(
        "Card",
        {
            beginDrag(props) {
                return { id: props.id }
            },

            isDragging(props, monitor) {
                return props.id === monitor.getItem().id
            },
        },
        (connect, monitor) => ({
            connectDragSource: connect.dragSource(),
            isDragging: monitor.isDragging(),
        })
    ),
])(Card)
