import React, { useState, useEffect } from 'react'
import moment from 'moment'

import Modal from '../../Modal'

import css from './TaskExecutionEditor.module.scss'

import { commonHelpers, timeHelper } from '../../../helpers'

const initialState = {
  id: null,
  note: '',
  project_id: '',
  task_id: '',
  hours: 0,
  minutes: 0,
  date: ''
}

const TaskExecutionEditor = (props) => {
  const [form, setForm] = useState(initialState)
  const [customer, setCustomer] = useState('')

  useEffect(() => {
    setForm({ ...form, project_id: '' })
  }, [customer])

  useEffect(() => {
    // console.log(props.task)
    if (props.task && props.task.start_date) {
      const time = props.task.end_date - props.task.start_date
      const project = props.projects.find((x) => x.id === props.task.project_id)
      const task = props.tasks.find((x) => x.id === props.task.task_id)
      const hours = time - (time % 3600)
      setForm({
        ...props.task,
        hours: hours / 3600,
        minutes: Math.ceil((time - hours) / 60),
        date: timeHelper.timeToDateInput(props.task.day),
        project,
        task
      })
    } else {
      // console.log(props.preSelectedTask)
      if (props.preSelectedTask) {
        const task = props.tasks.find((x) => x.id === props.preSelectedTask)
        // console.log(task)
        const prj = props.projects.find((x) => x.id === task.project_id)
        // console.log(prj)
        setForm({
          ...initialState,
          project_id: prj.id,
          task_id: props.preSelectedTask,
          date: timeHelper.timeToDateInput(props.task.day)
        })
      } else {
        setForm({
          ...initialState,
          date: timeHelper.timeToDateInput(props.task.day)
        })
      }
    }
  }, [props.task, props.tasks, props.projects, props.preSelectedTask])

  const saveFormHandler = () => {
    const start_date = moment
      .utc(moment(form.date).format('YYYY-MM-DD'))
      .startOf('day')
      .unix()
    const hours = form.hours * 3600
    const minutes = form.minutes * 60
    const payload = {
      note: form.note,
      project_id: form.project_id,
      task_id: form.task_id,
      creation_date: commonHelpers.time(),
      start_date,
      end_date: start_date + hours + minutes,
      recording: false,
      user_uid: props.uid,
      spent_seconds: hours + minutes
    }

    props.confirm(form.id, payload)
  }

  const deleteTaskExecutionHandler = () => {
    props.deleteTaskExecution(form.id)
  }

  const saveButtonIsDisabled =
    form.date === '' ||
    form.project_id === '' ||
    form.task_id === '' ||
    (form.hours === 0 && form.minutes === 0) ||
    form.hours > 24 ||
    form.minutes > 60

  // console.log(props)

  return (
    <Modal
      cancel={props.cancel}
      confirm={saveFormHandler}
      delete={form.id ? deleteTaskExecutionHandler : null}
      saveButtonIsDisabled={saveButtonIsDisabled}
    >
      <div>
        <h1>{props.task.id ? 'modifica' : 'aggiungi'} tempo</h1>
        <label>
          data
          <input
            type="date"
            value={form.date}
            onChange={(e) => setForm({ ...form, date: e.target.value })}
          />
        </label>
        <label>
          cliente
          <select
            value={customer}
            onChange={(e) => setCustomer(e.target.value)}
          >
            <option value=""></option>
            {props.customers.map((c, index) => {
              return (
                <option key={index} value={c.id}>
                  {c.name}
                </option>
              )
            })}
            <option value="null">Non associato a cliente</option>
          </select>
        </label>
        <label>
          progetto
          <select
            value={form.project_id}
            onChange={(e) => setForm({ ...form, project_id: e.target.value })}
          >
            <option value=""></option>
            {props.projects
              .filter((x) => !x.archived)
              .filter((i) => {
                if (customer) {
                  if (customer === 'null') {
                    return !i.customer_id && !i.estimate_id
                  } else {
                    if (i.estimate_id) {
                      const estm = props.estimates.find(
                        (e) => e.id === i.estimate_id
                      )
                      if (
                        estm &&
                        estm.v[estm.v.length - 1].info.customer === customer
                      )
                        return i
                    } else return i.customer_id === customer
                  }
                } else return i
              })
              .sort((a, b) =>
                a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
              )
              .map((p) => (
                <option key={p.id} value={p.id}>
                  {p.name}
                </option>
              ))}
          </select>
        </label>
        <label>
          task
          <select
            disabled={form.project_id === ''}
            value={form.task_id}
            onChange={(e) => setForm({ ...form, task_id: e.target.value })}
          >
            <option value=""></option>
            {props.tasks
              .filter((t) => t.project_id === form.project_id)
              .map((p) => (
                <option key={p.id} value={p.id}>
                  {p.name}
                </option>
              ))}
          </select>
        </label>
        <label>
          note
          <textarea
            placeholder="Note"
            value={form.note}
            onChange={(e) => setForm({ ...form, note: e.target.value })}
          ></textarea>
        </label>
        <label>
          tempo
          <ul className={css.UlTime}>
            <li>
              <input
                type="number"
                min="0"
                max="24"
                value={form.hours}
                onChange={(e) =>
                  setForm({
                    ...form,
                    hours: e.target.value ? parseInt(e.target.value) : ''
                  })
                }
              />{' '}
              ore
            </li>
            <li>
              <input
                type="number"
                min="0"
                max="60"
                value={form.minutes}
                onChange={(e) =>
                  setForm({
                    ...form,
                    minutes: e.target.value ? parseInt(e.target.value) : ''
                  })
                }
              />{' '}
              minuti
            </li>
          </ul>
        </label>
      </div>
    </Modal>
  )
}

export default TaskExecutionEditor
