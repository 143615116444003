export const estimatesAllConstants = {
    UPDATE_ESTIMATE: '[Estimates All] Update',
    REMOVE_ESTIMATE: '[Estimates All] Remove',
    LOADING_ESTIMATES: '[Estimates All] Loading',
    LOADING_ESTIMATES_ERROR: '[Estimates All] Loading error',
    ESTIMATE_AJAX_CALL: '[Estimates All] Ajax call',
    ESTIMATE_AJAX_SUCCESS: '[Estimates All] Ajax success',
    ESTIMATE_AJAX_FAILURE: '[Estimates All] Ajax error',
    ESTIMATE_RESET: '[Estimate All] Reset list'
}
