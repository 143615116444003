import { combineReducers } from 'redux'

import user from './user.reducer'
import background from './background.reducer'
import colleagues from './colleagues.reducer'
import estimates from './estimates.reducer'
import customers from './customers.reducer'
import companies from './companies.reducer'
import invoices from './invoices.reducer'
import tasks from './tasks.reducer'
import projects from './projects.reducer'
import sprints from './sprint.reducer'
import executions from './execution.reducer'
import economics from './economics.reducer'
import providers from './providers.reducer'
import roles from './roles.reducer'
import calendar from './calendar.reducer'
import vacations from './vacation.reducer'
import vacationDescriptions from './vacation-descriptions.reducer'
import estimatesAll from './estimates-all.reducer'

const rootReducer = combineReducers({
  user,
  background,
  colleagues,
  estimates,
  customers,
  companies,
  invoices,
  tasks,
  projects,
  sprints,
  executions,
  economics,
  providers,
  roles,
  calendar,
  vacations,
  vacationDescriptions,
  estimatesAll
})

export default rootReducer
