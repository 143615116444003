import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import DaysList from './CalendarWorkYear/DaysList/DaysList'
import CalendarList from './CalendarWorkYear/CalendarList/CalendarList'

import { timeHelper } from '../helpers/time.helpers'

import { loadCalendar, saveCalendar } from '../actions'

const Calendar = (props) => {
  const { loadCalendar, saveCalendar } = props

  useEffect(() => {
    loadCalendar(props.user.profile.currentYear)
  }, [loadCalendar, props.user.profile.currentYear])

  const setDaysHandler = (value) => {
    const ud = timeHelper.newUnixDate(
      props.user.profile.currentYear,
      value.month,
      value.day
    )

    const year = props.calendar.year
    let newDays = []

    if (year.days && year.days.length > 0) {
      const exists = year.days.findIndex((i) => i === ud) !== -1

      if (exists) {
        newDays = year.days.filter((i) => i !== ud)
      } else {
        newDays = [...year.days]
        newDays.push(ud)
      }
    } else {
      newDays.push(ud)
    }

    saveCalendar(year.id, { year: year.year, days: newDays })
  }

  const saveDaysListHandler = (weekDays) => {
    const days = weekDays.filter((i) => i.checked)

    const daysList = []

    //for every month
    for (let m = 0; m < 12; m++) {
      const month = moment.unix(
        timeHelper.addMonthFromYear(props.user.profile.currentYear, m)
      )

      //for every day
      for (let d = 1; d <= month.daysInMonth(); d++) {
        const actualDay = month
          .utc()
          .startOf('month')
          .startOf('day')
          .add(d - 1, 'days')

        if (days.findIndex((i) => +actualDay.format('e') === i.id) !== -1) {
          daysList.push(actualDay.unix())
        }
      }
    }

    const year = props.calendar.year

    saveCalendar(year.id, {
      year: year.year,
      days: daysList,
      selectedDays: weekDays
    })
  }

  return (
    <div className="calendar-container">
      <h1>Calendario giorni lavorativi</h1>
      <DaysList
        onSave={saveDaysListHandler}
        selectedDays={
          props.calendar.year ? props.calendar.year.selectedDays : []
        }
      />
      <CalendarList
        dayHandler={setDaysHandler}
        year={props.user.profile.currentYear}
        list={props.calendar.year.days.map((i) => {
          return { start_date: i }
        })}
      />
    </div>
  )
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {
  loadCalendar,
  saveCalendar
})(Calendar)
