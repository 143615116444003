import { notify } from 'react-notify-toast'
import history from '../history'

import { companiesRef } from '../database'
import { companiesConstants } from '../constants'

export let companiesSnap

export function loadCompanies() {
  return (dispatch) => {
    dispatch(loadingCompanies())
    companiesSnap = companiesRef.onSnapshot(
      (result) => {
        result.docChanges().forEach((change) => {
          const data = change.doc.data()
          const id = change.doc.id
          if (change.type === 'added') {
            dispatch(updateCompany({ id, ...data }))
          }
          if (change.type === 'modified') {
            dispatch(updateCompany({ id, ...data }))
          }
          if (change.type === 'removed') {
            dispatch(removeCompany({ id, ...data }))
          }
        })
      },
      (error) => {
        console.log(error)
        dispatch(loadingCompaniesError('Error: ' + error))
      }
    )
  }
}

function loadingCompanies() {
  return {
    type: companiesConstants.LOADING_COMPANIES
  }
}

function loadingCompaniesError(payload) {
  return {
    type: companiesConstants.LOADING_COMPANIES_ERROR,
    payload
  }
}

function updateCompany(payload) {
  return {
    type: companiesConstants.UPDATE_COMPANY,
    payload
  }
}

function removeCompany(payload) {
  return {
    type: companiesConstants.REMOVE_COMPANY,
    payload
  }
}

export function saveCompany(id, payload) {
  return (dispatch) => {
    dispatch(ajaxCall())
    if (id === null) {
      companiesRef
        .add(payload)
        .then(() => {
          dispatch(ajaxCallSuccess())
          notify.show('salvato con successo', 'success', 3000)
          history.push(`/registry`)
        })
        .catch((error) => {
          if (error.response !== undefined) {
            dispatch(ajaxCallFailure(error.response.statusText))
          } else {
            dispatch(ajaxCallFailure(error.message))
          }
        })
    } else {
      companiesRef
        .doc(id)
        .update({ ...payload })
        .then(() => {
          dispatch(ajaxCallSuccess())
          notify.show('salvato con successo', 'success', 3000)
          history.push(`/registry`)
        })
        .catch((error) => {
          console.log(error)
          if (error.response !== undefined) {
            dispatch(ajaxCallFailure(error.response.statusText))
          } else {
            dispatch(ajaxCallFailure(error.message))
          }
        })
    }
  }
}

function ajaxCall() {
  return {
    type: companiesConstants.COMPANY_AJAX_CALL
  }
}

function ajaxCallSuccess() {
  return {
    type: companiesConstants.COMPANY_AJAX_SUCCESS
  }
}

function ajaxCallFailure(payload) {
  return {
    type: companiesConstants.COMPANY_AJAX_FAILURE,
    payload
  }
}
