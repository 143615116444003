import React, { useEffect, useState } from 'react'
import Modal from '../../Modal'

import { commonHelpers } from '../../../helpers'

const ProviderRowDialog = (props) => {
  const [amount, setAmount] = useState('')
  const [description, setDescription] = useState('')
  const [date, setDate] = useState(commonHelpers.dateToTime(new Date()))
  const [invoiceNum, setInvoiceNum] = useState('')

  const [validationError, setValidationError] = useState(true)

  useEffect(() => {
    if (!amount || !date) setValidationError(true)
    else setValidationError(false)
  }, [amount, date])

  const onConfirm = () => {
    const newProv = {
      id: props.id,
      guid: commonHelpers.guid(),
      description: description,
      amount: +amount,
      date: date,
      invoice_num: invoiceNum
    }

    props.confirm(newProv)
  }

  return (
    <Modal
      cancel={props.cancel}
      confirm={onConfirm}
      saveButtonIsDisabled={validationError}
    >
      <h1>Aggiungi {props.title}</h1>
      <label>
        Fornitore
        <input
          type="text"
          placeholder="Nome"
          name="name"
          value={props.name}
          disabled={true}
        />
      </label>

      <label>
        Descrizione
        <input
          type="text"
          placeholder="Descrizione"
          name="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </label>

      <label>
        Data competenza
        <input
          type="date"
          placeholder="Data competenza"
          name="date"
          value={commonHelpers.timeToDate(date)}
          onChange={(e) => setDate(commonHelpers.dateToTime(e.target.value))}
        />
      </label>

      <label>
        Importo
        <input
          type="number"
          placeholder="Importo"
          name="amount"
          min="0"
          step="0.01"
          value={amount}
          onChange={(e) => setAmount(+e.target.value)}
        />
      </label>

      <label>
        Numero fattura
        <input
          type="text"
          placeholder="Numero fattura"
          name="invoice_num"
          value={invoiceNum}
          onChange={(e) => setInvoiceNum(e.target.value)}
        />
      </label>
    </Modal>
  )
}

export default ProviderRowDialog
