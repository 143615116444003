import React, { Component } from 'react'

class Modal extends Component {
  render() {
    return (
      <div className="modal-container">
        <div className="modal">
          <button
            className="lnk-close-modal"
            onClick={() => this.props.cancel()}
          >
            <i className="fa fa-times" aria-hidden="true"></i>
          </button>
          {this.props.children}
          <ul className="submit-btn">
            {this.props.delete && (
              <li className="li-modal-delete">
                <button
                  className="lnk-submit delete"
                  onClick={() => this.props.delete()}
                >
                  Elimina
                </button>
              </li>
            )}
            <li>
              <button
                className="lnk-submit cancel"
                onClick={() => this.props.cancel()}
              >
                Annulla
              </button>
            </li>
            <li>
              <button
                disabled={
                  this.props.saveButtonIsDisabled
                    ? this.props.saveButtonIsDisabled
                    : false
                }
                className="lnk-submit confirm"
                onClick={() => this.props.confirm()}
              >
                Conferma
              </button>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default Modal
