// import history from "../history"
import { notify } from 'react-notify-toast'
// import update from "immutability-helper"

import { executionsRef, database } from '../database'
import { executionsConstants } from '../constants'
// import { commonHelpers } from "../helpers"

export let executionsSnap

export function loadExecutions(uid, startYear, endYear) {
  return (dispatch) => {
    // dispatch(loadingtasks())
    if (executionsSnap) {
      executionsSnap()
      dispatch(resetExecutionList())
    }
    // console.log(uid, startYear, endYear)
    executionsSnap = executionsRef
      .where('user_uid', '==', uid)
      .where('start_date', '>=', startYear)
      .where('start_date', '<=', endYear)
      // .orderBy('creation_date', 'asc')
      .onSnapshot(
        (result) => {
          result.docChanges().forEach((change) => {
            const data = change.doc.data()
            const id = change.doc.id
            if (change.type === 'added') {
              dispatch(updateExecutions({ id, ...data }))
            }
            if (change.type === 'modified') {
              dispatch(updateExecutions({ id, ...data }))
            }
            if (change.type === 'removed') {
              dispatch(removeTasks(id))
            }
          })
        },
        (error) => {
          console.log(error)
          //   dispatch(loadingTasksError('Error: ' + error))
        }
      )
  }
}

export function saveExecution(id, payload) {
  return (dispatch) => {
    dispatch(ajaxCall())
    if (id == null) {
      executionsRef
        .add(payload)
        .then((res) => {
          dispatch(ajaxCallSuccess())
          notify.show('salvato con successo', 'success', 3000)
        })
        .catch((error) => {
          if (error.response !== undefined) {
            dispatch(ajaxCallFailure(error.response.statusText))
          } else {
            dispatch(ajaxCallFailure(error.message))
          }
        })
    } else {
      executionsRef
        .doc(id)
        .update({ ...payload })
        .then(() => {
          dispatch(ajaxCallSuccess())
          notify.show('salvato con successo', 'success', 3000)
        })
        .catch((error) => {
          if (error.response !== undefined) {
            dispatch(ajaxCallFailure(error.response.statusText))
          } else {
            dispatch(ajaxCallFailure(error.message))
          }
        })
    }
  }
}

export function saveMultipleExecutions(executionId, executions) {
  return (dispatch) => {
    dispatch(ajaxCall())

    let batch = database.batch()
    executions.forEach((execution, i) => {
      if (i === 0) {
        batch.update(executionsRef.doc(executionId), {
          start_date: execution.start_date,
          end_date: execution.end_date,
          recording: false
        })
      } else {
        let newDoc = executionsRef.doc()
        batch.set(newDoc, execution)
      }
    })

    batch
      .commit()
      .then(() => {
        notify.show('salvato con successo', 'success', 3000)
      })
      .catch((error) => {
        if (error.response !== undefined) {
          dispatch(ajaxCallFailure(error.response.statusText))
        } else {
          dispatch(ajaxCallFailure(error.message))
        }
      })
  }
}

export function deleteExecution(id) {
  return (dispatch) => {
    dispatch(ajaxCall())
    executionsRef
      .doc(id)
      .delete()
      .then((res) => {
        dispatch(ajaxCallSuccess())
        notify.show('eliminata con successo', 'success', 3000)
      })
      .catch((error) => {
        if (error.response !== undefined) {
          dispatch(ajaxCallFailure(error.response.statusText))
        } else {
          dispatch(ajaxCallFailure(error.message))
        }
      })
  }
}

function removeTasks(id) {
  return {
    type: executionsConstants.REMOVE_EXECUTION,
    id
  }
}

function ajaxCall() {
  return {
    type: executionsConstants.EXECUTION_AJAX_CALL
  }
}

function ajaxCallSuccess() {
  return {
    type: executionsConstants.EXECUTION_AJAX_SUCCESS
  }
}

function ajaxCallFailure(payload) {
  return {
    type: executionsConstants.EXECUTION_AJAX_FAILURE,
    payload
  }
}

function updateExecutions(payload) {
  return {
    type: executionsConstants.UPDATE_EXECUTIONS,
    payload
  }
}

function resetExecutionList() {
  return {
    type: executionsConstants.EXECUTION_RESET
  }
}

export function saveTaskExecutionRemediation(id, payload) {
  return (dispatch) => {
    executionsRef
      .doc(id)
      .update({ ...payload })
      .then(() => {
        console.log(id, 'ok')
      })
      .catch((error) => {
        console.log(id, error)
      })
  }
}
