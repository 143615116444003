import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import CalendarSummary from './Calendar/CalendarSummary/CalendarSummary'
import WeekView from './Calendar/WeekView/WeekView'
import TaskExecutionEditor from './Calendar/TaskExecutionEditor/TaskExecutionEditor'

import { saveExecution, deleteExecution } from '../actions'

const initialTaskExecution = {
  id: null
}

const Calendar = (props) => {
  const [view, setView] = useState({
    month: moment().utc(),
    week: moment().utc(),
    modal: false,
    taskExecution: {
      ...initialTaskExecution
    }
  })

  const miniNavigatorHandler = (dir) => {
    if (dir === 0) {
      const curYear = moment.utc().format('YYYY')
      if (curYear !== props.user.profile.currentYear) {
        setView({
          ...view,
          month: moment().utc().set({ year: props.user.profile.currentYear })
        })
      } else {
        setView({ ...view, month: moment().utc() })
      }
    } else {
      let nd = view.month.add(dir, 'month')
      nd = nd.add(props.user.profile.currentYear - nd.year(), 'year')
      setView({ ...view, month: nd })
    }
  }

  const setDayHandler = (day) => {
    setView({
      ...view,
      week: moment(view.month)
        .startOf('month')
        .utc()
        .add(day - 1, 'day')
        .utc()
    })
  }

  const weekNavigationHandler = (dir) => {
    if (dir === 0) {
      const curYear = moment.utc().format('YYYY')
      if (curYear !== props.user.profile.currentYear) {
        setView({
          ...view,
          week: moment().utc().set({ year: props.user.profile.currentYear })
        })
      } else {
        setView({ ...view, week: moment().utc() })
      }
    } else {
      let nd = view.week.add(dir, 'week')
      nd = nd.add(props.user.profile.currentYear - nd.year(), 'year')
      setView({ ...view, week: nd })
    }
  }

  const addEventHandler = (target, isNewTask) => {
    let taskExecution = {
      ...initialTaskExecution,
      day: moment.unix(target).utc().startOf('day').utc().unix()
    }
    if (!isNewTask) {
      const task = props.executions.list.find((x) => x.id === target)
      taskExecution = {
        ...task,
        day: moment.unix(task.start_date).utc().startOf('day').utc().unix()
      }
    }
    setView({ ...view, modal: true, taskExecution })
  }

  const saveTaskExecution = (id, payload) => {
    setView({ ...view, modal: false })
    props.saveExecution(id, payload)
  }

  const deleteTaskExecution = (id) => {
    setView({ ...view, modal: false })
    props.deleteExecution(id)
  }

  const prevUserRef = useRef(null)
  useEffect(() => {
    if (!prevUserRef.current && props.user.profile) {
      prevUserRef.current = props.user.profile
      const curYear = moment.utc().format('YYYY')
      if (curYear !== props.user.profile.currentYear) {
        const m = moment.utc().set({ year: props.user.profile.currentYear })
        setView({ ...view, week: moment(m), month: moment(m) })
      }
    }

    if (
      props.user.profile &&
      prevUserRef.current &&
      props.user.profile.currentYear !== prevUserRef.current.currentYear
    ) {
      prevUserRef.current = props.user.profile
      const m = moment(view.month)
        .utc()
        .set({ year: props.user.profile.currentYear })
      const w = moment(view.week)
        .utc()
        .set({ year: props.user.profile.currentYear })
      setView({ ...view, week: w, month: m })
    }
  }, [props.user, view])

  return (
    <div className="calendar-container">
      <h1>Calendario attività</h1>
      <ul className="ul-calendar">
        <li className="summary">
          <CalendarSummary
            month={view.month}
            navigatorHandler={miniNavigatorHandler}
            dayHandler={setDayHandler}
            list={props.executions.list || []}
          />
        </li>
        <li className="weekview">
          <WeekView
            week={view.week}
            list={props.executions.list || []}
            navigatorHandler={weekNavigationHandler}
            projects={props.projects.list || []}
            tasks={props.tasks.list || []}
            addEventHandler={addEventHandler}
          />
        </li>
      </ul>
      {view.modal && (
        <TaskExecutionEditor
          cancel={() => setView({ ...view, modal: false })}
          confirm={saveTaskExecution}
          projects={props.projects.list || []}
          customers={props.customers.list || []}
          estimates={props.estimatesAll.list || []}
          tasks={props.tasks.list || []}
          task={view.taskExecution}
          uid={props.user.profile.uid}
          deleteTaskExecution={deleteTaskExecution}
        />
      )}
    </div>
  )
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, { saveExecution, deleteExecution })(
  Calendar
)
