import React, { Component } from 'react'
import Dialog from '../../Dialog/Dialog'

import { addResource } from '../../../constants/tasks.constants'

class ResourceDialog extends Component {
  constructor(props) {
    super(props)

    const ar = this.props.availableResources.filter(
      (r) => this.props.currentTaskResources.indexOf(r.value) < 0
    )

    this.formInputs = {
      resources: {
        ...addResource.resources,
        options: [...ar],
        // options: [...this.props.availableResources],
      },
    }

    // const x = this.props.availableResources.filter(r => )

    //creating structure of component state
    let formData = {}

    Object.keys(addResource).map(
      (info) => (formData[info] = this.formInputs.resources.options[0].value)
    )
    // console.log(this.props.availableResources)
    // console.log(this.props.currentTaskResources)
    // console.log(this.formInputs)

    this.state = {
      ...formData,
    }
  }

  editState = (key, value) => this.setState({ [key]: value })

  submit = () => {
    this.props.manageResource('add', this.props.taskId, this.state.resources)
    this.props.toggleResourceModal()
  }

  render() {
    return (
      <Dialog
        formInputs={this.formInputs}
        title={'Aggiungi risorsa'}
        columns={1}
        submittedForm={this.submit}
        buttons={[
          {
            label: 'Annulla',
            func: this.props.toggleResourceModal,
            submit: '',
          },
          {
            label: 'Aggiungi',
            func: this.submit,
            submit: true,
          },
        ]}
        close={this.props.close}
        editState={this.editState}
        values={this.state}
      ></Dialog>
    )
  }
}

export default ResourceDialog
