import React from 'react'

import classes from './Chips.module.scss'
import { colleaguesHelpers } from '../../../helpers'
const images = require.context('../../../assets/animals', true)

const Chips = (props) => {
  const user = colleaguesHelpers.getUserByUid(props.user)
  const customer = props.customer
  const estimate = props.estimate

  if (user && user.avatar) {
    return (
      <span className={classes.Chips}>
        <img src={images(`./${user.avatar}`)} alt={user.displayName} />
        {user.displayName}
        {props.delete && (
          <button onClick={() => props.delete(props.user)}>
            <i className="fas fa-times-circle"></i>
          </button>
        )}
      </span>
    )
  } else if (customer) {
    return (
      <span className={classes.Chips}>
        {customer}
        {props.delete && (
          <button onClick={() => props.delete(props.customer)}>
            <i className="fas fa-times-circle"></i>
          </button>
        )}
      </span>
    )
  } else if (estimate) {
    return (
      <span className={classes.Chips}>
        {estimate}
        {props.delete && (
          <button onClick={() => props.delete(props.estimate)}>
            <i className="fas fa-times-circle"></i>
          </button>
        )}
      </span>
    )
  } else {
    return null
  }
}

export default Chips
