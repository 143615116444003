import { vacationDescRef } from '../database'
import { vacationDescConstants } from '../constants'

export let vacationDescSnap

export function loadVacationDescription() {
    return (dispatch) => {
        // dispatch(ajaxCall())
        dispatch(loadingVacationDesc())
        vacationDescSnap = vacationDescRef.onSnapshot(
            (result) => {
                // dispatch(ajaxCallSuccess())
                result.docChanges().forEach((change) => {
                    const data = change.doc.data()
                    const id = change.doc.id
                    if (change.type === 'added') {
                        dispatch(updateVacationDesc({ id, ...data }))
                    }
                    if (change.type === 'modified') {
                        dispatch(updateVacationDesc({ id, ...data }))
                    }
                    if (change.type === 'removed') {
                        dispatch(removeVacationDesc({ id, ...data }))
                    }
                })

            },
            (error) => {
                console.log(error)
                dispatch(loadingVacationDescError('Error: ' + error))
            }
        )
    }
}

function loadingVacationDesc() {
    return {
        type: vacationDescConstants.LOADING_VACATION_DESC
    }
}

function loadingVacationDescError(payload) {
    return {
        type: vacationDescConstants.LOADING_VACATION_DESC_ERROR,
        payload
    }
}

function updateVacationDesc(payload) {
    return {
        type: vacationDescConstants.UPDATE_VACATION_DESC,
        payload
    }
}

function removeVacationDesc(payload) {
    return {
        type: vacationDescConstants.REMOVE_VACATION_DESC,
        payload
    }
}

// function ajaxCall() {
//     return {
//         type: vacationDescConstants.VACATION_DESC_AJAX_CALL
//     }
// }

// function ajaxCallSuccess() {
//     return {
//         type: vacationDescConstants.VACATION_DESC_AJAX_SUCCESS
//     }
// }

// function ajaxCallFailure(payload) {
//     return {
//         type: vacationDescConstants.VACATION_DESC_AJAX_FAILURE,
//         payload
//     }
// }
