import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import InvoicesFilters from './Invoices/InvoicesFilters'
import InvoiceLine from './Invoices/InvoiceLine'

import Modal from './Modal'

import { commonHelpers } from '../helpers'
import { deleteInvoice, saveInvoicesFilter } from '../actions'

const initialState = {
  currentMonth: null,
  showDeleteModal: false,
  invoice: null
}

class Invoices extends Component {
  constructor() {
    super()
    this.state = initialState
    this.removeInvoice = this.removeInvoice.bind(this)
    this.escFunction = this.escFunction.bind(this)
    this.hideModal = this.hideModal.bind(this)
    this.confirmModalAction = this.confirmModalAction.bind(this)
  }

  escFunction(event) {
    switch (event.keyCode) {
      case 27:
        this.hideModal()
        break
      default:
        break
    }
  }

  changeMonth(dir) {
    this.props.saveInvoicesFilter({
      ...this.props.user.profile.invoicesFilters,
      currentMonth: commonHelpers.addMonthCurrentYear(
        this.state.currentMonth,
        dir
      )
    })
  }

  hideModal() {
    this.setState({ showDeleteModal: false, invoice: null })
  }

  filterList() {
    const { list } = this.props.invoices
    const fil = this.props.user.profile.invoicesFilters
    const sm = moment
      .unix(this.state.currentMonth)
      .utc()
      .startOf('month')
      .unix()
    const em = moment.unix(this.state.currentMonth).utc().endOf('month').unix()
    return (list || [])
      .filter((x) => {
        return x.invoiceDate <= em && x.invoiceDate >= sm
      })
      .filter((x) => !fil.sent || x.sent === !fil.sent)
      .filter((x) => fil.customer === '' || x.customer === fil.customer)
      .sort((a, b) => (a.customer > b.customer ? 1 : -1))
  }

  removeInvoice(invoice) {
    this.setState({ showDeleteModal: true, invoice })
  }

  confirmModalAction() {
    const invoice = this.state.invoice
    this.setState({ showDeleteModal: false, invoice: null }, () => {
      this.props.deleteInvoice(invoice.id)
    })
  }

  static getDerivedStateFromProps(props, state) {
    if (
      state.currentMonth !== props.user.profile.invoicesFilters.currentMonth
    ) {
      const cm = commonHelpers.setYMFromMonth(
        props.user.profile.invoicesFilters.currentMonth,
        props.user.profile.currentYear
      )
      return {
        currentMonth:
          cm || commonHelpers.currentMonthYear(props.user.profile.currentYear)
      }
    }
    return null
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false)
  }

  render() {
    const s = this.state
    const customers = this.props.customers.list
    // const { list } = this.props.invoices
    const filtered = this.filterList()

    return (
      <>
        <h1>Fatture</h1>
        <ul className="invoices-nav">
          <li>
            <button
              className="lnk-utils nm"
              onClick={() => this.changeMonth(-1)}
            >
              <i className="fas fa-chevron-left"></i>
            </button>
          </li>
          <li className="current">
            {commonHelpers.timeToMonth(s.currentMonth)}
          </li>
          <li>
            <button
              className="lnk-utils nm"
              onClick={() => this.changeMonth(1)}
            >
              <i className="fas fa-chevron-right"></i>
            </button>
          </li>
        </ul>

        <InvoicesFilters
          subtotal={filtered.reduce(function (a, b) {
            return a + b.amount
          }, 0)}
        />

        <div className="invoices-c">
          {
            (customers || [])
              .filter(
                (x) =>
                  (filtered || []).filter((c) => c.customer === x.id).length > 0
              )
              .map((x, index) => {
                return (
                  <div key={index}>
                    <div className="title-section">{x.name}</div>
                    <div className="invoice-line-c header">
                      <span className="del"></span>
                      <span className="eid">Numero</span>
                      <span className="title">Titolo</span>
                      <span className="amount">Importo</span>
                      <span className="oda">Oda</span>
                      <span className="bem">BEM</span>
                      <span className="year">Numero fattura</span>
                      <span className="year">Anno</span>
                      <span className="expire">Data fattura</span>
                      <span className="expire">Data scadenza</span>
                      <span className="button"></span>
                    </div>
                    {filtered
                      .filter((i) => i.customer === x.id)
                      .map((i, index2) => {
                        return (
                          <InvoiceLine
                            key={index2}
                            removeInvoice={this.removeInvoice}
                            i={i}
                          />
                        )
                      })}
                  </div>
                )
              })
            // this.filterList().map((i, index) => {
            //   return (
            //     <InvoiceLine
            //       key={index}
            //       removeInvoice={this.removeInvoice}
            //       i={i}
            //     />
            //   )
            // })
          }
        </div>
        {s.showDeleteModal && (
          <Modal cancel={this.hideModal} confirm={this.confirmModalAction}>
            <h3>Elimina</h3>
            Eliminare riga?
          </Modal>
        )}
      </>
    )
  }
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, { saveInvoicesFilter, deleteInvoice })(
  Invoices
)
