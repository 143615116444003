import { executionsConstants } from '../constants'
import { produce } from 'immer'

const initialState = {
  list: [],
  error: null,
  loading: false,
  ajaxSpinner: false,
  ajaxStatus: null
}

export default (state = initialState, action) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case executionsConstants.UPDATE_EXECUTIONS:
        draftState.list = !draftState.list.length
          ? [action.payload]
          : draftState.list.findIndex((x) => x.id === action.payload.id) === -1
          ? draftState.list.concat(action.payload)
          : draftState.list.map((item) => {
              return item.id === action.payload.id ? action.payload : item
            })
        break

      case executionsConstants.REMOVE_EXECUTION:
        draftState.list = state.list.filter((item) => {
          return item.id !== action.id
        })
        draftState.loading = false
        break
      case executionsConstants.EXECUTION_AJAX_CALL:
        draftState.loading = true
        draftState.error = null
        break
      case executionsConstants.EXECUTION_AJAX_SUCCESS:
        draftState.loading = false
        break
      case executionsConstants.EXECUTION_AJAX_FAILURE:
        draftState.loading = false
        draftState.error = action.payload
        break
      case executionsConstants.EXECUTION_RESET:
        draftState.list = []
        break
      default:
        return draftState
    }
  })
