import React, { Component } from 'react'

import TaskLineEditor from './TaskLineEditor'

class ProjectTasksEditor extends Component {
  tle(t, isSubtask) {
    return (
      <TaskLineEditor
        t={t}
        addResource={this.props.addResource}
        project={this.props.project}
        editTask={this.props.editTask}
        deleteElement={this.props.deleteElement}
        editSprint={this.props.editSprint}
        toggleSetTimeModal={this.props.toggleSetTimeModal}
        isSubtask={isSubtask}
        key={t.id}
      />
    )
  }
  render() {
    return (
      <>
        <h2>Tasks</h2>
        {this.props.tasks.map((t) => (
          <div className="task-container" key={t.id}>
            {this.tle(t, false)}
            {t.subtasks.map((s) => this.tle(s, true))}
          </div>
        ))}
      </>
    )
  }
}

export default ProjectTasksEditor
