import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { commonHelpers, invoicesHelpers } from '../../helpers'
import { createInvoice, deleteInvoiceByGuid } from '../../actions'

class Line extends PureComponent {
  mustBePay(pay) {
    const b = this.props.b
    const e = { ...this.props.e }
    if (!pay) {
      this.props.createInvoice(invoicesHelpers.createInvoiceLine(e, b))
    } else {
      this.props.deleteInvoiceByGuid(b.guid)
    }
  }

  render() {
    const b = this.props.b
    const pay = invoicesHelpers.invoiceExists(b)
    return (
      <div className="tbi-line-c">
        <span className="date">{commonHelpers.timeToDate(b.date)}</span>
        <span className="title">{b.title}</span>
        <span className="amount">{commonHelpers.toMoney(b.amount)}</span>
        <span className="button">
          <button
            className={'lnk-send-to-invoiced ' + (!pay ? '' : 'red')}
            onClick={() => this.mustBePay(pay)}
            disabled={invoicesHelpers.canRetire(b)}
          >
            {!pay && (
              <ul>
                <li>Invia</li>
                <li>
                  <i className="fas fa-angle-double-right"></i>
                </li>
              </ul>
            )}
            {pay && (
              <ul>
                <li>Richiama</li>
                <li>
                  <i className="fas fa-angle-double-left"></i>
                </li>
              </ul>
            )}
          </button>
        </span>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, { createInvoice, deleteInvoiceByGuid })(
  Line
)
