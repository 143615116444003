import React from 'react'
import { commonHelpers } from '../../helpers'

export default class ProjectsFilters extends React.Component {
  render() {
    const customers = this.props.customers

    return (
      <div className="fls-container">
        <div className="filter">
          <input
            className="input"
            type="date"
            onChange={(e) =>
              this.props.setFilter(
                'deadline',
                e.target.value ? commonHelpers.dateToTime(e.target.value) : ''
              )
            }
          />
          <select
            className="input"
            onChange={(e) => this.props.setFilter('assigned', e.target.value)}
          >
            <option value="">Tutti</option>
            <option value="mine">Assegnati a me</option>
          </select>
          <select
            className="input"
            onChange={(e) =>
              this.props.setFilter('showArchived', e.target.value === '1')
            }
          >
            <option value="0">Attivi</option>
            <option value="1">Archiviati</option>
          </select>

          <select
            className="input"
            onChange={(event) =>
              this.props.setFilter('customer', event.target.value)
            }
            value={this.props.customer}
          >
            <option value="">[Cliente]</option>
            {(customers || []).map((c, index) => {
              return (
                <option key={index} value={c.id}>
                  {c.name}
                </option>
              )
            })}
            <option value="null">Non associato a cliente</option>
          </select>

          <input
            type="text"
            className="input"
            placeholder="Cerca"
            value={this.props.description}
            onChange={(e) =>
              this.props.setFilter('description', e.target.value)
            }
          ></input>
        </div>
      </div>
    )
  }
}
