import React from "react"

export function Column({ title, children }) {
    return (
        <div className="column">
            <label>{title}</label>
            <div className="cards-container">{children}</div>
        </div>
    )
}
