import React from 'react'
import moment from 'moment'
import css from './CalendarList.module.scss'
import CalendarSummary from '../../Calendar/CalendarSummary/CalendarSummary'
import { timeHelper } from '../../../helpers'

const CalendarList = (props) => {
  const setDayHandler = (month, d) => {
    const dateValues = { month, day: d }
    props.dayHandler(dateValues)
  }

  return (
    <ul className={css.UlCalendar}>
      {[...Array(12)].map((m, index) => {
        const month = moment.unix(
          timeHelper.addMonthFromYear(props.year, index)
        )

        return (
          <li key={index}>
            <CalendarSummary
              month={month}
              navigatorHandler={null}
              dayHandler={setDayHandler.bind(this, index)}
              list={props.list}
            />
          </li>
        )
      })}
    </ul>
  )
}

export default CalendarList
