import { customersConstants } from '../constants'

const initialState = {
  list: null,
  error: null,
  loading: false,
  ajaxSpinner: false,
  ajaxStatus: null,
  ajaxAction: null
}

export default function customers(state = initialState, action) {
  switch (action.type) {
    case customersConstants.LOADING_CUSTOMERS:
      return { ...state, loading: true, error: null }
    case customersConstants.LOADING_CUSTOMERS_ERROR:
      return { ...state, loading: false, error: action.payload }
    case customersConstants.UPDATE_CUSTOMER:
      return {
        ...state,
        list:
          state.list === null
            ? [action.payload]
            : (state.list.findIndex((x) => x.id === action.payload.id) === -1
                ? state.list.concat(action.payload)
                : state.list.map((item) => {
                    return item.id === action.payload.id ? action.payload : item
                  })
              ).sort((a, b) => (a.name > b.name ? 1 : -1)),
        ajaxAction: 'update',
        loading: false
      }
    case customersConstants.REMOVE_CUSTOMER:
      return {
        ...state,
        list: state.list.filter((item) => {
          return item.id !== action.payload.id
        }),
        ajaxAction: 'remove',
        loading: false
      }
    case customersConstants.CUSTOMER_AJAX_CALL:
      return { ...state, ajaxSpinner: true, ajaxStatus: null }
    case customersConstants.CUSTOMER_AJAX_SUCCESS:
      return { ...state, ajaxSpinner: false, ajaxStatus: null }
    case customersConstants.CUSTOMER_AJAX_FAILURE:
      return { ...state, ajaxSpinner: false, ajaxStatus: action.payload }
    default:
      return state
  }
}
