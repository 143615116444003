export const userConstants = {
  LOGIN_REQUEST: '[User] Login request',
  LOGIN_SUCCESS: '[User] Login success',
  LOGIN_FAILURE: '[User] Login failure',
  LOGOUT: '[User] Logout',
  LOGOUT_SUCCESS: '[User] Logout success',
  LOGOUT_FAILURE: '[User] Logout failure',
  LOAD_USER_SUCCESS: '[User] Update user success',
  LOAD_USERS: '[USER] Load Users',
  LOAD_USER_FAILURE: '[User] Update user failure',
  LOCAL_SAVE_ESTIMATE_FILTERS: '[User] Local save estimate filters',
  LOCAL_SAVE_ORDER_FILTERS: '[User] Local save order filters',
  LOCAL_SAVE_TOBEINVOICED_FILTERS: '[User] Local save to be invoiced filters',
  LOCAL_SAVE_PROJECT_FILTERS: '[User] Local save project filters',
  USERS_AJAX_CALL: '[Users] Ajax call',
  USERS_AJAX_SUCCESS: '[Users] Ajax success',
  USERS_AJAX_FAILURE: '[Users] Ajax error',
  REMOVE_USERS: '[Users] Remove user',
  UPDATE_USERS: '[Users] Add user',
  UPDATE_USER_RAL: '[Users Update RAL',
  UPDATE_USER_HOURS: '[Users] Update hours',
  UPDATE_USER_VACATION: '[Users] Update manage vacation'
}

export const userRole = ['Nessuno', 'Front End developer', 'Back End developer']
