import moment from 'moment'
import { uiConstants } from '../constants'

export const timeHelper = {
  startOfYear,
  endOfYear,
  timeDiffHM,
  timeToDateInput,
  timeToInputTime,
  addMonthFromYear,
  newUnixDate,
  timeToDate
}

function startOfYear(year) {
  return moment().date(1).month(0).year(year).utc().startOf('day').unix()
}

function endOfYear(year) {
  return moment()
    .date(31)
    .month(11)
    .year(year)
    .utc()
    .endOf('month')
    .endOf('day')
    .unix()
}

function timeDiffHM(start, end) {
  const diff = end - start
  const h = (diff - (diff % 3600)) / 3600
  const m = Math.ceil((diff - h * 3600) / 60)

  let r = ''
  if (h > 0) {
    r = `${h} h`
  }
  if (m > 0) {
    if (r !== '') r += ', '
    r += `${m} m`
  }
  return r
}

function timeToDateInput(d) {
  return moment.unix(d).format(uiConstants.timeToDateInput)
}

function timeToDate(d) {
  return moment.unix(d)
}

function timeToInputTime(d) {
  const sd = moment.unix(d).startOf('day').utc().unix()
  const time = d - sd
  const hour = Math.floor(time / 3600)
  const min = time % 60
  return `${hour < 10 ? '0' : ''}${hour}:${min}${min < 10 ? '0' : ''}:00`
}

function addMonthFromYear(year, dir) {
  return moment().date(1).month(dir).year(year).utc().startOf('day').unix()
}

function newUnixDate(year, month, day) {
  return moment().month(month).year(year).date(day).utc().startOf('day').unix();
}
