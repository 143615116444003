import { store } from '../store'

export const companiesHelpers = {
  getCurrent
}

function getCurrent () {
  const st = store.getState()
  if (st.user === undefined || st.companies === undefined) { return null }
  const u = st.user.profile
  const c = st.companies.list
  if (u !== null && c !== null) {
    const f = c.filter(x => x.id === u.currentCompany)
    if (f.length > 0) {
      return f[0]
    }
    return null
  } else {
    return null
  }
}
