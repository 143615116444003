import { notify } from 'react-notify-toast'
import history from '../history'

import { rolesRef } from '../database'
import { rolesConstants } from '../constants'

export let rolesSnap

export function loadRoles() {
  return (dispatch) => {
    dispatch(loadingRoles())
    rolesSnap = rolesRef.onSnapshot(
      (result) => {
        result.docChanges().forEach((change) => {
          const data = change.doc.data()
          const id = change.doc.id
          if (change.type === 'added') {
            dispatch(updateRole({ id, ...data }))
          }
          if (change.type === 'modified') {
            dispatch(updateRole({ id, ...data }))
          }
          if (change.type === 'removed') {
            dispatch(removeRole({ id, ...data }))
          }
        })
      },
      (error) => {
        console.log(error)
        dispatch(loadingRolesError('Error: ' + error))
      }
    )
  }
}

function loadingRoles() {
  return {
    type: rolesConstants.LOADING_ROLES
  }
}

function loadingRolesError(payload) {
  return {
    type: rolesConstants.LOADING_ROLES_ERROR,
    payload
  }
}

function updateRole(payload) {
  return {
    type: rolesConstants.UPDATE_ROLE,
    payload
  }
}

function removeRole(payload) {
  return {
    type: rolesConstants.REMOVE_ROLE,
    payload
  }
}

export function saveRole(id, payload) {
  return (dispatch) => {
    dispatch(ajaxCall())
    if (id === null) {
      rolesRef
        .add(payload)
        .then((res) => {
          dispatch(ajaxCallSuccess())
          notify.show('salvato con successo', 'success', 3000)
          history.push(`/registry`)
        })
        .catch((error) => {
          if (error.response !== undefined) {
            dispatch(ajaxCallFailure(error.response.statusText))
          } else {
            dispatch(ajaxCallFailure(error.message))
          }
        })
    } else {
      rolesRef
        .doc(id)
        .update({ ...payload })
        .then(() => {
          dispatch(ajaxCallSuccess())
          notify.show('salvato con successo', 'success', 3000)
          history.push(`/registry`)
        })
        .catch((error) => {
          if (error.response !== undefined) {
            dispatch(ajaxCallFailure(error.response.statusText))
          } else {
            dispatch(ajaxCallFailure(error.message))
          }
        })
    }
  }
}

function ajaxCall() {
  return {
    type: rolesConstants.ROLE_AJAX_CALL
  }
}

function ajaxCallSuccess() {
  return {
    type: rolesConstants.ROLE_AJAX_SUCCESS
  }
}

function ajaxCallFailure(payload) {
  return {
    type: rolesConstants.ROLE_AJAX_FAILURE,
    payload
  }
}
