import React, { Component } from 'react'
import { connect } from 'react-redux'
// import Dialog from '../../Dialog/Dialog'
import moment from 'moment'

// import _ from 'lodash'

// import { commonHelpers } from '../../../helpers'

// import { dialogAddExec } from '../../../constants/timesheets.constants'

// import Modal from '../../Modal'
import TaskExecutionEditor from '../../Calendar/TaskExecutionEditor/TaskExecutionEditor'

// const momentCorrectTz = commonHelpers.getMoment()

const initialTaskExecution = {
  id: null
}

class TimeDialog extends Component {
  // constructor(props) {
  //   super(props)

  //   this.formInputs = {
  //     ...dialogAddExec
  //   }

  //   if (this.props.singleExecutionId) {
  //     delete this.formInputs.linked_task
  //   } else {
  //     this.formInputs.linked_task.options = [
  //       ...this.props.tasks.list
  //         .filter((x) => x.resources.indexOf(this.props.user.profile.uid) > -1)
  //         .map((t) => {
  //           return { label: t.name, value: t.id }
  //         })
  //     ]
  //   }

  //   this.formInputs.start_date.default = momentCorrectTz(
  //     this.props.toggleObj?.start || new Date()
  //   ).format('YYYY-MM-DD')

  //   let startDate = momentCorrectTz(
  //     this.props.toggleObj?.start || new Date()
  //   ).format('YYYY-MM-DD')

  //   this.state = {
  //     formData: this.props.formData,
  //     formInputs: this.formInputs,
  //     start_date: startDate,
  //     note: '',
  //     hours:
  //       _.round(
  //         (momentCorrectTz(this.props.toggleObj?.end).unix() -
  //           momentCorrectTz(this.props.toggleObj?.start).unix()) /
  //           3600,
  //         1
  //       ) ||
  //       _.round(
  //         (momentCorrectTz(this.props.toggleObj?.end_date) -
  //           momentCorrectTz(this.props.toggleObj?.start_date)) /
  //           3600,
  //         1
  //       ) ||
  //       0,
  //     linked_task:
  //       this.props.toggleObj?.task_id ||
  //       this.formInputs.linked_task?.options[0].value ||
  //       this.props.singleExecutionId,
  //     onEditExecution: this.props.toggleObj?.id || ''
  //   }
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   if (!_.isEqual(prevState, this.state) && this.state.firstValidation) {
  //     this.setState(commonHelpers.isFormNotValid(dialogAddExec, this.state))
  //   }
  // }

  // editState = (key, value) => {
  //   this.setState({ [key]: value })
  // }

  constructor() {
    super()
    this.state = {
      ...initialTaskExecution,
      day: moment.utc().startOf('day').utc().unix()
    }
  }

  saveExecution = (id, payload) => {
    // let startDate = momentCorrectTz(this.state.start_date).unix()

    // const t = this.props.tasks.list.find((x) => x.id === this.state.linked_task)

    // const payload = {
    //   start_date: startDate,
    //   end_date: startDate + this.state.hours * 3600,
    //   edit_date: commonHelpers.dateToTime(new Date()),
    //   task_id: this.state.linked_task,
    //   note: this.state.note,
    //   project_id: t.project_id !== undefined ? t.project_id : ''
    // }

    // this.state.onEditExecution
    //   ? this.props.saveExecution(this.state.onEditExecution, payload)
    //   : this.props.saveExecution(null, {
    //       ...payload,
    //       creation_date: commonHelpers.dateToTime(new Date()),
    //       user_id: this.props.userId,
    //       recording: false
    //     })
    // console.log(id, payload)

    this.props.saveExecution(id, payload)

    this.props.toggleTimeModal({})
  }

  deleteExecution = () => {
    this.props.deleteExecution(this.props.toggleObj.id)

    this.props.toggleTimeModal({})
  }

  // componentDidMount() {
  //   console.log(target)
  //   let taskExecution = {
  //     ...initialTaskExecution,
  //     day:
  //   }
  //   setView({ ...view, modal: true, taskExecution })
  // }

  render() {
    // console.log(this.props.singleExecutionId)
    return (
      <TaskExecutionEditor
        cancel={this.props.toggleTimeModal}
        confirm={this.saveExecution}
        projects={this.props.projects.list || []}
        customers={this.props.customers.list || []}
        estimates={this.props.estimatesAll.list || []}
        tasks={this.props.tasks.list || []}
        preSelectedTask={this.props.singleExecutionId}
        task={this.state}
        uid={this.props.user.profile.uid}
        deleteTaskExecution={this.deleteExecution}
      />
      // <Dialog
      //   childrenPreTitle
      //   title={this.state.onEditExecution ? 'Modifica Tempo' : 'Aggiungi tempo'}
      //   columns={this.props.singleExecutionId ? 2 : 3}
      //   formInputs={this.formInputs}
      //   buttons={[
      //     {
      //       label: 'Annulla',
      //       func: this.props.toggleTimeModal,
      //       submit: true
      //     },
      //     {
      //       label: this.state.onEditExecution ? 'Salva' : 'Aggiungi',
      //       func: this.saveExecution,
      //       submit: true
      //     }
      //   ]}
      //   values={this.state}
      //   editState={this.editState}
      // >
      //   {this.state.onEditExecution && (
      //     <div className="buttons-wrapper">
      //       <div
      //         className="lnk-submit delete"
      //         onClick={this.deleteExecution}
      //       ></div>
      //     </div>
      //   )}
      // </Dialog>
    )
  }
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {})(TimeDialog)
