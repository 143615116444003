import { backgroundConstants } from '../constants'

const initialState = {
  bing: null,
  bkgError: null,
  bkgLoading: true
}

export default function background (state = initialState, action) {
  switch (action.type) {
    case backgroundConstants.FETCH_BACKGROUND:
      return { bing: null, bkgLoading: true, bkgError: null }
    case backgroundConstants.FETCH_BACKGROUND_SUCCESS:
      return { bing: action.payload, bkgLoading: false, bkgError: null }
    case backgroundConstants.FETCH_BACKGROUND_FAILURE:
      return { bing: null, bkgLoading: false, bkgError: action.payload }
    default:
      return state
  }
}
