import { estimatesConstants } from '../constants'

const initialState = {
  list: null,
  error: null,
  loading: false,
  ajaxSpinner: false,
  ajaxStatus: null
}

export default function estimates(state = initialState, action) {
  switch (action.type) {
    case estimatesConstants.LOADING_ESTIMATES:
      return { ...state, loading: true, error: null }
    case estimatesConstants.LOADING_ESTIMATES_ERROR:
      return { ...state, loading: false, error: action.payload }
    case estimatesConstants.UPDATE_ESTIMATE:
      return {
        ...state,
        list:
          state.list === null
            ? [action.payload]
            : state.list.findIndex((x) => x.id === action.payload.id) === -1
            ? state.list.concat(action.payload)
            : state.list.map((item) => {
                return item.id === action.payload.id ? action.payload : item
              }),
        loading: false
      }
    case estimatesConstants.REMOVE_ESTIMATE:
      return {
        ...state,
        list: state.list.filter((item) => {
          return item.id !== action.payload.id
        }),
        loading: false
      }
    case estimatesConstants.PROJECTS_AJAX_CALL:
      return { ...state, ajaxSpinner: true, ajaxStatus: null }
    case estimatesConstants.PROJECTS_AJAX_SUCCESS:
      return { ...state, ajaxSpinner: false, ajaxStatus: null }
    case estimatesConstants.PROJECTS_AJAX_FAILURE:
      return { ...state, ajaxSpinner: false, ajaxStatus: action.payload }
    case estimatesConstants.ESTIMATE_RESET:
      return { ...state, list: null }
    default:
      return state
  }
}
