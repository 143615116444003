import axios from 'axios'
import { Uris } from '../Uris'

import { backgroundConstants } from '../constants'

export function loadBackground(u) {
  return (dispatch) => {
    dispatch(fetchAttendances())
    axios({
      method: 'GET',
      url: Uris.background,
      headers: Uris.axiosHeaders
    })
      .then((res) => {
        dispatch(fetchAttendancesSuccess(res.data))
      })
      .catch((error) => {
        console.log(error)
        if (error.response !== undefined) {
          dispatch(fetchAttendancesFailure(error.response.statusText))
        } else {
          dispatch(fetchAttendancesFailure(error.message))
        }
      })
  }
}

function fetchAttendances() {
  return {
    type: backgroundConstants.FETCH_BACKGROUND
  }
}

function fetchAttendancesSuccess(payload) {
  return {
    type: backgroundConstants.FETCH_BACKGROUND_SUCCESS,
    payload
  }
}

function fetchAttendancesFailure(payload) {
  return {
    type: backgroundConstants.FETCH_BACKGROUND_FAILURE,
    payload
  }
}
