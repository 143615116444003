import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dialog from '../../Dialog/Dialog'
import Chips from '../../UI/Chips/Chips'

import { commonHelpers } from '../../../helpers'

import { addProjectArchivedNote } from '../../../constants/project.constants'

const initialState = {
  note: '',
}

class ProjectArchivedNoteDialog extends Component {
  constructor() {
    super()
    const formData = {}

    Object.keys(addProjectArchivedNote).map(
      (info) => (formData[info] = addProjectArchivedNote[info].default)
    )

    this.state = initialState
  }

  editState = (key, value) => this.setState({ [key]: value })

  submit = () => {
    console.log('sono entrato nel Submit')
    const newNotePayload = {
      creation_date: commonHelpers.time(),
      owner: this.props.user.profile.uid,
      message: this.state.note,
      noteType: this.props.project.archived ? 'restored' : 'archived',
    }
    console.log('### newNotePayload')
    console.log(newNotePayload)

    const curPrj = { ...this.props.project }
    console.log('### curPrj')
    console.log(curPrj)

    curPrj.archived = !this.props.project.archived
    if (!curPrj.archivedNotes) {
      curPrj.archivedNotes = []
    }
    curPrj.archivedNotes = curPrj.archivedNotes.concat(newNotePayload)
    this.props.saveProject(this.props.project.id, curPrj)
    this.props.toggleProjectArchivedNote()
  }

  render() {

    console.log('### this.props.project.archivedNotes')
    console.log(this.props.project.archivedNotes)

    /*const archivedNotes = (this.props.project.archivedNotes || [])
      .slice(
        this.props.project.archivedNotes.length - 5,
        this.props.project.archivedNotes.length
      )
      .map((n) => {
        console.log('### n')
        console.log(n)
        return (
          <div className="archived-notes-line" key={n.creation_date}>
            <span className={n.noteType}>
              {n.noteType === 'archived' ? 'archiviata' : 'ripristinata'}
            </span>
            <span className="owner">
              <Chips user={n.owner} />
            </span>
            <span className="message">
              {n.message} ({commonHelpers.timeToDate(n.creation_date)})
            </span>
          </div>
        )
      })*/

    let archivedNotes = this.props.project.archivedNotes
    if (Array.isArray(archivedNotes) && archivedNotes.length > 0) {
      console.log('### Array.isArray(archivedNotes) === true')
      console.log('### archivedNotes.length')
      console.log(archivedNotes.length)
      console.log('### archivedNotes PRE-MODIFICHE')
      console.log(archivedNotes)

      console.log('### Object.entries(archivedNotes)')
      console.log(Object.entries(archivedNotes))
      archivedNotes.slice(
        this.props.project.archivedNotes.length - 5,
        this.props.project.archivedNotes.length
      )
      archivedNotes
        .map((n) => {
          return (
            <div className="archived-notes-line" key={n.creation_date}>
              <span className={n.noteType}>
                {n.noteType === 'archived' ? 'archiviata' : 'ripristinata'}
              </span>
              <span className="owner">
                <Chips user={n.owner} />
              </span>
              <span className="message">
                {n.message} ({commonHelpers.timeToDate(n.creation_date)})
            </span>
            </div>
          )
        })
      console.log('### archivedNotes POST-MAP')
      console.log(archivedNotes)
    } else {
      console.log('### Array.isArray(archivedNotes)')
      console.log(Array.isArray(archivedNotes))
      archivedNotes = []
      console.log('### archivedNotes che non esiste a db')
      console.log(archivedNotes)
    }

    return (
      <Dialog
        formInputs={addProjectArchivedNote}
        title={this.props.title}
        columns={1}
        submittedForm={this.submit}
        buttons={[
          {
            label: 'Annulla',
            func: this.props.toggleProjectArchivedNote,
            submit: '',
          },
          {
            label: 'Prosegui',
            func: this.submit,
            submit: true,
          },
        ]}
        close={this.props.close}
        editState={this.editState}
        values={this.state}
        beforeDialogBody={archivedNotes}
      ></Dialog>
    )
  }
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {})(ProjectArchivedNoteDialog)
