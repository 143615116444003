import { colleaguesRef } from '../database'
import { colleaguesConstants } from '../constants'

export let colleaguesSnap

export function loadColleagues(user) {
  return (dispatch) => {
    dispatch(loadingColleagues())
    colleaguesSnap = colleaguesRef.onSnapshot(
      (result) => {
        result.docChanges().forEach((change) => {
          const data = change.doc.data()
          const id = change.doc.id
          if (data.uid !== user.uid) {
            if (change.type === 'added') {
              dispatch(updateColleague({ id, ...data }))
            }
            if (change.type === 'modified') {
              dispatch(updateColleague({ id, ...data }))
            }
            if (change.type === 'removed') {
              dispatch(removeColleague({ id, ...data }))
            }
          }
        })
      },
      (error) => {
        console.log(error)
        dispatch(loadingColleaguesError('Error: ' + error))
      }
    )
  }
}

function loadingColleagues() {
  return {
    type: colleaguesConstants.LOADING_COLLEAGUES
  }
}

function loadingColleaguesError(payload) {
  return {
    type: colleaguesConstants.LOADING_COLLEAGUES_ERROR,
    payload
  }
}

function updateColleague(payload) {
  return {
    type: colleaguesConstants.UPDATE_COLLEAGUE,
    payload
  }
}

function removeColleague(payload) {
  return {
    type: colleaguesConstants.REMOVE_COLLEAGUE,
    payload
  }
}
