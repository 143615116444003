import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import produce from 'immer'
import _ from 'lodash'

import { commonHelpers } from '../../helpers'

import Spinner from '../Spinner'

import {
  toggleTask,
  saveTask,
  manageResource,
  saveSprint,
  deleteTask,
  deleteProject,
  saveExecution,
  saveProject,
  loadReportProject,
  loadAllEstimates
} from '../../actions'
import ProjectProgress from './ProjectProgress'
import ProjectInfo from './ProjectInfo'
import ProjectManagers from './ProjectManagers'
import ProjectTasksEditor from './ProjectTasksEditor'
import ProjectProviders from './ProjectProviders'

import TaskDialog from './modals/TaskDialog'
import Dialog from '../Dialog/Dialog'
import ResourceDialog from './modals/ResourceDialog'
import SprintDialog from './modals/sprintDialog/SprintDialog'
import TimeDialog from './modals/TimeDialog'
import { uiConstants } from '../../constants'

class ProjectEditor extends Component {
  addManager(uid) {
    const p = produce(this.props.project, (p) => {
      p.managers = p.managers.concat(uid)
    })
    this.props.saveProject(p.id, p)
  }
  deleteManager(id) {
    const p = produce(this.props.project, (p) => {
      p.managers = p.managers.filter((x) => x !== id)
    })
    this.props.saveProject(p.id, p)
  }

  saveProject() {
    const s = this.state
    const prov = s.providers ? s.providers : this.props.project.providers
    const p = produce(this.props.project, (p) => {
      p.name = s.name
      p.description = s.description
      p.deadline = s.deadline
      p.start_date = s.start_date
      p.customer_id = s.customer_id
      p.estimate_id = s.estimate_id
      p.providers = prov ? prov : []
    })
    this.props.saveProject(p.id, p)
  }

  handleInfo(state) {
    this.setState({
      name: state.name,
      description: state.description,
      deadline: state.deadline,
      start_date: state.start_date,
      customer_id: state.customer_id,
      estimate_id: state.estimate_id
    })
  }

  handleProviders = (state) => {
    this.setState({ providers: state, providersTouched: true })
  }

  state = {
    showTaskDialog: false,
    showSprintDialog: false,
    showResourceDialog: false,
    currentTaskResources: [],
    showProjectDeleteModal: false,
    onEditTask: '',
    onEditSprint: '',
    selectedTask: '',
    deletingElement: {
      taskId: '',
      resourceId: ''
    },
    providers: null
  }

  toggleSetTimeModal = (id) => {
    this.setState({
      selectedTask: this.state.selectedTask ? '' : id
    })
  }

  toggleTaskModal = (id) => {
    this.setState({
      showTaskDialog: !this.state.showTaskDialog,
      onEditTask: id
    })
  }

  toggleSprintModal = (id) => {
    this.setState({
      showSprintDialog: !this.state.showSprintDialog,
      onEditSprint: id
    })
  }

  toggleResourceModal = (id, resources) => {
    this.setState({ showResourceDialog: id, currentTaskResources: resources })
  }

  toggleDeleteDialog = (taskId, resourceId) => {
    this.setState(
      produce((draftState) => {
        draftState.deletingElement.taskId = taskId
        draftState.deletingElement.resourceId = resourceId
      })
    )
  }

  deleteElement = () => {
    if (this.state.deletingElement.resourceId) {
      this.props.manageResource(
        'remove',
        this.state.deletingElement.taskId,
        this.state.deletingElement.resourceId
      )
    } else {
      let parentTask = _.find(this.props.tasks.filteredTasks, (task) =>
        task.subtasks.includes(this.state.deletingElement.taskId)
      )?.id
      this.props.deleteTask(this.state.deletingElement.taskId, parentTask)
    }
    this.toggleDeleteDialog()
  }

  toggleProjectDeleteDialog = () => {
    this.setState({
      showProjectDeleteModal: !this.state.showProjectDeleteModal
    })
  }

  deleteProject = () => {
    this.props.deleteProject(this.props.project.id)
    this.props.history.goBack()
  }

  toggleTask = (id) => {
    let payload = {
      id: id
    }
    this.props.toggleTask(payload)
  }

  getCurrentTasks() {
    const p = this.props.project
    let t = this.props.tasks.tlist
    const tasks = t.filter((x) => p.tasks.indexOf(x.id) > -1)
    return tasks.map((x) => {
      let subtasks = []
      x.subtasks.forEach((u) => {
        const s = t.find((j) => j.id === u)
        if (s !== undefined) {
          subtasks = subtasks.concat(s)
        }
      })
      return { ...x, subtasks }
    })
  }

  loadReport() {
    const eco = this.props.economics.list.find((x) => x.title === 'project')
    this.props.loadReportProject(
      {
        ...eco,
        preview: true,
        type: uiConstants.economics.view
      },
      {
        project_id: this.props.project.id
      }
    )
  }

  render() {
    if (!this.props.project) {
      return <Spinner />
    } else {
      return (
        <>
          <div className="project-editor-tool-container">
            <button className="lnk-utils mr" onClick={() => this.loadReport()}>
              <i className="fas fa-cloud"></i>report
            </button>
            <button
              className="lnk-utils mr"
              onClick={() => this.toggleTaskModal()}
            >
              <i className="fas fa-plus"></i>aggiungi task
            </button>
            <button
              className="lnk-utils mr"
              onClick={() => this.toggleSprintModal()}
            >
              <i className="fas fa-plus"></i>aggiungi sprint
            </button>
            <Link to="/projects" className="lnk-utils">
              <i className="fas fa-times"></i> esci
            </Link>
          </div>
          <h1>Modifica progetto</h1>
          <ProjectInfo
            project={this.props.project}
            handleInfo={this.handleInfo.bind(this)}
            estimates={this.props.estimatesAll.elist || []}
          />
          <ProjectProviders
            projectProviders={(this.props.project || {}).providers || []}
            handleProviders={this.handleProviders}
          />
          <ProjectProgress
            endDate={commonHelpers.timeToDate(this.props.project.deadline)}
            estimatedHours={
              this.props.tasks.tlist &&
              _.filter(this.props.tasks.tlist, (task) =>
                this.props.project.tasks.includes(task.id)
              ).reduce((a, b) => {
                return a + b.estimated_time
              }, 0)
            }
            spentHours={
              this.props.tasks.tlist &&
              _.filter(this.props.tasks.tlist, (task) =>
                this.props.project.tasks.includes(task.id)
              ).reduce((a, b) => {
                return a + b.spent_time
              }, 0)
            }
          />
          <ProjectManagers
            managers={this.props.project.managers}
            colleagues={this.props.colleagues}
            deleteManager={this.deleteManager.bind(this)}
            addManager={this.addManager.bind(this)}
          />
          <ProjectTasksEditor
            tasks={this.getCurrentTasks()}
            project={this.props.project}
            editTask={this.toggleTaskModal}
            deleteElement={this.toggleDeleteDialog}
            editSprint={this.toggleSprintModal}
            toggleSetTimeModal={this.toggleSetTimeModal}
            addResource={this.toggleResourceModal}
          />
          <ul className="bottom-utils-buttons">
            <li className="left">
              <ul className="ul-utils-btn">
                <li>
                  <button
                    className="lnk-utils red"
                    onClick={() =>
                      this.setState({
                        showProjectDeleteModal: true
                      })
                    }
                  >
                    <i className="far fa-trash-alt"></i> elimina
                  </button>
                </li>
              </ul>
            </li>
            <li className="right">
              <ul className="ul-utils-btn">
                <li>
                  <Link to="/projects" className="lnk-utils">
                    <i className="fas fa-arrow-left"></i> annulla
                  </Link>
                </li>
                <li>
                  <button
                    className="lnk-utils"
                    onClick={() => this.saveProject()}
                  >
                    <i className="far fa-save"></i> salva
                  </button>
                </li>
              </ul>
            </li>
          </ul>
          {this.state.showTaskDialog && (
            <TaskDialog
              tasks={this.props.tasks.filteredTasks}
              close={this.toggleTaskModal}
              saveTask={this.props.saveTask}
              projectId={this.props.project.id}
              toggleTaskModal={this.toggleTaskModal}
              onEditTask={_.find(this.props.tasks.tlist, {
                id: this.state.onEditTask
              })}
              onEditTaskParent={_.find(this.props.tasks.filteredTasks, (task) =>
                task.subtasks.includes(this.state.onEditTask)
              )}
            />
          )}
          {this.state.showProjectDeleteModal && (
            <Dialog
              title={'Elimina progetto'}
              subtitle={'Vuoi davvero eliminare questo progetto?'}
              buttons={[
                {
                  label: 'Annulla',
                  func: this.toggleProjectDeleteDialog,
                  submit: undefined
                },
                {
                  label: 'Elimina',
                  func: this.deleteProject,
                  submit: true
                }
              ]}
            ></Dialog>
          )}
          {this.state.selectedTask && (
            <TimeDialog
              toggleTimeModal={this.toggleSetTimeModal}
              saveExecution={this.props.saveExecution}
              userId={this.props.user.profile.id}
              singleExecutionId={this.state.selectedTask}
            />
          )}
          {this.state.showSprintDialog && (
            <SprintDialog
              edit={this.state.onEditSprint}
              tasks={
                this.state.onEditSprint
                  ? this.props.tasks.projectTasks
                  : this.props.tasks.sprintTasks
              }
              sprintTasks={_.filter(this.props.tasks.projectTasks, {
                sprint_id: this.state.onEditSprint
              })}
              sprint={_.find(this.props.sprints.list, {
                id: this.state.onEditSprint
              })}
              close={this.toggleDndDialog}
              toggleSprintDialog={this.toggleSprintModal}
              saveSprint={this.props.saveSprint}
            />
          )}
          {this.state.deletingElement.taskId && (
            <Dialog
              title={
                this.state.deletingElement.resourceId
                  ? 'Elimina risorsa'
                  : 'Elimina task'
              }
              subtitle={
                this.state.deletingElement.resourceId
                  ? 'Vuoi davvero eliminare questa risorsa?'
                  : 'Vuoi davvero eliminare questo task?'
              }
              buttons={[
                {
                  label: 'Annulla',
                  func: this.toggleDeleteDialog,
                  submit: undefined
                },
                {
                  label: 'Elimina',
                  func: this.deleteElement,
                  submit: true
                }
              ]}
            ></Dialog>
          )}
          {this.state.showResourceDialog && (
            <ResourceDialog
              close={this.toggleResourceModal}
              toggleResourceModal={this.toggleResourceModal}
              taskId={this.state.showResourceDialog}
              currentTaskResources={this.state.currentTaskResources}
              availableResources={this.props.resources}
              manageResource={this.props.manageResource}
            />
          )}
        </>
      )
    }
  }
}

function mapStateToProps(
  {
    projects: { list },
    tasks: { list: tlist },
    user,
    sprints,
    executions,
    economics,
    estimatesAll
  },
  { location: { pathname } }
) {
  let id = commonHelpers.urlPath(pathname)[2]
  let project = _.find(list, { id: id })
  let filteredTasks = _.filter(
    tlist,
    (task) =>
      project?.tasks?.includes(task.id) &&
      !_.find(tlist, (tsk) => tsk.subtasks.includes(task.id))
  )

  let projectTasks = []
  filteredTasks.forEach((firstLevelTask) => {
    tlist.forEach((task) => {
      if (firstLevelTask.subtasks?.includes(task.id)) {
        projectTasks.push(task)
      }
    })
    if (firstLevelTask.subtasks?.length === 0) {
      projectTasks.push(firstLevelTask)
    }
  })

  let sprintTasks = _.filter(projectTasks, { sprint_id: '' })

  let resources = []
  if (user.list) {
    resources = user.list
      .map((resource) => ({
        label: `${resource.displayName}`,
        value: resource.uid
      }))
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
  }

  return {
    project,
    tasks: { tlist, filteredTasks, sprintTasks, projectTasks },
    user,
    sprints,
    executions,
    resources,
    economics,
    estimatesAll
  }
}
export default connect(
  mapStateToProps,
  {
    toggleTask,
    saveTask,
    manageResource,
    saveSprint,
    deleteTask,
    deleteProject,
    saveExecution,
    saveProject,
    loadReportProject,
    loadAllEstimates
  },
  null,
  {
    areStatesEqual: (next, prev) =>
      prev.projects.list === next.projects.list &&
      prev.tasks.list === next.tasks.list &&
      prev.sprints.list === next.sprints.list &&
      prev.executions.list === next.executions.list &&
      prev.estimatesAll.list === next.estimatesAll.list
  }
)(ProjectEditor)
