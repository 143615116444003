import React, { Component } from 'react'
import { connect } from 'react-redux'

import _ from 'lodash'

import { commonHelpers } from '../../helpers'
import DashBoard from '../dashboard/DashBoard'
import SprintDialog from './modals/sprintDialog/SprintDialog'
import TopInfo from '../TopInfo'
import Dialog from '../Dialog/Dialog'
import TimeDialog from './modals/TimeDialog'

import {
  saveTask,
  saveSprint,
  deleteSprint,
  saveExecution
} from '../../actions'
const moment = commonHelpers.getMoment()

class Sprint extends Component {
  state = {
    showSprintDialog: false,
    showDeleteModal: false,
    selectedTask: ''
  }

  updateRecording = (executionId, taskId) => {
    let payload = {}
    let currentDate = moment().unix()

    let selectedExecution = _.find(this.props.filteredExecutions, {
      id: executionId
    })

    if (
      (selectedExecution &&
        moment(commonHelpers.timeToDate(selectedExecution.end_date)).isSame(
          commonHelpers.timeToDate(currentDate),
          'day'
        )) ||
      selectedExecution?.recording
    ) {
      if (
        moment(commonHelpers.timeToDate(selectedExecution.start_date)).isSame(
          commonHelpers.timeToDate(currentDate),
          'day'
        )
      ) {
        payload.recording = !selectedExecution.recording
        payload.end_date = currentDate
        payload.edit_date = currentDate

        this.props.saveExecution(executionId, payload)
      } else {
        let dates = commonHelpers.enumerateDaysBetweenDates(
          commonHelpers.timeToDate(selectedExecution.start_date),
          commonHelpers.timeToDate(currentDate)
        )

        let datesObject = {}

        dates.forEach(
          (date) =>
            (datesObject[date] = {
              label: date,
              type: 'number',
              id: date,
              col: 6,
              default: 0
            })
        )

        this.setState({
          multipleDatesToSetForm: datesObject,
          selectedExecution: selectedExecution
        })
      }
    } else {
      const t = this.props.tasks.tlist.find((x) => x.id === taskId)
      payload.creation_date = currentDate
      payload.start_date = currentDate
      payload.end_date = currentDate
      payload.edit_date = currentDate
      payload.task_id = taskId
      payload.recording = true
      payload.user_id = this.props.user.profile.id
      payload.project_id = t.project_id !== undefined ? t.project_id : ''

      this.props.saveExecution(executionId, payload)
    }
  }

  toggleSetTimeModal = (id) => {
    this.setState({
      selectedTask: this.state.selectedTask ? '' : id
    })
  }

  toggleSprintDialog = () => {
    this.setState({
      showSprintDialog: !this.state.showSprintDialog
    })
  }

  toggleDeleteDialog = () => {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal
    })
  }

  deleteSprint = () => {
    this.props.deleteSprint(
      this.props.sprints.selectedSprint.id,
      this.props.tasks.filteredTasks
    )

    this.props.history.goBack()
  }

  render() {
    return (
      <div className="sprints">
        {this.props.sprints.selectedSprint ? (
          <div key={this.props.sprints.selectedSprint.id} className="sprint">
            <TopInfo
              title={this.props.sprints.selectedSprint.name}
              // editSprintButton={this.toggleSprintDialog}
              redBtnFunction={
                this.props.user.profile.admin ? this.toggleDeleteDialog : ''
              }
              redBtnText={'ELIMINA SPRINT'}
              backButton={this.props.history.goBack}
              endDate={commonHelpers.timeToDate(
                this.props.sprints.selectedSprint.end_date
              )}
              estimatedHours={
                this.props.tasks.filteredTasks
                  ? this.props.tasks.filteredTasks.reduce((a, b) => {
                      return a + b.estimated_time
                    }, 0)
                  : ''
              }
              spentHours={
                this.props.tasks.filteredTasks
                  ? this.props.tasks.filteredTasks.reduce((a, b) => {
                      return a + b.spent_time
                    }, 0)
                  : ''
              }
            />
            <DashBoard
              tasks={_.orderBy(
                this.props.tasks.filteredTasks,
                'priority',
                'desc'
              )}
              saveTask={this.props.saveTask}
              sprint={this.props.sprints.selectedSprint}
              resources={this.props.user.list}
              recordAction={this.updateRecording}
              executions={this.props.filteredExecutions}
              userId={this.props.user.profile.id}
              toggleSetTimeModal={this.toggleSetTimeModal}
            />
          </div>
        ) : (
          ''
        )}
        {this.state.showSprintDialog && (
          <SprintDialog
            edit={true}
            tasks={this.props.tasks.tlist}
            sprintTasks={this.props.tasks.filteredTasks}
            sprint={this.props.sprints.selectedSprint}
            close={this.toggleSprintDialog}
            toggleSprintDialog={this.toggleSprintDialog}
            saveSprint={this.props.saveSprint}
          />
        )}
        {this.state.showDeleteModal && (
          <Dialog
            title={'Elimina sprint'}
            subtitle={'Vuoi davvero eliminare questo sprint?'}
            buttons={[
              {
                label: 'Annulla',
                func: this.toggleDeleteDialog,
                submit: undefined
              },
              {
                label: 'Elimina',
                func: this.deleteSprint,
                submit: true
              }
            ]}
          ></Dialog>
        )}
        {this.state.selectedTask && (
          <TimeDialog
            toggleTimeModal={this.toggleSetTimeModal}
            saveExecution={this.props.saveExecution}
            userId={this.props.user.profile.id}
            singleExecutionId={this.state.selectedTask}
          />
        )}
      </div>
    )
  }
}

function mapStateToProps(
  {
    projects: { list },
    tasks: { list: tlist },
    user,
    sprints: { list: slist },
    executions
  },
  { location: { pathname } }
) {
  let id = commonHelpers.urlPath(pathname)[2]
  let idSprint = commonHelpers.urlPath(pathname)[4]
  let project = _.find(list, { id: id })

  let filteredTasks = _.filter(tlist, (task) => task.sprint_id === idSprint)
  filteredTasks = filteredTasks.map((task) => {
    let linked_sprint = _.find(slist, { id: task.sprint_id })
    if (linked_sprint) {
      return {
        ...task,
        color: linked_sprint.color
      }
    } else {
      return task
    }
  })

  let selectedSprint = _.find(slist, { id: idSprint })

  let filteredExecutions = _.filter(executions.list, {
    user_id: user.profile.id
  })

  return {
    project,
    tasks: { filteredTasks, tlist },
    user,
    sprints: { selectedSprint, slist },
    filteredExecutions
  }
}
export default connect(
  mapStateToProps,
  { saveTask, saveSprint, deleteSprint, saveExecution },
  null,
  {
    areStatesEqual: (next, prev) =>
      prev.projects.list === next.projects.list &&
      prev.tasks.list === next.tasks.list &&
      prev.sprints.list === next.sprints.list &&
      prev.executions.list === next.executions.list
  }
)(Sprint)
