import React, { Component } from 'react'

import produce from 'immer'
import _ from 'lodash'
import moment from 'moment'

import Dialog from '../../../Dialog/Dialog'
import { Board } from './Board'
import { sprintColors } from './Colors'

import { commonHelpers } from '../../../../helpers'

import { formDataSprint } from '../../../../constants/sprints.constants'

class SprintDialog extends Component {
  constructor(props) {
    super(props)
    this.child = React.createRef()

    let rightColTasks
    let leftColTasks

    if (this.props.edit) {
      rightColTasks = this.props.sprintTasks

      leftColTasks = _.filter(
        this.props.tasks,
        (t) => !rightColTasks.find((ft) => t.id === ft.id)
      )
    }

    let formData = {
      name: this.props.sprint?.name || '',
      color: this.props.sprint?.color || '',
      startingDate: this.props.sprint
        ? moment.unix(this.props.sprint?.starting_date).format('YYYY-MM-DD')
        : '',
      endDate: this.props.sprint
        ? moment.unix(this.props.sprint?.end_date).format('YYYY-MM-DD')
        : '',
    }

    this.state = {
      formData: formData,
      sprintColor: this.props.edit
        ? this.props.sprint.color
        : sprintColors[Math.floor(Math.random() * Math.floor(18))],

      cards: this.props.tasks.map((t) => {
        return { id: t.id, title: t.name }
      }),

      oldSprintTasks: this.props.sprintTasks,

      columns: this.props.edit
        ? [leftColTasks.map((t) => t.id), rightColTasks.map((t) => t.id)]
        : [this.props.tasks.map((t) => t.id), []],
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(prevState.formData, this.state.formData) &&
      this.state.firstValidation
    ) {
      this.setState(
        commonHelpers.isFormNotValid(formDataSprint, this.state.formData)
      )
    }
  }

  editState = (key, value) =>
    this.setState({ formData: { ...this.state.formData, [key]: value } })

  submit = () => {
    this.setState(
      commonHelpers.isFormNotValid(formDataSprint, this.state.formData)
    )

    if (
      !commonHelpers.isFormNotValid(formDataSprint, this.state.formData)
        .validationError
    ) {
      let payload = {
        dataset: {
          created_at: commonHelpers.dateToTime(new Date()),
          modified_at: commonHelpers.dateToTime(new Date()),
          starting_date: commonHelpers.dateToTime(
            new Date(this.state.formData.startingDate)
          ),
          end_date: commonHelpers.dateToTime(
            new Date(this.state.formData.endDate)
          ),
          name: this.state.formData.name,
          color: this.state.sprintColor,
        },
        tasks: this.state.columns[1],
      }

      this.props.edit
        ? this.props.saveSprint(
            this.props.sprint.id,
            payload,
            this.state.oldSprintTasks
          )
        : this.props.saveSprint(null, payload)

      this.props.toggleSprintDialog()
    } else {
      return console.log('Compilare tutti i campi prima di salvare')
    }
  }

  changeColor = () => {
    this.setState({
      sprintColor: sprintColors[Math.floor(Math.random() * Math.floor(18))],
    })
  }

  moveCard = (cardId, destColumnId, index) => {
    this.setState(
      produce((draftState) => {
        draftState.columns.map((column, index) =>
          _.flowRight(
            (ids) =>
              index === destColumnId ? ids.splice(index, 0, cardId) : ids,
            (ids) => _.pull(ids, cardId)
          )(column)
        )
      })
    )
  }

  render() {
    return (
      <div className="dnddialog">
        <Dialog
          ref={this.child}
          key="SprintDialog"
          title={!this.props.edit ? 'Aggiungi sprint' : 'Modifica sprint'}
          childrenAfter
          show={this.props.show}
          close={this.props.close}
          columns={2}
          formInputs={formDataSprint}
          buttons={[
            {
              label: 'Annulla',
              func: this.props.toggleSprintDialog,
            },
            { label: 'Salva', func: this.submit, submit: true },
          ]}
          editState={this.editState}
          values={this.state.formData}
          validationError={this.state.validationError}
        >
          <span
            className="color-picker"
            style={{ backgroundColor: this.state.sprintColor }}
            onClick={this.changeColor}
          >
            {' '}
          </span>

          <Board
            cardsColor={this.state.sprintColor}
            cards={this.state.cards}
            columns={this.state.columns}
            moveCard={this.moveCard}
          />
        </Dialog>
      </div>
    )
  }
}

export default SprintDialog
