export const dbConstants = {
  estimatesFilters: {
    draft: true,
    waiting: true,
    refused: true,
    confirmed: false,
    invoiced: false,
    searchTerm: '',
    fromDate: '',
    toDate: '',
    customer: ''
  },
  ordersFilters: {
    draft: false,
    waiting: false,
    refused: false,
    confirmed: true,
    invoiced: true,
    searchTerm: '',
    fromDate: '',
    toDate: '',
    customer: ''
  },
  toBeInvoicedFilters: {
    progetto: true,
    canone: true,
    consumo: true,
    expanded: false,
    invoiced: true,
    searchTerm: '',
    customer: ''
  },
  invoicesFilters: {
    currentMonth: 1564617600,
    customer: '',
    sent: false
  },
  vacationFilters: {
    currentMonth: 1564617600
  }
}
