import React, { useEffect, useState } from 'react'
import classes from './DaysList.module.scss'

const initialDays = [
  { id: 0, day: 'LUN', checked: false },
  { id: 1, day: 'MAR', checked: false },
  { id: 2, day: 'MER', checked: false },
  { id: 3, day: 'GIO', checked: false },
  { id: 4, day: 'VEN', checked: false },
  { id: 5, day: 'SAB', checked: false },
  { id: 6, day: 'DOM', checked: false }
]

const mapDays = (days) => {
  let newArray = []
  days.forEach((i) => {
    newArray.push({ ...i })
  })

  return newArray
}

const DaysList = (props) => {
  const [days, setDays] = useState(mapDays(initialDays))

  useEffect(() => {
    if (props.selectedDays && props.selectedDays.length > 0) {
      setDays(mapDays(props.selectedDays))
    } else setDays(mapDays(initialDays))
  }, [props.selectedDays])

  const changeDayCheckHandler = (day) => {
    setDays((prevState) => {
      const daysList = [...prevState]
      const updatedIndex = daysList.findIndex((i) => i.day === day)
      daysList[updatedIndex].checked = !daysList[updatedIndex].checked
      return daysList
    })
  }

  const saveHandler = () => {
    // setDays(mapInitialDays())
    props.onSave(days)
  }

  return (
    <React.Fragment>
      <ul className={classes.UlDays}>
        {days.map((i) => (
          <li key={i.id}>
            <button
              className={`${classes.BtnDay} ${
                i.checked ? classes.IsSelected : ''
              }`}
              onClick={changeDayCheckHandler.bind(this, i.day)}
            >
              {i.day}
            </button>
          </li>
        ))}
        <li>
          <button className="lnk-utils" onClick={saveHandler}>
            <i className="fas fa-save" /> SALVA
          </button>
        </li>
      </ul>
    </React.Fragment>
  )
}

export default DaysList
