export const navConstants = [
  {
    label: 'economics',
    link: '/economics',
    icon: 'fas fa-chart-line',
    menu: 'economics'
  },
  {
    label: 'dashboard',
    link: '/dashboard',
    icon: 'fas fa-tachometer-alt',
    menu: 'dashboard'
  },
  {
    label: 'preventivi',
    link: '/estimates',
    icon: 'fas fa-comment-dollar',
    menu: 'estimates'
  },
  {
    label: 'ordini',
    link: '/orders',
    icon: 'far fa-thumbs-up',
    menu: 'orders'
  },
  {
    label: 'da fatturare',
    link: '/tobeinvoiced',
    icon: 'fas fa-coins',
    menu: 'tobeinvoiced'
  },
  {
    label: 'progetti',
    link: '/projects',
    icon: 'fas fa-tasks',
    menu: 'projects'
  },
  {
    label: 'fatture',
    link: '/invoices',
    icon: 'fas fa-file-invoice-dollar',
    menu: 'invoices'
  },
  {
    label: 'timesheets',
    link: '/timesheets',
    icon: 'fas fa-calendar-check',
    menu: 'timesheets'
  },
  {
    label: 'calendario',
    link: '/calendar',
    icon: 'fas fa-calendar-alt',
    menu: 'calendar'
  },
  {
    label: 'presenze',
    link: '/vacation',
    icon: 'fas fa-calendar-times',
    menu: 'vacation'
  },
  {
    label: 'Utenti',
    link: '/manage_users',
    icon: 'fas fa-user',
    menu: 'manage_users'
  },
  {
    label: 'Anagrafiche',
    link: '/registry',
    icon: 'fas fa-book',
    menu: 'registry'
  }
]
