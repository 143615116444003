import React, { useEffect, useState, useCallback } from 'react'
import moment from 'moment'

import VacationInput from '../VacationInput/VacationInput'

import css from './VacationTable.module.scss'

const VacationTable = (props) => {
  const { month, year, types, users, calendarDays, record } = props
  const [daysInMonth, setDaysInMonth] = useState([])
  const [list, setList] = useState(null)
  const [note, setNote] = useState('')

  useEffect(() => {
    setList(() => {
      const item = record.find((i) => i.month === month)
      return item ? [...item.users] : null
    })
  }, [record, month])

  useEffect(() => {
    const dim = moment.unix(month).daysInMonth()
    const daysArray = []
    for (let x = 1; x <= dim; x++) {
      daysArray.push(x)
    }
    setDaysInMonth(daysArray)
  }, [month])

  useEffect(() => {
    if (list && list.length > 0) {
      const u = list.find((i) => i.uid === users[0].uid)
      if (u && u.note) {
        setNote(u.note)
      } else setNote('')
    } else setNote('')
  }, [list, users])

  const getDayStyle = (day) => {
    const currentDay = moment
      .unix(month)
      .utc()
      .startOf('month')
      .startOf('day')
      .add(day - 1, 'days')
      .unix()

    if (
      props.calendarDays &&
      props.calendarDays.length > 0 &&
      calendarDays.find((i) => i === currentDay)
    ) {
      return true
    } else return false
  }

  const getDayOfWeek = (day) => {
    const currentDayOfWeek = moment
      .unix(month)
      .utc()
      .startOf('month')
      .startOf('day')
      .add(day - 1, 'days')
      .format('e')

    switch (currentDayOfWeek) {
      case '0':
        return 'lun ' + day
      case '1':
        return 'mar ' + day
      case '2':
        return 'mer ' + day
      case '3':
        return 'gio ' + day
      case '4':
        return 'ven ' + day
      case '5':
        return 'sab ' + day
      case '6':
        return 'dom ' + day
      default:
        return day
    }
  }

  const changeHandler = (item, dip) => {
    let newRec
    if (!list) {
      newRec = [
        {
          uid: dip.uid,
          records: [{ ...item }],
          note: note
        }
      ]
    } else {
      newRec = [...list].map((i) => {
        return {
          uid: i.uid,
          note: i.note ?? '',
          records: i.records
            ? [...i.records].map((i) => {
                return { ...i }
              })
            : []
        }
      })

      const userData = newRec.find((i) => i.uid === dip.uid)

      if (userData && userData.records) {
        const index = userData.records.findIndex(
          (i) => i.day === item.day && i.type_id === item.type_id
        )

        if (index > -1) {
          userData.records[index].value = item.value
        } else {
          userData.records.push({ ...item })
        }
      } else {
        const newUserData = {
          uid: dip.uid,
          note: note,
          records: [{ ...item }]
        }

        newRec.push(newUserData)
      }
    }

    setList(newRec)

    const itemR = record.find((i) => i.month === month)

    props.onSave(itemR ? itemR.id : null, {
      year: year,
      month: month,
      users: newRec
    })
  }

  const blurNoteHandler = (dip) => {
    let newRec
    let hasChanged = false
    if (!list) {
      hasChanged = true
      newRec = [
        {
          uid: dip.uid,
          records: [],
          note: note
        }
      ]
    } else {
      newRec = [...list].map((i) => {
        return {
          uid: i.uid,
          note: i.note ? i.note : '',
          records: i.records
            ? [...i.records].map((i) => {
                return { ...i }
              })
            : []
        }
      })

      const userData = newRec.find((i) => i.uid === dip.uid)

      if (userData) {
        if (
          (userData.note && userData.note !== note) ||
          (!userData.note && note !== '')
        ) {
          userData.note = note
          hasChanged = true
        }
      } else {
        hasChanged = true
        const newUserData = {
          uid: dip.uid,
          note: note,
          records: []
        }

        newRec.push(newUserData)
      }
    }

    if (hasChanged) {
      setList(newRec)

      const itemR = record.find((i) => i.month === month)

      props.onSave(itemR ? itemR.id : null, {
        year: year,
        month: month,
        users: newRec
      })
    }
  }

  const changeNoteHandler = (event) => {
    setNote(event.target.value)
  }

  const getRecordValue = (dip, day, type) => {
    if (list) {
      const userData = list.find((i) => i.uid === dip.uid)

      if (userData && userData.records) {
        const val = userData.records.find(
          (i) =>
            i.day ===
              moment
                .unix(month)
                .utc()
                .startOf('month')
                .startOf('day')
                .add(day - 1, 'days')
                .unix() && i.type_id === type.id
        )
        if (val) return val.value
      }
    }

    return ''
  }

  const getColumnSum = (dip, day) => {
    const currentDay = moment
      .unix(month)
      .utc()
      .startOf('month')
      .startOf('day')
      .add(day - 1, 'days')
      .unix()

    let sum = 0
    if (list) {
      const userData = list.find((i) => i.uid === dip.uid)

      if (userData && userData.records) {
        userData.records.forEach((i) => {
          if (i.day === currentDay) sum += i.value ?? 0
        })
      }
    }

    return sum.toFixed(2).toString().replace('.', ',')
  }

  const getRowSum = (dip, typeid) => {
    let sum = 0
    if (list) {
      const userData = list.find((i) => i.uid === dip.uid)

      if (userData && userData.records) {
        userData.records.forEach((i) => {
          if (i.type_id === typeid) sum += i.value ?? 0
        })
      }
    }
    return sum.toFixed(2).toString().replace('.', ',')
  }

  const getTotalSum = useCallback(
    (dip) => {
      let sum = 0
      if (list && list.length > 0) {
        const userData = list.find((i) => i.uid === dip.uid)

        if (userData && userData.records) {
          userData.records.forEach((i) => {
            sum += i.value ?? 0
          })
        }
      }
      return sum.toFixed(2).toString().replace('.', ',')
    },
    [list]
  )

  return (
    <React.Fragment>
      {users &&
        users.map((dip) => (
          <ul key={dip.id} className={css.VacationUl}>
            <li>
              <label>
                Note
                <span>
                  <textarea
                    value={note}
                    onChange={changeNoteHandler}
                    onBlur={() => blurNoteHandler(dip)}
                  />
                </span>
              </label>
            </li>

            <li key="table">
              <div className={css.Overflow}>
                <table className={css.VacationTable}>
                  <thead>
                    <tr>
                      <th> </th>
                      {daysInMonth.map((i) => (
                        <th key={i + '.' + i}>
                          <label>{getDayOfWeek(i)}</label>
                        </th>
                      ))}
                      <th>
                        <label>Totali</label>
                      </th>
                      <th> </th>
                    </tr>
                    <tr>
                      <th>
                        <label>Ore</label>
                      </th>
                      {daysInMonth.map((d) => (
                        <th key={d + '-' + d}>
                          {getDayStyle(d)
                            ? dip.hours != null
                              ? dip.hours
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')
                              : ''
                            : ''}
                        </th>
                      ))}
                      <th> </th>
                      <th>
                        <label>Ore</label>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(types || [])
                      .sort((a, b) => (+a.id > +b.id ? 1 : -1))
                      .map((t) => (
                        <tr key={t.id}>
                          <td>{t.name}</td>
                          {daysInMonth.map((d) => (
                            <td
                              key={d}
                              className={
                                getDayStyle(d) ? '' : css.OrangeBackground
                              }
                            >
                              <VacationInput
                                day={d}
                                month={month}
                                type={t}
                                startValue={getRecordValue(dip, d, t)}
                                onUpdate={(e) => changeHandler(e, dip)}
                              />
                            </td>
                          ))}
                          <td className={css.Total}>{getRowSum(dip, t.id)}</td>
                          <td>{t.name}</td>
                        </tr>
                      ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className={css.Total}>
                        <label>Totali</label>
                      </td>
                      {daysInMonth.map((d) => (
                        <td
                          key={d}
                          className={
                            (getDayStyle(d) &&
                              parseFloat(
                                getColumnSum(dip, d).replace(',', '.')
                              ) !== dip.hours) ||
                            (!getDayStyle(d) &&
                              parseFloat(
                                getColumnSum(dip, d).replace(',', '.')
                              ) > 0)
                              ? css.RedText
                              : ''
                          }
                        >
                          {getColumnSum(dip, d)}
                        </td>
                      ))}
                      <td>{getTotalSum(dip)}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </li>
          </ul>
        ))}
    </React.Fragment>
  )
}

export default VacationTable
