import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import history from '../history'

import Spinner from './Spinner'
import { navConstants } from '../constants'
import {
  userLogout,
  toggleNav,
  setYear,
  loadEstimates,
  loadInvoices
} from '../actions'
import packageJson from '../../package.json'

const images = require.context('../assets/animals', true)

const initialState = {
  subMenu: false
}

class Nav extends Component {
  constructor() {
    super()
    this.state = initialState
  }

  closeSubMenu(i) {
    if (this.state.subMenu && i < 0) {
      this.toggleSubMenu()
    }
  }

  toggle() {
    this.props.toggleNav()
  }

  toggleSubMenu() {
    this.setState({ subMenu: !this.state.subMenu })
  }

  handleChangeYear(direction) {
    const year = this.props.user.profile.currentYear + direction
    this.goToBase()
    this.props.setYear(year)
  }

  handleToday() {
    const year = new Date().getFullYear()
    if (year !== this.props.user.profile.currentYear) {
      this.goToBase()
      this.props.setYear(year)
    }
  }

  goToBase() {
    const parts = this.props.location.pathname.split('/')
    if (parts.length > 2) {
      history.push(`/${parts[1]}`)
    }
  }

  componentDidMount() {
    const { childRef } = this.props
    childRef(this)
  }

  componentWillUnmount() {
    const { childRef } = this.props
    childRef(undefined)
  }

  render() {
    const { pathname } = this.props.location
    const { profile, error, loading } = this.props.user
    if (loading || profile === null) {
      return <Spinner />
    } else if (error) {
      return <nav>{error}</nav>
    } else {
      return (
        <nav className={profile.navCollapsed ? 'collapsed' : ''}>
          <div className="profile">
            <div className="avatar">
              <img
                src={images(`./${profile.avatar}`)}
                alt={profile.displayName}
                title={packageJson.version}
              />
            </div>
            <div className="name">
              <button
                className="lnk-submenu"
                onClick={() => this.toggleSubMenu()}
              >
                {profile.displayName}
                <i className="fas fa-chevron-down"></i>
              </button>
            </div>
          </div>

          <div className="nav-year">
            <button
              className={profile.navCollapsed ? 'collapsed' : ''}
              onClick={() => this.handleChangeYear(-1)}
            >
              <i className="fas fa-chevron-left"></i>
            </button>
            <button className="im-white" onClick={() => this.handleToday()}>
              {this.props.user.profile.currentYear}
            </button>
            <button
              className={profile.navCollapsed ? 'collapsed' : ''}
              onClick={() => this.handleChangeYear(1)}
            >
              <i className="fas fa-chevron-right"></i>
            </button>
          </div>

          <div className="menu-scroll">
            <div className="menu">
              {navConstants.map((n) => {
                if (
                  this.props.user.profile[n.menu] ||
                  this.props.user.profile.admin
                ) {
                  return (
                    <Link
                      to={n.link}
                      className={
                        'lnk-nav ' +
                        (pathname.indexOf(n.link) === 0 ? 'active' : '')
                      }
                      key={n.link}
                    >
                      <div className="icon">
                        <i className={n.icon}></i>
                      </div>
                      <div className="txt">{n.label}</div>
                    </Link>
                  )
                } else {
                  return null
                }
              })}
            </div>
          </div>

          <button className="lnk-nav nm" onClick={() => this.toggle()}>
            <div className="icon">
              <i
                className={
                  profile.navCollapsed
                    ? 'fas fa-chevron-circle-right'
                    : 'fas fa-chevron-circle-left'
                }
              ></i>
            </div>
            <div className="txt">Chiudi</div>
          </button>
          {this.state.subMenu && (
            <div className="submenu">
              <Link
                to="/settings"
                className="lnk-in-submenu"
                onClick={() => this.toggleSubMenu()}
              >
                Impostazioni
              </Link>
              <button
                className="lnk-in-submenu"
                onClick={() => this.props.userLogout()}
              >
                Esci
              </button>
            </div>
          )}
        </nav>
      )
    }
  }
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {
  userLogout,
  toggleNav,
  setYear,
  loadEstimates,
  loadInvoices
})(Nav)
