import React, { Component } from 'react'
import { connect } from 'react-redux'

import { uiConstants } from '../../constants'

import {
  localSaveToBeInvoicedFilter,
  saveToBeInvoicedFilter,
} from '../../actions'

const initialState = {
  searchTerm: undefined,
  customer: '',
  expanded: false,
  invoiced: true,
}

class TBIFilters extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
  }

  turnOffFilter(f) {
    const toBeInvoicedFilters = {
      ...this.props.user.profile.toBeInvoicedFilters,
    }
    toBeInvoicedFilters[f.value] = !toBeInvoicedFilters[f.value]
    this.props.saveToBeInvoicedFilter(toBeInvoicedFilters)
  }

  saveFilters(local = false) {
    const toBeInvoicedFilters = this.props.user.profile.toBeInvoicedFilters
    const nf = {
      ...toBeInvoicedFilters,
      searchTerm: this.state.searchTerm,
      customer: this.state.customer,
      expanded: this.state.expanded,
      invoiced: this.state.invoiced,
    }
    if (local) {
      this.props.localSaveToBeInvoicedFilter(nf)
    } else {
      this.props.saveToBeInvoicedFilter(nf)
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.searchTerm === undefined) {
      return {
        searchTerm: props.user.profile.toBeInvoicedFilters.searchTerm,
        customer: props.user.profile.toBeInvoicedFilters.customer,
        expanded: props.user.profile.toBeInvoicedFilters.expanded,
        invoiced: props.user.profile.toBeInvoicedFilters.invoiced,
      }
    }
    return null
  }

  render() {
    const profile = this.props.user.profile
    const customers = this.props.customers.list
    return (
      <ul className="filters-list">
        {uiConstants.toBeInvoicedFilters.map((f, index) => {
          return (
            <li key={index}>
              <button
                className={
                  'btn-flt ' +
                  f.value +
                  (profile.toBeInvoicedFilters[f.value] ? ' active' : '')
                }
                onClick={() => this.turnOffFilter(f)}
              >
                {f.label}
              </button>
            </li>
          )
        })}
        <li>
          <select
            onChange={(event) =>
              this.setState({ customer: event.target.value }, () => {
                this.saveFilters()
              })
            }
            value={this.state.customer}
          >
            <option value="">[Cliente]</option>
            {(customers || []).map((c, index) => {
              return (
                <option key={index} value={c.id}>
                  {c.name}
                </option>
              )
            })}
          </select>
        </li>
        <li>
          <input
            type="text"
            placeholder="Cerca"
            onChange={(event) =>
              this.setState({ searchTerm: event.target.value }, () => {
                this.saveFilters(true)
              })
            }
            value={this.state.searchTerm}
            onBlur={() => this.saveFilters()}
          />
        </li>
        <li>
          <label className="switch">
            <input
              type="checkbox"
              onChange={(event) =>
                this.setState({ expanded: event.target.checked }, () => {
                  this.saveFilters()
                })
              }
              checked={this.state.expanded}
            />
            <span className="slider"></span>
          </label>
          Espandi
        </li>
        <li>
          <label className="switch">
            <input
              type="checkbox"
              onChange={(event) =>
                this.setState({ invoiced: event.target.checked }, () => {
                  this.saveFilters()
                })
              }
              checked={this.state.invoiced}
            />
            <span className="slider"></span>
          </label>
          Da fatturare
        </li>
      </ul>
    )
  }
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {
  localSaveToBeInvoicedFilter,
  saveToBeInvoicedFilter,
})(TBIFilters)
