import React, { Component } from 'react'

import { connect } from 'react-redux'

import TopInfo from './TopInfo'
import UserLine from './Users/UserLine'

import { saveUser } from '../actions'

class ManageUsers extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return (
      <div className="full-container">
        <TopInfo title={'Impostazioni Utenti'} hideInfo={true} />
        <div className="users-container">
          {(this.props.users || []).map((user, i) => (
            <UserLine
              key={i}
              user={user}
              id={this.props.profile.id}
              saveUser={this.props.saveUser}
            ></UserLine>
          ))}
        </div>
      </div>
    )
  }
}

function mapStatetoProps({ user: { list, profile } }) {
  let users = []
  if (list) {
    users = list
      // .concat({
      //   ...profile
      // })
      .map((user) => ({
        id: user.id,
        email: user.email,
        displayName: user.displayName,
        avatar: user.avatar,
        options: {
          admin: user.admin,
          estimates: user.estimates,
          dashboard: user.dashboard,
          invoices: user.invoices,
          orders: user.orders,
          projects: user.projects,
          registry: user.registry,
          calendar: user.calendar,
          vacation: user.vacation
        },
        ral: user.ral,
        hours: user.hours,
        manage_vacation: user.manage_vacation
      }))
  }

  return { users, profile }
}

export default connect(mapStatetoProps, { saveUser }, null, {
  areStatesEqual: (next, prev) => prev.user.list === next.user.list
})(ManageUsers)
