import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import EstimatesFilter from './Estimates/EstimatesFilter'
import EstimateLine from './Estimates/EstimateLine'
import { estimatesHelpers } from '../helpers'
import { uiConstants } from '../constants'
import { commonHelpers } from '../helpers'

class Estimates extends Component {
  filterList(list, status) {
    const { estimatesFilters } = this.props.user.profile
    return estimatesHelpers.filterItems(list, status, estimatesFilters)
  }

  calculateTotal(list) {
    let total = 0

    list.forEach((e) => {
      const v = e.v[e.v.length - 1]
      const value = v.configurator
        ? v.configurator.totalCustom
          ? parseFloat(v.configurator.totalCustom)
          : 0
        : 0
      total += value
    })

    return commonHelpers.toMoney(total, true)
  }

  render() {
    const { list, subtotal } = this.props.estimates
    const { estimatesFilters } = this.props.user.profile

    // console.log("LIST ==>", list);

    return (
      <>
        <h1>Preventivi</h1>
        <Link to="/estimates/new" className="lnk-new">
          <i className="fas fa-plus"></i> Nuovo
        </Link>
        <EstimatesFilter subtotal={subtotal} />

        {uiConstants.estimatesFilters.map((s, index) => {
          return (
            estimatesFilters[s.value] && (
              <div key={index}>
                <div className={'title-section ' + s.value}>
                  {s.label}{' '}
                  <span>
                    {this.calculateTotal(this.filterList(list, s.value))}
                  </span>
                </div>
                {this.filterList(list, s.value)
                  .sort((a, b) => (a.number > b.number ? 1 : -1))
                  .map((e, index2) => {
                    return <EstimateLine key={index2} e={e} />
                  })}
              </div>
            )
          )
        })}
      </>
    )
  }
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {})(Estimates)
