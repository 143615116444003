import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import history from './history'

/* redux */
import { Provider } from 'react-redux'
import { store } from './store'

import Layout from './components/Layout'
import AuthLayout from './components/AuthLayout'
import Dashboard from './components/Dashboard'
import Login from './components/Login'
import Estimates from './components/Estimates'
import EstimatesEditor from './components/Estimates/EstimatesEditor'
import Settings from './components/Settings'
import Orders from './components/Orders'
import ToBeInvoiced from './components/ToBeInvoiced'
import Invoices from './components/Invoices'
import Projects from './components/Projects'
// import ProjectWbs from './components/Projects/ProjectWbs.jsx'
import Sprint from './components/Projects/Sprint'
import ProjectEditor from './components/Projects/ProjectEditor'
import AdminPage from './components/AdminPage'
import Economics from './components/Economics'
import Remediation from './components/Remediation'
import Timesheets from './components/Timesheets'
import Calendar from './components/Calendar';
import Vacation from './components/Vacation';
import Registry from './components/Registry'
import RegistryEditor from './components/Registry/RegistryEditor'

/* moment */
import moment from 'moment-timezone'
import 'moment/locale/it'
moment.tz.setDefault('Europe/London')

function App() {
  return (
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <Router history={history}>
          <Route
            render={({ location }) => (
              <Layout location={location}>
                <Switch>
                  <Route exact path="/" component={Login} />
                  <AuthLayout>
                    <Route exact path="/economics" component={Economics} />
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route exact path="/estimates" component={Estimates} />
                    <Route exact path="/remediation" component={Remediation} />
                    <Route
                      exact
                      path="/estimates/:id"
                      component={EstimatesEditor}
                    />
                    <Route
                      exact
                      path="/estimates/:id/:number"
                      component={EstimatesEditor}
                    />
                    <Route
                      exact
                      path="/estimates/:id/:number/:version"
                      component={EstimatesEditor}
                    />
                    <Route exact path="/orders" component={Orders} />
                    <Route
                      exact
                      path="/orders/:id/:number/:version"
                      component={EstimatesEditor}
                    />
                    <Route exact path="/settings" component={Settings} />
                    <Route
                      exact
                      path="/tobeinvoiced"
                      component={ToBeInvoiced}
                    />
                    <Route exact path="/invoices" component={Invoices} />
                    <Route exact path="/projects" component={Projects} />
                    {/* <Route exact path="/projects/:id/" component={ProjectWbs} /> */}
                    <Route
                      exact
                      path="/projects/:id/"
                      component={ProjectEditor}
                    />
                    <Route
                      exact
                      path="/projects/:projectid/sprints/:sprintid"
                      component={Sprint}
                    />
                    <Route exact path="/timesheets" component={Timesheets} />
                    <Route exact path="/calendar" component={Calendar} />
                    <Route exact path="/vacation" component={Vacation} />
                    <Route exact path="/manage_users" component={AdminPage} />
                    <Route exact path="/registry" component={Registry} />
                    <Route
                      exact
                      path="/registry/:collection/:id"
                      component={RegistryEditor}
                    />
                  </AuthLayout>
                </Switch>
              </Layout>
            )}
          />
        </Router>
      </DndProvider>
    </Provider>
  )
}

export default App
