import { store } from '../store'

export const colleaguesHelpers = {
  getNameByUid,
  getUserByUid,
}

function getNameByUid(uid) {
  const s = store.getState()
  const me = s.user.profile
  if (uid === me.uid) {
    return me.displayName
  }

  const colleagues = s.colleagues
  if (colleagues === null) {
    return null
  }
  const list = colleagues.list
  if (list === null) {
    return null
  }
  const e = list.filter((x) => x.id === uid)
  if (e.length > 0) {
    return e[0].displayName
  }
  return uid
}

function getUserByUid(uid) {
  const s = store.getState()
  const me = s.user.profile
  if (uid === me.uid || uid === me.id) {
    return me
  }
  const colleagues = s.colleagues
  if (colleagues === null) {
    return null
  }
  const list = colleagues.list
  if (list === null) {
    return null
  }
  const e = list.filter((x) => x.uid === uid)
  if (e.length > 0) {
    return e[0]
  } else {
    const e = list.filter((x) => x.id === uid)
    if (e.length > 0) {
      return e[0]
    }
  }
  return uid
}
