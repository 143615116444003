import React, { /*useEffect,*/ useState } from 'react'
import { connect } from 'react-redux'
import { customersHelpers, estimatesHelpers } from '../../helpers'

import { loadAllEstimates } from '../../actions'

const ProjectEstimateFilter = (props) => {
  //   const { loadAllEstimates } = props

  const [year, setYear] = useState('')
  const [text, setText] = useState('')
  const [, setEstimate] = useState('')

  //   useEffect(() => {
  //     loadAllEstimates()
  //   }, [loadAllEstimates])

  //   useEffect(() => {
  //     if (estimate) props.onPickEstimate(estimate)
  //   }, [estimate, props.onPickEstimate])

  const changeYearHandler = (event) => {
    setYear(event.target.value)
  }

  const changeTextHandler = (event) => {
    setText(event.target.value)
  }

  const changeEstimateHandler = (event) => {
    setEstimate(event.target.value)

    props.onPickEstimate(event.target.value)
  }

  return (
    <div className="estimate-filter-line">
      <select
        className="select-year"
        name="estimate_year"
        onChange={changeYearHandler}
      >
        <option value="" key="null">
          [Anno]
        </option>
        {estimatesHelpers
          .getEstimatesYears(props.estimatesAll.list || [])
          .sort((a, b) => (a > b ? 1 : -1))
          .map((i) => (
            <option value={i} key={i}>
              {i}
            </option>
          ))}
      </select>
      <input
        placeholder="Cerca"
        type="text"
        name="estimate_filter"
        onChange={changeTextHandler}
        disabled={!year}
      />
      <select
        name="estimate_id"
        onChange={changeEstimateHandler}
        disabled={!year}
      >
        <option value="" key="null">
          [Preventivo]
        </option>
        {estimatesHelpers
          .filterEstimates(props.estimatesAll.list || [], year, text)
          .sort((a, b) => (a.number > b.number ? 1 : -1))
          .map((i) => (
            <option value={i.id} key={i.id}>
              {`${i.number} - ${customersHelpers.getNameByUid(
                i.v[i.v.length - 1].info.customer
              )} - ${i.v[i.v.length - 1].info.title}`}
            </option>
          ))}
      </select>
    </div>
  )
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {
  loadAllEstimates
})(ProjectEstimateFilter)
