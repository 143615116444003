import React, { useEffect, useState } from 'react'
import moment from 'moment'

const VacationInput = (props) => {
  const [value, setValue] = useState('')
  const { month, type, startValue, day } = props

  useEffect(() => {
    setValue(startValue ? startValue.toFixed(2) : '')
  }, [startValue])

  const changeHandler = (event) => {
    setValue(event.target.value)
  }

  const onBlurHandler = () => {
    if (!(!startValue && !value) && parseFloat(value) !== startValue) {
      const date = moment
        .unix(month)
        .utc()
        .startOf('month')
        .startOf('day')
        .add(day - 1, 'days')
        .unix()

      const newRec = {
        day: date,
        type_id: type.id,
        value: value && value !== 0 && value !== '0' ? +value : null
      }

      props.onUpdate(newRec)
    }
  }

  return (
    <input
      type="number"
      name="hours"
      min="0"
      max="24"
      step="0.1"
      value={value}
      onChange={(e) => changeHandler(e)}
      onBlur={onBlurHandler}
    />
  )
}

export default VacationInput
