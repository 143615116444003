import React, { Component } from 'react'
import { connect } from 'react-redux'
import firebase from '../firestore'
import history from '../history'

class Layout extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0)
    }
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((result) => {
      if (result) {
        if (this.props.location.pathname === '/') {
          history.push('/dashboard')
        }
      }
    })
  }

  render() {
    return <>{this.props.children}</>
  }
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {})(Layout)
